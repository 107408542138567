import { Injectable } from '@angular/core';

import * as moment from 'moment';
import * as _ from 'lodash';

//import { VisaType, teacherTraineeStudentVisas, teacherTraineeVisas, ForJVisas} from './visit/Visa';
import { VisaType, teacherTraineeVisas, ForJVisas} from './visit/Visa';
import { Visit } from './visit/Visit';
import { YearSummary } from './YearSummary';


//create list of years for dropdown
const currentYear = new Date().getFullYear()
var yearList = []; 
for (var i = currentYear+1; i >= currentYear-40; i--) {
    yearList.push(i);
}

export const selectableTaxYears = yearList;

export const binChoices=[
    'Yes',
    'No',
]

export const selectYesNoChoices=[
    'Select...',
    'Yes',
    'No',
]

export enum NonResidentStatusText {
    NonResident = 'Nonresident Alien',
    FullYearResident = 'Resident Alien',
    DualStatusAlien = 'Dual-Status Alien'
}

export interface NonResidentStatus {
    status: NonResidentStatusText;
    daysPresentInTaxYear: number;
    daysPresentYearPriorToTaxYear: number;
    daysPresent2YearsPriorToTaxYear: number;
    daysExemptInTaxYear: number;
    residencyStartDate?: string;
    residencyEndDate?: string;
    exempt3Of6Count: number;
    exemptAsForJInTaxYear: boolean;
    presentAsTeacherTraineeYears: number[];
    allExemptYears: number[];
    dualStatusResidentText: boolean;
    taxYear: number;
}

//I THINK I CAN DELETE THIS???
export interface SortedVisits {
    sortedVisits: Visit;
}

export interface SortedVisitsInTaxYear {
    sortedVisitsInTaxYear: Visit[];
}

@Injectable({
    providedIn: 'root'
})

export class aminraService {

    constructor () {}

    //stateVisits: Visit[];
    //sortedVisitsCopy: Visit[] = []

    /**
     * Checks if there is any overlap in an array of Visits
     * @param visits - Visit array
     */
    doVisitsOverlap(visits: Visit[]): boolean {
        const sortedVisits: Visit[] = this.sortVisitsChronologically(visits);
        for (let index = 1; index < sortedVisits.length; index++) {
            const previous = sortedVisits[index - 1];
            const current = sortedVisits[index];

            if (!previous.getExit() && current.getEntry()) {
                return true;
            }
            if ((previous.getExit().startOf("d")).isSameOrAfter((current.getEntry().startOf("d"))))
                return true;
        }
        return false;
    }

//    setVisits(visits: Visit[]) {
//        this.stateVisits = visits
//    }
    

////I THINK I CAN DELETE THIS???
//    getSortedVisits() {
//        return this.sortVisitsChronologically(this.stateVisits);
//    }


    sortedTaxYearVisits(visits: Visit[], taxYear: number): SortedVisitsInTaxYear {
        // Catch anything unexpected
        try {
            const sortedVisits = this.sortVisitsChronologically(visits);
            let allPresentYears: number[] = [];
            let allExemptYears: number[] = [];

            var sortedVisitsInTaxYear: Visit[] = [];

            // For each visit determine exempt years and build presentYears array
            sortedVisits.forEach((visit: Visit) => {
                this.setNonExemptYearsForVisit(visit, allPresentYears, allExemptYears, taxYear, false);
                allPresentYears = _.union(visit.getYearsPresent(), allPresentYears);
                allExemptYears = _.union(visit.getExemptYears(), allExemptYears);
            });
            // Sort in descending order
            allPresentYears.sort();
            allPresentYears.reverse();

            // Add only the visits for the Tax Year and replace any null exit value with year end date
            sortedVisits.forEach((visit: Visit) =>  {
                if (visit.getYearsPresent().includes(taxYear)) {
                    if (visit.exit == null) {
                        visit.exit = moment([taxYear]).endOf('year')
                    }
                    sortedVisitsInTaxYear.push(visit)
                }
            })

            sortedVisitsInTaxYear.forEach((usVisit: Visit) => {
                //for (let USvisitNo=0; USvisitNo < n; USvisitNo++) {
                  if (usVisit.entry.isBefore(moment([taxYear]).startOf('year'))) {
                    usVisit.entry = moment([taxYear]).startOf('year')
                  }
                  if (usVisit.exit.isAfter(moment([taxYear]).endOf('year'))) {
                    usVisit.exit = moment([taxYear]).endOf('year')
                  }
                })
        return {
            sortedVisitsInTaxYear
        }
    
        } catch (e) {
            return null;
        }
    }


    /**
     * This is the function that performs the Nonresident Status Check given all lifetime visits and a selected tax year
     * @param visits - An array of Visit objects representing a visit
     * @param taxYear - Tax year to calculate residency status for
     **/
    isNonresident(visits: Visit[], taxYear: number, FIncomeQYesYes: boolean): NonResidentStatus {
        // Catch anything unexpected
        try {
            // Sort Visits
            let visas: string[] = [];
            const sortedVisits = this.sortVisitsChronologically(visits);
            let allPresentYears: number[] = [];
            let allExemptYears: number[] = [];
            const yearSummaries: YearSummary[] = [];
            // For each visit determine exempt years and build presentYears array
            sortedVisits.forEach((visit: Visit) => {
                this.setNonExemptYearsForVisit(visit, allPresentYears, allExemptYears, taxYear, FIncomeQYesYes);
                allPresentYears = _.union(visit.getYearsPresent(), allPresentYears);
                allExemptYears = _.union(visit.getExemptYears(), allExemptYears);
                visas.push(visit.getVisa())
            });
            // Sort in descending order
            allPresentYears.sort();
            allPresentYears.reverse();

            // Create a YearSummary for each present year
            allPresentYears.forEach((year: number) => {
                // For each YearSummary add visits that are present in that year.
                const visitsInThisYear: Visit[] = _.filter(visits, (visit: Visit) => {
                    return visit.getYearsPresent().includes(year);
                });
                yearSummaries.push(new YearSummary(year, visitsInThisYear));
            });


            //make array of data for each year
            let visitsSummarizedByYear = [];
            let GCVisaEntry: string = ""
            for (let yr=0; yr < yearSummaries.length; yr++) {
                visitsSummarizedByYear.push(new Array(1));
                visitsSummarizedByYear[yr][0] = yearSummaries[yr].getYear();
                for (var visitNo=0; visitNo < yearSummaries[yr]['visits'].length; visitNo++) {
                    visitsSummarizedByYear[yr].push(yearSummaries[yr].getVisits()[visitNo].getVisa());
                    visitsSummarizedByYear[yr].push(yearSummaries[yr].getVisits()[visitNo].getVisaType());
                    if (yearSummaries[yr].getVisits()[visitNo].getVisa() == "Green Card (Permanent Resident)") {
                        GCVisaEntry = yearSummaries[yr].getVisits()[visitNo].getEntry().format('MM/DD/YYYY');
                    }
                    if (yearSummaries[yr].getVisits()[visitNo].getExemptYears().length === 1) {
                        visitsSummarizedByYear[yr].push("Exempt");
                    }
                    else {
                        visitsSummarizedByYear[yr].push("NonExempt");
                    }
                }
            }

            //check if exempt as teacher, trainee or student for any part of 3 or fewer of the preceding 6 years - FOREIGN INCOME Q
            var exempt3Of6Count: number=0;
            for (let yr=0; yr < visitsSummarizedByYear.length; yr++) {
                if (visitsSummarizedByYear[yr][0] >= (taxYear-6) && visitsSummarizedByYear[yr][0] < taxYear) {
                    for (let yrItem=0; yrItem < visitsSummarizedByYear[yr].length; yrItem++) {
                        if (teacherTraineeVisas.includes(visitsSummarizedByYear[yr][yrItem])) {
                            exempt3Of6Count++;
                            break;                     
                        }

                    }
                }
            }

            //check if present in the United States as a teacher or trainee in any of the 6 prior years.
            //ANY F/J/M/Q Exempt
            //To not be exempt you need at least 2 of the 6
            var presentAsTeacherTraineeYears: number[] = [];
            for (let yr=0; yr < visitsSummarizedByYear.length; yr++) {
                if (visitsSummarizedByYear[yr][0] >= (taxYear-6) && visitsSummarizedByYear[yr][0] < taxYear) {
                    for (let yrItem=0; yrItem < visitsSummarizedByYear[yr].length; yrItem++) {
                        if (teacherTraineeVisas.includes(visitsSummarizedByYear[yr][yrItem])) {
                            presentAsTeacherTraineeYears.push(visitsSummarizedByYear[yr][0]);
                            break;                     
                        }

                    }
                }
            }
            //ADDED DURING TESTING OF 8843 - Showing Yes to exempt in 2 of 6, but should be no (if present only in a single year)
            if (presentAsTeacherTraineeYears.length >= 1) {
                sessionStorage.teacherTrainee2Of6 = "Yes"
            }
            
            //Check if Exempt for Tax Year under F or J visa (to provide notice about filing Form 8843)
            var exemptAsForJInTaxYear = false;
            for (let yr=0; yr < visitsSummarizedByYear.length; yr++) {
                if (visitsSummarizedByYear[yr][0] == taxYear) {
                    if (ForJVisas.some(i => visitsSummarizedByYear[yr].includes(i)) && visitsSummarizedByYear[yr].includes('Exempt')) {
                        exemptAsForJInTaxYear = true;
                        break;
                    }
                }
            }

            // Check if visa was F/J/M/Q and then there was enother entry with a different visa
            sessionStorage.FJMQChange = 'No'
            let visaType: string = null;
            if (visas.length > 1) {
                for (let i=0; i < visas.length; i++) {
                    if (visas[i].substring(0,1) == "F" || visas[i].substring(0,1) =="J" || visas[i].substring(0,1) =="M" || visas[i].substring(0,1) =="Q") {
                        visaType = visas[i]
                        break
                    }
                }
                for (let i=0; i < visas.length; i++) {
                    if (visas[i] != visaType) {
                        sessionStorage.FJMQChange = 'Yes'
                    }
                }
            }

            //Check for change in visa type without leaving the country
            const clonedVisits = this.sortVisitsChronologically(visits);
            sessionStorage.visaChangeWithoutLeaving = "No"
            sessionStorage.visaChangeWithoutLeaving1a = ''
            sessionStorage.visaChangeWithoutLeaving1b = ''
            sessionStorage.visaChangeWithoutLeavingExitInfo = ''
            sessionStorage.visaChangeWithoutLeavingEntryInfo = ''
            sessionStorage.visaChangeWithoutLeavingEntryVisa = ''

            let usedVisas: string[] = []
            for (let i=0; i < visas.length; i++) {
                if (!usedVisas.includes(visas[i])) {
                    usedVisas.push(visas[i])
                }
            }
            if (usedVisas.length > 1) {
                if (clonedVisits[clonedVisits.length - 1].getVisa() != clonedVisits[clonedVisits.length - 2].getVisa()) {
                    if (clonedVisits[clonedVisits.length - 1].getEntry().startOf('d').diff(clonedVisits[clonedVisits.length - 2].getExit().startOf('d'), 'd') == 1){
                        sessionStorage.visaChangeWithoutLeaving = "Yes"
                        sessionStorage.visaChangeWithoutLeaving1a = sortedVisits[sortedVisits.length - 2].getVisa().toString().substring(0,3) + ' ' + clonedVisits[clonedVisits.length - 2].getEntry().format('MM/DD/YYYY').toString()
                        sessionStorage.visaChangeWithoutLeaving1b = sortedVisits[sortedVisits.length - 1].getVisa().toString().substring(0,3) + ' ' + clonedVisits[clonedVisits.length - 1].getEntry().format('MM/DD/YYYY').toString() + ' ' + sortedVisits[sortedVisits.length - 2].getVisa().toString().substring(0,3)
                        sessionStorage.visaChangeWithoutLeavingExitInfo = sortedVisits[sortedVisits.length - 2].getVisa().toString() + ' ' + clonedVisits[clonedVisits.length - 2].getExit().format('MM/DD/YYYY').toString()
                        sessionStorage.visaChangeWithoutLeavingEntryInfo = sortedVisits[sortedVisits.length - 1].getVisa().toString() + ' ' + clonedVisits[clonedVisits.length - 1].getEntry().format('MM/DD/YYYY').toString()
                        sessionStorage.visaChangeWithoutLeavingEntryVisa = sortedVisits[sortedVisits.length - 1].getVisa().toString()
                    }   
                }
            }


            //Get visa types held at the end of each of past 6 years (for Form 8843)
            sessionStorage.visaAtEndOfYearTY1 = ''
            sessionStorage.visaAtEndOfYearTY2 = ''
            sessionStorage.visaAtEndOfYearTY3 = ''
            sessionStorage.visaAtEndOfYearTY4 = ''
            sessionStorage.visaAtEndOfYearTY5 = ''
            sessionStorage.visaAtEndOfYearTY6 = ''
           
            yearSummaries.forEach((yearSummary: YearSummary) => {
                let yr = yearSummary.getYear()
                let relativeYr = yr - taxYear
                if (relativeYr == -1) {sessionStorage.visaAtEndOfYearTY1 = String(yearSummary.getVisits()[yearSummary.visits.length-1].getVisa()).substring(0,3)}
                if (relativeYr == -2) {sessionStorage.visaAtEndOfYearTY2 = String(yearSummary.getVisits()[yearSummary.visits.length-1].getVisa()).substring(0,3)}
                if (relativeYr == -3) {sessionStorage.visaAtEndOfYearTY3 = String(yearSummary.getVisits()[yearSummary.visits.length-1].getVisa()).substring(0,3)}
                if (relativeYr == -4) {sessionStorage.visaAtEndOfYearTY4 = String(yearSummary.getVisits()[yearSummary.visits.length-1].getVisa()).substring(0,3)}
                if (relativeYr == -5) {sessionStorage.visaAtEndOfYearTY5 = String(yearSummary.getVisits()[yearSummary.visits.length-1].getVisa()).substring(0,3)}
                if (relativeYr == -6) {sessionStorage.visaAtEndOfYearTY6 = String(yearSummary.getVisits()[yearSummary.visits.length-1].getVisa()).substring(0,3)}
            })
            

            const yearSummariesForSPT = this.getYearSummariesForSPT(yearSummaries, taxYear, allPresentYears);
            const yearSummariesForSPTFor1YearAfterTaxYear = this.getYearSummariesForSPT(yearSummaries, taxYear+1, allPresentYears);
            
            // Perform SPT on taxYear, taxYear - 1 and taxYear - 2 to determine status
            const passedSPT: boolean = this.substantialPresenceTest(yearSummariesForSPT, taxYear);
            const passedSPTFor1YearAfterTaxYear: boolean = this.substantialPresenceTest(yearSummariesForSPTFor1YearAfterTaxYear, taxYear+1);

            // Get present days for the above years
            const yearSummaryForTaxYear: YearSummary = this.getYearSummaryForYear(yearSummaries, taxYear);
            const yearSummaryForYearPriorToTaxYear: YearSummary = this.getYearSummaryForYear(yearSummaries, taxYear - 1);
            const yearSummaryFor2YearsPriorToTaxYear: YearSummary = this.getYearSummaryForYear(yearSummaries, taxYear - 2);

            const daysPresentInTaxYear = yearSummaryForTaxYear ? yearSummaryForTaxYear.getNumberOfPresentDays() : 0;
            const daysPresentYearPriorToTaxYear = yearSummaryForYearPriorToTaxYear ? yearSummaryForYearPriorToTaxYear.getNumberOfPresentDays() : 0;
            const daysPresent2YearsPriorToTaxYear = yearSummaryFor2YearsPriorToTaxYear ? yearSummaryFor2YearsPriorToTaxYear.getNumberOfPresentDays() : 0;

            // Get the Non-exempt days for the current tax year
            const daysExemptInTaxYear = yearSummaryForTaxYear ? yearSummaryForTaxYear.getNumberOfExemptDays() : 0;
            // Sort the current tax years visits and determine the residency start date and residency end date
            const nonExemptVisits: Visit[] = yearSummaryForTaxYear ? this.sortVisitsChronologically(yearSummaryForTaxYear.getNonExemptVisits()) : [];
            let residencyStartDate: string;
            let residencyEndDate: string;
            let status: NonResidentStatusText;

            if (GCVisaEntry == "") {

                if (nonExemptVisits.length > 0 && passedSPT) {
                    const lastIndex: number = nonExemptVisits.length - 1;
                    // residency start date
                    // entry date in year
                    if (nonExemptVisits[0].getEntry().year() === taxYear) {
                        residencyStartDate = nonExemptVisits[0].getEntry().format('MM/DD/YYYY');
                    // entry date before year
                    } else {
                        residencyStartDate = moment(`${taxYear}-01-01`).format('MM/DD/YYYY');
                    }
                    // get residency end date
                    if (nonExemptVisits[lastIndex].getExit() && nonExemptVisits[lastIndex].getExit().year() === taxYear) {
                        residencyEndDate = nonExemptVisits[lastIndex].getExit().format('MM/DD/YYYY');
                    }
                }


                var dualStatusResidentText = false;

                if (passedSPT) {
                    if (passedSPTFor1YearAfterTaxYear) {
                        residencyEndDate = moment(`${taxYear}-12-31`).format('MM/DD/YYYY');
                    }
                    if (residencyStartDate === `01/01/${taxYear}` && (!residencyEndDate || residencyEndDate === `12/31/${taxYear}`)) {
                        status = NonResidentStatusText.FullYearResident;
                    }
                    else {
                        const yearSummariesForPriorYearSPT: YearSummary[] = this.getYearSummariesForSPT(
                            yearSummaries,
                            taxYear - 1,
                            allPresentYears
                        );
                        const priorYearPassedSPT: boolean = this.substantialPresenceTest(yearSummariesForPriorYearSPT, taxYear - 1);
                        if (priorYearPassedSPT) {
                            residencyStartDate = `01/01/${taxYear}`;
                            if (residencyEndDate && residencyEndDate != `12/31/${taxYear}`) {
                                status = NonResidentStatusText.DualStatusAlien;
                            } else {
                                status = NonResidentStatusText.FullYearResident;
                            }
                        } else {
                            status = NonResidentStatusText.DualStatusAlien;
                        }
                    }
                }
                else {
                    status = NonResidentStatusText.NonResident;
                }

            }

            else {
                if (nonExemptVisits.length > 0 && passedSPT) {
                    const lastIndex: number = nonExemptVisits.length - 1;
                    // residency start date
                    // entry date in year
                    if (nonExemptVisits[0].getEntry().year() === taxYear) {
                        residencyStartDate = nonExemptVisits[0].getEntry().format('MM/DD/YYYY');
                    // entry date before year
                    } else {
                        residencyStartDate = moment(`${taxYear}-01-01`).format('MM/DD/YYYY');
                    }
                    // get residency end date
                    if (nonExemptVisits[lastIndex].getExit() && nonExemptVisits[lastIndex].getExit().year() === taxYear) {
                        residencyEndDate = nonExemptVisits[lastIndex].getExit().format('MM/DD/YYYY');
                    }
                }

                var dualStatusResidentText = false;

                if (passedSPT) { //Do meet SPT for tax year
                    if (passedSPTFor1YearAfterTaxYear) {
                        residencyEndDate = moment(`${taxYear}-12-31`).format('MM/DD/YYYY');
                    }
                    if (residencyStartDate === `01/01/${taxYear}` && (!residencyEndDate || residencyEndDate === `12/31/${taxYear}`)) {
                        status = NonResidentStatusText.FullYearResident;
                    }
                    else {
                        const yearSummariesForPriorYearSPT: YearSummary[] = this.getYearSummariesForSPT(
                            yearSummaries,
                            taxYear - 1,
                            allPresentYears
                        );
                        const priorYearPassedSPT: boolean = this.substantialPresenceTest(yearSummariesForPriorYearSPT, taxYear - 1);
                        if (priorYearPassedSPT) {
                            residencyStartDate = `01/01/${taxYear}`;
                            if (residencyEndDate && residencyEndDate != `12/31/${taxYear}`) {
                                status = NonResidentStatusText.DualStatusAlien;
                            } else {
                                status = NonResidentStatusText.FullYearResident;
                            }
                        } else {
                            status = NonResidentStatusText.DualStatusAlien;
                        }
                    }
                }
                else {  //Do not meet SPT for tax year
                    residencyStartDate = GCVisaEntry;
                    residencyEndDate = `12/31/${taxYear}`
                    status = NonResidentStatusText.DualStatusAlien
                }
            }
           
            //store visit data for the PDF guide
            i = 0;
            sortedVisits.forEach((visit: Visit) => {
                i += 1;
                sessionStorage['visitVisa' + i] = String(visit.getVisa());
                sessionStorage['visitEntry' + i] = String(visit.getEntry().format('MM/DD/YYYY'));
                if (visit.getExit() == null) {sessionStorage['visitExit' + i] = 'No date'}
                else {sessionStorage['visitExit' + i] = String(visit.getExit().format('MM/DD/YYYY'))}
            })
            sessionStorage.visitEntryLast = sessionStorage['visitEntry' + i]
            sessionStorage.visitCount = i;
            sessionStorage.visaType = sessionStorage['visitVisa' + i];
            if (sessionStorage.visaType != 'Non-exempt visa (B1/B2, TN, H1-b, etc.)' && sessionStorage.visaType != 'Other Exempt visa (A, G, NATO, etc.)') {
                sessionStorage.FJMQVisaType = 'Yes';
            }

            return {
                status,
                daysPresentInTaxYear,
                daysPresentYearPriorToTaxYear,
                daysPresent2YearsPriorToTaxYear,
                daysExemptInTaxYear,
                residencyStartDate,
                residencyEndDate,
                exempt3Of6Count,
                presentAsTeacherTraineeYears,
                exemptAsForJInTaxYear,
                allExemptYears,
                dualStatusResidentText,
                taxYear
            };
            // entry date in year
        } catch (e) {
            return null;
        }
    }
    /**
     * For specific visit set what years of that visit are considered non-exempt
     * @param visit - Visit we are aggregating non exempt years for
     * @param yearsPresentPriorToVisit - Array of all years that were marked as present prior to this Visit
     * @param taxYear - Tax year, so we don't include chosen tax year in non exempt years
     */
    setNonExemptYearsForVisit(visit: Visit, yearsPresentPriorToVisit: number[], yearsExemptPriorToVisit: number[], taxYear: number, FIncomeQYesYes: boolean): Visit {
        // _.union prior years with years present in visit
        const presentYearsIncludingVisit: number[] = _.union(yearsPresentPriorToVisit, visit.getYearsPresent());
        const yearsExemptIncludingVisit: number[] = _.union(yearsExemptPriorToVisit, visit.getExemptYears());
        presentYearsIncludingVisit.sort();
        // for each year present in visit
        for (let year of presentYearsIncludingVisit) {
            if (visit.getYearsPresent().includes(year) && year <= taxYear+1) {
                if (!this.isYearExempt(yearsExemptIncludingVisit, year, visit.getVisaType(), FIncomeQYesYes)) {
                    visit.addNonExemptYear(year);
                    // Once nonExempt all years following in this visit will also be non exempt so add them
                    //while (year < Math.max(...visit.getYearsPresent()) && year < taxYear) {
                        //year++;
                        //visit.addNonExemptYear(year);
                    //}
                    //return visit;
                }
                else if (visit.getYearsPresent().includes(year)) {
                    if (visit.getVisaType() != VisaType.Exempt) {
                        visit.addExemptYear(year);
                        if (!yearsExemptIncludingVisit.includes(year)) {
                            yearsExemptIncludingVisit.push(year);
                        }
                    }
                }
            }
            if (!presentYearsIncludingVisit.includes(year)) {
                presentYearsIncludingVisit.push(year);
            }
        }
        return visit;
    }

    /**
     * Determine if a year is exempt depending on its VisaType and years prior marked as exempt
     * @param yearsPresent - Years exempt
     * @param yearBeingChecked - The year exemption is being calculated for
     * @param visaType - The type of Visa (0=Student, 1=NonStudent, 2=NonExempt, 3=Exempt)
     */
    isYearExempt(yearsPresent: number[], yearBeingChecked: number, visaType: VisaType, FIncomeQYesYes: boolean): boolean {
        if (FIncomeQYesYes) {
            return true;
        }
        if (visaType === VisaType.NonExempt) {
            return false;
        } else if (visaType === VisaType.Exempt) {
            return true;
            //return false;
        } else if (visaType === VisaType.Student) {
            return _.filter(yearsPresent, (year: number) => year < yearBeingChecked).length < 5;
        } else {
            return this.getYearsInRange(yearBeingChecked - 6, yearBeingChecked, yearsPresent).length < 2;
        }
    }

    /**
     * Helper function that gets years in a range
     * @param minInclusive - lower end of the range will be included if found
     * @param maxExclusive higher end of the range, will be excluded from result
     * @param years - list of years to pull range from
     */
    getYearsInRange(minInclusive: number, maxExclusive: number, years: number[]): number[] {
        let yearsInRange: number[] = [];
        // Return a filtered list of YearWithVisa objects that has a year attribute within the specified range
        yearsInRange = _.filter(years, (year: number) => {
            return year >= minInclusive && year < maxExclusive;
        });
        return yearsInRange;
    }

    /**
     * Sort visits in order from latest entry date to most recent entry date
     * @param visits - Array of visits to sort
     */
    sortVisitsChronologically(visits: Visit[]): Visit[] {
        if (visits && visits.length > 1) {
            return visits.sort((visitA: Visit, visitB: Visit) => {
                return visitA.getEntry().diff(visitB.getEntry());
            });
        } else {
            return visits;
        }
    }

    /**
     * Return whether the substantial presence test has been passed (meaning they scored over 183 days)
     * @param yearSummaries - YearSummaries for the last 3 years in order from most recent to less recent
     * @param taxYear - Tax year being calculated for
     * @param allPresentYears - All present years
     */
    substantialPresenceTest(yearSummariesForSubstantialPresenceTest: YearSummary[], taxYear: number): boolean {
        let substantialPresenceTestDays = 0;
        let daysPresentInTaxYear = 0;
        // For each YearSummary add the number of exempt days
        yearSummariesForSubstantialPresenceTest.forEach((yearSummary: YearSummary) => {
            if (yearSummary.getYear() === taxYear) {
                substantialPresenceTestDays += yearSummary.getNumberOfNonExemptDays();
                daysPresentInTaxYear = yearSummary.getNumberOfPresentDays();
            } else if (yearSummary.getYear() === taxYear - 1) {
                substantialPresenceTestDays += Math.ceil(yearSummary.getNumberOfNonExemptDays() / 3);
            } else if (yearSummary.getYear() === taxYear - 2) {
                substantialPresenceTestDays += Math.ceil(yearSummary.getNumberOfNonExemptDays() / 6);
            }
        });
        return substantialPresenceTestDays >= 183 && daysPresentInTaxYear >= 31;
    }

    /**
     * Gets the specified YearSummaries for SPT calculation taxYear, taxYear -1 and taxYear -2
     * @param yearSummaries - All YearSummary objects created for all Visits
     * @param taxYear - Tax year calculating SPT for
     * @param allPresentYears - All present years
     */
    getYearSummariesForSPT(yearSummaries: YearSummary[], taxYear: number, allPresentYears: number[]): YearSummary[] {
        const yearsForSubstantialPresenceTest: number[] = this.getYearsInRange(taxYear - 2, taxYear + 1, allPresentYears);
        return _.filter(yearSummaries, (yearSummary: YearSummary) => {
            return yearsForSubstantialPresenceTest.includes(yearSummary.getYear());
        });
    }

    /**
     * Get the YearSummary for the specified year, if it doesn't exist return null
     * @param yearSummaries - All YearSummary objects that were created out of the Visits
     * @param year - Year for the YearSummary we want
     */
    getYearSummaryForYear(yearSummaries: YearSummary[], year: number): YearSummary {
        const index = _.findIndex(yearSummaries, (yearSummary: YearSummary) => yearSummary.getYear() === year);
        return index >= 0 ? yearSummaries[index] : null;
    }


}
