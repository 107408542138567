import { Injectable } from '@angular/core';

export const visaTypes = [
  'Non-exempt visa (B1/B2, TN, H1-b, etc.)',
  'F-1 Student',
  'J-1 Student',
  'J-1 Professor',
  'J-1 Research Scholar',
  'J-1 Short-term Scholar',
  'J-1 Teacher',
  'J-1 Trainee',
  'J-1 Intern',
  'J-1 Physician',
  'J-1 Specialist',
  'J-1 Summer Work Travel',
  'J-1 Au Pair and EduCare',
  'J-1 Camp Counselor',
  'J-1 Government Visitor',
  'J-1 International Visitor (Dept. of State use)',
  'F-2 Student',
  'J-2 Student',
  'J-2 Non-Student (all other J-2 types)',
  'M-1 Student',
  'M-2 Student',
  'Q-1 Student',
  'Q-1 Non-Student',
  'Q-2 Student',
  'Q-2 Non-Student',
  'Other Exempt visa (A, G, NATO, etc.)',
  'Green Card (Permanent Resident)'
]

export const J1NonTeacherNonStudent = [
  'J-1 Professor',
  'J-1 Research Scholar',
  'J-1 Short-term Scholar',
  'J-1 Trainee',
  'J-1 Intern',
  'J-1 Physician',
  'J-1 Specialist',
  'J-1 Summer Work Travel',
  'J-1 Au Pair and EduCare',
  'J-1 Camp Counselor',
  'J-1 Government Visitor',
  'J-1 International Visitor (Dept. of State use)'
]

export const FJMQStudent = [
  'F-1 Student',
  'J-1 Student',,
  'F-2 Student',
  'J-2 Student',
  'M-1 Student',
  'M-2 Student',
  'Q-1 Student',
  'Q-2 Student'
]

@Injectable({
  providedIn: 'root'
})
export class PgNr8843Service {

  constructor() { }
}
