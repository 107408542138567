import { Component, OnInit } from '@angular/core';
import { statesList, stateInfoCodes } from "./pg-nr06.service"
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-pg-nr06',
  templateUrl: './pg-nr06.component.html',
  styleUrls: ['./pg-nr06.component.scss']
})
export class PGnr06Component implements OnInit {

  selectYesNo = ['Yes', 'No']
  selectTaxResidencyStatus = ['Resident', 'Dual Status', 'Nonresident']
  taxYear: any;
  states = statesList;
  stList = statesList;
  stateLived1: string = null;
  stateLived2: string = null;
  stateLived3: string = null;
  stateLived4: string = null;
  stateLived5: string = null;
  stateIncome1: string = null;
  stateIncome2: string = null;
  stateIncome3: string = null;
  stateIncome4: string = null;
  stateIncome5: string = null;
  stateIncomeArr: any[] = [];
  stateCodesList = stateInfoCodes;
  stateIncomeCode1: string[] = []
  stateIncomeCode2: string[] = []
  stateIncomeCode3: string[] = []
  stateIncomeCode4: string[] = []
  stateIncomeCode5: string[] = []
  stateIncomeCode6: string[] = []
  errorMismatched: boolean = false;
  errorNoneYes: boolean = false;
  showContinueButton: boolean;

  constructor(
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.showContinueButton = false;
    this.taxYear = sessionStorage.taxYear;
    sessionStorage.stateLived1 = ''
    sessionStorage.stateLived2 = ''
    sessionStorage.stateLived3 = ''
    sessionStorage.stateLived4 = ''
    sessionStorage.stateLived5 = ''
    sessionStorage.stateIncome1 = ''
    sessionStorage.stateIncome2 = ''
    sessionStorage.stateIncome3 = ''
    sessionStorage.stateIncome4 = ''
    sessionStorage.stateIncome5 = ''
    this.stateIncomeArr = [];
    this.errorMismatched = false;
    this.errorNoneYes = false;
  }

 entriesChange() {
  if ( (this.stateLived1 != null   || this.stateLived2 != null   || this.stateLived3 != null  || this.stateLived4 != null  || this.stateLived5 != null  ) &&
      (this.stateIncome1 == 'Yes' || this.stateIncome2 == 'Yes' || this.stateIncome3 == 'Yes'|| this.stateIncome4 == 'Yes'|| this.stateIncome5 == 'Yes')) {
    this.showContinueButton = true;
  }
  else {
    this.showContinueButton = false;
  }

 }

  clearEntries() {
    this.stateLived1 = null
    this.stateLived2 = null
    this.stateLived3 = null
    this.stateLived4 = null
    this.stateLived5 = null
    this.stateIncome1 = null
    this.stateIncome2 = null
    this.stateIncome3 = null
    this.stateIncome4 = null
    this.stateIncome5 = null
    sessionStorage.stateLived1 = ''
    sessionStorage.stateLived2 = ''
    sessionStorage.stateLived3 = ''
    sessionStorage.stateLived4 = ''
    sessionStorage.stateLived5 = ''
    sessionStorage.stateIncome1 = ''
    sessionStorage.stateIncome2 = ''
    sessionStorage.stateIncome3 = ''
    sessionStorage.stateIncome4 = ''
    sessionStorage.stateIncome5 = ''
    this.showContinueButton = false
    this.errorMismatched = false;
  }

  onSubmitGoToNextPage() {
    if ( (this.stateLived1 == null && this.stateIncome1 != null) || (this.stateLived1 != null && this.stateIncome1 == null) ||
         (this.stateLived2 == null && this.stateIncome2 != null) || (this.stateLived2 != null && this.stateIncome2 == null) ||
         (this.stateLived3 == null && this.stateIncome3 != null) || (this.stateLived3 != null && this.stateIncome3 == null) ||
         (this.stateLived4 == null && this.stateIncome4 != null) || (this.stateLived4 != null && this.stateIncome4 == null) ||
         (this.stateLived5 == null && this.stateIncome5 != null) || (this.stateLived5 != null && this.stateIncome5 == null) ) {
      this.errorMismatched = true;
      return
    }

    if (this.stateLived1 != null) {this.stateIncomeArr[this.stateLived1] = this.stateIncome1}
    if (this.stateLived2 != null) {this.stateIncomeArr[this.stateLived2] = this.stateIncome2}
    if (this.stateLived3 != null) {this.stateIncomeArr[this.stateLived3] = this.stateIncome3}
    if (this.stateLived4 != null) {this.stateIncomeArr[this.stateLived4] = this.stateIncome4}
    if (this.stateLived5 != null) {this.stateIncomeArr[this.stateLived5] = this.stateIncome5}

    this.appService.addStateIncomes(this.stateIncomeArr)
    //let stateCodes = this.appService.getStateIncomes()
    let stateCodes = []

    let k;
    for (k of Object.keys(this.stateIncomeArr)) {
      //this.stateIndex = this.stList.indexOf(k)
      var strKey = k.toString()
      var index = this.stList.indexOf(strKey);
      stateCodes.push(this.stateCodesList[index][1])
    }

    //Make list of which States have income for each State code
    //let n = 0
    //stateCodes.forEach((code: number) => {
    //  if (code == 1) {this.stateIncomeCode1.push('a')}
    //})

    //Determine what other info is needed based on the states/state codes
    sessionStorage.stateVisitEntries = 'No'
    sessionStorage.stateAddlQ = 'No'
    sessionStorage.stateAddlQNames = ''
    sessionStorage.MNIncome = "No"
    sessionStorage.MNAddlQ = "No"

    let k2;
    let i = 0

    for (k2 of Object.keys(this.stateIncomeArr)) {

      //Code 1
      if (stateCodes[i] == 1) {
        sessionStorage.guide_stateCode1_NoStateIncTax = 'Yes'
      }

      //Code 2
      if (stateCodes[i] == 2) {
        sessionStorage.guide_stateCode2_NotSupported = 'Yes'
      }

      //Code 3 - CA, PA, WI
      if (stateCodes[i] == 3) {
        sessionStorage.guide_stateCode3_NR = 'Yes'
      }

      //Code 4 - NC
      if (stateCodes[i] == 4) {
        if (sessionStorage.daysInUSTY > 183) {
          sessionStorage.stateVisitEntries = 'Yes'
        }
      }
  
      //Code 5 - MN
      if (stateCodes[i] == 5) {
        if (sessionStorage.daysInUSTY >= 183) {
          sessionStorage.stateVisitEntries = 'Yes'
        }
        else {
          sessionStorage.stateResMN = "NR"
          sessionStorage.stateVisitEntries = 'Yes'
        }
        sessionStorage.stateAddlQ = 'Yes'
        sessionStorage.MNAddlQ = "Yes"
        //sessionStorage.MNIncome = "Yes"
        //sessionStorage.stateAddlQNames = sessionStorage.stateAddlQNames + k2.toString() + " , "
      }
    
      //Code 6 - MD
      if (stateCodes[i] == 6) {
        if (sessionStorage.daysInUSTY >= 183) {
          sessionStorage.stateVisitEntries = 'Yes'
        }
        //sessionStorage.stateAddlQ = 'Yes'
        //sessionStorage.stateAddlQNames = sessionStorage.stateAddlQNames + k2.toString() + " , "
      }

      i += 1
    }


    sessionStorage.stateLived1 = this.stateLived1;
    sessionStorage.stateLived2 = this.stateLived2 ?? '';
    sessionStorage.stateLived3 = this.stateLived3 ?? '';
    sessionStorage.stateLived4 = this.stateLived4 ?? '';
    sessionStorage.stateLived5 = this.stateLived5 ?? '';
    sessionStorage.stateIncome1 = this.stateIncome1;
    sessionStorage.stateIncome2 = this.stateIncome2 ?? '';
    sessionStorage.stateIncome3 = this.stateIncome3 ?? '';
    sessionStorage.stateIncome4 = this.stateIncome4 ?? '';
    sessionStorage.stateIncome5 = this.stateIncome5 ?? '';

    //Track number of states for autopopulating pg-nr06a table if only one state
    if (Object.keys(this.stateIncomeArr).length == 1) {
      sessionStorage.noOfStates = "1"
    }
    else {
      sessionStorage.noOfStates = ">1"
    }


    sessionStorage.navDir = 'forward'
    sessionStorage.prevPage = 'pg-nr06'
    
    if (sessionStorage.stateVisitEntries == 'Yes') {
      this.router.navigate(['../pg-nr06a'], {relativeTo: this.route});
    }
    else if (sessionStorage.stateVisitEntries == 'No' && sessionStorage.stateAddlQ == 'Yes') {
      this.router.navigate(['../pg-nr07'], {relativeTo: this.route});
    }
    else {				   
      this.router.navigate(['../pg-nr08'], {relativeTo: this.route});
    }
    
  }


  onSubmitBack() {
    sessionStorage.navDir = 'backward'
    this.router.navigate(['../pg-nr05'], {relativeTo: this.route});
  }

}