<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR01.jpg" alt='Progress Bar 1 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - Citizenship and Tax Residency</h3>
            <!--<p>Comment</p>-->
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-7 col-lg-7">
            <label for="USCitizen">Are you a U.S. citizen, national, or lawful permanent resident (green card holder)?</label>
        </div>
        <div class="col-sm-2 col-lg-2">
            <ng-select id="USCitizen" [items]="selectYesNo" [(ngModel)]="USCitizen" (ngModelChange)="USCitizenChange()" placeholder="Select" [clearable]="false"></ng-select>
        </div>        
        <div class="col-sm-3 col-lg-3">
            <p>&nbsp;</p>
        </div>     
    </div>

    <div *ngIf="showUSCitizenText">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>TaxSlayer is designed for resident taxes, and has a lot of tools built into the software to assist you with preparing your resident return.</p>
                <p>Click on the button to go to our resident site.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" onclick="window.location.href='https://www.nrtaxhelp.org/free-tax-assistance';">Return to NoRTH</button>
            </div>
        </div>
    </div>

    <div *ngIf="showCitizenshipCountryQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label>What country or countries are you a citizen of?</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3 col-lg-4">
                <ng-select id="selectedCitizenCountry1" [items]="countries" [(ngModel)]="selectedCitizenCountry1" (ngModelChange)="CitizenCountry1Change()" 
                    placeholder="Select Country" [clearable]="false">
                </ng-select>
            </div>
            <div class="col-sm-3 col-lg-4">
                <ng-select id="selectedCitizenCountry2" [items]="countries" [(ngModel)]="selectedCitizenCountry2"
                    placeholder="Select Country" [clearable]="false">
                </ng-select>
            </div>
            <div class="col-sm-3 col-lg-4">
                <ng-select id="selectedCitizenCountry3" [items]="countries" [(ngModel)]="selectedCitizenCountry3"
                    placeholder="Select Country" [clearable]="false">
                </ng-select>
            </div>
        </div>
    </div>

    <div *ngIf="showCitizenCountryQError">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
         <div class="invalid-feedback d-block">
            <p>You need to select at least one country.</p>
        </div>
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div> 

    <div *ngIf="showTaxResidencyCountryQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label for="selectedTaxResidentCountry">What country were you a tax resident of before you came to the U.S.?</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3 col-lg-4">
                <ng-select id="selectedTaxResidentCountry" [items]="countries" [(ngModel)]="selectedTaxResidentCountry" (ngModelChange)="ResidentCountryChange()"
                placeholder="Select Country" [clearable]="false">
                </ng-select>
            </div>
            <div class="col-sm-9 col-lg-8">
                <p>&nbsp;</p>
            </div>    
        </div>
        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="col-sm-1 col-lg-1">
                </div>
                <div class="col-sm-12 col-lg-12">  
                    <p><small>Your country of tax residence is the country where you lived (and worked, if you worked) before coming to the U.S.  It is usually your country 
                        of citizenship, but that is not a requirement.  Typically you have to live in a country for at least 6 months to establish tax residency there.</small></p>
                    <p><small>If you have questions about your tax residency, please email us at <b>taxhelp@nrtaxhelp.org</b>, and we can help you determine your country 
                        of tax residency.</small></p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showContinueButton">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitNRA()">Continue</button>
            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a>.</small></p>
        </div>
    </div>


</div>