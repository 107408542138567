<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR08.jpg" alt='Progress Bar 10 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide Questionnaire - Completed!</h3>
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>Thank you for completing this questionnaire!</p>
            <p>&nbsp;</p>
            <p>Clicking the button below will generate your Personalized Guide and automatically download it.</p>
        </div>
    </div>

    <div class="row">
        <div *ngIf="activeButton" class="col-12">
            <button class="btn btn-custom mb-sm-0 mb-2" onclick="gtag('event','create_pg')"  (click)="onSubmitCreateGuide()">Create my Personalized Guide!</button>
        </div>
        <div *ngIf="!activeButton" class="col-12">
            <button class="btn btn-custom-grey mb-sm-0 mb-2" (click)="onSubmitInactiveButton()">Please wait</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p *ngIf="waitForGuide">Please wait while the guide is being created and downloaded... (may take up to 15 seconds)</p>
        </div>
    </div>

<!--
    <div *ngIf="showShortURL" class="row">
        <div class="col-12">
            <a [href]="shortenedUrl" target="_blank">Short Link: {{ shortenedUrl }}</a>
        </div>
    </div>
-->
    
    <div *ngIf="pdfNotFoundError" class="invalid-feedback d-block">
        There was an error in generating your Personalized Guide. Please refresh the page and try again. You can also clear cached content, images and files in your browser settings. If this error continues, please contact 
        <a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a> for assistance.
    </div>

</div>