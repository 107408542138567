import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ctbdata, ctbdataCountryOnly } from '../treatybenefits/docs/TBData';

@Component({
  selector: 'app-pg-nr04',
  templateUrl: './pg-nr04.component.html',
  styleUrls: ['./pg-nr04.component.scss']
})

export class PGnr04Component implements OnInit {

  //Variables
  taxYear: any;
  selectYesNo = ['Yes', 'No'];
  selectInterestPayor = ['Bank', 'IRS or Other']
  showContinueButton: boolean;
  goToTBPage: boolean = false;
  showUSIncomeQ: boolean = false;
  SSNITIN: string = null;
  showFedTaxWithheldQ: boolean = false;
  FedTaxWithheld: string = null;
  showInterestPayorQ: boolean = false;
  interestPayor: string = null;
								   
  showIncomeNoneError: boolean = false;
  showFedTaxWithheldError: boolean = false;
  showSSNITINError: boolean = false;
  formChecked: boolean;
  J1NS: boolean = false;
  F1J1S: boolean = false;
  ForJS: boolean = false;
  ctbdataCountryOnly = ctbdataCountryOnly
  cdata = ctbdata[0];
  countryIndex: number = null
  J1NSTypes = ['J-1 Professor', 'J-1 Research Scholar', 'J-1 Short-term Scholar', 'J-1 Teacher', 'J-1 Trainee', 'J-1 Intern', 'J-1 Physician', 'J-1 Specialist', 'J-1 Summer Work Travel', 'J-1 Au Pair and EduCare', 'J-1 Camp Counselor', 'J-1 Government Visitor', 'J-1 International Visitor (Dept. of State use)']
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    
    this.goToTBPage = false;
    this.formChecked = false;
    this.cdata = this.grabBenefits(sessionStorage.taxResidentCountry);
    this.fetchSelectedItems();
    if (this.J1NSTypes.includes(sessionStorage.visaType)) {this.J1NS = true}
    if (sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') {this.F1J1S = true}
    if (sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student' || sessionStorage.visaType == 'F-2 Student' || sessionStorage.visaType == 'J-2 Student') {this.ForJS = true}
    this.countryIndex = ctbdataCountryOnly.indexOf(sessionStorage.taxResidentCountry)
    this.canadaCode18();
    this.checkJ1NS();
    this.checkF1J1S();
    this.showUSIncomeQ = false;
    this.showFedTaxWithheldQ = false;
    this.interestPayor = null;
    this.showInterestPayorQ = false;
    this.showFedTaxWithheldError = false;
    this.showSSNITINError = false;
    this.taxYear = sessionStorage.taxYear;
    sessionStorage.TB = 'No';
    sessionStorage.navFromPGnr04 = "Yes";
    sessionStorage.incomeW2 = null;
    sessionStorage.income1042S16 = null;
    sessionStorage.income1042S18 = null;
    sessionStorage.income1042S19 = null;
    sessionStorage.income1042S20 = null;
    sessionStorage.incomeScholarship = null;
    sessionStorage.income1099INT = null;
    sessionStorage.income1099DIV = null;
    sessionStorage.income1099B = null;
    sessionStorage.income1099R = null;
    sessionStorage.income1099Misc = null;
    sessionStorage.income1099NEC = null;
    sessionStorage.income1042SInterest = null;
    sessionStorage.income1042SDividends = null;
    sessionStorage.incomeOther = null;
    sessionStorage.incomeNone = null;
    sessionStorage.SSNITIN = null;
    sessionStorage.incomeINTPayor = null;
    sessionStorage.TB_IncomeDIVTreatyRate = null; //number
    sessionStorage.TB_IncomeCapGainTreatyRate = null; //number
    sessionStorage.TB_Dividend = null; //Yes,No
    sessionStorage.TB_CapGain = null; //Yes.No

  }

  selectedItemsList = [];
  checkedIDs = [];

  incomeCheckboxesDataList = [
    {
      id: 'incomeW2',
      label: ' W-2',
      isChecked: false
    },
    {
      id: 'income1042S16',
      label: ' 1042-S Box 1 income code 16 - Scholarship or Fellowship',
      isChecked: false
    },
    {
      id: 'incomeScholarship',
      label: ' Scholarship or Fellowship not reported on an income document',
      isChecked: false
    },
    {
      id: 'income1099INT',
      label: ' 1099-INT',
      isChecked: false
    },
    {
      id: 'income1099DIV',
      label: ' 1099-DIV',
      isChecked: false
    },
    {
      id: 'income1099B',
      label: ' 1099-B',
      isChecked: false
    },
/*
    {
      id: 'income1099G',
      label: ' 1099-G State Income Tax Refund',
      isChecked: false
    },
*/
    {
      id: 'income1099R',
      label: ' 1099-R',
      isChecked: false
    },
    {
      id: 'income1099Misc',
      label: ' 1099-Misc',
      isChecked: false
    },
    {
      id: 'income1099NEC',
      label: ' 1099-NEC',
      isChecked: false
    },
    {
      id: 'income1042SInterest',
      label: ' 1042-S Box 1 income code 29 - Interest',
      isChecked: false
    },
    {
      id: 'income1042SDividends',
      label: ' 1042-S Box 1 income code 06 - Dividends',
      isChecked: false
    },
    {
      id: 'incomeOther',
      label: ' Other income not listed',
      isChecked: false
    },
    {
      id: 'incomeNone',
      label: ' No U.S. income',
      isChecked: false
    }
  ]


  canadaCode18() {
    if (sessionStorage.taxResidentCountry == "Canada") {
      this.incomeCheckboxesDataList.splice(2, 0,
        {id: 'income1042S18',
        label: ' 1042-S Box 1 income code 18 - Dependent Personal Services',
        isChecked: false}
        )
    }
  }

  checkJ1NS() {
    var pos: number;
    if (this.J1NS) {
      if (sessionStorage.taxResidentCountry != "Canada") {pos = 2}
      else {pos=3}
      this.incomeCheckboxesDataList.splice(pos, 0,
      {id: 'income1042S19',
      label: ' 1042-S Box 1 income code 19 - Wages',
      isChecked: false}
      )
    }
  }

  checkF1J1S() {
    var pos: number = 2;
    if (sessionStorage.taxResidentCountry == "Canada") {
      pos += 1
    }
    if (this.J1NS) {pos += 1}
    if (this.F1J1S) {
      this.incomeCheckboxesDataList.splice(pos, 0,
      {id: 'income1042S20',
      label: ' 1042-S Box 1 income code 20 - Wages',
      isChecked: false}
      )
    }
  }

  changeCheckBoxSelection() {
    this.fetchSelectedItems()
    this.fetchCheckedIDs()
    if ( (this.checkedIDs.includes("income1099INT") || this.checkedIDs.includes("income1042SInterest"))) {
      this.showFedTaxWithheldQ = true;
      this.showInterestPayorQ = true;
      if (this.FedTaxWithheld == null || this.interestPayor == null ) {
        this.showContinueButton = false;
      }
      else {
        this.showContinueButton = true;
      }
    } 
    else {
      this.showFedTaxWithheldQ = false;
      this.FedTaxWithheld = null;
      sessionStorage.incomeFedTaxWithheld = ''
      this.showInterestPayorQ = false;
      this.interestPayor = null;
      sessionStorage.incomeINTPayor = ''
      this.showContinueButton = true;
    }
																									  
    if (this.checkedIDs.includes("incomeNone") && this.checkedIDs.length > 1) {this.showIncomeNoneError = true} else {this.showIncomeNoneError = false}
  }

  fetchSelectedItems() {
    this.formChecked = false;
    this.selectedItemsList = this.incomeCheckboxesDataList.filter((value, index) => {
      if (value.isChecked == true) {
        this.formChecked = true;
        this.showContinueButton = true
      }
      return
    })
  }

  fetchCheckedIDs() {
    this.checkedIDs = []
    this.incomeCheckboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.id);
        this.showContinueButton = true
      }
    });
  }

  SSNITINChange() {
    this.fetchCheckedIDs();
    if (this.checkedIDs.length == 0) {
      this.showContinueButton = false;
    }
    else {
      this.showContinueButton = true;
    }
    this.showUSIncomeQ = true;
    
    if (this.SSNITIN == "Yes") {
      sessionStorage.SSNITIN = "Yes"
      //this.showUSIncomeQ = true;
      //this.showSSNITINError = false;
    }
    else {
      sessionStorage.SSNITIN = "No"
      //this.showContinueButton = false;
      //this.showSSNITINError = false;
    }
  }

  FedTaxWithheldChange() {
    if (this.FedTaxWithheld == "Yes") {
      sessionStorage.incomeFedTaxWithheld = "Yes"
      this.showFedTaxWithheldError = false;
    }
    else {
      sessionStorage.incomeFedTaxWithheld = "No"
      this.showFedTaxWithheldError = false;
    }
    if (this.interestPayor != null) {
      this.showContinueButton = true;
    }
    else {this.showContinueButton = false
    }
  }

  interestPayorChange() {
    sessionStorage.incomeINTPayor = this.interestPayor
    if (this.FedTaxWithheld != null) {
      this.showContinueButton = true;
    }
    else {this.showContinueButton = false
    }
  }

  grabBenefits(item) {
    for (var val in ctbdata) {
      if (item === ctbdata[val][0]) {
        return ctbdata[val];
      }
    }
    return ctbdata[0];
  }

  setCheckboxSessionStorage() {
    let checkboxID: any;
    
    for (var checkbox in this.incomeCheckboxesDataList) {
       checkboxID = this.incomeCheckboxesDataList[checkbox].id
       if (this.incomeCheckboxesDataList[checkbox].isChecked) {sessionStorage[checkboxID] = "Yes"}
       else {sessionStorage[checkboxID] = "No"}
    }
    
    /* Older code that is replaced by the for... loop above
    sessionStorage.income1042S16 = "No"
    sessionStorage.income1042S18 = "No"
    sessionStorage.income1042S19 = "No"
    sessionStorage.income1042S20 = "No"
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.incomeW2 = "Yes"; i += 1} 
      else {sessionStorage.incomeW2 = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1042S16 = "Yes"; i += 1}
      else {sessionStorage.income1042S16 = "No"; i += 1}
    if (this.J1NS && this.F1J1S) {
      if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1042S19 = "Yes"}
      else {sessionStorage.income1042S19 = "No"};
      if (this.incomeCheckboxesDataList[i+1].isChecked){sessionStorage.income1042S20 = "Yes"}
      else {sessionStorage.income1042S20 = "No"};
       i += 2}
    else if (this.J1NS) {
      if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1042S19 = "Yes"}
      else {sessionStorage.income1042S19 = "No"};
      i += 1}
    else if (this.F1J1S) {
      if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1042S20 = "Yes"}
      else {sessionStorage.income1042S20 = "No"};
      i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.incomeScholarship = "Yes"; i += 1}
    else {sessionStorage.incomeScholarship = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1099INT = "Yes"; i += 1}
    else {sessionStorage.income1099INT = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1099DIV = "Yes"; i += 1}
    else {sessionStorage.income1099DIV = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1099B = "Yes"; i += 1}
    else {sessionStorage.income1099B = "No"; i += 1}
    //if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1099G = "Yes"; i += 1}
    //else {sessionStorage.income1099G  = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1099R = "Yes"; i += 1}
    else {sessionStorage.income1099R = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1099Misc = "Yes"; i += 1}
    else {sessionStorage.income1099Misc = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1099NEC = "Yes"; i += 1}
    else {sessionStorage.income1099NEC = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1042SInterest = "Yes"; i += 1}
    else {sessionStorage.income1042SInterest = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.income1042SDividends = "Yes"; i += 1}
    else {sessionStorage.income1042SDividends = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.incomeOther = "Yes"; i += 1}
    else {sessionStorage.incomeOther = "No"; i += 1}
    if (this.incomeCheckboxesDataList[i].isChecked){sessionStorage.incomeNone = "Yes"}
    else {sessionStorage.incomeNone = "No"}
    */
  return
  }

  onSubmitContinue() {
    this.setCheckboxSessionStorage();
    this.fetchCheckedIDs();

    // Show possible errors
    if (this.showFedTaxWithheldQ == true && this.FedTaxWithheld == null) {
      this.showFedTaxWithheldError = true;
      return
    }
    if (this.SSNITIN == null) {
      this.showSSNITINError = true;
      return
    }

    // remove data if check boxes are not checked
    if (this.showFedTaxWithheldQ == false) {
      sessionStorage.incomeFedTaxWithheld = '';
      sessionStorage.incomeINTPayor = '';
    }

    // /*

    //DIVIDENDS
    if ( sessionStorage.income1099DIV == "Yes" || sessionStorage.income1042SDividends == "Yes") {
      if (this.cdata[5][1] == 'None' || this.cdata[5][0] == null) {
        sessionStorage.TB_Dividend = 'No'
      }
      else {
        sessionStorage.TB_Dividend = 'Yes'
        sessionStorage.TB_IncomeDIVTreatyRate = String(this.cdata[5][0])
        var textBlock_DividendTable = []
        var textBlock_DividendText = []
        var textBlock_DividendSpecialNotes = []
        
        textBlock_DividendTable.push(this.cdata[5][0], this.cdata[5][1])
        textBlock_DividendText.push(
          "Generally, dividend income from investments in U.S.corporate stock is considered FDAP (Fixed, Determinable, Annual or Periodic) income, NOT effectively ",
          "connected to the taxpayer's U.S. trade or business and is therefore taxable at a 30% rate on Form 1040-NR, Schedule NEC, NOT on the front income section ",
          "of the form.",
          "",
          "You must review all paragraphs of the treaty article to fully ensure that all conditions are met. You can access the list of tax treaties here: ",
          "https://www.irs.gov/businesses/international-businesses/united-states-income-tax-treaties-a-to-z",
          ""
        )

        let i = 0
        if (ctbdata[this.countryIndex][10].length > 0) {
          while (i < ctbdata[this.countryIndex][10].length) {
            textBlock_DividendSpecialNotes.push(ctbdata[this.countryIndex][10][i])
            textBlock_DividendSpecialNotes.push("")
            i += 1
          }
        }
      }
      sessionStorage.setItem('TB_DividendTable', JSON.stringify(textBlock_DividendTable))
      sessionStorage.setItem('TB_DividendText', JSON.stringify(textBlock_DividendText))
      sessionStorage.setItem('TB_DividendSpecialNotes', JSON.stringify(textBlock_DividendSpecialNotes))
    }

    //CAP GAINS
    if (sessionStorage.income1099B == "Yes") {
      if (this.cdata[4][1] == 'None' || this.cdata[4][0] == null) {
        sessionStorage.TB_CapGain = 'No'
      }
      else {
        sessionStorage.TB_CapGain = 'Yes'
        sessionStorage.TB_IncomeCapGainTreatyRate = String(this.cdata[4][0])
        var textBlock_CapGainTable = []
        var textBlock_CapGainText = []
        var textBlock_CapGainSpecialNotes = []

        textBlock_CapGainTable.push(this.cdata[4][0], this.cdata[4][1])
        textBlock_CapGainText.push(
          "You must review all paragraphs of the treaty article to fully ensure that all conditions are met. Some treaties limit the benefit based on items ",
          "like the percentage of stock ownership held, types of assets held by the corporation, etc. You can access the list of tax treaties here:",
          "https://www.irs.gov/businesses/international-businesses/united-states-income-tax-treaties-a-to-z.",
          "",
          "If a nonresident alien is physically present in the U.S. for less than 183 days during the tax year, none of the capital gains from these sales",
          "are taxable. The days counted for excludable gains consider all days of presence, regardless of exempt days based on visa status under IRC §7701(b).",
          "If the nonresident is present in the U.S. for 183 days or more, generally the rate of tax on the gain is 30% unless a treaty tax rate applies.",
          "",
          "This income is reported on 1040-NR, Schedule NEC, Tax on Income Not Effectively Connected With a U.S. Trade or Business, NOT on Schedule D, ",
          "Capital Gains and Losses, nor on the income section of Form 1040-NR. Capital losses of nonresident aliens may only offset other capital gains. ",
          "(Capital losses of nonresident aliens cannot be used against other income, nor can they be carried forward to another tax year.)",
          ""
        )

        let i = 0
        if (ctbdata[this.countryIndex][9].length > 0) {
          while (i < ctbdata[this.countryIndex][9].length) {
            textBlock_CapGainSpecialNotes.push(ctbdata[this.countryIndex][9][i])
            textBlock_CapGainSpecialNotes.push("")
            i += 1
          }
        }
      }
    
    sessionStorage.setItem('TB_CapGainTable', JSON.stringify(textBlock_CapGainTable))
    sessionStorage.setItem('TB_CapGainText', JSON.stringify(textBlock_CapGainText))
    sessionStorage.setItem('TB_CapGainSpecialNotes', JSON.stringify(textBlock_CapGainSpecialNotes))
      
    }

    // */

    // Set up conditions for how to proceed base on checked items
    //var ForJS = ["F-1 Student", "J-1 Student", "F-2 Student", "J-2 Student"]

    var pos = this.ctbdataCountryOnly.indexOf(sessionStorage.taxResidentCountry)
    
    if (pos == -1) {
      this.goToTBPage = false;
    }
 
    else if (this.ForJS && ctbdata[pos][1][0] == null && ctbdata[pos][3][0] == null) { this.goToTBPage = false; }
    
    else if (this.J1NSTypes.includes(sessionStorage.visaType) && ctbdata[pos][2][0] == null) { this.goToTBPage = false; }

    else {
      
      if (this.checkedIDs.includes('incomeW2')) {
        if ((this.J1NS  && ctbdata[pos][2][0] != null) ||
            (this.ForJS && ctbdata[pos][3][0] != null)) {
          this.goToTBPage = true;
        }
      }

      if (this.checkedIDs.includes('income1042S16')) {
        if (ctbdata[pos][1][0] != null) {
          this.goToTBPage = true;
        }
      }

      if (this.checkedIDs.includes('income1042S18')) {
        if (ctbdata[pos][2][0] != null && this.J1NS) {
          this.goToTBPage = true;
        }
      }

      if (this.checkedIDs.includes('income1042S19')) {
        if (ctbdata[pos][2][0] != null && this.J1NS) {
          this.goToTBPage = true;
        }
      }

      if (this.checkedIDs.includes('income1042S20')) {
        if (ctbdata[pos][3][0] != null && this.ForJS) {
          this.goToTBPage = true;
        }
      }

      if (this.checkedIDs.includes('incomeScholarship')) {
        if (ctbdata[pos][1][0] != null) {
          this.goToTBPage = true;
        }
      }
    }
    
    // Set up navigation
    sessionStorage.navDir = 'forward';
    sessionStorage.prevPage = 'pg-nr04'

    if (this.checkedIDs.includes('incomeNone') && this.SSNITIN == "No") {
      this.router.navigate(['../pg-nr8843'], {relativeTo: this.route});
    }
    else if (this.checkedIDs.includes('incomeNone') && this.SSNITIN == "Yes") {
      sessionStorage.backpgnr08 = 'pg-nr04'
      this.router.navigate(['../pg-nr08'], {relativeTo: this.route});
    }

    else if (this.goToTBPage == true) {
      sessionStorage.pg = 'pgTrue'
      sessionStorage.TB = 'Yes'
      this.router.navigate(['../treatybenefits'], {relativeTo: this.route});
    }
    else {
      this.router.navigate(['../pg-nr05'], {relativeTo: this.route});
    }
    
  }

	
  onSubmitBack() {
    this.setCheckboxSessionStorage();
    sessionStorage.prevPage = 'pg-nr04'
    sessionStorage.navDir = 'backward'
    if (sessionStorage.taxResidentCountry == 'Canada'
      || sessionStorage.taxResidentCountry == 'Mexico'
      || sessionStorage.taxResidentCountry == 'India'
      || sessionStorage.taxResidentCountry == 'Korea, South') {
      this.router.navigate(['../pg-nr03'], {relativeTo: this.route});
    }
    else {
      sessionStorage.children = ''
      sessionStorage.childBornUS = ''
      sessionStorage.childPresentUS = ''
      sessionStorage.childNotPresent = ''
      this.router.navigate(['../pg-nr02'], {relativeTo: this.route});
    }
  }
}
