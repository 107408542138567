<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR06.jpg" alt='Progress Bar pg-nr06'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - U.S. States Income</h3>
            <!--<p>Comment</p>-->
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div>
        <div class="row">
            <div class="col-sm-5 col-sm-5">
                <label>Select the U.S. states you were physically present in during {{taxYear}}.</label>
                <div class="row">
                    <div class="col-0">
                    </div>
                </div>
            </div>
            <div class="col-sm-7 col-sm-7">
                <label>Did you receive any income while you were in this state?</label>
                <div class="row">
                    <div class="col-0">
                    </div>
                    <div class="col-12">
                        <p><small>(Wages, Scholarships, other income) </small>
                            <fa-icon class="clickable" [icon]="['fa', 'info-circle']"  
                                title="Could include any of the following:&#013;&#013;W-2 &#013;1042-S Box 1 income code 16 - Scholarship or Fellowship&#013;Scholarship or Fellowship not reported on an income document&#013;1099-INT &#013;1099-DIV &#013;1099-B &#013;1099-R &#013;1099-Misc &#013;1099-NEC &#013;1042-S Box 1 Incme code 29 - Interest &#013;1042-S Box 1 income code 06 - Dividends &#013;State income tax refund &#013;Other income not listed">
                            </fa-icon></p>
                    </div>
                </div>
            </div>
        </div>
<!--
i icon - list from check boxes without "No Income" and add "State income tax refund" at the bottom.
<fa-icon class="clickable" [icon]="['fa', 'info-circle']" 
            title = "F/J/M/Q visas are for students, teachers, trainees, and other types of exchange visitors.  People with those visas are exempt from the residency test for a certain period of time.

Other Exempt visas are intended for diplomats, government officials, employees of international organizations, and their families.  People with these visas are always exempt from the residency test.  These include the following visa types: A (but not A-3), G (but not G-5), NATO.

All other visas are non-exempt.  Unless your visa is listed in one of the two categories above, it is non-exempt.  Some of the common types of non-exempt visas / visits are:  B1/B2, TN/TD, H (including H1-b), L, O, P, visa waiver, and Temporary Protected Status (TPS).  This is not a complete list of all non-exempt visa types.  If your visa is not a F/J/M/Q or other exempt visa as described above, then it is non-exempt."
            >
        </fa-icon>
    -->
        <div class="row">
            <div class="col-sm-3 col-lg-3">
                <ng-select id="stateLived1" [items]="stList" [(ngModel)]="stateLived1" (ngModelChange)="entriesChange()" placeholder="Select state" [clearable]="false"></ng-select>
            </div>
            <div class="col-sm-2 col-lg-2">
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="stateIncome1" [items]="selectYesNo" [(ngModel)]="stateIncome1" (ngModelChange)="entriesChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div>
        </div>
        <div class="row mt-2">
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3">
                <ng-select id="stateLived2" [items]="stList" [(ngModel)]="stateLived2" (ngModelChange)="entriesChange()" placeholder="Select state" [clearable]="false"></ng-select>
            </div>
            <div class="col-sm-2 col-lg-2">
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="stateIncome2" [items]="selectYesNo" [(ngModel)]="stateIncome2" (ngModelChange)="entriesChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div>
        </div>
        <div class="row mt-2">
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3">
                <ng-select id="stateLived3" [items]="stList" [(ngModel)]="stateLived3" (ngModelChange)="entriesChange()" placeholder="Select state" [clearable]="false"></ng-select> 
            </div>
            <div class="col-sm-2 col-lg-2">
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="stateIncome3" [items]="selectYesNo" [(ngModel)]="stateIncome3" (ngModelChange)="entriesChange()" placeholder="Select" [clearable]="false"></ng-select> 
            </div> 
        </div>
        <div class="row mt-2">
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3">
                <ng-select id="stateLived4" [items]="stList" [(ngModel)]="stateLived4" (ngModelChange)="entriesChange()" placeholder="Select state" [clearable]="false"></ng-select> 
            </div>
            <div class="col-sm-2 col-lg-2">
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="stateIncome4" [items]="selectYesNo" [(ngModel)]="stateIncome4" (ngModelChange)="entriesChange()" placeholder="Select" [clearable]="false"></ng-select> 
            </div> 
        </div>
        <div class="row mt-2">
        </div>
        
        <div class="row">
            <div class="col-sm-3 col-lg-3">
                <ng-select id="stateLived5" [items]="stList" [(ngModel)]="stateLived5" (ngModelChange)="entriesChange()" placeholder="Select state" [clearable]="false"></ng-select> 
            </div>
            <div class="col-sm-2 col-lg-2">
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="stateIncome5" [items]="selectYesNo" [(ngModel)]="stateIncome5" (ngModelChange)="entriesChange()" placeholder="Select" [clearable]="false"></ng-select> 
            </div> 
        </div>
        <div class="row mt-2">
        </div>
 
    </div>

    <div class="row">
        <div *ngIf="errorMismatched" class="col-12">
            <div class="invalid-feedback d-block">Please make sure you have selected both a state and a Yes/No answer for each applicable row.</div>
        </div>
        <div *ngIf="errorNoneYes" class="col-12">
            <div class="invalid-feedback d-block">You have not selected 'Yes' for any rows, but you had indicated you had income.  Please fix.</div>
        </div>
    </div>

    <div>
        <div class="row">
            <div>
                <p>&nbsp;</p>
            </div>
        </div>
    
        <div class="row">
            <div class="col-10">
                <button class="btn btn-danger float-right" (click)="clearEntries()">Clear Entries</button>
            </div> 
        </div>
    </div>

    <div *ngIf="showContinueButton">
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToNextPage()">Continue</button>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a>.</small></p>
        </div>
    </div>


</div>