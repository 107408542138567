<div class="container page-with-footer">
    <div class="row">
        <div *ngIf="showIntro" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div *ngIf="showNameFormEntry" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowIntro()">Back</button>
        </div>
        <div *ngIf="showAddressFormEntry" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowNameFormEntry()">Back</button>
        </div>
        <div *ngIf="showVisaChangeLeaveUSFormEntry" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowAddressFormEntry()">Back</button>
        </div>
        <div *ngIf="showCitizenFormEntry && visaChangeLeaveUS" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowVisaChangeLeaveUSFormEntry()">Back</button>
        </div>
        <div *ngIf="showCitizenFormEntry && !visaChangeLeaveUS" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowAddressFormEntry()">Back</button>
        </div>
        <div *ngIf="showGCAppFormEntry && visaFJMQStudent" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowCitizenFormEntry()">Back</button>
        </div>
        <div *ngIf="showGCAppFormEntry && !visaFJMQStudent" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowAddressFormEntry()">Back</button>
        </div>
        <div *ngIf="showSchoolFormEntry && visaFJMQStudent" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowGCAppFormEntry()">Back</button>
        </div>
        <div *ngIf="showSchoolFormEntry && !visaFJMQStudent" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowCitizenFormEntry()">Back</button>
        </div>
        <div *ngIf="showCreate8843" class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitPrevShowSchoolFormEntry()">Back</button>
        </div>

        <div class="col-6" style="text-align:right">
            <p *ngIf="showIntro"                     >Progress: <img src="../../../assets/ProgressBarNR8843-1.jpg" alt='Progress Bar pg-nr8843-1'></p>
            <p *ngIf="showNameFormEntry"             >Progress: <img src="../../../assets/ProgressBarNR8843-2.jpg" alt='Progress Bar pg-nr8843-2'></p>
            <p *ngIf="showAddressFormEntry"          >Progress: <img src="../../../assets/ProgressBarNR8843-3.jpg" alt='Progress Bar pg-nr8843-3'></p>
            <p *ngIf="showVisaChangeLeaveUSFormEntry">Progress: <img src="../../../assets/ProgressBarNR8843-4.jpg" alt='Progress Bar pg-nr8843-4'></p>
            <p *ngIf="showCitizenFormEntry"          >Progress: <img src="../../../assets/ProgressBarNR8843-5.jpg" alt='Progress Bar pg-nr8843-5'></p>
            <p *ngIf="showGCAppFormEntry"            >Progress: <img src="../../../assets/ProgressBarNR8843-6.jpg" alt='Progress Bar pg-nr8843-6'></p>
            <p *ngIf="showSchoolFormEntry"           >Progress: <img src="../../../assets/ProgressBarNR8843-7.jpg" alt='Progress Bar pg-nr8843-7'></p>
            <p *ngIf="showCreate8843"                >Progress: <img src="../../../assets/ProgressBarNR8843-8.jpg" alt='Progress Bar pg-nr8843-8'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h3 *ngIf="!showAutoFill">Personalized Guide - Form 8843</h3>
            <h3 *ngIf="showAutoFill">Personalized Guide - Form 8843 Additional Info</h3>
            <!--<p>Comment</p>-->
            <hr class="custom-line-silver">
            <!--<p>&nbsp;</p>-->
        </div>
    </div>

    <div *ngIf="showIntro" class="row">
        <div class="col-12">
            <p>Based on your inputs you need to file a Form 8843.</p>
            <p>We can help you fill out this form by asking you some additional questions, and then entering the information you have provided 
                into the correct locations on the form.  As part of this, we will ask you for your name and address, which is personally-identifiable 
                information.   If you choose to enter additional information, this data will be stored temporarily in your browser, included in the 
                filled out PDF form you download, and then will be deleted when you close the browser tab.  The PDF download will also include 
                instructions about how to file this form.
            </p>
            <p>Note: If you proceed with entering additional information, some of the form fields will contain pre-populated entries. 
                The text in these fields will be a light grey.  You can overwrite these entries by typing new information into the field, 
                or deleting that field.  You will need to manually write the data on the form for any fields that you delete the entries for.
            </p>
            <p><b>Entering additional information is completely optional.</b> If you do not wish to do this you can choose “Fill out Form 8843 myself”.  
                Clicking on this button will download a blank Form 8843, along with our general guide for filling out this form by hand.
            </p>
        </div>
    </div>

    <div *ngIf="showIntro">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-6">
                    <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToNameEntry()">Enter additional Form 8843 information</button>
                </div>
                <div class="col-6">
                    <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToSelfFill()">Fill out Form 8843 myself</button>
                </div>
            </div>

        </div>
    </div>

    
    <div *ngIf="showAutoFill" class="form-group">
        
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>

        <form [formGroup]="f8843Form" (ngSubmit)="onSubmit8843Info()">

            <div *ngIf="showNameFormEntry">
                <div class="form-group row">
                    <label for="firstName" class="col-sm-3 col-form-label">
                        First Name:
                    </label>
                    <div class="col-sm-9">
                        <input id="firstName" type="text" formControlName="firstName" style="width:50%" class="span6" placeholder="">
                    </div>
                </div>
            
                <div class="form-group row">
                    <label for="initial" class="col-sm-3 col-form-label">
                        First letter of your middle name (if you have one):
                    </label>
                    <div class="col-sm-9">
                        <input id="initial" type="text" formControlName="initial"  style="width:5%" class="span2" placeholder="">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="lastName" class="col-sm-3 col-form-label">
                        Last Name:
                    </label>
                    <div class="col-sm-9">
                        <input id="lastName" type="text" formControlName="lastName" style="width:50%" class="span6" placeholder="">
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-4">
                        <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToAddressEntry()">Continue</button>
                    </div>
                </div>

            </div>


            <div *ngIf="showAddressFormEntry">
                <div class="form-group row">
                    <label for="validationTextareaAddressRes" class="col-sm-3 col-form-label">
                        Mailing address in {{ countryOfRes }} (4 lines max.):
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaAddressRes" formControlName="addressRes" placeholder=""  rows="4" style="width:50%"></textarea>
                    </div>
                </div>
                
                <div class="form-group row">
                    <label for="validationTextareaAddressUS" class="col-sm-3 col-form-label">
                        Mailing address in U.S. (4 lines max.):
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaAddressUS" formControlName="addressUS" placeholder="" rows="4" style="width:50%"></textarea>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                        <p><b>Note:</b> Addresses will be left blank on your form 8843 if you do not make an entry on this page.  You can write in this information 
                            manually after you download the PDF.  Don't forget to include the country in your address information.</p>
                    </div>
                </div>

                <div *ngIf="visaChangeLeaveUS" class="row">
                    <div class="col-12">
                        <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToVisaChangeLeaveUS()">Continue</button>
                    </div>
                </div>
                <div *ngIf="!visaChangeLeaveUS" class="row">
                    <div class="col-12">
                        <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToCitizenEntry()">Continue</button>
                    </div> 
                </div>
            </div>

            <div *ngIf="showVisaChangeLeaveUSFormEntry">
                <div class="form-group row">
                    <label for="visaChangeLeaveUS" class="col-sm-3 col-form-label">
                        Your two most recent visits have two different visas on consecutive days ( {{ visaChangeWithoutLeavingExitInfo }}, {{ visaChangeWithoutLeavingEntryInfo }}).  Did you leave the U.S. prior to re-entering the U.S. on the {{ visaChangeWithoutLeavingEntryVisa }} visa? 
                    </label>
                    <div class="col-sm-9">
                        <ng-select id="visaChangeLeaveUS"
                            [items]="selectYesNo"
                            formControlName="visaChangeLeaveUS"
                            [placeholder]="Select"
                            [clearable]="false"
                            style="width:20%">    
                        </ng-select>
                    </div> 
                </div>
            </div>

            <div *ngIf="visaChangeLeaveUSShowContinue" class="row">
                <div class="col-12">
                    <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToCitizenEntry()">Continue</button>
                </div>
            </div>

            <div *ngIf="showCitizenFormEntry">
                <div class="form-group row">
                    <label for="countryCitizen" class="col-sm-3 col-form-label">
                        What country or countries you were a citizen of during the tax year?
                    </label>
                    <div class="col-sm-9">
                        <input id="countryCitizen" type="text" formControlName="countryCitizen" style="width:50%" class="span6" placeholder="{{countryCitizen}}">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="countryPassport" class="col-sm-3 col-form-label">
                        What country or countries issued you a passport?
                    </label>
                    <div class="col-sm-9">
                        <input id="countryPassport" type="text" formControlName="countryPassport" style="width:50%" class="span6" placeholder="{{countryPassport}}">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="passport" class="col-sm-3 col-form-label">
                        Enter your passport number(s).  Note: If you have multiple passports, enter your countries and passport numbers in the same order in the boxes.
                    </label>
                    <div class="col-sm-9">
                        <input id="passport" type="text" formControlName="passport" style="width:50%" class="span6" placeholder="">
                    </div>
                </div>

                <div *ngIf="!(visaJ1NonTeacherNonStudent || visaJ1Teacher)" class="row">
                    <div class="col-12">
                        <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToGCAppEntry()">Continue</button>
                    </div>
                </div>

                <div *ngIf="(visaJ1NonTeacherNonStudent || visaJ1Teacher)" class="row">
                    <div class="col-12">
                        <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToSchoolEntry()">Continue</button>
                    </div>
                </div>

            </div>

            
            <div *ngIf="showGCAppFormEntry && visaFJMQStudent" class="form-group row">
                <label for="gcApp" class="col-sm-3 col-form-label">
                    During 2023, did you apply for, or take other affirmative steps to apply for, lawful permanent resident status in the 
                    United States or have an application pending to change your status to that of a lawful permanent resident of the United States?
                    <fa-icon class="clickable" [icon]="['fa', 'info-circle']" 
                    title = "If you entered the Green Card lottery, select 'No'.  If you won a Green Card through the lottery, select 'Yes'"
                    >
                </fa-icon>
                </label>
                <div class="col-sm-9">
                    <ng-select id="gcApp"
                        [items]="selectYesNo"
                        formControlName="gcApp"
                        [placeholder]="Select"
                        [clearable]="false"
                        style="width:20%">    
                    </ng-select>
                </div> 
            </div>

            <div *ngIf="showGCAppFormEntry && visaFJMQStudent && showGCAppExplain" class="form-group row">
                <label for="validationTextareaSchoolAddressOther" class="col-sm-3 col-form-label">
                    Provide a brief explanation of the steps you took towards Permanent Resident status (2 lines max, up to 50 characters max. each line). 
                    Note:  If you need more room for an explanation, start your explanation here, and then write the remainder of the explanation on 
                    another sheet of paper and include it with your form.
                </label>
                <div class="col-sm-9">
                    <div class="row">
                        <textarea id="gcAppExplain1" formControlName="gcAppExplain1" placeholder="" rows="1" style="width:80%" maxlength="50"></textarea>
                    </div>
                    <div class="row">
                        <textarea id="gcAppExplain2" formControlName="gcAppExplain2" placeholder="" rows="1" style="width:80%" maxlength="80"></textarea>
                    </div>
                </div>
            </div>

        <!--
            <div *ngIf="showGCAppFormEntry && visaFJMQStudent && showGCAppExplain" class="form-group row">
                <label for="validationTextareaSchoolAddressOther" class="col-sm-3 col-form-label">
                    Provide an explanation of the steps you took towards Permanent Resident status (2 rows max.):
                </label>
                <div class="col-sm-9">
                    <textarea id="gcAppExplain" formControlName="gcAppExplain" placeholder="Enter explanation here" rows="2" style="width:80%" maxlength="10"></textarea>
                </div>
            </div>
        -->


            <div *ngIf="showContinueToSchoolFormEntry" class="row">
                <div class="col-12">
                    <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToSchoolEntry()">Continue</button>
                </div>
            </div>




            <div *ngIf="showSchoolFormEntry">
                <div class="form-group row">
                    <label for="schoolDropdownName" class="col-sm-3 col-form-label">
                        Select school
                    </label>
                    <div class="col-sm-9">
                        <ng-select id="schoolDropdownName"
                            [items]="schoolList"
                            formControlName="schoolDropdownName"
                            placeholder="Select"
                            [clearable]="false"
                            style="width:50%">    
                        </ng-select>
                    </div> 
                </div>

                <div *ngIf="(showSchoolExisting && showContactExisting)" class="form-group row">
                    <label for="validationTextareaSchoolContactExisting" class="col-sm-3 col-form-label">
                        School contact name:
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaSchoolContactExisting" formControlName="schoolContactExisting" placeholder="{{ schoolContact }}" rows="1" style="width:50%"></textarea>
                    </div>
                </div>
    
                <div *ngIf="showSchoolExisting" class="form-group row">
                    <label for="validationTextareaSchoolNameExisting" class="col-sm-3 col-form-label">
                        School name:
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaSchoolNameExisting" formControlName="schoolNameExisting" placeholder="{{ schoolName }}" rows="1" style="width:50%"></textarea>
                    </div>
                </div>
    
                <div *ngIf="showSchoolExisting" class="form-group row">
                    <label for="validationTextareaSchoolAddressExisting" class="col-sm-3 col-form-label">
                        School address:
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaSchoolAddressExisting" formControlName="schoolAddressExisting" placeholder="{{ schoolAddress }}" rows="2" style="width:50%"></textarea>
                    </div>
                </div>
    
                <div *ngIf="showSchoolExisting" class="form-group row">
                    <label for="validationTextareaSchoolPhoneExisting" class="col-sm-3 col-form-label">
                        School phone number:
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaSchoolPhoneExisting" formControlName="schoolPhoneExisting" placeholder="{{ schoolPhone }}" rows="1" style="width:50%"></textarea>
                    </div>
                </div>
    

                <div *ngIf="(showSchoolOther && showContactOther)" class="form-group row">
                    <label for="validationTextareaSchoolContactOther" class="col-sm-3 col-form-label">
                        School contact name:
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaSchoolContactOther" formControlName="schoolContactOther" placeholder="" rows="1" style="width:50%"></textarea>
                    </div>
                </div>
    
            
                <div *ngIf="showSchoolOther" class="form-group row">
                    <label for="validationTextareaSchoolNameOther" class="col-sm-3 col-form-label">
                        School name:
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaSchoolNameOther" formControlName="schoolNameOther" placeholder="" rows="1" style="width:50%"></textarea>
                    </div>
                </div>
    
                <div *ngIf="showSchoolOther" class="form-group row">
                    <label for="validationTextareaSchoolAddressOther" class="col-sm-3 col-form-label">
                        School address:
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaSchoolAddressOther" formControlName="schoolAddressOther" placeholder="" rows="4" style="width:50%"></textarea>
                    </div>
                </div>
    
                <div *ngIf="showSchoolOther" class="form-group row">
                    <label for="validationTextareaSchoolPhoneOther" class="col-sm-3 col-form-label">
                        School phone number:
                    </label>
                    <div class="col-sm-9">
                        <textarea id="validationTextareaSchoolPhoneOther" formControlName="schoolPhoneOther" placeholder="" rows="1" style="width:50%"></textarea>
                    </div>
                </div>

                <div *ngIf="showSchoolExisting || showSchoolOther" class="row">
                    <div class="col-12">
                        <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToCreateForm8843()">Continue</button>
                    </div>
                </div>
            </div>
          
        </form>
    </div>

    <div>
        <div *ngIf="showCreate8843" class="row">
            <div class="col-12">
                <p>That is all of the additional information needed to fill out your form 8843.</p>
                <p>Click the button below to create and download your Form 8843 along with filing instructions.</p>  
                <p>Be sure to go back and write in your information if you didn't answer all of the questions!</p>
            </div>
            <div class="col-12">
                <p>&nbsp;</p>
                <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmit8843Info()">Create your Form 8843 and Continue</button>
            </div>
        </div>
    </div>

</div>