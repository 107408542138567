import { Inject, Injectable } from '@angular/core';

import * as moment from 'moment';
import * as _ from 'lodash';

import { StateVisit } from './statevisit/statevisit';
//import { VisitModel } from "../aminra/visit/VisitModel";
import { Visit } from "../aminra/visit/Visit";
import { YearSummary } from '../aminra/YearSummary';


//create list of years for dropdown
const currentYear = new Date().getFullYear()
var yearList = []; 
for (var i = currentYear+1; i >= currentYear-40; i--) {
    yearList.push(i);
}

export const selectableTaxYears = yearList;

export const binChoices=[
    'Yes',
    'No',
]

export const selectYesNoChoices=[
    'Select...',
    'Yes',
    'No',
]

export interface NonResidentStatus {
    daysPresentInTaxYear: number;
    residencyStartDate?: string;
    residencyEndDate?: string;
    taxYear: number;
}

export interface SortedStateVisitsInTaxYear {
    sortedStateVisitsInTaxYear: StateVisit[];
}


@Injectable({
    providedIn: 'root'
})

export class pgnr06aService {

    constructor () {}

    stateVisits: StateVisit[];

    createYearSummaries(visits: Visit[], taxYear: number) {
        
        let allPresentYears: number[] = [];
        const yearSummaries: YearSummary[] = [];
        // Create a YearSummary for each present year
        allPresentYears.forEach((year: number) => {
            // For each YearSummary add visits that are present in that year.
            const visitsInThisYear: Visit[] = _.filter(visits, (visit: Visit) => {
                return visit.getYearsPresent().includes(year);
            });
            if (year == taxYear) {
            yearSummaries.push(new YearSummary(year, visitsInThisYear));
            }
        });
    }
    
    /**
     * Checks if there is any overlap in an array of Visits
     * @param stateVisits - Visit array
     */
    doStateVisitsOverlap(stateVisits: StateVisit[]): boolean {
        const sortedStateVisits: StateVisit[] = this.sortStateVisitsChronologically(stateVisits);
        for (let index = 1; index < sortedStateVisits.length; index++) {
            const previous = sortedStateVisits[index - 1];
            const current = sortedStateVisits[index];

            if (!previous.getStateExit() && current.getStateEntry()) {
                return true;
            }
            //if (previous.getStateExit().isSameOrAfter(current.getStateEntry()))
            if ((previous.getStateExit().startOf('day')).isAfter(current.getStateEntry().startOf('day')))
                return true;
        }
        return false;
    }



    /**
     * Checks if there is any overlap in an array of Visits
     * @param stateAndOUSVisits - Visit array
     */
    doStateAndOUSVisitsOverlap(stateAndOUSVisits: StateVisit[]): boolean {
        const sortedStateVisits: StateVisit[] = this.sortStateVisitsChronologically(stateAndOUSVisits);
        for (let index = 1; index < sortedStateVisits.length; index++) {
            const previous = sortedStateVisits[index - 1];
            const current = sortedStateVisits[index];

            if (!previous.getStateExit() && current.getStateEntry()) {
                return true;
            }
            //if (previous.getStateExit().isSameOrAfter(current.getStateEntry()))
            if ((previous.getStateExit().startOf('day')).isSameOrAfter(current.getStateEntry().startOf('day')))
                return true;
        }
        return false;
    }





    sortStateVisits(stateVisits: StateVisit[], taxYear: number): SortedStateVisitsInTaxYear {
        // Catch anything unexpected
        try {
            // Sort State Visits
            const sortedStateVisits = this.sortStateVisitsChronologically(stateVisits);
            for (let index = 0; index < sortedStateVisits.length; index++) {
                const current = sortedStateVisits[index];
            }
            let allPresentYears: number[] = [];
            let allExemptYears: number[] = [];
            //const yearSummaries: YearSummary[] = [];
            var sortedStateVisitsInTaxYear: StateVisit[] = [];


            // Add only the visits for the Tax Year
            stateVisits.forEach((stateVisit: StateVisit) =>  {
                //if (stateVisit.getYearsPresent().includes(taxYear)) {
                    sortedStateVisitsInTaxYear.push(stateVisit)
                //}
            })


        return {
            sortedStateVisitsInTaxYear
        }
    
        } catch (e) {
            return null;
        }

    }


    setStateVisits(stateVisits: StateVisit[]) {
        this.stateVisits = stateVisits
    }
    
    getSortedStateVisits() {
        return this.sortStateVisitsChronologically(this.stateVisits);
    }

    /**
 * Sort visits in order from latest entry date to most recent entry date
 * @param stateVisits - Array of visits to sort
 */
    sortStateVisitsChronologically(stateVisits: StateVisit[]): StateVisit[] {
        if (stateVisits && stateVisits.length > 1) {
            return stateVisits.sort((stateVisitA: StateVisit, stateVisitB: StateVisit) => {
                return stateVisitA.getStateEntry().diff(stateVisitB.getStateEntry());
            });
        } else {
            return stateVisits;
        }
    }

    /**
     * Helper function that gets years in a range
     * @param minInclusive - lower end of the range will be included if found
     * @param maxExclusive higher end of the range, will be excluded from result
     * @param years - list of years to pull range from
     */

    getYearsInRange(minInclusive: number, maxExclusive: number, years: number[]): number[] {
        let yearsInRange: number[] = [];
        // Return a filtered list of YearWithVisa objects that has a year attribute within the specified range
        yearsInRange = _.filter(years, (year: number) => {
            return year >= minInclusive && year < maxExclusive;
        });
        return yearsInRange;
    }

}
