import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
// tslint:disable-next-line: max-line-length
//import { faBars, faInfoCircle, faGlobeAmericas, faCaretDown, faMapPin, faCalendar, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle, faCalendar } from '@fortawesome/free-solid-svg-icons';
//import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
//import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './ngbDateCustomFormatter';
//import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPrintModule } from 'ngx-print';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { ToolsComponent } from './tools/tools.component';
import { aminraComponent } from './tools/aminra/aminra.component';
import { VisitComponent } from './tools/aminra/visit/visit.component';
import { tbComponent } from './tools/treatybenefits/tb.component';
import { tbDocComponent } from './tools/treatybenefits/docs/docs.component';
import { PGnr00Component } from './tools/pg-nr00/pg-nr00.component';
import { PGnr01Component } from './tools/pg-nr01/pg-nr01.component';
import { PGnr02Component } from './tools/pg-nr02/pg-nr02.component';
import { PGnr03Component } from './tools/pg-nr03/pg-nr03.component';
import { PGnr04Component } from './tools/pg-nr04/pg-nr04.component';
import { PGnr05Component } from './tools/pg-nr05/pg-nr05.component';
import { PGnr06Component } from './tools/pg-nr06/pg-nr06.component';
import { PGnr06aComponent } from './tools/pg-nr06a/pg-nr06a.component';
import { StateVisitComponent } from './tools/pg-nr06a/statevisit/statevisit.component';
import { PGnr06bComponent } from './tools/pg-nr06b/pg-nr06b.component';
import { PGnr07Component } from './tools/pg-nr07/pg-nr07.component';
import { PGnr08Component } from './tools/pg-nr08/pg-nr08.component';
import { PGnr08CompleteComponent } from './tools/pg-nr08-complete/pg-nr08-complete.component';
import { PGnr8843Component } from './tools/pg-nr8843/pg-nr8843.component'
import { PGnr8843CompleteComponent } from './tools/pg-nr8843-complete/pg-nr8843-complete.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ToolsComponent,
    aminraComponent,
    VisitComponent,
    tbComponent,
    tbDocComponent,
    PGnr00Component,
    PGnr01Component,
    PGnr02Component,
    PGnr03Component,
    PGnr04Component,
    PGnr05Component,
    PGnr06Component,
    PGnr06aComponent,
    StateVisitComponent,
    PGnr06bComponent,
    PGnr07Component,
    PGnr08Component,
    PGnr08CompleteComponent,
    PGnr8843Component,
    PGnr8843CompleteComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FontAwesomeModule,
    //LeafletModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    //RecaptchaModule,
    //RecaptchaFormsModule,
    NgSelectModule,
    NgxPrintModule
  ],
  entryComponents: [],
  //providers: [NavigationService, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    //library.add(faBars, faTwitter, faFacebook, faInfoCircle, faGlobeAmericas, faCaretDown, faMapPin, faCalendar, faAngleDoubleRight);
    library.add(faInfoCircle, faCalendar, faYoutube);
  }
}
