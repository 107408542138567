import { Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { StateVisitModel } from '../pg-nr06a/statevisit/statevisitModel';
import { StateVisit } from '../pg-nr06a/statevisit/statevisit';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service';


//
@Component({
  selector: 'app-pg-nr06b',
  templateUrl: './pg-nr06b.component.html',
  styleUrls: ['./pg-nr06b.component.scss'],
})

export class PGnr06bComponent implements OnInit {

  // Variables for controlling whether messages are shown to the user, error messages or status
  showNewStateVisitHelpText = false;
  showOverlapError = false;
  showEndDateCaveat = false;
  stateVisitsHaveErrors = false;
  showStatus = false;

  // Variables holding information
  
  stateVisits: StateVisitModel[] = [];
  stateVisitsInTaxYear: StateVisit[] = [];
  taxYear: any;
  selectAllPartNone = ['Yes - for ALL of this visit', 'Yes - for PART of this visit', 'No - for NONE of this visit']
  selectYesNo = ['Yes', 'No']
  stateIncomes: any[] = [];
  navFrompg: boolean;

  numberStateVisits: number = 0
  numberOUSVisits: number = 0
  OUSVisitsInTaxYearStartShow: moment.Moment[] = [];
  OUSVisitsInTaxYearEndShow: moment.Moment[] = [];

  MNAbode: boolean[]    = [false, false, false, false, false, false, false, false, false, false]
  notMNAbode: boolean[] = [false, false, false, false, false, false, false, false, false, false]
  OUSMNAbode: boolean[] = [false, false, false, false, false, false, false, false, false, false]
  //MNAbodeIncome: boolean[] = [false, false, false, false, false, false, false, false, false, false]
  notMNAbodeIncome: boolean[] = [null, null, null, null, null, null, null, null, null, null]
  OUSMNAbodeIncome: boolean[] = [false, false, false, false, false, false, false, false, false, false]
  MNAbodeAllPartNone: string[] = []
  notMNAbodeAllPartNone: string[] = []
  USMNAbodeAllPartNone: string[] = []
  OUSMNAbodeAllPartNone: string[] = []
  allMNAbodeAllPartNone: string[] = []

  MNAbodeTF: boolean = false
  MNAbodeYN0: string = null
  MNAbodeYN1: string = null
  MNAbodeYN2: string = null
  MNAbodeYN3: string = null
  MNAbodeYN4: string = null
  MNAbodeYN5: string = null
  MNAbodeYN6: string = null
  MNAbodeYN7: string = null
  MNAbodeYN8: string = null
  MNAbodeYN9: string = null
  
  notMNAbodeTF: boolean = false
  notMNAbodeYN0: string = null;
  notMNAbodeYN1: string = null;
  notMNAbodeYN2: string = null;
  notMNAbodeYN3: string = null;
  notMNAbodeYN4: string = null;
  notMNAbodeYN5: string = null;
  notMNAbodeYN6: string = null;
  notMNAbodeYN7: string = null;
  notMNAbodeYN8: string = null;
  notMNAbodeYN9: string = null;

  notMNAbodeIncomeTF: boolean = false
  notMNAbodeIncomeYN0: string = null;
  notMNAbodeIncomeYN1: string = null;
  notMNAbodeIncomeYN2: string = null;
  notMNAbodeIncomeYN3: string = null;
  notMNAbodeIncomeYN4: string = null;
  notMNAbodeIncomeYN5: string = null;
  notMNAbodeIncomeYN6: string = null;
  notMNAbodeIncomeYN7: string = null;
  notMNAbodeIncomeYN8: string = null;
  notMNAbodeIncomeYN9: string = null;

  showNotMNAbodeIncomeQ0: boolean = false
  showNotMNAbodeIncomeQ1: boolean = false
  showNotMNAbodeIncomeQ2: boolean = false
  showNotMNAbodeIncomeQ3: boolean = false
  showNotMNAbodeIncomeQ4: boolean = false
  showNotMNAbodeIncomeQ5: boolean = false
  showNotMNAbodeIncomeQ6: boolean = false
  showNotMNAbodeIncomeQ7: boolean = false
  showNotMNAbodeIncomeQ8: boolean = false
  showNotMNAbodeIncomeQ9: boolean = false

  OUSMNAbodeTF: boolean = false
  OUSMNAbodeYN0: string = null
  OUSMNAbodeYN1: string = null
  OUSMNAbodeYN2: string = null
  OUSMNAbodeYN3: string = null
  OUSMNAbodeYN4: string = null
  OUSMNAbodeYN5: string = null
  OUSMNAbodeYN6: string = null
  OUSMNAbodeYN7: string = null
  OUSMNAbodeYN8: string = null
  OUSMNAbodeYN9: string = null

  OUSMNAbodeIncomeTF: boolean = false
  OUSMNAbodeIncomeYN0: string = null;
  OUSMNAbodeIncomeYN1: string = null;
  OUSMNAbodeIncomeYN2: string = null;
  OUSMNAbodeIncomeYN3: string = null;
  OUSMNAbodeIncomeYN4: string = null;
  OUSMNAbodeIncomeYN5: string = null;
  OUSMNAbodeIncomeYN6: string = null;
  OUSMNAbodeIncomeYN7: string = null;
  OUSMNAbodeIncomeYN8: string = null;
  OUSMNAbodeIncomeYN9: string = null;

  OUSShowMNAbodeIncomeQ0: boolean = false
  OUSShowMNAbodeIncomeQ1: boolean = false
  OUSShowMNAbodeIncomeQ2: boolean = false
  OUSShowMNAbodeIncomeQ3: boolean = false
  OUSShowMNAbodeIncomeQ4: boolean = false
  OUSShowMNAbodeIncomeQ5: boolean = false
  OUSShowMNAbodeIncomeQ6: boolean = false
  OUSShowMNAbodeIncomeQ7: boolean = false
  OUSShowMNAbodeIncomeQ8: boolean = false
  OUSShowMNAbodeIncomeQ9: boolean = false

  MNAbodeTotalDays: number;

  // Used to give each added visit a unique id
  stateVisitCounter = 0;
  showUnexpectedErrorMessage: boolean;

  constructor(
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    ) {}
 
  ngOnInit() {
    this.navFrompg = true
    this.taxYear = sessionStorage.taxYear;

    this.stateVisitsInTaxYear = this.appService.getStateVisits()
    this.numberStateVisits = this.stateVisitsInTaxYear.length

    this.OUSVisitsInTaxYearStartShow = this.appService.getOUSVisitsInTaxYearStartShow()
    this.OUSVisitsInTaxYearEndShow = this.appService.getOUSVisitsInTaxYearEndShow()
    this.numberOUSVisits = this.OUSVisitsInTaxYearEndShow.length

    this.MNAbodeTotalDays = 0
  }
 


//OUS VISITS

  ////
  OUSMNAbodeChange0() {
    if (this.OUSMNAbodeYN0 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN0 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN0 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN0 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[0] = true
      this.OUSShowMNAbodeIncomeQ0 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[0].diff(this.OUSVisitsInTaxYearStartShow[0], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[0] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[0].diff(this.OUSVisitsInTaxYearStartShow[0], "d") + 1)
      }
      this.OUSMNAbode[0] = false
      this.OUSShowMNAbodeIncomeQ0 = false
    }
  }
  OUSMNAbodeIncomeChange0() {
    if (this.OUSMNAbodeIncomeYN0 == 'Yes') {
      this.OUSMNAbodeIncome[0] = true
    }
    else {
      this.notMNAbodeIncome[0] = false
    }
  }

  ////
  OUSMNAbodeChange1() {
    if (this.OUSMNAbodeYN1 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN1 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN1 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN1 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[1] = true
      this.OUSShowMNAbodeIncomeQ1 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[1].diff(this.OUSVisitsInTaxYearStartShow[1], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[1] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[1].diff(this.OUSVisitsInTaxYearStartShow[1], "d") + 1)
      }
      this.OUSMNAbode[1] = false
      this.OUSShowMNAbodeIncomeQ1 = false
    }
  }
  OUSMNAbodeIncomeChange1() {
    if (this.OUSMNAbodeIncomeYN1 == 'Yes') {
      this.OUSMNAbodeIncome[1] = true
    }
    else {
      this.notMNAbodeIncome[1] = false
    }
  }

  ////
  OUSMNAbodeChange2() {
    if (this.OUSMNAbodeYN2 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN2 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN2 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN2 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[2] = true
      this.OUSShowMNAbodeIncomeQ2 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[2].diff(this.OUSVisitsInTaxYearStartShow[2], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[2] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[2].diff(this.OUSVisitsInTaxYearStartShow[2], "d") + 1)
      }
      this.OUSMNAbode[2] = false
      this.OUSShowMNAbodeIncomeQ2 = false
    }
  }
  OUSNotMNAbodeIncomeChange2() {
    if (this.OUSMNAbodeIncomeYN2 == 'Yes') {
      this.notMNAbodeIncome[2] = true
    }
    else {
      this.notMNAbodeIncome[2] = false
    }
  }


  ////
  OUSMNAbodeChange3() {
    if (this.OUSMNAbodeYN3 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN3 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN3 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN3 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[3] = true
      this.OUSShowMNAbodeIncomeQ3 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[3].diff(this.OUSVisitsInTaxYearStartShow[3], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[3] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[3].diff(this.OUSVisitsInTaxYearStartShow[3], "d") + 1)
      }
      this.OUSMNAbode[3] = false
      this.OUSShowMNAbodeIncomeQ3 = false
    }
  }
  OUSNotMNAbodeIncomeChange3() {
    if (this.OUSMNAbodeIncomeYN3 == 'Yes') {
      this.notMNAbodeIncome[3] = true
    }
    else {
      this.notMNAbodeIncome[3] = false
    }
  }
  
  ////
  OUSMNAbodeChange4() {
    if (this.OUSMNAbodeYN4 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN4 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN4 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN4 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[4] = true
      this.OUSShowMNAbodeIncomeQ4 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[4].diff(this.OUSVisitsInTaxYearStartShow[4], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[4] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[4].diff(this.OUSVisitsInTaxYearStartShow[4], "d") + 1)
      }
      this.OUSMNAbode[4] = false
      this.OUSShowMNAbodeIncomeQ4 = false
    }
  }
  OUSNotMNAbodeIncomeChange4() {
    if (this.OUSMNAbodeIncomeYN4 == 'Yes') {
      this.notMNAbodeIncome[4] = true
    }
    else {
      this.notMNAbodeIncome[4] = false
    }
  }
  
  ////
  OUSMNAbodeChange5() {
    if (this.OUSMNAbodeYN5 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN5 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN5 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN5 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[5] = true
      this.OUSShowMNAbodeIncomeQ5 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[5].diff(this.OUSVisitsInTaxYearStartShow[5], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[5] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[5].diff(this.OUSVisitsInTaxYearStartShow[5], "d") + 1)
      }
      this.OUSMNAbode[5] = false
      this.OUSShowMNAbodeIncomeQ5 = false
    }
  }
  OUSNotMNAbodeIncomeChange5() {
    if (this.OUSMNAbodeIncomeYN5 == 'Yes') {
      this.notMNAbodeIncome[5] = true
    }
    else {
      this.notMNAbodeIncome[5] = false
    }
  }
  
  ////
  OUSMNAbodeChange6() {
    if (this.OUSMNAbodeYN6 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN6 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN6 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN6 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[6] = true
      this.OUSShowMNAbodeIncomeQ6 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[6].diff(this.OUSVisitsInTaxYearStartShow[6], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[6] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[6].diff(this.OUSVisitsInTaxYearStartShow[6], "d") + 1)
      }
      this.OUSMNAbode[6] = false
      this.OUSShowMNAbodeIncomeQ6 = false
    }
  }
  OUSNotMNAbodeIncomeChange6() {
    if (this.OUSMNAbodeIncomeYN6 == 'Yes') {
      this.notMNAbodeIncome[6] = true
    }
    else {
      this.notMNAbodeIncome[6] = false
    }
  }
  
  ////
  OUSMNAbodeChange7() {
    if (this.OUSMNAbodeYN7 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN7 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN7 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN7 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[7] = true
      this.OUSShowMNAbodeIncomeQ7 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[7].diff(this.OUSVisitsInTaxYearStartShow[7], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[7] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[7].diff(this.OUSVisitsInTaxYearStartShow[7], "d") + 1)
      }
      this.OUSMNAbode[7] = false
      this.OUSShowMNAbodeIncomeQ7 = false
    }
  }
  OUSNotMNAbodeIncomeChange7() {
    if (this.OUSMNAbodeIncomeYN7 == 'Yes') {
      this.notMNAbodeIncome[7] = true
    }
    else {
      this.notMNAbodeIncome[7] = false
    }
  }
  
  ////
  OUSMNAbodeChange8() {
    if (this.OUSMNAbodeYN8 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN8 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN8 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN8 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[8] = true
      this.OUSShowMNAbodeIncomeQ8 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[8].diff(this.OUSVisitsInTaxYearStartShow[8], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[8] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[8].diff(this.OUSVisitsInTaxYearStartShow[8], "d") + 1)
      }
      this.OUSMNAbode[8] = false
      this.OUSShowMNAbodeIncomeQ8 = false
    }
  }
  OUSNotMNAbodeIncomeChange8() {
    if (this.OUSMNAbodeIncomeYN8 == 'Yes') {
      this.notMNAbodeIncome[8] = true
    }
    else {
      this.notMNAbodeIncome[8] = false
    }
  }
  
  
  ////
  OUSMNAbodeChange9() {
    if (this.OUSMNAbodeYN9 == 'Yes - for ALL of this visit' ||  this.OUSMNAbodeYN9 == 'Yes - for PART of this visit') {
      if (this.OUSMNAbodeYN9 == 'Yes - for ALL of this visit') {this.OUSMNAbodeAllPartNone.push('ALL')}
      if (this.OUSMNAbodeYN9 == 'Yes - for PART of this visit') {this.OUSMNAbodeAllPartNone.push('PART')}
      this.OUSMNAbode[9] = true
      this.OUSShowMNAbodeIncomeQ9 = false
      this.MNAbodeTotalDays += (this.OUSVisitsInTaxYearEndShow[9].diff(this.OUSVisitsInTaxYearStartShow[9], "d") + 1)
    }
    else {
      this.OUSMNAbodeAllPartNone.push('NONE')
      if (this.OUSMNAbode[9] == true) {
        this.MNAbodeTotalDays -= (this.OUSVisitsInTaxYearEndShow[9].diff(this.OUSVisitsInTaxYearStartShow[9], "d") + 1)
      }
      this.OUSMNAbode[9] = false
      this.OUSShowMNAbodeIncomeQ9 = false
    }
  }
  OUSNotMNAbodeIncomeChange9() {
    if (this.OUSMNAbodeIncomeYN9 == 'Yes') {
      this.notMNAbodeIncome[9] = true
    }
    else {
      this.notMNAbodeIncome[9] = false
    }
  }



//STATE VISITS

  /////
  MNAbodeChange0() {
    if (this.MNAbodeYN0 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN0 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN0 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN0 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[0] = true
      this.stateVisitsInTaxYear[0].abode = true
      this.showNotMNAbodeIncomeQ0 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[0].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[0] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[0].days
      }
      this.MNAbode[0] = false
      this.stateVisitsInTaxYear[0].abode = false
      this.showNotMNAbodeIncomeQ0 = false
    }
  }
  notMNAbodeChange0() {
    if (this.notMNAbodeYN0 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN0 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN0 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN0 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.notMNAbode[0] = true
      this.stateVisitsInTaxYear[0].abode = true
      this.showNotMNAbodeIncomeQ0 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[0].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[0] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[0].days
      }
      this.notMNAbode[0] = false
      this.stateVisitsInTaxYear[0].abode = false
      this.showNotMNAbodeIncomeQ0 = false
    }
  }
  notMNAbodeIncomeChange0() {
    if (this.notMNAbodeIncomeYN0 == 'Yes') {
      this.notMNAbodeIncome[0] = true
      sessionStorage.notMNAbodeIncomeYN0 = "Yes"
    }
    else {
      this.notMNAbodeIncome[0] = false
      sessionStorage.notMNAbodeIncomeYN0 = "No"
    }
  }

  /////
  MNAbodeChange1() {
    if (this.MNAbodeYN1 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN1 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN1 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN1 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[1] = true
      this.stateVisitsInTaxYear[1].abode = true
      this.showNotMNAbodeIncomeQ1 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[1].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[1] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[1].days
      }
      this.MNAbode[1] = false
      this.stateVisitsInTaxYear[1].abode = false
      this.showNotMNAbodeIncomeQ1 = false
    }
  }
  notMNAbodeChange1() {
    if (this.notMNAbodeYN1 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN1 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN1 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN1 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[1] = true
      this.notMNAbode[1] = true
      this.stateVisitsInTaxYear[1].abode = true
      this.showNotMNAbodeIncomeQ1 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[1].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[1] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[1].days
      }
      this.notMNAbode[1] = false
      this.stateVisitsInTaxYear[1].abode = false
      this.showNotMNAbodeIncomeQ1 = false
    }
  }
  notMNAbodeIncomeChange1() {
    if (this.notMNAbodeIncomeYN1 == 'Yes') {
      this.notMNAbodeIncome[1] = true
      sessionStorage.notMNAbodeIncomeYN1 = "Yes"
    }
    else {
      this.notMNAbodeIncome[1] = false
      sessionStorage.notMNAbodeIncomeYN1 = "No"
    }
  }

  /////
  MNAbodeChange2() {
    if (this.MNAbodeYN2 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN2 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN2 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN2 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[2] = true
      this.stateVisitsInTaxYear[2].abode = true
      this.showNotMNAbodeIncomeQ2 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[2].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[2] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[2].days
      }
      this.MNAbode[2] = false
      this.stateVisitsInTaxYear[2].abode = false
      this.showNotMNAbodeIncomeQ2 = false
    }
  }
  notMNAbodeChange2() {
    if (this.notMNAbodeYN2 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN2 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN2 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN2 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[2] = true
      this.notMNAbode[2] = true
      this.stateVisitsInTaxYear[2].abode = true
      this.showNotMNAbodeIncomeQ2 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[2].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[2] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[2].days
      }
      this.notMNAbode[2] = false
      this.stateVisitsInTaxYear[2].abode = false
      this.showNotMNAbodeIncomeQ2 = false
    }
  }
  notMNAbodeIncomeChange2() {
    if (this.notMNAbodeIncomeYN2 == 'Yes') {
      this.notMNAbodeIncome[2] = true
      sessionStorage.notMNAbodeIncomeYN2 = "Yes"
    }
    else {
      this.notMNAbodeIncome[2] = false
      sessionStorage.notMNAbodeIncomeYN2 = "No"
    }
  }

  /////
  MNAbodeChange3() {
    if (this.MNAbodeYN3 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN3 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN3 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN3 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[3] = true
      this.stateVisitsInTaxYear[3].abode = true
      this.showNotMNAbodeIncomeQ3 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[3].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[3] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[3].days
      }
      this.MNAbode[3] = false
      this.stateVisitsInTaxYear[3].abode = false
      this.showNotMNAbodeIncomeQ3 = false
    }
  }
  notMNAbodeChange3() {
    if (this.notMNAbodeYN3 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN3 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN3 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN3 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[3] = true
      this.notMNAbode[3] = true
      this.stateVisitsInTaxYear[3].abode = true
      this.showNotMNAbodeIncomeQ3 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[3].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[3] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[3].days
      }
      this.notMNAbode[3] = false
      this.stateVisitsInTaxYear[3].abode = false
      this.showNotMNAbodeIncomeQ3 = false
    }
  }
  notMNAbodeIncomeChange3() {
    if (this.notMNAbodeIncomeYN3 == 'Yes') {
      this.notMNAbodeIncome[3] = true
      sessionStorage.notMNAbodeIncomeYN3 = "Yes"
    }
    else {
      this.notMNAbodeIncome[3] = false
      sessionStorage.notMNAbodeIncomeYN3 = "No"
    }
  }

  ////
  MNAbodeChange4() {
    if (this.MNAbodeYN4 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN4 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN4 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN4 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[4] = true
      this.stateVisitsInTaxYear[4].abode = true
      this.showNotMNAbodeIncomeQ4 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[4].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[4] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[4].days
      }
      this.MNAbode[4] = false

      this.stateVisitsInTaxYear[4].abode = false
      this.showNotMNAbodeIncomeQ4 = false
    }
  }
  notMNAbodeChange4() {
    if (this.notMNAbodeYN4 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN4 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN4 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN4 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[4] = true
      this.notMNAbode[4] = true
      this.stateVisitsInTaxYear[4].abode = true
      this.showNotMNAbodeIncomeQ4 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[4].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[4] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[4].days
      }
      this.notMNAbode[4] = false
      this.stateVisitsInTaxYear[4].abode = false
      this.showNotMNAbodeIncomeQ4 = false
    }
  }
  notMNAbodeIncomeChange4() {
    if (this.notMNAbodeIncomeYN4 == 'Yes') {
      this.notMNAbodeIncome[4] = true
      sessionStorage.notMNAbodeIncomeYN4 = "Yes"
    }
    else {
      this.notMNAbodeIncome[4] = false
      sessionStorage.notMNAbodeIncomeYN4 = "No"
    }
  }

  /////
  MNAbodeChange5() {
    if (this.MNAbodeYN5 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN5 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN5 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN5 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[5] = true
      this.stateVisitsInTaxYear[5].abode = true
      this.showNotMNAbodeIncomeQ5 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[5].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[5] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[5].days
      }
      this.MNAbode[5] = false
      this.stateVisitsInTaxYear[5].abode = false
      this.showNotMNAbodeIncomeQ5 = false
    }
  }
  notMNAbodeChange5() {
    if (this.notMNAbodeYN5 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN5 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN5 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN5 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[5] = true
      this.notMNAbode[5] = true
      this.stateVisitsInTaxYear[5].abode = true
      this.showNotMNAbodeIncomeQ5 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[5].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[5] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[5].days
      }
      this.notMNAbode[5] = false
      this.stateVisitsInTaxYear[5].abode = false
      this.showNotMNAbodeIncomeQ5 = false
    }
  }
  notMNAbodeIncomeChange5() {
    if (this.notMNAbodeIncomeYN5 == 'Yes') {
      this.notMNAbodeIncome[5] = true
      sessionStorage.notMNAbodeIncomeYN5 = "Yes"
    }
    else {
      this.notMNAbodeIncome[5] = false
      sessionStorage.notMNAbodeIncomeYN5 = "No"
    }
  }

  /////
  MNAbodeChange6() {
    if (this.MNAbodeYN6 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN6 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN6 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN6 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[6] = true
      this.stateVisitsInTaxYear[6].abode = true
      this.showNotMNAbodeIncomeQ6 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[6].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[6] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[6].days
      }
      this.MNAbode[6] = false
      this.stateVisitsInTaxYear[6].abode = false
      this.showNotMNAbodeIncomeQ6 = false
    }
  }
  notMNAbodeChange6() {
    if (this.notMNAbodeYN6 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN6 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN6 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN6 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[6] = true
      this.notMNAbode[6] = true
      this.stateVisitsInTaxYear[6].abode = true
      this.showNotMNAbodeIncomeQ6 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[6].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[6] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[6].days
      }
      this.notMNAbode[6] = false
      this.stateVisitsInTaxYear[6].abode = false
      this.showNotMNAbodeIncomeQ6 = false
    }
  }
  notMNAbodeIncomeChange6() {
    if (this.notMNAbodeIncomeYN6 == 'Yes') {
      this.notMNAbodeIncome[6] = true
      sessionStorage.notMNAbodeIncomeYN6 = "Yes"
    }
    else {
      this.notMNAbodeIncome[6] = false
      sessionStorage.notMNAbodeIncomeYN6 = "No"
    }
  }

  /////
  MNAbodeChange7() {
    if (this.MNAbodeYN7 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN7 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN7 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN7 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[7] = true
      this.stateVisitsInTaxYear[7].abode = true
      this.showNotMNAbodeIncomeQ7 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[7].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[7] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[7].days
      }
      this.MNAbode[7] = false
      this.stateVisitsInTaxYear[7].abode = false
      this.showNotMNAbodeIncomeQ7 = false
    }
  }
  notMNAbodeChange7() {
    if (this.notMNAbodeYN7 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN7 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN7 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN7 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[7] = true
      this.notMNAbode[7] = true
      this.stateVisitsInTaxYear[7].abode = true
      this.showNotMNAbodeIncomeQ7 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[7].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[7] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[7].days
      }
      this.notMNAbode[7] = false
      this.stateVisitsInTaxYear[7].abode = false
      this.showNotMNAbodeIncomeQ7 = false
    }
  }
  notMNAbodeIncomeChange7() {
    if (this.notMNAbodeIncomeYN7 == 'Yes') {
      this.notMNAbodeIncome[7] = true
      sessionStorage.notMNAbodeIncomeYN7 = "Yes"
    }
    else {
      this.notMNAbodeIncome[7] = false
      sessionStorage.notMNAbodeIncomeYN7 = "No"
    }
  }

  /////
  MNAbodeChange8() {
    if (this.MNAbodeYN8 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN8 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN8 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN8 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[8] = true
      this.stateVisitsInTaxYear[8].abode = true
      this.showNotMNAbodeIncomeQ8 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[8].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[8] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[8].days
      }
      this.MNAbode[8] = false
      this.stateVisitsInTaxYear[8].abode = false
      this.showNotMNAbodeIncomeQ8 = false
    }
  }
  notMNAbodeChange8() {
    if (this.notMNAbodeYN8 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN8 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN8 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN8 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[8] = true
      this.notMNAbode[8] = true
      this.stateVisitsInTaxYear[8].abode = true
      this.showNotMNAbodeIncomeQ8 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[8].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[8] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[8].days
      }
      this.notMNAbode[8] = false
      this.stateVisitsInTaxYear[8].abode = false
      this.showNotMNAbodeIncomeQ8 = false
    }
  }
  notMNAbodeIncomeChange8() {
    if (this.notMNAbodeIncomeYN8 == 'Yes') {
      this.notMNAbodeIncome[8] = true
      sessionStorage.notMNAbodeIncomeYN8 = "Yes"
    }
    else {
      this.notMNAbodeIncome[8] = false
      sessionStorage.notMNAbodeIncomeYN8 = "No"

    }
  }

  ////
  MNAbodeChange9() {
    if (this.MNAbodeYN9 == 'Yes - for ALL of this visit' ||  this.MNAbodeYN9 == 'Yes - for PART of this visit') {
      if (this.MNAbodeYN9 == 'Yes - for ALL of this visit') {this.MNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.MNAbodeYN9 == 'Yes - for PART of this visit') {this.MNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[9] = true
      this.stateVisitsInTaxYear[9].abode = true
      this.showNotMNAbodeIncomeQ9 = false
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[9].days
    }
    else {
      this.MNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.MNAbode[9] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[9].days
      }
      this.MNAbode[9] = false
      this.stateVisitsInTaxYear[9].abode = false
      this.showNotMNAbodeIncomeQ9 = false
    }
  }
  notMNAbodeChange9() {
    if (this.notMNAbodeYN9 == 'Yes - for ALL of this visit' ||  this.notMNAbodeYN9 == 'Yes - for PART of this visit') {
      if (this.notMNAbodeYN9 == 'Yes - for ALL of this visit') {this.notMNAbodeAllPartNone.push('ALL'); this.USMNAbodeAllPartNone.push('ALL') }
      if (this.notMNAbodeYN9 == 'Yes - for PART of this visit') {this.notMNAbodeAllPartNone.push('PART'); this.USMNAbodeAllPartNone.push('PART') }
      this.MNAbode[9] = true
      this.notMNAbode[9] = true
      this.stateVisitsInTaxYear[9].abode = true
      this.showNotMNAbodeIncomeQ9 = true
      this.MNAbodeTotalDays += this.stateVisitsInTaxYear[9].days
    }
    else {
      this.notMNAbodeAllPartNone.push('NONE')
      this.USMNAbodeAllPartNone.push('NONE')
      if (this.notMNAbode[9] == true) {
        this.MNAbodeTotalDays -= this.stateVisitsInTaxYear[9].days
      }
      this.notMNAbode[9] = false
      this.stateVisitsInTaxYear[9].abode = false
      this.showNotMNAbodeIncomeQ9 = false
    }
  }
  notMNAbodeIncomeChange9() {
    if (this.notMNAbodeIncomeYN9 == 'Yes') {
      this.notMNAbodeIncome[9] = true
      sessionStorage.notMNAbodeIncomeYN9 = "Yes"
    }
    else {
      this.notMNAbodeIncome[9] = false
      sessionStorage.notMNAbodeIncomeYN9 = "No"
    }
  }

  otherStateIncomes(inc: Object, state: string) {
    var otherStateYes = false
    for (var entry = 0; entry < Object.keys(inc).length; ++entry) {
      Object.keys(inc).forEach(function(entry) {
        if (entry != state) {
          if (inc[entry] == 'Yes') {
            otherStateYes = true
          }
        }
      })
    }
    return otherStateYes
  }

  onSubmit() {
    this.stateIncomes = this.appService.getStateIncomes()

    //NEW PARSING LOGIC
    this.allMNAbodeAllPartNone.push.apply(this.allMNAbodeAllPartNone, this.MNAbodeAllPartNone)
    this.allMNAbodeAllPartNone.push.apply(this.allMNAbodeAllPartNone, this.notMNAbodeAllPartNone)
    this.allMNAbodeAllPartNone.push.apply(this.allMNAbodeAllPartNone, this.OUSMNAbodeAllPartNone)

    const uniqueValues = [...new Set (this.allMNAbodeAllPartNone)]

    if (uniqueValues.length == 1 && uniqueValues[0] == "ALL") {
      sessionStorage.stateResMN = "FYR"
    }
    else if (uniqueValues.length == 1 && uniqueValues[0] == "NONE") {
      sessionStorage.stateResMN = "NR"
    }
    else {
      sessionStorage.stateResMN = "PYR"
    }


    // ADD SESSION VARIABLES FOR PG
    let i = 0;
    this.OUSMNAbodeAllPartNone.forEach((str: string) => {
      sessionStorage['OUSAbode' + i] = String(str)
      i += 1;
    })

    i = 0
    this.USMNAbodeAllPartNone.forEach((str: string) => {
      sessionStorage['stateAbode' + i] = String(str)
      i += 1;
    })

    i = 0
    this.notMNAbodeIncome.forEach((item: boolean) => {
      if (item == true) {
        sessionStorage['notMNAbodeIncome' + i.toString] = "Yes"
        i += 1
      }
      if (item == false) {
        sessionStorage['notMNAbodeIncome' + i.toString] = "No"
        i += 1
      }

    })


/*


    //Parse all abode occurances
    if (this.MNAbode.includes(true)) {
      this.MNAbodeTF = true }
    if (this.notMNAbode.includes(true)) {
      this.notMNAbodeTF = true }
    if (this.OUSMNAbode.includes(true)) {
      this.OUSMNAbodeTF = true }
    
    //if (this.MNAbodeIncome.includes(true)) {
    //  this.MNAbodeIncomeTF = true }
    if (this.notMNAbodeIncome.includes(true)) {
      this.notMNAbodeIncomeTF = true }
    if (this.OUSMNAbodeIncome.includes(true)) {
      this.OUSMNAbodeIncomeTF = true }

    //Abode = 0 days
    if (this.MNAbodeTotalDays == 0) {
      sessionStorage.MNQPage = 'Yes'
//      sessionStorage.guide_MN2 = 'Yes'
//      sessionStorage.guide_MN3 = 'Yes'
    }

    //Abode = 365/366 days
    else if (this.MNAbodeTotalDays == (moment([this.taxYear]).isLeapYear() ? 366: 365)) {
      sessionStorage.stateResMN = "FYR"
      sessionStorage.MNQPage = 'Yes'
      sessionStorage.MNPropTaxPage = 'Yes'
//      if (this.otherStateIncomes(this.stateIncomes, 'Minnesota') == false) {
//        sessionStorage.guide_MN = "Yes"
//      }
//      else {
//        sessionStorage.guide_MNOtherState = "Yes"
//      }
    }

    //0 < Abode < 365/366, MN Income = No, other state income while MN abode = Yes
    else if (this.stateIncomes['Minnesota'] == 'No' && this.notMNAbodeIncomeTF == true) {
      sessionStorage.stateResMN = "PYR"
      sessionStorage.MNQPage = 'Yes'
      sessionStorage.MNPropTaxPage = 'Yes'
//      sessionStorage.guide_MNPY = "Yes"
//      sessionStorage.guide_MNOtherState = "Yes"
    }

    //0 < Abode < 365/366, MN Income = Yes, other state income while MN abode = Yes
    else if (this.stateIncomes['Minnesota'] == 'Yes' && this.notMNAbodeIncomeTF == true) {
      sessionStorage.stateResMN = "PYR"
      sessionStorage.MNQPage = 'Yes'
      sessionStorage.MNPropTaxPage = 'Yes'
//      sessionStorage.guide_MNPY = "Yes"
//      sessionStorage.guide_MNOtherState = "Yes"
    }

    //0 < Abode < 365/366, MN Income = Yes, other state income while MN abode = No
    else if (this.stateIncomes['Minnesota'] == 'Yes' && this.notMNAbodeIncomeTF == false) {
      sessionStorage.stateResMN = "PYR"
      sessionStorage.MNQPage = 'Yes'
      sessionStorage.MNPropTaxPage = 'Yes'
//      sessionStorage.guide_MNPY = "Yes"
    }

    //0 < Abode < 365/366, MN Income = No, other state income while MN abode = No
    else if (this.stateIncomes['Minnesota'] == 'No' && this.notMNAbodeIncomeTF == false) {
    }



*/


    if (sessionStorage.MNAddlQ == 'Yes') {
      sessionStorage.backPage = 'pg-nr06b'
      this.router.navigate(['../pg-nr07'], {relativeTo: this.route})
    }
    else {
      sessionStorage.backPage = 'pg-nr06b'
      this.router.navigate(['../pg-nr08'], {relativeTo: this.route})
    }
  }

  onSubmitBack() {
      if (this.navFrompg) {
        this.router.navigate(['../pg-nr06a'], {relativeTo: this.route})
      }
      else {
        this.router.navigate(['../tools'], {relativeTo: this.route});
      }
  }
  
}
