import { Component, OnInit } from '@angular/core';
import { countriesList } from './pg-nr01.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pg-nr01',
  templateUrl: './pg-nr01.component.html',
  styleUrls: ['./pg-nr01.component.scss']
})


export class PGnr01Component implements OnInit {

  selectYesNo = ['Yes', 'No']
  showCitizenshipCountryQ: boolean = false;
  showTaxResidencyCountryQ: boolean = false;
  countries = countriesList;
  selectedCitizenCountry1 = null;
  selectedCitizenCountry2 = null;
  selectedCitizenCountry3 = null;
  showCitizenCountryQError: boolean = false;
  selectedTaxResidentCountry = null;
  showTaxResidencyCountryQError: boolean = false;
  showTaxResExpl: boolean = false;
  USCitizen = null;
  showUSCitizenText: boolean = false;
  showContinueButton: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute
    ) {}

  ngOnInit() {
    this.showUSCitizenText = false;
    this.showCitizenshipCountryQ = false;
    this.showTaxResidencyCountryQ = false;
    this.showCitizenCountryQError = false;
    this.showTaxResidencyCountryQError = false;
    this.showTaxResExpl = false;
    this.showContinueButton = false;
    sessionStorage.taxYear = new Date().getFullYear()-1;
    sessionStorage.USCitizen = null;
    sessionStorage.citizenCountry1 = '';
    sessionStorage.citizenCountry2 = '';
    sessionStorage.citizenCountry3 = '';
    sessionStorage.taxResidentCountry = '';
  }

  USCitizenChange() {
    sessionStorage.USCitizen = this.USCitizen;
    if (this.USCitizen == 'No') {
      this.showUSCitizenText = false;
      this.showCitizenshipCountryQ = true;
    }
    else if (this.USCitizen == 'Yes') {
      this.showUSCitizenText = true;
      this.showCitizenshipCountryQ = false;
    } 
    this.selectedCitizenCountry1 = null;
    this.selectedCitizenCountry2 = null;
    this.selectedCitizenCountry3 = null;
    this.showCitizenCountryQError = false;
    this.showTaxResExpl = false;
    this.showTaxResidencyCountryQ = false;
    this.selectedTaxResidentCountry = null;
    this.showContinueButton = false;
  }

  CitizenCountry1Change() {
    if (this.selectedCitizenCountry1 == null || this.selectedCitizenCountry1 == '') {
      this.showCitizenCountryQError = true;
      this.showTaxResidencyCountryQ = false;
      this.showTaxResExpl = false;
      this.selectedTaxResidentCountry = null;
    }
    else {
      this.showCitizenCountryQError = false;
      this.showTaxResidencyCountryQ = true;
      this.showTaxResExpl = false;
      this.showCitizenCountryQError = false;
      this.selectedTaxResidentCountry = null;
    }
  }

  ResidentCountryChange() {
    this.showTaxResExpl = true;
    this.showContinueButton = true;
  }

  onSubmitNRA() {
    sessionStorage.citizenCountry1 = this.selectedCitizenCountry1;
    if (this.selectedCitizenCountry2 == null) {sessionStorage.citizenCountry2 = ''}
    else {sessionStorage.citizenCountry2 = this.selectedCitizenCountry2}
    if (this.selectedCitizenCountry3 == null) {sessionStorage.citizenCountry3 = ''}
    else {sessionStorage.citizenCountry3 = this.selectedCitizenCountry3}
    
    if (this.selectedTaxResidentCountry == 'Armenia'
     || this.selectedTaxResidentCountry == 'Azerbaijan'
     || this.selectedTaxResidentCountry == 'Belarus'
     || this.selectedTaxResidentCountry == 'Georgia'
     || this.selectedTaxResidentCountry == 'Kyrgyzstan'
     || this.selectedTaxResidentCountry == 'Moldova'
     || this.selectedTaxResidentCountry == 'Tajikistan'
     || this.selectedTaxResidentCountry == 'Turkmenistan'
     || this.selectedTaxResidentCountry == 'Uzbekistan') {
      sessionStorage.taxResidentCountry  = 'Commonwealth of Independent States'
     }
    else {sessionStorage.taxResidentCountry  = this.selectedTaxResidentCountry};
    sessionStorage.pg = 'pgTrue'
    sessionStorage.navDir = 'forward'
    sessionStorage.prevPage = 'pg-nr01'
    this.router.navigate(['../aminra'], {relativeTo: this.route});
  }

  onSubmitBack() {
    sessionStorage.navDir = 'backward'
    sessionStorage.prevPage = 'pg-nr01'
    this.router.navigate(['../pg-nr00'], {relativeTo: this.route});
  }

}
