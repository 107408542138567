<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR08-complete.jpg" alt='Progress Bar pg-nr08-complete'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - Created!</h3>
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>Your Personalized Guide creation was successful. Please check your "Downloads" folder for a PDF file named "Personal Guide *date* *time*.pdf".</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
        <div class="col-12">
            <p>Click the link below to go to the NoRTH Home Page to prepare your return.</p>
            <button class="btn btn-custom mb-sm-0 mb-2" onclick="window.location.href='https://www.nrtaxhelp.org/';">Return to NoRTH Home Page</button>
        </div>
    </div>

</div>
