import { Injectable } from '@angular/core';

export const statesList=[
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
  ]

  export var stateInfoCodes = [
    ['Alabama',             2],
    ['Alaska',              1],
    ['Arizona',             2],
    ['Arkansas',            1],
    ['California',          3],
    ['Colorado',            2],
    ['Connecticut',         2],
    ['Delaware',            2],
    ['District of Columbia',2],
    ['Florida',             1],
    ['Georgia',             2],
    ['Hawaii',              2],
    ['Idaho',               2],
    ['Illinois',            2],
    ['Indiana',             2],
    ['Iowa',                2],
    ['Kansas',              2],
    ['Kentucky',            2],
    ['Louisiana',           2],
    ['Maine',               2],
    ['Maryland',            6],
    ['Massachusetts',       2],
    ['Michigan',            2],
    ['Minnesota',           5],
    ['Mississippi',         2],
    ['Missouri',            2],
    ['Montana',             2],
    ['Nebraska',            2],
    ['Nevada',              1],
    ['New Hampshire',       1],
    ['New Jersey',          2],
    ['New Mexico',          2],
    ['New York',            2],
    ['North Carolina',      4],
    ['North Dakota',        2],
    ['Ohio',                2],
    ['Oklahoma',            2],
    ['Oregon',              2],
    ['Pennsylvania',        3],
    ['Rhode Island',        2],
    ['South Carolina',      2],
    ['South Dakota',        1],
    ['Tennessee',           1],
    ['Texas',               1],
    ['Utah',                2],
    ['Vermont',             2],
    ['Virginia',            2],
    ['Washington',          1],
    ['West Virginia',       2],
    ['Wisconsin',           3],
    ['Wyoming',             1]
  ]
 

@Injectable({
  providedIn: 'root'
})


export class PGnr06Service {

  constructor() { }
}
