import { Component, OnInit } from '@angular/core';

import { Tool } from './Tool';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})

export class ToolsComponent implements OnInit {
  
  tools: Tool[] = [
    {
      path: 'aminra',
      title: 'Am I a Nonresident Alien?',
      description: 'Use this tool to check your U.S. tax residency status.'
    },
    {
      path: 'treatybenefits',
      title: 'What are my treaty benefits?',
      description: 'Use this tool to check if you have any treaty benefits you can claim on your tax return.'
    },
//    {
//      path: 'pg-nr00',
//      title: 'Personal Guide',
//      description: 'Use this tool to determine your specific filing requirements.'
//    },
  ];
  constructor(
    private router: Router,
    private route: ActivatedRoute
    ) 
    { }

  ngOnInit() {
  }

  /**
   * Navigate to the specified tools route i.e. tools/tool_name where tool_name is the path property of the tool.
   * @param tool - Tool that we want to navigate to.
   */
  openTool(tool: Tool) {
    sessionStorage.prevPage = 'tools'
    if (tool.path == 'pg-nr00') {
      sessionStorage.pg = 'pgTrue';
      sessionStorage.navDir = 'forward';
    }
    else {
      sessionStorage.pg = 'pgFalse'
      sessionStorage.navDir = 'na'
    }

    this.router.navigate(['./' + tool.path], {relativeTo: this.route});

  }

}
