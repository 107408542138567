<div class="form-row">
    <div class="col-sm-3 mb-sm-0" [ngClass]="{'mb-2': showLabels, 'mb-4': !showLabels}">
        <label *ngIf="showLabels" for="{{ 'doc_' + docId }}">Document Type</label>
        <ng-select id="{{ 'doc_' + docId }}"
            [items]="dtypes"
            [(ngModel)]="doc.doctype"
            placeholder="Select Document type"
            (ngModelChange)="docChange()"
            aria-label="Document Type">
        </ng-select>
    </div>
    <div class="col-sm-3 mb-sm-0" [ngClass]="{'mb-2': showLabels, 'mb-4': !showLabels}">
        <label *ngIf="showLabels" for="{{ 'incomeCode_' + docId }}">Income Code (if 1042-S)</label>
        <ng-select id="{{ 'incomeCode_' + docId }}"
            [disabled]="doc.doctype=='W-2' || doc.doctype=='Fellowship'"
            [items]="incodes"
            [(ngModel)]="doc.incomecode"
            placeholder="Select Income Code"
            (ngModelChange)="resChange()"
            aria-label="Income Code">
        </ng-select>
    </div>
    <div class="col-sm-3 mb-sm-0 mb-4" [ngClass]="{'mb-2': showLabels, 'mb-4': !showLabels }">
        <label *ngIf="showLabels" for="{{ 'incomeinput' + docId }}">Income</label>
        <div class="input-group">
            <input id="{{ 'incomeinput' + docId }}"  
            type="number" 
            name="income" 
            [(ngModel)]="doc.incomeval"
            (ngModelChange)="conChange()"
            min="0" required
            aria-label="Income">
        </div>
    </div>
    <div class="col-sm-3">
        <label *ngIf="showLabels"></label>
        <div class="form-control-static">
            <button class="btn btn-danger float-sm-right" [ngClass]="{ 'mt-sm-2': showLabels }" (click)="removeDoc()" id="remove">Remove Document</button>
        </div>
    </div>
</div>
<div *ngIf="doc.error && doc.error.length > 0" class="row">
    <div *ngFor="let error of doc.error" class="col-12">
        <div class="invalid-feedback d-block">{{error}}</div>
    </div>
</div>
<!--
<div class="row">
    <div class="col-12">
        <hr>
    </div>
</div>
-->
