<div class="form-row">
    <div class="col-sm-4 mb-sm-0" [ngClass]="{'mb-2': showLabels, 'mb-4': !showLabels}">
        <label *ngIf="showLabels" for="{{ 'visa_' + visitId }}">Visa 
            <fa-icon class="clickable" [icon]="['fa', 'info-circle']" 
            title = "F/J/M/Q visas are for students, teachers, trainees, and other types of exchange visitors.  People with those visas are exempt from the residency test for a certain period of time.

Other Exempt visas are intended for diplomats, government officials, employees of international organizations, and their families.  People with these visas are always exempt from the residency test.  These include the following visa types: A (but not A-3), G (but not G-5), NATO.

All other visas are non-exempt.  Unless your visa is listed in one of the two categories above, it is non-exempt.  Some of the common types of non-exempt visas / visits are:  B1/B2, TN/TD, H (including H1-b), L, O, P, visa waiver, and Temporary Protected Status (TPS).  This is not a complete list of all non-exempt visa types.  If your visa is not a F/J/M/Q or other exempt visa as described above, then it is non-exempt."
            >
        </fa-icon>
        </label>
        <ng-select id="{{ 'visa_' + visitId }}"
            [items]="visaTypes"
            [(ngModel)]="visit.visa"
            placeholder="Select a visa type"
            (ngModelChange)="visaChange()"
            aria-label="Visa">
        </ng-select>
    </div>
    <div class="col-sm-3 mb-sm-0" [ngClass]="{'mb-2': showLabels, 'mb-4': !showLabels}">
        <label *ngIf="showLabels" for="{{ 'entryDateInput_' + visitId }}">Entry Date</label>
        <div class="input-group">
            <input id="{{ 'entryDateInput_' + visitId }}" class="form-control" placeholder="MM/DD/YYYY" name="entry" ngbDatepicker #entryToggle="ngbDatepicker" [(ngModel)]="entryDate" (blur)="entryDateChange()" attr.aria-label="Entry Date {{visitId}}" (dateSelect)="entryDateChange()">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="entryToggle.toggle()">
                        <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="col-sm-3 mb-sm-0 mb-4" [ngClass]="{'mb-2': showLabels, 'mb-4': !showLabels }">
        <label *ngIf="showLabels" for="{{ 'exitDateInput_' + visitId }}">Exit Date</label>
        <div class="input-group">
            <input id="{{ 'exitDateInput_' + visitId }}" class="form-control" placeholder="MM/DD/YYYY" name="exit" ngbDatepicker #exitToggle="ngbDatepicker" [(ngModel)]="exitDate" (blur)="exitDateChange()" attr.aria-label="Exit Date {{visitId}}" (dateSelect)="exitDateChange()">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="exitToggle.toggle()">
                        <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="col-sm-2">
        <label *ngIf="showLabels"></label>
        <div class="form-control-static">
            <button class="btn btn-danger float-sm-right" [ngClass]="{ 'mt-sm-2': showLabels }" (click)="removeVisit()" id="remove">Remove Visit</button>
        </div>
    </div>
</div>
<div *ngIf="visit.error && visit.error.length > 0" class="row">
    <div *ngFor="let error of visit.error" class="col-12">
        <div class="invalid-feedback d-block">{{error}}</div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <!--<hr>-->
    </div>
</div>

