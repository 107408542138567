import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { VisitModel } from './visit/VisitModel';
import { aminraService, NonResidentStatus, selectableTaxYears, selectYesNoChoices, SortedVisitsInTaxYear  } from './aminra.service';
import { AppService } from "../../app.service";
import * as moment from 'moment';
import { Visit } from './visit/Visit';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-aminra',
  templateUrl: './aminra.component.html',
  template: '<ng-select id="foreignIncomeInTaxYear"',
  styleUrls: ['./aminra.component.scss'],
})

export class aminraComponent implements OnInit {
  // Variables for controlling whether messages are shown to the user, error messages or status
  showNewVisitHelpText = false;
  showOverlapError = false;
  showIncomeQuestionsError = false;
  showEndDateCaveat = false;
  showForm8843Caveat = false;
  visitsHaveErrors = false;
  showStatus = false;
  showIncomeQuestions = false;
  fIncTaxYr = false;
  fIncPresYrs = false;
  showDualStatusResidentText = false;
  singleTaxYear: boolean = false;

  // Variables holding information
  visits: VisitModel[] = [];
  taxYear: any;
  nextYear: number;
  selectableYears = selectableTaxYears;
  selectedYear: number;
  nonResidentStatus: NonResidentStatus;
  selectYesNo = selectYesNoChoices;
  foreignIncomeInTaxYear = "Select...";
  foreignIncomeInPresentYears = "Select...";
  foreignPayYears: string;
  foreignIncomeYesYes: boolean;
  FIncomeQ: boolean;
  navFrompg: boolean;

  sortedVisitsForTaxYear: Visit[]
  sortedVisitsInTaxYear: Visit[]
  visitsInTaxYear: SortedVisitsInTaxYear;
  singleVisit: Visit;

  sVITY: Visit[]
  tempDate: moment.Moment
  OUSVisitsInTaxYearStart: moment.Moment[] = [];
  OUSVisitsInTaxYearEnd: moment.Moment[] = [];
  OUSVisitsInTaxYearStartShow: moment.Moment[] = [];
  OUSVisitsInTaxYearEndShow: moment.Moment[] = [];
  totalOUSDays: number = 0;
  runningTotalTYDays: number = 0;

  // Used to give each added visit a unique id
  visitCounter = 0;
  showUnexpectedErrorMessage: boolean;

  constructor(
    private aminraService: aminraService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    ) {}

  ngOnInit() {
    if (sessionStorage.pg == 'pgTrue') {
      this.navFrompg = true;
      this.taxYear = sessionStorage.taxYear;
      this.nextYear = Number(sessionStorage.taxYear) + 1
      this.selectedYear = Number(sessionStorage.taxYear);
    }
    else {
      this.navFrompg = false;
      this.selectedYear = selectableTaxYears[2];
    }
    sessionStorage.visaAtEndOfYearTY1 = ''
    sessionStorage.visaAtEndOfYearTY2 = ''
    sessionStorage.visaAtEndOfYearTY3 = ''
    sessionStorage.visaAtEndOfYearTY4 = ''
    sessionStorage.visaAtEndOfYearTY5 = ''
    sessionStorage.visaAtEndOfYearTY6 = ''
    this.OUSVisitsInTaxYearStart = []
    this.OUSVisitsInTaxYearStartShow = []
    this.OUSVisitsInTaxYearEnd = []
    this.OUSVisitsInTaxYearEndShow = []
    this.appService.clearOUSVisitsInTY()
    
    this.addNewVisit();

    //if ()


  }

  addNewVisit() {
    this.showUnexpectedErrorMessage = false;
    this.showIncomeQuestions = false;
    this.showIncomeQuestionsError = false;
    this.foreignIncomeInTaxYear = "Select..."
    this.foreignIncomeInPresentYears = "Select..."
    if (this.canAddNewVisit()) {
      this.showNewVisitHelpText = false;
      this.showEndDateCaveat = false;
      this.showForm8843Caveat = false;
  
      this.visits.push({
        visa: null,
        entry: null,
        exit: null,
        id: this.visitCounter++
      });
    } else {
      this.showNewVisitHelpText = true;
    }
  }

  canAddNewVisit() {
    let numberOfEmptyExitDates = 0;
    for (const visit of this.visits) {
      if (visit.visa === null || visit.entry === null) {
        return false;
      } else {
        if (visit.exit === null) {
          numberOfEmptyExitDates++;
          if (numberOfEmptyExitDates > 1) {
            return false;
          }
        }
      }
    }
    if (this.aminraService.doVisitsOverlap(this.constructVisits())) {
      this.showOverlapError = true;
      return false;
    }
    return true;
  }

  onVisitChanged(visit, index) {
    this.showUnexpectedErrorMessage = false;
    this.showStatus = false;
    this.showIncomeQuestions = false;
    this.showIncomeQuestionsError = false;
    this.foreignIncomeInTaxYear = "Select..."
    this.foreignIncomeInPresentYears = "Select..."
    this.showNewVisitHelpText = false;
    this.showEndDateCaveat = false;
    this.showForm8843Caveat = false;
    this.visits[index] = _.cloneDeep(visit);
    this.checkVisitsForErrors();
    if (!this.visitsHaveErrors) {
      this.showOverlapError = this.aminraService.doVisitsOverlap(this.constructVisits());
    }
  }

  onVisitRemoved(visit, index) {
    this.showUnexpectedErrorMessage = false;
    this.showStatus = false;
    this.showIncomeQuestions = false;
    this.showIncomeQuestionsError = false;
    this.foreignIncomeInTaxYear = "Select..."
    this.foreignIncomeInPresentYears = "Select..."
    this.showNewVisitHelpText = false;
    this.showEndDateCaveat = false;
    this.showForm8843Caveat = false;
    this.visits.splice(index, 1);
    if (this.visits.length === 0) {
      this.addNewVisit();
    }
    this.checkVisitsForErrors();
    if (!this.visitsHaveErrors) {
      this.showOverlapError = this.aminraService.doVisitsOverlap(this.constructVisits());
    }
  }

  trackIndex(index, item) {
    return item.id;
  }

  clearVisits() {
    this.visitsHaveErrors = false;
    this.showOverlapError = false;
    this.showStatus = false;
    this.showIncomeQuestions = false;
    this.showIncomeQuestionsError = false;
    this.foreignIncomeInTaxYear = "Select..."
    this.foreignIncomeInPresentYears = "Select..."
    this.showEndDateCaveat = false;
    this.showForm8843Caveat = false;
    this.visits = [];
    this.showUnexpectedErrorMessage = false;
    this.addNewVisit();
  }

  checkVisitsForErrors() {
    this.visitsHaveErrors = false;
    this.visits.forEach((visit: VisitModel) => {
      if (visit.error && visit.error.length > 0) {
        this.visitsHaveErrors = true;
        return;
      }
    });
  }

  constructVisits() {
    const visits: Visit[] = [];
    this.visits.forEach((visit: VisitModel) => {
      visits.push(new Visit(visit.id, visit.visa, visit.entry, visit.exit, visit.error));
    });
    return visits;
  }

  onSubmitCheckStatus() {
    if (this.canAddNewVisit()) {
      sessionStorage.visaAtEndOfYearTY1 = ''
      sessionStorage.visaAtEndOfYearTY2 = ''
      sessionStorage.visaAtEndOfYearTY3 = ''
      sessionStorage.visaAtEndOfYearTY4 = ''
      sessionStorage.visaAtEndOfYearTY5 = ''
      sessionStorage.visaAtEndOfYearTY6 = ''
      this.OUSVisitsInTaxYearStart = []
      this.OUSVisitsInTaxYearStartShow = []
      this.OUSVisitsInTaxYearEnd = []
      this.OUSVisitsInTaxYearEndShow = []
      this.appService.clearOUSVisitsInTY()

      this.showNewVisitHelpText = false;

      if (this.foreignIncomeInTaxYear === "Yes" && this.foreignIncomeInPresentYears === "Yes") {
        this.FIncomeQ = true;
      }
      else {
        this.FIncomeQ = false;
      }

      //this.aminraService.setVisits(this.constructVisits());
      this.nonResidentStatus = this.aminraService.isNonresident(this.constructVisits(), this.selectedYear, this.FIncomeQ);
      sessionStorage.nonResidentStatus = this.nonResidentStatus.status;
      sessionStorage.daysInUSTY = this.nonResidentStatus.daysPresentInTaxYear;
      sessionStorage.daysInUSTY1 = this.nonResidentStatus.daysPresentYearPriorToTaxYear;
      sessionStorage.daysInUSTY2 = this.nonResidentStatus.daysPresent2YearsPriorToTaxYear;
      sessionStorage.exemptCount = this.nonResidentStatus.daysExemptInTaxYear;
      sessionStorage.exempt2of6ForeignIncomeYes = ""



      ////////////////////////////////////////
      //Create data for the State Visits Pages
      this.visitsInTaxYear = this.aminraService.sortedTaxYearVisits(this.constructVisits(), this.selectedYear)
      this.appService.clearSortedVisitsInTY()
      this.visitsInTaxYear.sortedVisitsInTaxYear.forEach((visit: Visit) => {
        this.appService.addSortedVisitsInTY(visit)
      })


      this.sortedVisitsInTaxYear = this.appService.getSortedVisitsInTY()
      const n = this.sortedVisitsInTaxYear.length
      this.totalOUSDays = 0

      this.sVITY = _.cloneDeep(this.sortedVisitsInTaxYear)

      //1 Visit
      if (n == 1) {
  
        //1-1
        //Single visit starting on 01/01/TY, ending before 12/31/TY
        if (this.sVITY[0].entry.startOf('day').dayOfYear() == moment([this.taxYear]).startOf('year').dayOfYear()
        && (this.sVITY[0].exit.dayOfYear() != moment([this.taxYear]).endOf('year').dayOfYear())) {
          
          this.OUSVisitsInTaxYearStart.push(this.sVITY[0].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[0].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(moment([this.taxYear]).endOf('year'))
          this.OUSVisitsInTaxYearEndShow.push(moment([this.taxYear]).endOf('year'))
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
        }
      
        //1-2
        //Single visit starting on 01/01/TY, ending on 12/31/TY
        else if (this.sVITY[0].entry.startOf('day').dayOfYear() == moment([this.taxYear]).startOf('year').dayOfYear()
        && ((this.sVITY[0].exit.dayOfYear() == moment([this.taxYear]).endOf('year').dayOfYear()))) {
          this.totalOUSDays = 0
        }
       
        //1-3
        //Single visit starting after 01/01/TY, ending before 12/31/TY
        else if (this.sVITY[0].entry.dayOfYear() != moment([this.taxYear]).startOf('year').dayOfYear()
        && this.sVITY[0].exit.dayOfYear() != moment([this.taxYear]).endOf('year').dayOfYear()) {
         
          this.OUSVisitsInTaxYearStart.push(moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearStartShow.push(moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearEnd.push(this.sVITY[0].entry)
          //this.tempDate = moment(this.sVITY[0].entry)
          //this.OUSVisitsInTaxYearEndShow.push(this.sVITY[0].entry.subtract(1, 'd'))
          this.OUSVisitsInTaxYearEndShow.push(this.sVITY[0].entry.subtract(1, 'd'))
          //this.OUSVisitsInTaxYearEndShow.push(this.tempDate.subtract(1, 'd'))
  
          this.OUSVisitsInTaxYearStart.push(this.sVITY[0].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[0].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(moment([this.taxYear]).endOf('year'))
          this.OUSVisitsInTaxYearEndShow.push(moment([this.taxYear]).endOf('year'))
          
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
        }
        
        //1-4
        //Single visit starting after 01/01/TY, ending on 12/31/TY
        else if (this.sVITY[0].entry.dayOfYear() != moment([this.taxYear]).startOf('year').dayOfYear()
        && (this.sVITY[0].exit.dayOfYear() == moment([this.taxYear]).endOf('year').dayOfYear())) {
         
          this.OUSVisitsInTaxYearStart.push(moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearStartShow.push(moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearEnd.push(this.sVITY[0].entry)
          this.OUSVisitsInTaxYearEndShow.push(this.sVITY[0].entry.subtract(1, 'd'))
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
       }
  
      }
  
      //2 visits
      else if (n == 2) {
  
        //2-1
        //First visit starts on 01/01/TY, Second visit ends before 12/31/TY
        if (this.sVITY[0].entry.dayOfYear() == moment([this.taxYear]).startOf('year').dayOfYear() 
        && this.sVITY[1].exit.dayOfYear() != moment([this.taxYear]).endOf('year').dayOfYear()) {
          
          this.OUSVisitsInTaxYearStart.push(    this.sVITY[0].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[0].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(      this.sVITY[1].entry)
          this.OUSVisitsInTaxYearEndShow.push(  this.sVITY[1].entry.subtract(1, 'd'))
  
          this.OUSVisitsInTaxYearStart.push(    this.sVITY[1].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[1].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(      moment([this.taxYear]).endOf('year'))
          this.OUSVisitsInTaxYearEndShow.push(  moment([this.taxYear]).endOf('year'))
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
        }
  
        //2-2
        //First visit starts on 01/01/TY, second visit ends on 12/31/TY
        else if (this.sVITY[0].entry.dayOfYear() == moment([this.taxYear]).startOf('year').dayOfYear() 
        && this.sVITY[1].exit.dayOfYear() == moment([this.taxYear]).endOf('year').dayOfYear()) {
          
          this.OUSVisitsInTaxYearStart.push(    this.sVITY[0].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[0].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(      this.sVITY[1].entry)
          this.OUSVisitsInTaxYearEndShow.push(  this.sVITY[1].entry.subtract(1, 'd'))
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
        }
     
        //2-3
        //First visit starts after 01/01/TY, second visit ends before 12/31/TY
        else if (this.sVITY[0].entry.dayOfYear() != moment([this.taxYear]).startOf('year').dayOfYear() 
        && this.sVITY[1].exit.dayOfYear() != moment([this.taxYear]).endOf('year').dayOfYear()) {
          
          this.OUSVisitsInTaxYearStart.push(    moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearStartShow.push(moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearEnd.push(      this.sVITY[0].entry)
          this.OUSVisitsInTaxYearEndShow.push(  this.sVITY[0].entry.subtract(1, 'd'))
  
          this.OUSVisitsInTaxYearStart.push(    this.sVITY[0].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[0].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(      this.sVITY[1].entry)
          this.OUSVisitsInTaxYearEndShow.push(  this.sVITY[1].entry.subtract(1, 'd'))
  
          this.OUSVisitsInTaxYearStart.push(    this.sVITY[1].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[1].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(      moment([this.taxYear]).endOf('year'))
          this.OUSVisitsInTaxYearEndShow.push(  moment([this.taxYear]).endOf('year'))
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
         }
  
        //2-4
        //First visit starts after 01/01/TY, second visit ends on 12/31/TY
        else if (this.sVITY[0].entry.dayOfYear() != moment([this.taxYear]).startOf('year').dayOfYear() 
        && this.sVITY[1].exit.dayOfYear() == moment([this.taxYear]).endOf('year').dayOfYear()) {
          
          this.OUSVisitsInTaxYearStart.push(    moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearStartShow.push(moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearEnd.push(      this.sVITY[0].entry)
          this.OUSVisitsInTaxYearEndShow.push(  this.sVITY[0].entry.subtract(1, 'd'))
   
          this.OUSVisitsInTaxYearStart.push(    this.sVITY[0].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[0].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(      this.sVITY[1].entry)
          this.OUSVisitsInTaxYearEndShow.push(  this.sVITY[1].entry.subtract(1, 'd'))
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
        }
      }
  
      //3 or more visits
      else if (n >= 3) {
  
        //3-1
        //First visit starts on 01/01/TY, last visit ends before 12/31/TY
        if (this.sVITY[0].entry.dayOfYear() == moment([this.taxYear]).startOf('year').dayOfYear() 
        && this.sVITY[n-1].exit.dayOfYear() != moment([this.taxYear]).endOf('year').dayOfYear()) {
  
          for (let visitNo=0; visitNo < n-1; visitNo++) {
            //If concurrent visits (e.g. change in visa) then skip this visit, otherwise treat as normal visit
            if ((this.sVITY[visitNo + 1].entry.dayOfYear() != this.sVITY[visitNo].exit.dayOfYear()
              || (this.sVITY[visitNo + 1].entry.add(1, 'd').dayOfYear() == this.sVITY[visitNo].exit.dayOfYear())) ) {
              this.OUSVisitsInTaxYearStart.push(this.sVITY[visitNo].exit)
              this.OUSVisitsInTaxYearStartShow.push(this.sVITY[visitNo].exit.add(1, 'd'))            
              this.OUSVisitsInTaxYearEnd.push(this.sVITY[visitNo + 1].entry)
              this.OUSVisitsInTaxYearEndShow.push(this.sVITY[visitNo + 1].entry.subtract(2,'d'))
            }
          }
  
          //Last OUS Visit
          this.OUSVisitsInTaxYearStart.push(    this.sVITY[n-1].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[n-1].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(      moment([this.taxYear]).endOf('year'))
          this.OUSVisitsInTaxYearEndShow.push(  moment([this.taxYear]).endOf('year'))
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
        }
  
        //3-2
        //First visit starts on 01/01/TY, last visit ends on 12/31/TY
        else if (this.sVITY[0].entry.dayOfYear() == moment([this.taxYear]).startOf('year').dayOfYear() 
        && this.sVITY[n-1].exit.dayOfYear() == moment([this.taxYear]).endOf('year').dayOfYear()) {
          
          for (let visitNo=0; visitNo < n-1; visitNo++) {
            //If concurrent visits (e.g. change in visa) then skip this visit, otherwise treat as normal visit
            if (!((this.sVITY[visitNo + 1].entry.dayOfYear() == this.sVITY[visitNo].exit.dayOfYear())
              || (this.sVITY[visitNo + 1].entry.add(1, 'd').dayOfYear() == this.sVITY[visitNo].exit.dayOfYear())) ) {
              this.OUSVisitsInTaxYearStart.push(this.sVITY[visitNo].exit)
              this.OUSVisitsInTaxYearStartShow.push(this.sVITY[visitNo].exit.add(1, 'd'))            
              this.OUSVisitsInTaxYearEnd.push(this.sVITY[visitNo + 1].entry)
              this.OUSVisitsInTaxYearEndShow.push(this.sVITY[visitNo + 1].entry.subtract(2, 'd'))
            }
          }
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
        }
     
        //3-3
        //First visit starts after 01/01/TY, last visit ends before 12/31/TY
        else if (this.sVITY[0].entry.dayOfYear() != moment([this.taxYear]).startOf('year').dayOfYear()
        && this.sVITY[n-1].exit.dayOfYear() != moment([this.taxYear]).endOf('year').dayOfYear()) {
         
          this.OUSVisitsInTaxYearStart.push(    moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearStartShow.push(moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearEnd.push(      this.sVITY[0].entry)
          this.OUSVisitsInTaxYearEndShow.push(  this.sVITY[0].entry.subtract(1, 'd'))
  
          for (let visitNo=0; visitNo < n-1; visitNo++) {
            //If concurrent visits (e.g. change in visa) then skip this visit, otherwise treat as normal visit
            if (!((this.sVITY[visitNo + 1].entry.dayOfYear() == this.sVITY[visitNo].exit.dayOfYear())
              || (this.sVITY[visitNo + 1].entry.add(1, 'd').dayOfYear() == this.sVITY[visitNo].exit.dayOfYear())) ) {
              this.OUSVisitsInTaxYearStart.push(this.sVITY[visitNo].exit)
              this.OUSVisitsInTaxYearStartShow.push(this.sVITY[visitNo].exit.add(1, 'd'))            
              this.OUSVisitsInTaxYearEnd.push(this.sVITY[visitNo + 1].entry)
              this.OUSVisitsInTaxYearEndShow.push(this.sVITY[visitNo + 1].entry.subtract(2, 'd'))
            }
          }
  
          //Last OUS Visit
          this.OUSVisitsInTaxYearStart.push(    this.sVITY[n-1].exit)
          this.OUSVisitsInTaxYearStartShow.push(this.sVITY[n-1].exit.add(1, 'd'))
          this.OUSVisitsInTaxYearEnd.push(      moment([this.taxYear]).endOf('year'))
          this.OUSVisitsInTaxYearEndShow.push(  moment([this.taxYear]).endOf('year'))
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
        }
  
        //3-4
        //First visit starts after 01/01/TY, last visit ends on 12/31/TY
        else if (this.sVITY[0].entry.dayOfYear() != moment([this.taxYear]).startOf('year').dayOfYear() 
        && this.sVITY[n-1].exit.dayOfYear() == moment([this.taxYear]).endOf('year').dayOfYear()) {
          
          this.OUSVisitsInTaxYearStart.push(    moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearStartShow.push(moment([this.taxYear]).startOf('year'))
          this.OUSVisitsInTaxYearEnd.push(      this.sVITY[0].entry)
          this.OUSVisitsInTaxYearEndShow.push(  this.sVITY[0].entry.subtract(1, 'd'))
  
          for (let visitNo=0; visitNo < n-1; visitNo++) {
            //If concurrent visits (e.g. change in visa) then skip this visit, otherwise treat as normal visit
            if (!((this.sVITY[visitNo + 1].entry.dayOfYear() == this.sVITY[visitNo].exit.dayOfYear())
              || (this.sVITY[visitNo + 1].entry.add(1, 'd').dayOfYear() == this.sVITY[visitNo].exit.dayOfYear())) ) {
              this.OUSVisitsInTaxYearStart.push(this.sVITY[visitNo].exit)
              this.OUSVisitsInTaxYearStartShow.push(this.sVITY[visitNo].exit.add(1, 'd'))            
              this.OUSVisitsInTaxYearEnd.push(this.sVITY[visitNo + 1].entry)
              this.OUSVisitsInTaxYearEndShow.push(this.sVITY[visitNo + 1].entry.subtract(2, 'd'))
            }
          }
  
          for (let OUSvisitNo=0; OUSvisitNo < this.OUSVisitsInTaxYearStart.length; OUSvisitNo++) {
            this.totalOUSDays += this.OUSVisitsInTaxYearEnd[OUSvisitNo].dayOfYear() - this.OUSVisitsInTaxYearStart[OUSvisitNo].dayOfYear() + 1
          }
  
        }
      }
      
      this.runningTotalTYDays = this.totalOUSDays

      this.appService.addOUSVisitsInTaxYearStart(this.OUSVisitsInTaxYearStart)
      this.appService.addOUSVisitsInTaxYearStartShow(this.OUSVisitsInTaxYearStartShow)
      this.appService.addOUSVisitsInTaxYearEnd(this.OUSVisitsInTaxYearEnd)
      this.appService.addOUSVisitsInTaxYearEndShow(this.OUSVisitsInTaxYearEndShow)
      this.appService.addTotalOUSDays(this.totalOUSDays)

      //End of create data for State Visits pages




      if (!this.nonResidentStatus) {
        this.showUnexpectedErrorMessage = true;
      } else if (this.nonResidentStatus.residencyEndDate && this.nonResidentStatus.residencyEndDate.includes('*')) {
        this.showEndDateCaveat = true;
        this.showUnexpectedErrorMessage = false;
      }

      //this.showForm8843Caveat = this.nonResidentStatus.exemptAsForJInTaxYear;
      if (this.nonResidentStatus.daysExemptInTaxYear > 0) {
        this.showForm8843Caveat = true;
      }

      this.showIncomeQuestionsError = false;
      
      //Test to see if need to show foreign income questions
      if (this.nonResidentStatus.exempt3Of6Count <= 3 && this.nonResidentStatus.presentAsTeacherTraineeYears.length > 0 && !this.nonResidentStatus.allExemptYears.includes(this.selectedYear)) {
        this.showIncomeQuestions = true;
        this.foreignPayYears = this.nonResidentStatus.presentAsTeacherTraineeYears.reverse().toString().split(",").join(", ");
      }
      
     
      if (this.showIncomeQuestions === false) {
        this.showStatus = true;
      }
      else if (this.showIncomeQuestions == true && (this.foreignIncomeInTaxYear === "Select..." || this.foreignIncomeInPresentYears === "Select...")) {
        this.showStatus = false;
        this.showIncomeQuestionsError = true;
      }
      
      else if (this.showIncomeQuestions == true && this.foreignIncomeInTaxYear != "Select..." && this.foreignIncomeInPresentYears != "Select...") {
        this.showStatus = true;
        this.showIncomeQuestionsError = false;
        if (this.showIncomeQuestions == true && this.foreignIncomeInTaxYear == "Yes" && this.foreignIncomeInPresentYears == "Yes") {
          sessionStorage.exempt2of6ForeignIncomeYes = "Yes"
        }
      }

      else {
          this.showStatus = true;
      }

      if (this.nonResidentStatus.dualStatusResidentText == true) {
        this.showDualStatusResidentText = true;
      }

    }
    
    else {
      this.showStatus = false;
      this.showIncomeQuestions = false;
      this.showNewVisitHelpText = true;
    }
  }

  selectedYearChange() {
    this.showStatus = false;
    this.showIncomeQuestions = false;
  }

  foreignIncomeInTaxYearChange() {
    if (this.showIncomeQuestions == true && this.foreignIncomeInTaxYear != "Select..." && this.foreignIncomeInPresentYears != "Select...") {
      this.showStatus = true;
      this.showIncomeQuestionsError = false;
    }
    else {this.showIncomeQuestionsError = true}
  }

  foreignIncomeInPresentYearsChange() {
    if (this.showIncomeQuestions == true && this.foreignIncomeInTaxYear != "Select..." && this.foreignIncomeInPresentYears != "Select...") {
      this.showStatus = true;
      this.showIncomeQuestionsError = false;
    }
    else {this.showIncomeQuestionsError = true}
  }

  onSubmitGoTonr02() {
      this.router.navigate(['../pg-nr02'], {relativeTo: this.route});
  }

  onSubmitBack() {
      if (this.navFrompg) {
        this.router.navigate(['../pg-nr01'], {relativeTo: this.route});
      }
      else {
        this.router.navigate(['../tools'], {relativeTo: this.route});
      }
  }
  
}
