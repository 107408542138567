import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pg-nr08-complete',
  templateUrl: './pg-nr08-complete.component.html',
  styleUrls: ['./pg-nr08-complete.component.scss']
})
export class PGnr08CompleteComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  backToNorthURL: string = ''

  ngOnInit(): void {  }

  onSubmitBackToNorth() {
    var prevPage = sessionStorage.prevPage
    sessionStorage.navDir = 'backward'
    sessionStorage.prevPage = 'pg-nr08'

    if (sessionStorage.urlRefLoc != "") {
      window.location.href='https://www.nrtaxhelp.org/' + sessionStorage.urlRefLoc + '/' + sessionStorage.urlRefLoc + 'DYT'
    }
    else { window.location.href='https://www.nrtaxhelp.org/free-tax-assistance' }
  }


  onSubmitBack() {
    //var prevPage = sessionStorage.prevPage
    sessionStorage.navDir = 'backward'
    //sessionStorage.prevPage = 'pg-nr08'
    this.router.navigate(['../pg-nr08'], {relativeTo: this.route});
  }


}
