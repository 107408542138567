import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import * as moment from 'moment';

import { VisitModel } from './VisitModel';
import { Visa } from './Visa';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss']
})
export class VisitComponent implements OnInit {
  @Output() visitChanged = new EventEmitter<VisitModel>();
  @Output() visitRemoved = new EventEmitter<VisitModel>();
  @Input() showLabels: boolean;
  @Input() visitId: number;
  visit: VisitModel;
  entryDate: NgbDateStruct;
  exitDate: NgbDateStruct;
  visaTypes: string[];
  constructor() { }

  ngOnInit() {
    this.visit = {
      visa: null,
      entry: null,
      exit: null,
      id: this.visitId
    };
    this.visaTypes = Object.values(Visa);
  }

  visaChange() {
    this.visitChanged.emit(this.visit);
  }

  entryDateChange() {
    try {
      if (this.entryDate && !(typeof this.entryDate === 'string')) {
        this.visit.entry = moment();
        //this.entryDate.month--;
        this.visit.entry.month(this.entryDate.month).subtract(1, 'months').calendar();
        this.visit.entry.date(this.entryDate.day);
        this.visit.entry.year(this.entryDate.year);
      } else if (this.entryDate && (typeof this.entryDate === 'string')) {
        this.visit.entry = moment(this.entryDate, 'MM/DD/YYYY', true);
      } else {
        this.visit.entry = null;
      }
    } catch (e) {}
    this.setVisitError();
    this.visitChanged.emit(this.visit);
  }

  exitDateChange() {
    try {
      if (this.exitDate && !(typeof this.exitDate === 'string')) {
        this.visit.exit = moment();
        //this.exitDate.month--;
        this.visit.exit.month(this.exitDate.month).subtract(1, 'months').calendar();
        this.visit.exit.date(this.exitDate.day);
        this.visit.exit.year(this.exitDate.year);
      } else if (this.exitDate && (typeof this.exitDate === 'string')) {
        this.visit.exit = moment(this.exitDate, 'MM/DD/YYYY', true);
      } else {
        this.visit.exit = null;
      }
    } catch (e) {}
    this.setVisitError();
    this.visitChanged.emit(this.visit);
  }

  setVisitError() {
    this.visit.error = [];
    if (this.visit.entry && (!this.visit.entry.isValid() || this.visit.entry.year().toString().length !== 4)) {
      this.visit.error.push('Entry date is invalid. Please make sure the date is in the format MM/DD/YYYY and has valid values.');
    }
    if (this.visit.exit && (!this.visit.exit.isValid() || this.visit.exit.year().toString().length !== 4)) {
      this.visit.error.push('Exit date is invalid. Please make sure the date is in the format MM/DD/YYYY and has valid values.');
    }
    if (this.visit.entry && this.visit.exit) {
      if (!this.visit.exit.isAfter(this.visit.entry)) {
        this.visit.error.push('Your exit date must be after your entry date.');
      }
    }
  }

  removeVisit() {
    this.visitRemoved.emit(this.visit);
  }
}
