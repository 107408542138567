<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR03.jpg" alt='Progress Bar 4 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - Children</h3>
            <!--<p>Comment</p>-->
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-7 col-lg-7">
            <label for="children">Do you have any children?</label>
                <div class="col-sm-1 col-lg-1"></div>
                <div class="col-sm-12 col-lg-12">       
                    <p><small>(A child under the age of 18).</small></p>
                </div>
        </div>
        <div class="col-sm-2 col-lg-2">
            <ng-select id="children" [items]="selectYesNo" [(ngModel)]="childrenAnswer" (ngModelChange)="childrenChange()" placeholder="Select" [clearable]="false"></ng-select>
        </div> 
    </div>
    
    <div *ngIf="showContinueButton">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoTonr04()">Continue</button>
            </div>
        </div>
    </div>
 

    <div *ngIf="showChildrenForm">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Select the circumstances that apply to your children.</label>
            </div>
            <ul class="checkbox-items">
                <li *ngFor="let item of childrenCheckboxesDataList">
                  <input type="checkbox" [(ngModel)]="item.isChecked" (change)="changeCheckBoxSelection()">{{item.label}}
                </li>
              </ul>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <div class="col-sm-1 col-lg-1"></div>
                <div class="col-sm-12 col-lg-12">       
                    <p><small>Note that each child will only have one circumstance that applies, but you may make multiple 
                        selections if you have more than one child and their circumstances are different.</small></p>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="formChecked">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoTonr04()">Continue</button>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a>.</small></p>
        </div>
    </div>


</div>
