import * as moment from 'moment';
import { StateVisitModel } from './statevisitModel';

/**
 * This is an object representation of Visit. It encapsulates all information and logic needed by the NRA Tool to
 * compute residency status.
 */
export class StateVisit {
    public entry: moment.Moment;
    public exit: moment.Moment;
    public selectedState: string;
    public days: number;
    public abode: boolean;
    private id: number;
    private error: string[];

    /**
     * This method is a method used by all StateVisit objects to compute the number of days between two dates
     * including both the end date and start date.
     * @param firstDate - The date coming first in the calendar
     * @param secondDate - The date coming last in the calendar
     */
    static getNumberOfDaysBetweenDatesInclusive(firstDate: moment.Moment, secondDate: moment.Moment): number {
        return secondDate.endOf('day').diff(firstDate.startOf('day'), 'days') + 1;
    }

    /**
     * Builds a new instance of a Visit.
     * Sets any values passed into the constructor.
     * Sets property defaults.
     * Sets properties that can be inferred by the provided information.
     * @param id - The state visits ID
     * @param entry - The first date in the state visit
     * @param exit - The last date in the state visit (can be null)
     * @param selectedState - The State for that state Visit
     * @param days - number of days between entery and exit inclusive
     * @param abode - did you maintain an abode in MN
     * @param error - Any errors flagged by the UI
     */

    constructor(
        id: number,
        entry: moment.Moment,
        exit: moment.Moment,
        selectedState: string,
        days: number,
        abode: boolean,
        error?: string[]
    ) {
        this.id = id;
        this.entry = entry;
        this.exit = exit;
        this.selectedState = selectedState;
        this.days = days;   
        this.abode = abode;
        this.error = error;
    }

    /**
     * Returns the StateVisitModel representation of this StateVisit instance.
     * This is the type of object that the UI produces and consumes.
     */
    getStateVisitModel(): StateVisitModel {
        return {
            id: this.id,
            entry: this.entry,
            exit: this.exit,
            selectedState: this.selectedState,
            days: this.days,
            abode: this.abode,
            error: this.error
        };
    }

    /**
     * Public methods that can be called on an instance of this class
     * ==============================================================
     */

    /**
     * Get the number of days present in the specified year for this visit.
     * If the visit overlaps with other years, the beginning and/or the end date of the year is used to calculate the days present
     * @param year - Year we want the number of days present for.
     */
    getNumberOfPresentDaysInYear(year: number): number {
        const beginningOfYear: moment.Moment = moment(`${year}-01-01`);
        const endOfYear: moment.Moment = moment(`${year}-12-31`);
        // If the entry date is after year specified
        if (this.entry.year() > year) {
            return 0;
        // If the entry is in the year specified
        } else if (this.entry.year() === year) {
            // If the exit is null or the exit is after specified year
            if (!this.exit || this.exit.year() > year) {
                return StateVisit.getNumberOfDaysBetweenDatesInclusive(this.entry, endOfYear);
            // If the exit is in the year specified
            } else {
                return StateVisit.getNumberOfDaysBetweenDatesInclusive(this.entry, this.exit);
            }
        // If the entry is before the year specified
        } else {
            // If the exit is null or the exit is after the year specified
            if (!this.exit || this.exit.year() > year) {
                return StateVisit.getNumberOfDaysBetweenDatesInclusive(beginningOfYear, endOfYear);
            // If the exit is in the year specified
            } else {
                return StateVisit.getNumberOfDaysBetweenDatesInclusive(beginningOfYear, this.exit);
            }
        }

    }

    /**
     * Simple getter methods that retrieve values and can be called on the instance of this class
     * ========================================================================
     */

    getStateEntry(): moment.Moment { return this.entry; }

    getStateExit(): moment.Moment { return this.exit; }

    getStateDays(): number { return this.days; }
    
    /**
     * End Public Methods
     * ==================
     */
}
