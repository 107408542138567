<div class="container page-with-footer">
  
  <div *ngIf="navFrompg" class="row">
    <div class="col-6">
        <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
    </div>
    <div class="col-6" style="text-align:right">
      <p>Progress: <img src="../../../assets/ProgressBarNR04aTB.jpg" alt='Progress Bar 6 of 10'></p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
        <!--<hr>-->
        <p>&nbsp;</p>
    </div>
  </div>
  <!--<div class="row">
      <div class="col-12">
          <hr>
      </div>
  </div>-->

  <div *ngIf="!navFrompg">
    <div class="row">
      <div class="col-12">
        <h3>What are my treaty benefits?</h3>
        <p>This tool is intended to assist you in determining what treaty benefits you may be eligible to claim for wage 
          or scholarship income, as well as income from dividend or stock sales. This tool will also help you evaluate 
          if your treaty benefits match your income documents, and provide adjusting entries you can make in TaxSlayer to 
          claim your full treaty benefits when they do not.</p>
        <div class="alert alert-custom">
          <p class="mb-0"><b>It is your individual responsibility to determine your eligibility to claim a tax treaty
              benefit on your federal tax return. This tool will describe what the benefits are, and summarize the
              eligibility requirements. Please see IRS Publications <a
                href="https://www.irs.gov/pub/irs-pdf/p519.pdf">519</a> and <a
                href="https://www.irs.gov/pub/irs-pdf/p901.pdf">901</a> for further details regarding tax treaties and 
                eligibility. If your country of tax residency is not listed in this tool that means that there are no 
                treaty benefits. The benefits for dividends and stock sales are not common, and you should review Publication
                901 and the complete text of your tax treaty to confirm if you qualify. Also note that not all states honor 
                federal income tax treaties, and so the benefits described here may or may not apply to your state tax return. </b></p>
        </div>
        <hr class="custom-line-silver">
      </div>
    </div>
  </div>
  
  <div id="print-section">

    <div *ngIf="!navFrompg">   <!-- Start of top section only shown if not coming from Personalized Guide -->
      <div>
        <!-- Input Section Always Shows -->
        <div class="row">
          <div class="col-sm-3 col-lg-4">
            <label for="selectedVisa" class="h5">Select Visa</label>
            <ng-select id="selectedVisa" [items]="selectableVisas" [(ngModel)]="selectedVisa"
              placeholder="Select a visa type" (ngModelChange)="selectedVisaChange()" [clearable]="false">
            </ng-select>
            <div *ngIf="s11" class="invalid-feedback d-block">Please Select a visa</div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <label for="selectedCountry" class="h5">Select Country of Tax Residency</label>
            <ng-select id="selectedCountry" [items]="countriescovered" [(ngModel)]="selectedCountry"
              placeholder="Select Country" (ngModelChange)="selectedCountryChange()" [clearable]="false">
            </ng-select>
            <div *ngIf="s12" class="invalid-feedback d-block">Please Select a country of tax residency</div>
          </div>
          <div class="col-sm-3 col-lg-4">
            <label for="selectedResidency" class="h5">Select Federal Tax Residency</label>
            <ng-select id="selectedResidency" [items]="residentialStatuses" [(ngModel)]="selectedResidency"
              placeholder="Country of origin" (ngModelChange)="selectedResidencyChange()" [clearable]="false">
            </ng-select>
            <div *ngIf="s13" class="invalid-feedback d-block">Please Select a residency status</div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-3 col-lg-6">
          <!--<p>&nbsp;</p>-->
          <label for="dividends" class="h5">Did you receive dividends from U.S. Corporations</label>
          <ng-select id="dividends" [items]="binaryagree" [(ngModel)]="dividends"
            (ngModelChange)="selectedDividendChange()" placeholder="Select" [clearable]="false">
          </ng-select>
          <div *ngIf="s14" class="invalid-feedback d-block">Please Select a confirm your capital gain recipiency</div>
        </div>
        <div class="col-sm-3 col-lg-6">
          <!--<p>&nbsp;</p>-->
          <label for="capitalgains" class="h5">Did you have capital gains from sales of U.S. stocks</label>
          <ng-select id="capitalgains" [items]="binaryagree" [(ngModel)]="capitalgains"
            (ngModelChange)="selectedCapgainsChange()" placeholder="Select" [clearable]="false">
          </ng-select>
          <div *ngIf="s15" class="invalid-feedback d-block">Please Select a confirm your dividend recipiency</div>
        </div>
      </div>
      <!-- Input Section Always Shows -->

      <!-- Input Buttons Always Shows -->
      <div class="row my-2">
        <div class="col-6">
          <button class="btn btn-custom mb-sm-0 mb-2" (click)="checkBenefits()"> Check Treaty Benefits </button>
        </div>
        <div class="col-6">
          <button class="btn btn-danger float-right" (click)="clearBenefits()"> Clear Treaty Benefits </button>
        </div>
      </div>

      <!-- Input Buttons Always Shows -->


      <!-- Start of results output -->

      <hr class="custom-line-silver">
      <!-- Benefit Viewing Section Only Shows Toggled by showBenefits and s1f -->
      <!-- s1f indicates if there are any fails in the input -->
      <div *ngIf="showBenefits && s1f" class="row">
        <!-- Triggers for showing any treaty benefits -->
        <div *ngIf="type19 || type20" class="col-12 my-2">
          <!-- If neither of these are active then nothing shows -->

          <!-- Code 16 Benefit Section -->

          <table *ngIf="bc16;else bc16f" class="table table-bordered">
            <thead>
              <tr>
                <th colspan="3">My treaty benefit for scholarship or fellowship income (Code 16)</th>
              </tr>

              <tr>
                <th scope="col">Maximum Dollar Amount</th>
                <th scope="col">Maximum Years in the U.S.</th>
                <th scope="col">Treaty Article</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{cdata[1][1]}}</td>
                <td>{{cdata[1][0]}}</td>
                <td>{{cdata[1][2]}}</td>
              </tr>
            </tbody>
          </table>
          <!-- Code 16 Benefit Section -->
          <!-- Special Disclaimer Section -->
          <div *ngIf="cdata[11][0] && bc16 && selectedResidency=='Resident Alien'" class="col-12 my-2">
            <p>Typically only the income of a nonresident alien is eligible for income tax treaty exemptions. The Saving
              Clause in an income tax treaty prevents citizens or residents of the U.S. from using the tax treaty to
              reduce their U.S. tax liability or otherwise benefit from the treaty. The tax treaty between the U.S. and
              your country of tax residency has an exception to this savings clause which may allow a resident alien to
              continue to claim treaty benefits.</p>
            <p>Resident tax returns with treaty benefits are out of scope for IRS VITA clinics. You are not required to
              claim a treaty benefit, and can file your taxes for free if you meet all other requirements. If the treaty
              benefit is valuable, it might be beneficial for you to consider finding a paid tax preparer who can help you
              file a return that claims these benefits. </p>
          </div>
          <div *ngIf="cdata[11][2] && bc16 && selectedResidency=='Resident Alien' && cdata[3][0] != 'No Limit'"
            class="col-12 my-2">
            <p>Your country of tax residency has a time limit for claiming treaty benefits. This time starts when you
              first arrive on your visa. Usually this treaty benefit expires by the time you become a resident alien,
              though there are occasionally situations where an individual may still be able to claim the treaty benefit
              if they have not met this time limit. We encourage you to carefully review your visa, travel history, and
              tax treaty documents, and to discuss your situation with a competent tax preparer before you file a return
              claiming these benefits. As this is out of scope for VITA clinics, we cannot assist you in this process. We
              strongly suggest that you ensure that any paid tax preparer you speak to is knowledgeable regarding these
              issues before you proceed.</p>
          </div>
          <div *ngFor="let specialnotes of cdata[6]; index as i; trackBy:trackIndex" class="col-12 mb-2 mt-1">
            <p *ngIf="bc16"> {{specialnotes}} </p>
          </div>
          <!-- Special Disclaimer Section -->
          <ng-template #bc16f>
            <p>You are not eligible for any treaty benefits for scholarships or fellowships under (Code 16)</p>
          </ng-template>
          <hr />
        </div>
        <!-- Code 16 Section -->
        <!-- Code 19 Section -->
        <div *ngIf="type19" class="col-12 my-2">
          <!-- Code 19 Benefit Section -->
          <table *ngIf="bc19;else bc19f" class="table table-bordered">
            <thead>
              <tr>
                <th colspan="3">My treaty benefit for wage income for Teaching (Code 19)</th>
              </tr>

              <tr>
                <th scope="col">Maximum Dollar Amount</th>
                <th scope="col">Maximum Years in the U.S.</th>
                <th scope="col">Treaty Article</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{cdata[2][1]}}</td>
                <td>{{cdata[2][0]}}</td>
                <td>{{cdata[2][2]}}</td>
              </tr>
            </tbody>
          </table>
          <!-- Code 19 Benefit Section -->
          <!-- Special Disclaimer Section -->
          <div *ngIf="cdata[11][1] && bc19 && selectedResidency=='Resident Alien'" class="col-12 my-2">
            <p>Typically only the income of a nonresident alien is eligible for income tax treaty exemptions. The Saving
              Clause in an income tax treaty prevents citizens or residents of the U.S. from using the tax treaty to
              reduce their U.S. tax liability or otherwise benefit from the treaty. The tax treaty between the U.S. and
              your country of tax residency has an exception to this savings clause which may allow a resident alien to
              continue to claim treaty benefits.</p>
            <p>Resident tax returns with treaty benefits are out of scope for IRS VITA clinics. You are not required to
              claim a treaty benefit, and can file your taxes for free if you meet all other requirements. If the treaty
              benefit is valuable, it might be beneficial for you to consider finding a paid tax preparer who can help you
              file a return that claims these benefits. </p>
          </div>
          <div *ngIf="cdata[11][2] && bc19 && selectedResidency=='Resident Alien' && cdata[3][0] != 'No Limit'"
            class="col-12 my-2">
            <p>Your country of tax residency has a time limit for claiming treaty benefits. This time starts when you
              first arrive on your visa. Usually this treaty benefit expires by the time you become a resident alien,
              though there are occasionally situations where an individual may still be able to claim the treaty benefit
              if they have not met this time limit. We encourage you to carefully review your visa, travel history, and
              tax treaty documents, and to discuss your situation with a competent tax preparer before you file a return
              claiming these benefits. As this is out of scope for VITA clinics, we cannot assist you in this process. We
              strongly suggest that you ensure that any paid tax preparer you speak to is knowledgeable regarding these
              issues before you proceed.</p>
          </div>
          <!-- Special Disclaimer Section -->
          <ng-template #bc19f>
            <p>You are not eligible for any treaty benefits for wages under (Code 19)</p>
          </ng-template>
          <div *ngFor="let specialnotes of cdata[7]; index as i; trackBy:trackIndex" class="col-12 mb-2 mt-1">
            <p> {{specialnotes}} </p>
          </div>
          <hr />
        </div>
        <!-- Code 19 Section -->
        <!-- Code 20 Section -->
        <!-- Code 20 Benefit Section -->
        <div *ngIf="type20" class="col-12 my-2">
          <table *ngIf="bc20;else bc20f" class="table table-bordered">
            <thead>
              <tr>
                <th colspan="3">My treaty benefit for wage income for Studying and Training (Code {{tbWage2018}})</th>
              </tr>
              <tr>
                <th scope="col">Maximum Dollar Amount</th>
                <th scope="col">Maximum Years in the U.S.</th>
                <th scope="col">Treaty Article</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{cdata[3][1]}}</td>
                <td>{{cdata[3][0]}}</td>
                <td>{{cdata[3][2]}}</td>
              </tr>
            </tbody>
          </table>
        <!-- Code 20 Benefit Section -->
          <!-- Special Disclaimer Section -->
          <div *ngIf="cdata[11][2] && bc20 && selectedResidency=='Resident Alien'" class="col-12 my-2">
            <p>Typically only the income of a nonresident alien is eligible for income tax treaty exemptions. The Saving
              Clause in an income tax treaty prevents citizens or residents of the U.S. from using the tax treaty to
              reduce their U.S. tax liability or otherwise benefit from the treaty. The tax treaty between the U.S. and
              your country of tax residency has an exception to this savings clause which may allow a resident alien to
              continue to claim treaty benefits.</p>
            <p>Resident tax returns with treaty benefits are out of scope for IRS VITA clinics. You are not required to
              claim a treaty benefit, and can file your taxes for free if you meet all other requirements. If the treaty
              benefit is valuable, it might be beneficial for you to consider finding a paid tax preparer who can help you
              file a return that claims these benefits. </p>
          </div>
          <div *ngIf="cdata[11][2] && bc20 && selectedResidency=='Resident Alien' && cdata[3][0] != 'No Limit'"
            class="col-12 my-2">
            <p>Your country of tax residency has a time limit for claiming treaty benefits. This time starts when you
              first arrive on your visa. Usually this treaty benefit expires by the time you become a resident alien,
              though there are occasionally situations where an individual may still be able to claim the treaty benefit
              if they have not met this time limit. We encourage you to carefully review your visa, travel history, and
              tax treaty documents, and to discuss your situation with a competent tax preparer before you file a return
              claiming these benefits. As this is out of scope for VITA clinics, we cannot assist you in this process. We
              strongly suggest that you ensure that any paid tax preparer you speak to is knowledgeable regarding these
              issues before you proceed.</p>
          </div>
          <div *ngFor="let specialnotes of cdata[8]; index as i; trackBy:trackIndex" class="col-12 mb-2 mt-1">
            <p *ngIf="bc20"> {{specialnotes}} </p>
          </div>
          <ng-template #bc20f>
            <p>You are not eligible for any treaty benefits for wages under (Code {{tbWage2018}})</p>
          </ng-template>
          <!-- Special Disclaimer Section -->
          <hr />
        </div>

      </div>
        <!-- Code 20 Section -->

      <div *ngIf="showBenefits && s1f" class="row">
        <!-- Capital Gain Benefit Section -->
        <div *ngIf="capitalgainaccess  && selectedResidency != 'Resident Alien'" class="col-12 my-2">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th colspan="3">Capital Gain Tax Rate and Limit</th>
              </tr>
              <tr>
                <th scope="col">Treaty Rate (%)</th>
                <th scope="col">Treaty Article</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{cdata[4][0]}}</td>
                <td>{{cdata[4][1]}}</td>
              </tr>
            </tbody>
          </table>
          <!-- Capital Gain Benefit Section -->
          <!-- Special Disclaimer Section -->

          <p *ngIf="selectedResidency == 'Nonresident Alien' && capitalgainaccess">You must review all paragraphs of the
            treaty article to
            fully ensure that all conditions are met. Some treaties limit the benefit based on items like the percentage
            of stock ownership held, types of assets held by the corporation, etc. You can access the list of tax treaties
            here:
            <a href="https://www.irs.gov/businesses/international-businesses/united-states-income-tax-treaties-a-to-z">You
              can access the list of tax treaties here:</a>
          </p>
          <p *ngIf="selectedResidency == 'Nonresident Alien' && capitalgainaccess">If a nonresident alien is physically
            present in the U.S. for less than 183 days during the tax year, none of the capital gains from these sales 
            are taxable. The days counted for excludable gains consider all days of presence, regardless of exempt days 
            based on visa status under IRC §7701(b).</p>
          <p *ngIf="selectedResidency == 'Nonresident Alien' && capitalgainaccess">If the nonresident is present in the
            U.S.
            for 183 days or
            more, generally the rate of tax on the gain is 30% unless a treaty tax rate applies.</p>
          <p *ngIf="selectedResidency == 'Nonresident Alien' && capitalgainaccess">This income is reported on 1040-NR,
            Schedule NEC, Tax on Income Not Effectively Connected With a U.S. Trade or Business, NOT on Schedule D, 
            Capital Gains and Losses, nor on the income section of Form 1040-NR. Capital losses of nonresident aliens 
            may only offset other capital gains. (Capital losses of nonresident aliens cannot be used against other 
            income, nor can they be carried forward to another tax year.)</p>
          <div *ngFor="let specialnotes of cdata[9]; index as i; trackBy:trackIndex" class="col-12 mb-2 mt-1">
            <p *ngIf="capitalgainaccess  && selectedResidency != 'Resident Alien'"> {{specialnotes}} </p>
          </div>
          <hr />
          <!-- Special Disclaimer Section -->
        </div>
        
        <!-- Dividend Benefit Section -->
        <div *ngIf="dividendaccess  && selectedResidency != 'Resident Alien'" class="col-12 my-2">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th colspan="3">Dividend Rate and Limit</th>
              </tr>
              <tr>
                <th scope="col">Treaty Rate (%)</th>
                <th scope="col">Treaty Article</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{cdata[5][0]}}</td>
                <td>{{cdata[5][1]}}</td>
              </tr>
            </tbody>
          </table>
          <!-- Dividend Benefit Section -->
          <!-- Special Disclaimer Section -->
          <p *ngIf="selectedResidency == 'Nonresident Alien' && dividendaccess">Generally, dividend income from investments in U.S.
            corporate stock is considered FDAP (Fixed, Determinable, Annual or Periodic) income, NOT effectively connected
            to the taxpayer's U.S. trade or business and is therefore taxable at a 30% rate on Form 1040-NR, Schedule NEC,
            NOT on the front income section of the form.</p>
          <p *ngIf="selectedResidency == 'Nonresident Alien' && dividendaccess">You must review all paragraphs of the
            treaty article to fully ensure that all conditions are met.
            <a href="https://www.irs.gov/businesses/international-businesses/united-states-income-tax-treaties-a-to-z">You
              can access the list of tax treaties here:</a>
          </p>
          <div *ngFor="let specialnotes of cdata[10]; index as i; trackBy:trackIndex" class="col-12 mb-2 mt-1">
            <p *ngIf="dividendaccess  && selectedResidency != 'Resident Alien' "> {{specialnotes}} </p>
          </div>
        </div>
        <hr class="custom-line-silver">
      </div>

      <div class="row">
        <!-- Special Disclaimer Section -->
      </div>

      <!-- Document Entry Section -->
      <hr *ngIf="documententry && showBenefits && s1f  && selectedResidency != 'Resident Alien'" class="custom-line">
      <div *ngIf="documententry && showBenefits && s1f && selectedResidency != 'Resident Alien'" class="row">
        <div>
          <div class="col-12">
            <h5><b>Entering my Income Documents to See If My Treaty Benefits Match</b></h5>
            <p>This section of the tool is intended to assist you in determining if your treaty benefits match the income
              documents you have received for any scholarships or wage income. This tool will also help you calculate the
              adjusting entries you will need to make in TaxSlayer. Please note that this will only work for
              treaty benefits for scholarships and wage income.</p>
            <p>Note: The UofMn provides 1042-S documents by March 15th by U.S. postal mail. The UofMn does not provide an
              income document for all scholarships and fellowships. Please review your One-Stop student account to determine
              if you received a scholarship or fellowship. You can contact payroll at ohr@umn.edu to ask if you will receive
              a 1042-S form.</p>
            <p>Only enter income documents related to your listed treaty benefits. If you do not have a treaty benefit for a
              particular kind of income, follow the instructions in your basic scenario and review the descriptions for the
              special circumstances guides to determine if you should use one or more of them.</p>
          </div>
        </div>
      </div>  
    </div>  <!-- End of top section only shown if not coming from Personalized Guide -->

    <div *ngIf="navFrompg">
      <div class="row">
        <div class="col-12">
          <h3>Personalized Guide - Income Match for Treaty Benefits</h3>
          <hr class="custom-line-silver">
          <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
          </div>
          <p>We need to check if your income documents match your treaty benefits.  Please enter information below.</p>
        </div>
      </div>
    </div>

    <div *ngIf="(documententry && showBenefits && s1f && selectedResidency != 'Resident Alien') || navFrompg" class="row"> 
      <div class="col-12 my-2 alert alert-light">
        <p>For a W-2, enter the amount of income in Box 1</p>
        <p>For a 1042-S, enter the income code from Box 1, and the amount of income in Box 2</p>
        <p>For an undocumented fellowship, enter the amount of income from your fellowship letter or student account
          statement</p>
      </div>
        <!-- Document Data Entry Section -->
        <div *ngFor="let doc of docs; index as i; trackBy:trackIndex" class="col-12 mb-2 mt-1">
        <app-docs [docId]="doc.id" [showLabels]="i===0" (docRemoved)="onDocRemoved($event, i)"
          (docChanged)="onDocChanged($event, i)"></app-docs>
      </div>
        <!-- Document Data Entry Section -->
        <div class="col-12">
        <a class="no-href clickable" (click)="addNewDoc()">Add another document</a>
      </div>
        <!-- Error Read Section -->
        <div *ngIf="emptydoc" class="col-12">
        <div class="invalid-feedback d-block">One of your documents is missing an income document type</div>
      </div>
      <div *ngIf="crossedincome" class="col-12">
        <div class="invalid-feedback d-block">Your wage income type on your 1042-S is not consistent with your visa
          type. Students should have wage income on a 1042-S shown as income code 20, and teachers/researchers/etc.
          should have income code 19. Please review your document to ensure that it was entered correctly. If you did
          enter both visa and 1042-S as they were provided to you, please contact us for further guidance.</div>
      </div>
      <div *ngIf="docsHaveErrors" class="col-12">
        <div class="invalid-feedback d-block">Some of your documents have errors. Please fix them before submitting.
        </div>
        <!-- Error Read Section -->
      </div>
    </div>

    <!-- Diagnostic Variables Uncomment To Monitor States -->
    <!--
      {{s1042_s}}/{{fellow_count}}/{{stsvalues[3]}}/{{scholarshipmatch}}/{{stsvalues}}
    <hr />
    {{s1042_w_count}}/{{w2_count}}/{{wtsvalues[3]}}/{{wagematch}}/{{wtsvalues}}
    <hr />
    {{stsvalues[4]}}
    <hr />
    {{wtsvalues[4]}}
    -->

    <div *ngIf="!navFrompg">

      <div *ngIf="documententry && showBenefits && s1f  && selectedResidency != 'Resident Alien'" class="row my-2">
        <div class="col-4">
          <button class="btn btn-custom mb-sm-0 mb-2" (click)="checkMatches()">Check if treaty benefits match</button>
        </div>
        <div class="col-8">
          <button class="btn btn-danger float-right" (click)="clearDocs()">Clear All Documents</button>
        </div>
      </div>
    
      <!-- Match Display Section -->
      <div class="row">
        <div *ngIf="showDocMatches" class="col-12 my-2">
          
          <div *ngIf="bc16" class="col-12 my-2">
            <table *ngIf="s1042_s_count > 0 || fellow_count > 0" class="table table-bordered">
              <thead>
                <tr>
                  <th colspan="5">Match Status for scholarships</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <th colspan="3">Treaty Information</th>
                  <th colspan="2">My Information</th>
                </tr>
                <tr>
                  <td>Maximum Dollar Amount</td>
                  <td>Maximum Years in the U.S.</td>
                  <td>Treaty Article</td>
                  <td>Income Document Type</td>
                  <td>Income Amount</td>
                </tr>
                <tr *ngFor="let document of scholarshipvalues; index as i; trackBy:trackIndex">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ document[0] }}</td>
                  <td>{{ document[1] }}</td>
                </tr>

                <tr>
                  <td>{{ scholarshiptreaty[1] }}</td>
                  <td>{{ scholarshiptreaty[0] }}</td>
                  <td>{{ scholarshiptreaty[2] }}</td>
                  <td>Total</td>
                  <td>{{ scholarshipamount }}</td>
                </tr>

              </tbody>
            </table>

            <div *ngIf="scholarshipmatch && (s1042.count > 0 || fellow_count > 0); else scholarshipmatchf"
              class="col-12 my-2 alert alert-success">
              <p>Your treaty benefits match</p>
            </div>
            <ng-template #scholarshipmatchf>
              <div *ngIf="s1042_s_count > 0 || fellow_count > 0" class="col-12 my-2 alert alert-custom">
                <p>Your treaty benefits do not match</p>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>TaxSlayer Modified Entries</th>
                    </tr>
                    <tr>
                      <th>Where to enter</th>
                      <th>Amount to enter</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr *ngIf="stsvalues[2]">
                      <td>Schedule OI - General Information, Wages exempt by a treaty</td>
                      <td>{{ stsvalues[0] }}</td>
                    </tr>

                    <tr>
                      <td>Schedule OI - Income exempt from Tax, Amount of exempt income</td>
                      <td>{{ stsvalues[1] }}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </ng-template>
          </div>

          <div *ngIf="bc20 || bc19" class="col-12 my-2">
            <table *ngIf="s1042_w_count > 0 || w2_count > 0 " class="table table-bordered">
              <thead>
                <tr>
                  <th colspan="5">Match Status for Wages</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="3">Treaty Information</th>
                  <th colspan="2">My Information</th>
                </tr>

                <tr>
                  <td>Maximum Dollar Amount</td>
                  <td>Maximum Years in the U.S.</td>
                  <td>Treaty Article</td>
                  <td>Income Document Type</td>
                  <td>Income Amount</td>
                </tr>
                <tr *ngFor="let document of wagevalues; index as i; trackBy:trackIndex">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ document[0] }}</td>
                  <td>{{ document[1] }}</td>
                </tr>

                <tr>
                  <td>{{ wagetreaty[1] }}</td>
                  <td>{{ wagetreaty[0] }}</td>
                  <td>{{ wagetreaty[2] }}</td>
                  <td>Total</td>
                  <td>{{ amountWageTotal }}</td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="wagematch && (s1042_w_count > 0 || w2_count > 0 ); else wagematchf" class="col-12 my-2 alert alert-success">
              <p>Your treaty benefits match</p>
            </div>
            <ng-template #wagematchf>
              <div *ngIf="s1042_w_count > 0 || w2_count > 0 " class="col-12 my-2 alert alert-custom">
                <p>Your treaty benefits do not match</p>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>TaxSlayer Modified Entries</th>
                    </tr>
                    <tr>
                      <th>Where to enter</th>
                      <th>Amount to enter</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>Schedule OI - General Information, Wages exempt by a treaty</td>
                      <td>{{ wtsvalues[0] }}</td>
                    </tr>

                    <tr *ngIf="wtsvalues[2] == 0">
                      <td>Schedule OI - Income exempt from Tax, Amount of exempt income</td>
                      <td>{{ wtsvalues[1] }}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </ng-template>
          </div>
        </div>
        <br>
      </div>


      <!-- End of Match Display Section -->

      <!-- Scenario Section -->

      <div *ngIf="showBenefits && selectedResidency != 'Resident Alien'" class="col-12 my-2 alert alert-custom">
        <div *ngIf="scenario == 1 && s1f" class="col-12 my-2">
          <p><b>You are not eligible for any treaty benefits for wages or scholarships, however you are eligible to claim
              the standard deduction on your tax return. Use the guide for Basic Scenario 1 to prepare your return.</b>
          </p>
        </div>

        <div *ngIf="scenario == 2 && s1f" class="col-12 my-2">
          <p><b>Follow the Basic Scenario 2 guide for entering your income and treaty benefits into TaxSlayer.</b></p>
          <p *ngIf="(wagematch == 0 && showDocMatches && (s1042_w_count > 0 || w2_count > 0 ))"> After completing Basic Scenario 2,
            follow the special circumstances guide for Wage Income with No 1042-S, or Your Treaty Benefits DO NOT MATCH</p>
          <p *ngIf="showDocMatches && ((scholarshipmatch == 0 && bc16) ||  (scholarshipmatch &&  fellow_count > 0))"> After
            completing Basic Scenario 2, follow the special circumstances guide for Fellowship or Scholarship Income Not Reported on a 1042-S</p>
        </div>

        <div *ngIf="scenario == 3 && s1f" class="col-12 my-2">
          <p><b>Use the guide for Basic Scenario 3 to prepare your return.</b></p>
        </div>

        <div *ngIf="dividendaccess || capitalgainaccess" class="col-12 my-2">
          <p>Use the special circumstances scenario for Investment Transactions to report this income.</p>
        </div>

        <div *ngFor="let specialnotes of cdata[12]; index as i; trackBy:trackIndex" class="col-12 mb-2 mt-1">
          <p *ngIf="capitalgainaccess"> {{specialnotes}} </p>
        </div>
      </div>

      <!--<div *ngIf="!navFrompg">-->
      <div>
        <p>We strongly recommend that you print your results or take screenshots for reference.</p>
        <button class="btn btn-custom-blue" ngxPrint printSectionId="print-section" [useExistingCss]="true">
          Print Results
        </button>
      </div>
      
    </div> <!-- End of section only showning if not coming from Personal Guide page -->

  </div>  <!-- End of print-section-->

  <div *ngIf="showUnexpectedErrorMessage" class="col-12 my-2 alert alert-danger">
    <div>An unexpected error has occurred while determining your residency status. We apologize for any inconvenience.
      If you would still like to determine your residency status, please send us screenshots of your input to <a
        href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a> and we will assist in any way we can.
    </div>
  </div>

  <div *ngIf="navFrompg">
    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToNextPage()">Continue</button>-->
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="checkBenefits()">Continue</button>
        </div>
    </div>
  </div>


  <div *ngIf="navFrompg" class="row">
    <div class="col-12">
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
    <div class="row">
        <div class="col-12">
            <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a>.</small></p>
        </div>
    </div>
  </div>

</div>
