<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR07.jpg" alt='Progress Bar 9 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - Minnesota Specific</h3>
            <!--<p>Comment</p>-->
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-7 col-lg-7">
            <label>Did you receive a Certificate of Rent Paid?</label>
        </div>
        <div class="col-sm-2 col-lg-2">
            <ng-select id="certificateMN" [items]="selectYesNo" [(ngModel)]="certificateMNAnswer" (ngModelChange)="certificateMNChange()" placeholder="Select" [clearable]="false"></ng-select>
        </div> 
    </div>

    <div *ngIf="showPayPropTaxMNQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Did you receive a Statement of Property Taxes Payable for {{taxYear+1}}?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="payPropTaxMN" [items]="selectYesNo" [(ngModel)]="payPropTaxMNAnswer" (ngModelChange)="payPropTaxMNChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>
    </div> 

    <div *ngIf="showOnetimePaymentMNQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Did you receive the MN One-time tax rebate payment?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="onetimePaymentMN" [items]="selectYesNo" [(ngModel)]="onetimePaymentMNAnswer" (ngModelChange)="onetimePaymentMNChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>
    </div> 

    <div *ngIf="showFirstFilingMNQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Have you ever previously filed a MN state return?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="firstFileMN" [items]="selectYesNo" [(ngModel)]="firstFilingMNAnswer" (ngModelChange)="firstFileMNChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>
    </div> 

    <div *ngIf="showNewAddressMNQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Did you move since you last filed a MN return?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="newAddressMN" [items]="selectYesNo" [(ngModel)]="newAddressMNAnswer" (ngModelChange)="newAddressMNChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>
    </div> 

    <div *ngIf="showContinueButton">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToNextPage()">Continue</button>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a>.</small></p>
        </div>
    </div>


</div>