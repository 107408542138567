<nav class="navbar 
            navbar-expand-sm 
            navbar-dark 
            bg-darkblue
            sticky-top">
    <!--<a routerLink='' style="color: rgb(253, 151, 38);" class="nav-item navbar-brand">Tools Home</a>-->
    <!--<a href="https://nrtaxhelp.org/" style="color: rgb(223, 224, 225);" class="nav-item navbar-brand">NonResident Tax Help Group (NoRTH)</a>-->
        <span style="float:left"><a href="https://nrtaxhelp.org/" style="color: rgb(223, 224, 225);" class="nav-item navbar-brand">NoRTH Home</a></span>
        <span><a style="color: rgb(223, 224, 225);" class="nav-item navbar-brand">|</a></span>
        <span style="float:right"><a href="https://tools.nrtaxhelp.org/" style="color: rgb(223, 224, 225);" class="nav-item navbar-brand">Tools Home</a></span>
    
    <div>
        <ul class="navbar-nav mr-auto">
        </ul>
    </div>
 </nav>