import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import * as moment from 'moment';
import { stateNames } from "./statevisit.service";
import { StateVisitModel } from './statevisitModel';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-statevisit',
  templateUrl: './statevisit.component.html',
  styleUrls: ['./statevisit.component.scss']
})
export class StateVisitComponent implements OnInit {
  @Output() stateVisitChanged = new EventEmitter<StateVisitModel>();
  @Output() stateVisitRemoved = new EventEmitter<StateVisitModel>();
  @Output() daysDataEmitter = new EventEmitter<number[]>();
  @Input() stateShowLabels: boolean;
  @Input() stateVisitId: number;

  stateVisit: StateVisitModel;
  stateEntryDate: NgbDateStruct;
  stateExitDate: NgbDateStruct;
  stateList: string[];

  taxYear: number;
  stateVisits: StateVisitModel;
  selectYesNo = ['Yes', 'No'];
  stateDaysStayed: number;
  

  constructor() { }

  ngOnInit() {
    this.taxYear = sessionStorage.taxYear
    this.stateVisit = {
      entry: null,
      exit: null,
      selectedState: null,
      days: null,
      abode: false,
      id: this.stateVisitId
    };
    this.stateList = stateNames;
  }


  stateEntryChange() {
    this.stateVisitChanged.emit(this.stateVisit);
  }


  stateEntryDateChange() {
    try {
      if (this.stateEntryDate && !(typeof this.stateEntryDate === 'string')) {
        this.stateVisit.entry = moment();
        this.stateVisit.entry.month(this.stateEntryDate.month).subtract(1, 'months').calendar();
        this.stateVisit.entry.date(this.stateEntryDate.day);
        this.stateVisit.entry.year(this.stateEntryDate.year);
      } else if (this.stateEntryDate && (typeof this.stateEntryDate === 'string')) {
        this.stateVisit.entry = moment(this.stateEntryDate, 'MM/DD/YYYY', true);
      } else {
        this.stateVisit.entry = null;
      }
    } catch (e) {}
    if (this.stateExitDate != null) {
      this.stateVisit.days =  this.getNumberOfDaysBetweenDatesInclusive(this.stateVisit.entry, this.stateVisit.exit);
    }
    this.setStateVisitError();
    this.stateVisitChanged.emit(this.stateVisit);
  }


  stateExitDateChange() {
    try {
      if (this.stateExitDate && !(typeof this.stateExitDate === 'string')) {
        this.stateVisit.exit = moment();
        this.stateVisit.exit.month(this.stateExitDate.month).subtract(1, 'months').calendar();
        this.stateVisit.exit.date(this.stateExitDate.day);
        this.stateVisit.exit.year(this.stateExitDate.year);
      } else if (this.stateExitDate && (typeof this.stateExitDate === 'string')) {
        this.stateVisit.exit = moment(this.stateExitDate, 'MM/DD/YYYY', true);
      } else {
        this.stateVisit.exit = null;
      }
    } catch (e) {}
    if (this.stateEntryDate != null) {
      this.stateVisit.days = this.getNumberOfDaysBetweenDatesInclusive(this.stateVisit.entry, this.stateVisit.exit);
    }
    this.setStateVisitError();
    this.stateVisitChanged.emit(this.stateVisit);
  }


  setStateVisitError() {
    this.stateVisit.error = [];
    if (this.stateVisit.entry && (!this.stateVisit.entry.isValid() || this.stateVisit.entry.year().toString().length != 4)) {
      this.stateVisit.error.push('Entry date is invalid. Please make sure the date is in the format MM/DD/YYYY and has valid values.');
    }
    if (this.stateVisit.exit && (!this.stateVisit.exit.isValid() || this.stateVisit.exit.year().toString().length != 4)) {
      this.stateVisit.error.push('Exit date is invalid. Please make sure the date is in the format MM/DD/YYYY and has valid values.');
    }
    if (this.stateVisit.entry && this.stateVisit.exit) {
      if (!this.stateVisit.exit.isAfter(this.stateVisit.entry)) {
        this.stateVisit.error.push('Your exit date must be after your entry date.');
      }
    }
  }


  removeStateVisit() {
    this.stateVisitRemoved.emit(this.stateVisit);
  }


  getNumberOfDaysBetweenDatesInclusive(firstDate: moment.Moment, secondDate: moment.Moment): number {
    return secondDate.endOf('day').diff(firstDate.startOf('day'), 'days') + 1;
  }

}