import { Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { StateVisitModel } from './statevisit/statevisitModel';
import { pgnr06aService } from './pg-nr06a.service';
import { stateInfoCodes, statesList } from '../pg-nr06/pg-nr06.service';
import { StateVisit } from './statevisit/statevisit';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Visit } from '../aminra/visit/Visit';


//
@Component({
  selector: 'app-pg-nr06a',
  templateUrl: './pg-nr06a.component.html',
  styleUrls: ['./pg-nr06a.component.scss'],
})

export class PGnr06aComponent implements OnInit {

  //public days: number[] = []

  // Variables for controlling whether messages are shown to the user, error messages or status
  showNewStateVisitHelpText = false;
  showOverlapError = false;
  showEndDateCaveat = false;
  stateVisitsHaveErrors = false;
  showStatus = false;
  showYearError: boolean = false

  // Variables holding information
  stateVisits: StateVisitModel[] = [];
  sortedStateVisits: StateVisit[] = [];
  sortedVisitsInTaxYear: Visit[] = [];
  stateVisitStates: string[] = [];
  stateIncomeStates: string[] = [];
  OUSVisitsInTaxYearStart: moment.Moment[] = [];
  OUSVisitsInTaxYearEnd: moment.Moment[] = [];
  OUSVisitsInTaxYearStartShow: moment.Moment[] = [];
  OUSVisitsInTaxYearEndShow: moment.Moment[] = [];
  oneState: boolean = false
  oneStateName: string = ''
  oneStateText: string = ''

  taxYear: any;
  selectYesNo = ['Yes', 'No'];
  navFrompg: boolean;
  stateIncomes: any[] = [];
  stateDays: any[] = [];
  totalOUSDays: number = 0;
  runningTotalTYDays: number = 0;
  maxDaysInTY: number = null;

  stateVisitsInTaxYear: StateVisit[];
  stList = statesList
  stateIndex: number = null;
  stateCodesList = stateInfoCodes;
  stateVisitCounter = 0;
  monthList: string[] = [];
  showUnexpectedErrorMessage: boolean = false;
  statesIncomesVisitsMismatch: boolean = false
  stateIncomeStateNames: string = ''

  stateVisitQs: boolean = false
  showContinueButton: boolean = false
  stateAddlQ: string[] = []
  stateMNAbodeQ: boolean = false

  stateCode1: string[] = []
  stateCode2: string[] = []
  stateCode3: string[] = []
  stateCode4: string[] = []
  stateCode5: string[] = []
  stateCode6: string[] = []

  constructor(
    private pgnr06aService: pgnr06aService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    ) {}


  ngOnInit() {
    this.navFrompg = true
    this.taxYear = sessionStorage.taxYear;
    this.showContinueButton = false;

    this.maxDaysInTY = (moment([this.taxYear]).isLeapYear() ? 366: 365)
    this.stateAddlQ = []

    this.stateCode1 = ['Alaska', 'Florida', 'Nevada', 'New Hampshire', 'South Dakota', 'Tennessee', 'Texas', 'West Virginia', 'Wyoming']
    this.stateCode2 = ['Alabama', 'Arizona', 'Arkansas', 'Colorado', 'Connecticut', 'Delaware', 'Georgia', 'Hawaii', 'Idaho',
                      'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Massachusetts', 'Michigan',
                      'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'New Jersey', 'New Mexico', 'New York', 'North Dakota',
                      'Ohio', 'Oklahoma', 'Oregon', 'Rhode Island', 'South Carolina', 'Utah', 'Vermont', 'Virginia', 'West Virginia']
    this.stateCode3 = ['California', 'Pennsylvania', 'Wisconsin']
    this.stateCode4 = ['North Carolina']
    this.stateCode5 = ['Minnesota']
    this.stateCode6 = ['Maryland']
     

    this.OUSVisitsInTaxYearStart = this.appService.getOUSVisitsInTaxYearStart()
    this.OUSVisitsInTaxYearStartShow = this.appService.getOUSVisitsInTaxYearStartShow()
    this.OUSVisitsInTaxYearEnd = this.appService.getOUSVisitsInTaxYearEnd()
    this.OUSVisitsInTaxYearEndShow = this.appService.getOUSVisitsInTaxYearEndShow()

    if (sessionStorage.noOfStates == "1") {
      this.oneState = true
      this.oneStateName = sessionStorage.stateLived1
      this.oneStateText = 'You indicated that you only were physically present in one state (' + this.oneStateName + ') in your entries on the previous page. We have pre-populated your state visists based on this information. If this is not correct, go back and select any additional states that you were present in or go back to the Tax Residency page to change the dates you were outside the U.S. '
      this.sortedVisitsInTaxYear = this.appService.getSortedVisitsInTY()
      this.runningTotalTYDays = this.maxDaysInTY
      this.appService.clearStateVisits();
      this.stateVisitCounter = 0
      for (let v = 0; v < this.sortedVisitsInTaxYear.length; v++) {
        this.stateVisits.push({
          entry: this.sortedVisitsInTaxYear[v].entry,
          exit: this.sortedVisitsInTaxYear[v].exit,
          selectedState: this.oneStateName,
          days: ((this.sortedVisitsInTaxYear[v].exit.startOf("d").diff(this.sortedVisitsInTaxYear[v].entry.startOf("d"), "d")) + 1),
          abode: null,
          id: this.stateVisitCounter++
        });
      }
    }

    else {
    this.oneStateText = 'You indicated that you were physically present in multiple states in your entries on the previous page. Please enter the start date, end date, and U.S. state for where you were present during ' + this.taxYear + '.'
    this.totalOUSDays = this.appService.getTotalOUSDays()
    this.runningTotalTYDays = this.totalOUSDays

    this.appService.clearStateVisits();
    this.addNewStateVisit();
    }

  }

  addNewStateVisit() {
    this.showUnexpectedErrorMessage = false;
    if (this.canAddNewStateVisit()) {
      this.showNewStateVisitHelpText = false;
      this.showEndDateCaveat = false;
  
      this.stateVisits.push({
        entry: null,
        exit: null,
        selectedState: null,
        days: null,
        abode: null,
        id: this.stateVisitCounter++
      });
    } else {
      this.showNewStateVisitHelpText = true;
    }
  }

  canAddNewStateVisit() {
    let numberOfEmptyExitDates = 0;
    for (const stateVisit of this.stateVisits) {
      if (stateVisit.entry === null || stateVisit.selectedState === null) {
        return false;
      } else {
        if (stateVisit.exit === null) {
          numberOfEmptyExitDates++;
          if (numberOfEmptyExitDates > 1) {
            return false;
          }
        }
      }
    }
    if (this.pgnr06aService.doStateAndOUSVisitsOverlap(this.constructStateAndOUSVisits())) {
      this.showOverlapError = true;
      return false;
    }
    return true;
  }

  onStateVisitChanged(stateVisit, index) {
    this.showUnexpectedErrorMessage = false;
    this.showStatus = false;
    this.showNewStateVisitHelpText = false;
    this.showEndDateCaveat = false;
    this.showYearError = false
    this.stateVisits[index] = _.cloneDeep(stateVisit);
    this.checkStateVisitsForErrors();
    if (!this.stateVisitsHaveErrors) {
      if (stateVisit.entry && stateVisit.exit) {
        this.showOverlapError = this.pgnr06aService.doStateAndOUSVisitsOverlap(this.constructStateAndOUSVisits())
      }
    }
    if ((stateVisit.entry && stateVisit.entry.year() != this.taxYear) || (stateVisit.exit && stateVisit.exit.year() != this.taxYear)) {
      this.showYearError = true
    }
    if (this.canAddNewStateVisit) {
      this.runningTotalTYDays = this.getRunningTotalTYDays()
    }
  }


  onStateVisitRemoved(stateVisit, index) {
    this.showUnexpectedErrorMessage = false;
    this.showStatus = false;
    this.showNewStateVisitHelpText = false;
    this.showEndDateCaveat = false;
    this.stateVisits.splice(index, 1);
    if (this.stateVisits.length === 0) {
      this.addNewStateVisit();
    }
    this.checkStateVisitsForErrors();
    if (!this.stateVisitsHaveErrors) {
      this.showOverlapError = this.pgnr06aService.doStateAndOUSVisitsOverlap(this.constructStateAndOUSVisits())
    }
    this.runningTotalTYDays = this.getRunningTotalTYDays()
  }

  trackIndex(index, item) {
    return item.id;
  }

  clearStateVisits() {
    this.stateVisitsHaveErrors = false;
    this.showOverlapError = false;
    this.showStatus = false;
    this.showEndDateCaveat = false;
    this.stateVisits = [];
    this.showUnexpectedErrorMessage = false;
    this.runningTotalTYDays = this.getRunningTotalTYDays()
    this.addNewStateVisit();
  }

  checkStateVisitsForErrors() {
    this.stateVisitsHaveErrors = false;
    this.stateVisits.forEach((stateVisit: StateVisitModel) => {
      if (stateVisit.error && stateVisit.error.length > 0) {
        this.stateVisitsHaveErrors = true;
        return;
      }
    });
  }

  constructStateVisits() {
    const stateVisits: StateVisit[] = [];
    this.stateVisits.forEach((stateVisit: StateVisitModel) => {
      stateVisits.push(new StateVisit(
      stateVisit.id, 
      stateVisit.entry, 
      stateVisit.exit,
      stateVisit.selectedState,
      stateVisit.days,
      stateVisit.abode,
      ));
    });
    return stateVisits;
  }

  constructStateAndOUSVisits() {
    const stateAndOUSVisits: StateVisit[] = [];
    this.stateVisits.forEach((stateVisit: StateVisitModel) => {
      stateAndOUSVisits.push(new StateVisit(
      stateVisit.id, 
      stateVisit.entry, 
      stateVisit.exit,
      stateVisit.selectedState,
      stateVisit.days,
      stateVisit.abode,
      ));
    });
    for (let visitNo=0; visitNo < this.OUSVisitsInTaxYearStart.length; visitNo++) {
      stateAndOUSVisits.push(new StateVisit(
      visitNo, 
      this.OUSVisitsInTaxYearStartShow[visitNo], 
      this.OUSVisitsInTaxYearEndShow[visitNo],
      "OUS",
      this.OUSVisitsInTaxYearEndShow[visitNo].dayOfYear() - this.OUSVisitsInTaxYearStartShow[visitNo].dayOfYear() + 1,
      null,
      ));
    };
      return stateAndOUSVisits;
  }


  getRunningTotalTYDays() {
    let nullStateName = false
    let runningTotal = this.totalOUSDays
    this.stateVisits.forEach((stVisit: StateVisitModel) => {
      runningTotal += stVisit.days
      if (stVisit.selectedState == null) {
        nullStateName = true
      }
    })
    if (runningTotal == this.maxDaysInTY && nullStateName == false) {
      this.showContinueButton = true
    }
    else {
      this.showContinueButton = false
    }
    return runningTotal
  }



  otherStateIncomes(inc: Object, state: string) {
    var otherStateYes = false
    for (var stIncEntry = 0; stIncEntry < Object.keys(inc).length; ++stIncEntry) {
      Object.keys(inc).forEach(function(stIncEntry) {
        if (stIncEntry != state) {
          if (inc[stIncEntry] == 'Yes') {
            otherStateYes = true
          }
        }
      })
    }
    return otherStateYes
  }


  onSubmit() {
    if (this.canAddNewStateVisit()) {
      this.showNewStateVisitHelpText = false;
      this.stateVisitsInTaxYear = this.pgnr06aService.sortStateVisitsChronologically(this.constructStateVisits())
      this.appService.clearStateVisits()
      this.stateVisitsInTaxYear.forEach((stateVisit: StateVisit) => {
        this.appService.addStateVisits(stateVisit)
      })
      this.stateIncomes = this.appService.getStateIncomes()
      this.stateDays = []


      //Test for same states selected in pg-nr06 and pg-nr06a

      //Create alphabetical list of states stayed during TY
      this.stateVisitStates = []
      for (var i = 0; i < this.stateVisitsInTaxYear.length; i++) {
        if (!this.stateVisitStates.includes(this.stateVisitsInTaxYear[i].selectedState)) {
          this.stateVisitStates.push(this.stateVisitsInTaxYear[i].selectedState)
        }
      }
      this.stateVisitStates.sort()

      //Create alphabetical list of state income states
      var j;
      for (j of Object.keys(this.stateIncomes)) {
        if (!this.stateIncomeStates.includes(j.toString())) {
          var strKey = j.toString()
          this.stateIncomeStates.push(strKey.toString())
          i++
        }
      }
      this.stateIncomeStates.sort()

      //Compare lists of states
      if (this.stateVisitStates.length != this.stateIncomeStates.length) {
        this.statesIncomesVisitsMismatch = true
        this.stateIncomeStateNames = '('
        for (i = 0; i < this.stateIncomeStates.length; i++) {
          this.stateIncomeStateNames = this.stateIncomeStateNames + this.stateIncomeStates[i] + ', '
        }
        this.stateIncomeStateNames.substring(0, this.stateIncomeStateNames.length - 1)
        this.stateIncomeStateNames = this.stateIncomeStateNames + ')'
        return
      }
      else {
        for (i = 0; i < this.stateIncomeStates.length; i++) {
          if (this.stateIncomeStates[i] != this.stateVisitStates[i]) {
            this.statesIncomesVisitsMismatch = true
            this.stateIncomeStateNames = '('
            for (i = 0; i < this.stateIncomeStates.length; i++) {
              this.stateIncomeStateNames = this.stateIncomeStateNames + this.stateIncomeStates[i] + ', '
            }
            this.stateIncomeStateNames = this.stateIncomeStateNames.substring(0, this.stateIncomeStateNames.length - 2)
            this.stateIncomeStateNames = this.stateIncomeStateNames + ')'
            return
          }
        }
      }

    
      //store visit data for the personalized Guide and the next page
      this.stateVisitsInTaxYear.forEach((stVisit: StateVisit) => {
        if (!(stVisit.selectedState in this.stateDays)) {
          this.stateDays[stVisit.selectedState] = stVisit.days
        }
        else {
          this.stateDays[stVisit.selectedState] += stVisit.days
        }
      })

      //determine the codes for each state with income to determine if additional info is needed and which guide pieces to provide
      let stateCodes = []
      let k;
      for (k of Object.keys(this.stateIncomes)) {
        var strKey = k.toString()
        var index = this.stList.indexOf(strKey);
        stateCodes.push(this.stateCodesList[index][1])
      }

      //Determine what to do based on the state codes present

      for (k of Object.keys(this.stateIncomes)) {

        //Code=1
        if (this.stateCode1.includes(k.toString())) {
          sessionStorage.pgGuide_NoStateIncTax = 'Yes'
        }

        //Code=2
        if (this.stateCode2.includes(k.toString())) {
          sessionStorage.pgGuide_Unsupported = 'Yes'
        }

        //Code=3 (CA, PA, WI)
        if (this.stateIncomes.hasOwnProperty('California') ||
            this.stateIncomes.hasOwnProperty('Pennsylvania') ||
            this.stateIncomes.hasOwnProperty('Wisconsin')) { 
        }

        //Code=4 (NC)
        if (this.stateIncomes.hasOwnProperty('North Carolina')) { 
          if (this.stateDays['North Carolina'] <= 183) {
            sessionStorage.stateResNC = "NR"
          }
          else {
            sessionStorage.stateResNC = "R"
          }
        }

        //Code=5 (MN)
        if (this.stateIncomes.hasOwnProperty('Minnesota')) { 
          if (this.stateDays['Minnesota'] >= 183) {
            this.stateMNAbodeQ = true
            sessionStorage.stateMNAbodeQ = "Yes"
          }
          else {
            this.stateMNAbodeQ = false
            sessionStorage.stateMNAbodeQ = "No"
            sessionStorage.stateResMN = "NR"
          }
          this.stateAddlQ.push("MN")
        }


        //Code=6 (MD)
        if (this.stateIncomes.hasOwnProperty('Maryland')) { 
          if (this.stateDays['Maryland'] <= 182) {sessionStorage.stateResMD = "NR"}
          else if (this.stateDays['Maryland'] >= 187) {sessionStorage.stateResMD = "R"}
          else if (this.stateDays['Maryland'] >= 183 && this.stateDays['Maryland'] <= 186) {
            this.stateVisitsInTaxYear.forEach((stateVisit: StateVisit) => {
              if (stateVisit.selectedState == 'Maryland') {
                const monthsDiff = stateVisit.exit.diff(stateVisit.entry, 'months')
                for (let i = 0; i <= monthsDiff; i++) {
                  const currentMonth = stateVisit.entry.clone().add(i, 'months');
                  if (!this.monthList.includes(currentMonth.format('MMMM'))) {
                    this.monthList.push(currentMonth.format('MMMM'));
                  }
                }
              }
            })
            if (this.monthList.length > 6) {sessionStorage.stateResMD = "R"}
            else {sessionStorage.stateResMD = "NR"}
          }
        }

        this.appService.addStateAddnlQ(this.stateAddlQ)

      }

      sessionStorage.backPage = 'pg-nr06a'

      if (this.stateMNAbodeQ == true) {
        this.router.navigate(['../pg-nr06b'], {relativeTo: this.route});
      }

      else if (sessionStorage.MNAddlQ == "Yes") {
      this.router.navigate(['../pg-nr07'], {relativeTo: this.route});
      }
      else {
        this.router.navigate(['../pg-nr08'], {relativeTo: this.route});
      }
    }

    else {
      this.showStatus = false;
      this.showNewStateVisitHelpText = true;
    }
  }


  onSubmitBack() {
    this.router.navigate(['../pg-nr06'], {relativeTo: this.route});
  }
 
}
