<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR8843-complete.jpg" alt='Progress Bar pg-nr8843-complete'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide Questionnaire - Completed!</h3>
            <!--<p>Comment</p>-->
            <hr class="custom-line-silver">
            <!--<p>&nbsp;</p>-->
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>Thank you for completing this questionnaire!</p>
            <p>Your Form 8843 with filing instructions has been created and downloaded. Check your Downloads folder for a PDF file titled {{f8843Filename}}.</p>
            <p></p>
        </div>
    </div>

    <div *ngIf="showSpouseComment" class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>You indicated in the survey that you were married, and that your spouse is a nonresident alien on an F/J/M/Q visa. 
                Your spouse should create their own Personalized Guide to help them prepare their own tax documents.
            </p>

        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
        <div *ngIf="f8843Blank" class="col-12">
            <p>Click the link below to return to your member home page.</p>
        </div>
        <div *ngIf="f8843Filled" class="col-12">
            <p>Close your browser to delete your information, or click the link below to return to your member home page.</p>
        </div>

        <div class="col-12">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBackToNorth()">Return to Member Home</button>
        </div>
    </div>


</div>
