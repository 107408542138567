import { Component, OnInit } from '@angular/core';
import { selectableTaxYears} from '../aminra/aminra.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pg-nr05',
  templateUrl: './pg-nr05.component.html',
  styleUrls: ['./pg-nr05.component.scss']
})
export class PGnr05Component implements OnInit {
  taxYear: any;
  selectYesNo = ['Yes', 'No'];
  charityContribAnswer: string = null;
  showSLoan1098EQ: boolean = false
  sLoan1098EAnswer: string = null;
  showFilePrevTaxYearQ: boolean = null;
  filePrevTaxYearAnswer: string = null;
  showPayAddnlStateIncTaxQ: boolean = false;
  payAddnlStateIncTaxAnswer: string = null;
  showNextPageContinueButton: boolean = false;
  stateRefundAnswer: string = null;
  showStateRefundQ: boolean = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.showFilePrevTaxYearQ = false;
    this.showStateRefundQ = false;
    this.showPayAddnlStateIncTaxQ = false;
    this.showSLoan1098EQ = false;
    this.taxYear = selectableTaxYears[2];
    this.showNextPageContinueButton = false;
    sessionStorage.filePrevTaxYear = null;
    sessionStorage.stateRefund = null;
    sessionStorage.payAddnlStateIncTax = null;
    sessionStorage.charityContrib = null;
  }


  charityContribChange() {
    sessionStorage.charityContrib = this.charityContribAnswer;
    this.showSLoan1098EQ= true;
    this.showFilePrevTaxYearQ = false;
    this.showStateRefundQ = false;
    this.showPayAddnlStateIncTaxQ = false;
    this.showNextPageContinueButton = false;

  }

  sLoan1098EChange() {
    sessionStorage.sLoan1098E = this.sLoan1098EAnswer;
    this.showSLoan1098EQ= true;
    this.showFilePrevTaxYearQ = true;
    this.showStateRefundQ = false;
    this.showPayAddnlStateIncTaxQ = false;
    this.showNextPageContinueButton = false;
  }

  filePrevTaxYearChange() {
    sessionStorage.filePrevTaxYear = this.filePrevTaxYearAnswer;
    if (this.filePrevTaxYearAnswer == 'Yes') {
      this.showSLoan1098EQ= true;
      this.showFilePrevTaxYearQ = true;
      this.showStateRefundQ = true;
      this.showNextPageContinueButton = false;
    }
    else if (this.filePrevTaxYearAnswer == 'No') {
      this.showNextPageContinueButton = true;
      this.showStateRefundQ = false;
    }
    this.showPayAddnlStateIncTaxQ = false;
    this.stateRefundAnswer = null;
  }

  stateRefundChange() {
    sessionStorage.stateRefund = this.stateRefundAnswer;
    if (this.stateRefundAnswer == 'No') {
      this.showPayAddnlStateIncTaxQ = true;
      this.payAddnlStateIncTaxAnswer = null;
      this.showNextPageContinueButton = false;
    }
    else if (this.stateRefundAnswer == 'Yes') {
      this.showPayAddnlStateIncTaxQ = false;
      this.payAddnlStateIncTaxAnswer = null;
      this.showNextPageContinueButton = true;
    }

    else if (this.stateRefundAnswer == 'Not sure') {
      this.showPayAddnlStateIncTaxQ = false;
      this.payAddnlStateIncTaxAnswer = null;
      this.showNextPageContinueButton = true;
    }
  }

  payAddnlStateIncomeTaxChange() {
    sessionStorage.payAddnlStateIncTax = this.payAddnlStateIncTaxAnswer;
    this.showNextPageContinueButton = true;
  }


  onSubmitGoToNextPage() {
    sessionStorage.navDir = 'forward'
    this.router.navigate(['../pg-nr06'], {relativeTo: this.route});
  }

  onSubmitBack() {
    sessionStorage.navDir = 'backward'
    if (sessionStorage.navFromTB == "Yes") {
      this.router.navigate(['../treatybenefits'], {relativeTo: this.route});
    }
    else {
      sessionStorage.TB = 'No'
      sessionStorage.navFromTB = ''
      sessionStorage.navFromPGnr04 = ''
      sessionStorage.TB_MatchStatusWages = ''
      sessionStorage.TB_MatchStatusWagesSec1 = '';
      sessionStorage.TB_MatchStatusWagesSec2 = '';
      sessionStorage.TB_MatchStatusWagesSec3 = '';
      sessionStorage.TB_MatchStatusScholarship = ''
      sessionStorage.TB_MatchStatusScholarshipSec1 = '';
      sessionStorage.TB_MatchStatusScholarshipSec2 = '';
      sessionStorage.TB_MatchStatusScholarshipSec3 = '';
      sessionStorage.TB_Wages = ''
      sessionStorage.TB_Scholarship = ''
  
      this.router.navigate(['../pg-nr04'], {relativeTo: this.route});
    }
  }
}
