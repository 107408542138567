import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pg-nr07',
  templateUrl: './pg-nr07.component.html',
  styleUrls: ['./pg-nr07.component.scss']
})
export class PGnr07Component implements OnInit {

  certificateMNAnswer: string
  showOnetimePaymentMNQ: boolean
  onetimePaymentMNAnswer: string
  showPayPropTaxMNQ: boolean
  payPropTaxMNAnswer: string
  showFirstFilingMNQ: boolean
  firstFilingMNAnswer: string
  showNewAddressMNQ: boolean
  newAddressMNAnswer: string
  showContinueButton: boolean
  taxYear: any
  selectYesNo=['Yes','No']

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.certificateMNAnswer = null;
    this.onetimePaymentMNAnswer = null;
    this.newAddressMNAnswer = null;
    this.showPayPropTaxMNQ = false;
    this.showOnetimePaymentMNQ = false;
    this.showFirstFilingMNQ = false
    this.showNewAddressMNQ = false
    if (sessionStorage.filePrevTaxYear == 'No') {
      sessionStorage.newAddressMN = "Yes"
    }
    this.showContinueButton = false;
    this.taxYear = Number(sessionStorage.taxYear);
  }

  certificateMNChange() {
    sessionStorage.certificateRentPaidMN = this.certificateMNAnswer
    this.showPayPropTaxMNQ = true;
    this.showOnetimePaymentMNQ = false;
    this.showContinueButton = false;
  }

  payPropTaxMNChange() {
    sessionStorage.payPropTaxMN = this.payPropTaxMNAnswer;
    this.showPayPropTaxMNQ = true;
    this.showOnetimePaymentMNQ = true;
    this.showContinueButton = false;   
  }

  onetimePaymentMNChange() {
    sessionStorage.onetimePaymentMN = this.onetimePaymentMNAnswer;
    this.showPayPropTaxMNQ = true
    if (sessionStorage.filePrevTaxYear == "No") {
      this.showFirstFilingMNQ = false
      this.showNewAddressMNQ = false;
      this.showContinueButton = true;  
    }
    else {
      this.showFirstFilingMNQ = true
      this.showContinueButton = false
    }
    this.showNewAddressMNQ = false  
  }

  firstFileMNChange() {
    sessionStorage.firstFilingMN = this.firstFilingMNAnswer
    this.showPayPropTaxMNQ = true;
    this.showOnetimePaymentMNQ = true;
    this.showFirstFilingMNQ = true;
    if (this.firstFilingMNAnswer == 'No') {  
      sessionStorage.newAddressMN = "Yes"
      this.showNewAddressMNQ = false;
      this.showContinueButton = true;
    }
    else {
      this.showNewAddressMNQ = true;
      this.showContinueButton = false;  
    }
  }

  newAddressMNChange() {
    sessionStorage.newAddressMN = this.newAddressMNAnswer
    this.showPayPropTaxMNQ = true;
    this.showOnetimePaymentMNQ = true;
    this.showFirstFilingMNQ = true;
    this.showNewAddressMNQ = true;
    this.showContinueButton = true;   
  }

  onSubmitGoToNextPage() {
    sessionStorage.navDir = 'forward'
    sessionStorage.prevPage = 'pg-nr07'
    this.router.navigate(['../pg-nr08'], {relativeTo: this.route});
  }

  onSubmitBack() {
    sessionStorage.navDir = 'backward'
    //sessionStorage.prevPage = 'pg-nr07'
    if (sessionStorage.stateVisitEntries == 'Yes') {
      this.router.navigate(['../pg-nr06b'], {relativeTo: this.route});}
    else {
      this.router.navigate(['../pg-nr06'], {relativeTo: this.route});}
  
    //this.router.navigate(['../' + sessionStorage.backPageNow], {relativeTo: this.route});
  }

}
