<div class="container page-with-footer" id="print-section">
    <div *ngIf="navFrompg" class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR01aAMINRA.jpg" alt='Progress Bar 2 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>
    <!--<div class="row">
        <div class="col-12">
            <hr class="custom-line-silver">
        </div>
    </div>-->

    <div class="row">
        <div class="col-12">
            <h3 *ngIf="!navFrompg">Am I a Nonresident Alien?</h3>
            <h3 *ngIf="navFrompg">Personalized Guide - Tax Residency</h3>
            <p *ngIf="navFrompg">The next step is to determine your U.S. tax residency.  Please see IRS <a href="https://www.irs.gov/pub/irs-pdf/p519.pdf" target="_blank">Publication 519</a> for definitive guidance 
                regarding determining your federal tax residency.</p>
            <!--<p *ngIf="navFrompg">This tool is intended to assist you in determining your federal tax residency status.</p>-->
            <div *ngIf="!navFrompg" class="alert alert-custom">
                <p class="mb-0"><b>It is your individual responsibility to determine your federal tax residency. This tool can describe most common situations, 
                    but it does not cover every possible scenario. Please see IRS <a href="https://www.irs.gov/pub/irs-pdf/p519.pdf" target="_blank">Publication 519</a> for definitive guidance 
                    regarding determining your federal tax residency.</b></p>
            </div>
            <hr class="custom-line-silver">
        </div>
    </div>

    <div>  
        <div *ngIf="navFrompg" class="row mb-2">
            <div class="col-sm-3 col-lg-2">
                <label for="selectedTaxYear" class="h5">Tax Year: {{taxYear}}</label>
            </div>
        </div>

        <div *ngIf="!navFrompg" class="row mb-2">
            <div class="col-sm-3 col-lg-2">
                <label for="selectedTaxYear" class="h5">Select Tax Year</label>
                <ng-select id="selectedTaxYear"
                    [items]="selectableYears"
                    [(ngModel)]="selectedYear"
                    placeholder="Select tax year"
                    (ngModelChange)="selectedYearChange()"
                    [clearable]="false">    
                </ng-select>
            </div>
            <div *ngIf="selectedYear > selectableYears[2]" class="col-12">
                <div class="invalid-feedback d-block"><b>Warning: For Projection Purposes Only!</b>   Taxes are based on a calendar year, and you cannot 
                    prepare your {{ selectedYear }} taxes until after that year is completed.</div>
            </div>
            <div class="col-12">
                <hr class="custom-line-silver">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h5>Enter Your Visits</h5>
            <p>Please enter the entry and exit dates, along with the visa you held for that visit, for <b>all</b> of your visits to the United States. If you do 
                not enter all of your lifetime visits and your correct visa information, then the results provided in this tool may not be accurate.  
                You can view your travel history at <a href="https://i94.cbp.dhs.gov/I94/#/home" target="_blank">https://i94.cbp.dhs.gov/I94/#/home</a>.</p>
            <p>Your most recent visit can have no exit date, if you are still present in the U.S. on that same visit.  The calculation will use the end of the year 
                <fa-icon class="clickable" [icon]="['fa', 'info-circle']" 
                title = "The year will be the tax year selected if the entry is in the tax year or earlier.&#013;If your last entry is in a future tax year the exit will be the end of that year.">
                </fa-icon>
                as the exit date. To project your status in the future, you can enter the future date you expect to depart.</p>
            <p>If your visa changed without you leaving the U.S. enter two visits: one with an exit date the same as the end date of the first visa, and the 
                second with an entry date the same as the first date of the second visa.</p>
 
        </div>
        <div *ngFor="let visit of visits; index as i; trackBy:trackIndex" class="col-12 mb-2 mt-1">
            <app-visit [visitId]="visit.id" [showLabels]="i===0" (visitRemoved)="onVisitRemoved($event, i)" (visitChanged)="onVisitChanged($event, i)"></app-visit>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <a class="no-href clickable" (click)="addNewVisit()">Add another visit</a>
        </div>
        <div *ngIf="showNewVisitHelpText" class="col-12">
            <div class="invalid-feedback d-block">You have incomplete visits! Finish adding information before adding another or submitting.</div>
        </div>
        <div *ngIf="visitsHaveErrors" class="col-12">
            <div class="invalid-feedback d-block">Some of your visits have errors. Please fix them before submitting.</div>
        </div>
        <div *ngIf="showOverlapError" class="col-12">
            <div class="invalid-feedback d-block">You have entered overlapping visits. Please review and fix your entries before submitting.</div>
        </div>
        <div *ngIf="showDualStatusResidentText" class="col-12">
            <div class="invalid-feedback d-block">You may be a resident for {{selectedYear}} depending on your visits for {{selectedYear+1}}.  
                Please enter your actual or expected visits for next year and click Check Status again.</div>
        </div>
    </div>

    <div *ngIf="showIncomeQuestions" class="row">
        <p>&nbsp;</p>
    </div>
    <div *ngIf="showIncomeQuestions" class="alert-moreinfo">
        <p>Based on your visit information above, you need to answer these questions about Foreign Income...</p>    
    </div>
    <div *ngIf="showIncomeQuestions" class="row">
        <div class="col-8">
            <label for="foreignIncomeInTaxYear">Did a foreign employer pay all of your compensation during {{selectedYear}}?</label>
        </div>
        <div class="col-2">
            <ng-select id="foreignIncomeInTaxYear" [items]="selectYesNo" [(ngModel)]="foreignIncomeInTaxYear" (ngModelChange)="foreignIncomeInTaxYearChange()" placeholder="Select tax year" [clearable]="false"></ng-select>
        </div>        
        <div class="col-2">
        </div>        
    </div>
    <div *ngIf="showIncomeQuestions" class="row">
        <div class="col-8">
            <label for="foreignIncomeInPresentYears">Did a foreign employer pay all of your compensation during the year(s) {{foreignPayYears}}?</label>
        </div>
        <div class="col-2">
            <ng-select id="foreignIncomeInPresentYears" [items]="selectYesNo" [(ngModel)]="foreignIncomeInPresentYears" (ngModelChange)="foreignIncomeInPresentYearsChange()" placeholder="Select tax year" [clearable]="false"></ng-select>
        </div>        
        <div class="col-2">
            <p>&nbsp;</p>
        </div>        
    </div>
    <div *ngIf="showIncomeQuestionsError" class="row" class="alert-moreinfo">
        <p>&nbsp;</p>
        <p>You must answer "Yes" or "No" to the above two questions to determine if you are a Nonresident alien.</p>
    </div>

    <div class="row my-2">
        <div class="col-4">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitCheckStatus()" [disabled]="visitsHaveErrors || showOverlapError">Check Status</button>  
        </div>
        <div class="col-8">
            <button class="btn btn-danger float-right" (click)="clearVisits()">Clear Visits</button>
        </div>
    </div>

    <div class="row">
        <div *ngIf="showStatus && !navFrompg" class="col-12 my-2 alert alert-custom">

            <h5>You are a {{ nonResidentStatus.status }} for Tax Year {{ selectedYear }}.</h5>
            <hr class="custom-line-blue">
            <!--<hr class="custom-line-silver">-->

                <div *ngIf="nonResidentStatus.status === 'Dual-Status Alien' || nonResidentStatus.status === 'Nonresident Alien'">
                    <p>Number of days <b>Present in {{selectedYear}}</b>: {{nonResidentStatus.daysPresentInTaxYear}}</p>
                    <p>Number of days <b>Present in {{selectedYear - 1}}</b>: {{nonResidentStatus.daysPresentYearPriorToTaxYear}}</p>
                    <p>Number of days <b>Present in {{selectedYear - 2}}</b>: {{nonResidentStatus.daysPresent2YearsPriorToTaxYear}}</p>
                    <p>Number of days <b>Exempt in {{selectedYear}}</b>: {{nonResidentStatus.daysExemptInTaxYear}}</p>
                    <p *ngIf="showForm8843Caveat"><b>Note: You are required to file Form 8843 to report your Exempt days for {{selectedYear}}.</b></p>
                    <p></p>
                </div>

                <div *ngIf="nonResidentStatus.status === 'Dual-Status Alien'">
                    <p *ngIf="nonResidentStatus.residencyStartDate">Your residency start date is: {{ nonResidentStatus.residencyStartDate }}</p>
                    <p *ngIf="nonResidentStatus.residencyEndDate">Your residency end date is: {{ nonResidentStatus.residencyEndDate }}</p>
                    <p><b>Based on the information you have provided, you are a Dual-Status Alien.</b></p> 
                    <p *ngIf="!nonResidentStatus.residencyEndDate.includes('12/31')"><b>Your residency status may be affected if you are present in the U.S. 
                        during the following year ({{selectedYear+1}}). If you are/will be present in the U.S. during {{selectedYear+1}} enter that visit 
                        information above and re-check your status. If you are in the U.S. during {{selectedYear+1}} and have not left, enter your best estimate 
                        for the date you will depart. If you become a resident during {{selectedYear+1}} you are considered to have maintained your residency 
                        through the end of {{selectedYear}}.</b></p>
                    <p>You may be able to make an election (choice) that will change your status. You should confirm your status and election options by reviewing 
                        the residency requirements in IRS Publication 519 (Aliens).</p>
                    <p>Dual-Status Aliens and residency elections are out of scope for all IRS VITA clinics.  We are unable to answer any questions or provide 
                        any guidance about how a dual-status return should be prepared, and the TaxSlayer software is not configured to prepare this type of return.  
                        Dual status returns are complicated, and we would encourage you to either consult with a knowledgeable professional, or thoroughly 
                        research your situation before proceeding.  You can find free publications, forms, and instructions on the IRS website 
                        at <a href="https://www.irs.gov/" target="_blank">www.irs.gov</a>.</p>
                 </div>
        </div>
    </div>

    <div class="row">
        <div *ngIf="showStatus && navFrompg && nonResidentStatus.status == 'Resident Alien'" class="col-12 my-2">
            <div class="row">
                <div class="col-12">
                    <p>&nbsp;</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h5>Your federal tax residency is: {{ nonResidentStatus.status }}.</h5>
                    <p>If the result is not what you expected please double-check your data entries, then contact us at 
                        <b><a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a></b> if it is still not what you expect.
                    </p>
                    <p>&nbsp;</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-custom mb-sm-0 mb-2" onclick="window.location.href='https://www.nrtaxhelp.org/free-tax-assistance';">Back to NRTaxHelp site</button>
                </div>  
            </div>
        </div>
    </div>

    <div class="row">
        <div *ngIf="showStatus && navFrompg && nonResidentStatus.status == 'Dual-Status Alien'" class="col-12 my-2">
            <div class="row">
                <div class="col-12">
                    <p>&nbsp;</p>
                </div>
            </div>
            <h5>Your federal tax residency is: {{ nonResidentStatus.status }}.</h5>
            <div class="row">
                <div class="col-12">
                    <p>If the result is not what you expected please double-check your data entries, then contact us at 
                        <b><a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a></b> if it is still not what you expect.</p>
                    <p *ngIf="!nonResidentStatus.residencyEndDate.includes('12/31')"><b>Note: Your residency status may be affected if you are present in the U.S. during the following year ({{nextYear}}). If you are/will be 
                        present in the U.S. during {{nextYear}} enter that visit information above and re-check your status. If you are in the U.S. 
                        during {{nextYear}} and have not left, enter your best estimate for the date you will depart. If you become a resident 
                        during {{nextYear}} you are considered to have maintained your residency through the end of {{taxYear}}.</b></p>
                    <p>You may be able to make an election (choice) that will change your status. You should confirm your status and election options by reviewing 
                        the residency requirements in IRS Publication 519 (Aliens).</p>
                    <p>Dual-Status Aliens and residency elections are out of scope for all IRS VITA clinics.  We are unable to answer any questions or provide any 
                        guidance about how a dual-status return should be prepared, and the TaxSlayer software is not configured to prepare this type of return.  
                        Dual status returns are complicated, and we would encourage you to either consult with a knowledgeable professional, or thoroughly research 
                        your situation before proceeding.  You can find free publications, forms, and instructions on the IRS website 
                        at <a href="https://www.irs.gov/" target="_blank">www.irs.gov</a>.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-custom mb-sm-0 mb-2" onclick="window.location.href='https://www.nrtaxhelp.org/free-tax-assistance';">Back to NRTaxHelp site</button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div *ngIf="showStatus && navFrompg && nonResidentStatus.status == 'Nonresident Alien'" class="col-12 my-2">
            <div class="row">
                <div class="col-12">
                    <p>&nbsp;</p>
                </div>
            </div>
            <h5>Your federal tax residency is: {{ nonResidentStatus.status }}.</h5>
            <div class="row">
                <div class="col-12">
                    <p>If the result is not what you expected please double-check your data entries, then contact us at 
                    <b><a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a></b> if it is still not what you expect.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>&nbsp;</p>
                </div>
            </div>
            
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoTonr02()" [disabled]="Error">Continue</button>
                </div>
            </div>  
        </div>
    </div>




    <div *ngIf="navFrompg" class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
        <div class="row">
            <div class="col-12">
                <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a>.</small></p>
            </div>
        </div>
    </div>



    <div *ngIf="showStatus && !navFrompg" class="col-12 my-2">
        <p>We strongly recommend that you print your results or take screenshots for reference.</p>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom-blue"
                    ngxPrint
                    printSectionId="print-section"
                    [useExistingCss]="true">
                    Print Results
                </button>
            </div>
        </div>
    </div>
    
    <div *ngIf="showUnexpectedErrorMessage" class="col-12 my-2 alert alert-danger">
        <div>
            <p>An unexpected error has occurred while determining your residency status. We apologize for any inconvenience. If you would still like to 
            determine your residency status, please send us screenshots of your input to <b><a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a></b>
            and we will assist in any way we can.</p>
        </div>
    </div>
 

</div>


