import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-pg-nr8843-complete',
  templateUrl: './pg-nr8843-complete.component.html',
  styleUrls: ['./pg-nr8843-complete.component.scss']
})

export class PGnr8843CompleteComponent implements OnInit {

  backToNorthURL: string = ''
  f8843Filename: string = ''
  f8843Blank: boolean = false
  f8843Filled: boolean = false
  showSpouseComment: boolean = false
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    if (sessionStorage.f8843Type == 'blank') {
      this.f8843Blank = true
      this.f8843Filename = 'Form8843_NonFilled *date* *time*.pdf'
    }
    else if (sessionStorage.f8843Type == 'filled') {
      this.f8843Filled = true
      this.f8843Filename = 'Form8843_Prefilled *date* *time*.pdf'
    }

    if (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No' && sessionStorage.spouseTaxResidency == 'Nonresident' && sessionStorage.spousePresentFJMQVisa == 'Yes') {
      this.showSpouseComment = true
    }
    else {this.showSpouseComment = false}

  }

  onSubmitBackToNorth() {
    var prevPage = sessionStorage.prevPage
    sessionStorage.navDir = 'backward'
    sessionStorage.prevPage = 'pg-nr8843'
    if (sessionStorage.urlRefLoc != "") {
    window.location.href='https://www.nrtaxhelp.org/' + sessionStorage.urlRefLoc
    }
    else { window.location.href='https://www.nrtaxhelp.org/free-tax-assistance' }
  }

  onSubmitBack() {
    var prevPage = sessionStorage.prevPage
    sessionStorage.navDir = 'backward'
    sessionStorage.prevPage = 'pg-nr8843'
    this.router.navigate(['../pg-nr8843'], {relativeTo: this.route});
  }

}
