export var schoolData = [
    //
    // School name                                      Contact Person          Address                                                                                     Phone number      
    [ "Select..."                                       , ""                    , ""                                                                                        , ""                    ],
    [ "Enter My Own Information"                        , "Enter Name"          , "Enter Address"                                                                           , "Enter Phone Number"  ],
    [ "Appalachian State University"                    , "Karen Marshall"      , "263 Locust Street, Plemmons Student Union Suite 321, Boone, NC 28608-2083"               , "828-262-2046"        ],
    [ "Lehigh University"                               , "Olga Scarpero"       , "Coxe Hall, Suite 104, 32 Sayre Drive, Bethlehem, PA 18018"                               , "610-758-2981"        ],
    [ "University of Maryland, Baltimore County"        , "David Anguish"       , "1000 Hilltop Circle, Baltimore, MD 21250"                                                , "410-455-1000"        ],
    [ "University of Minnesota - Twin Cities"           , "Megan Carmes"        , "190 Hubert H. Humphrey School, 301 19th Avenue South, Minneapolis, MN 55455"             , "612-626-7100"        ],
    [ "University of Minnesota - Duluth"                , "Anna Gilmore"        , "Kirby Student Center 237, 247 and 249, 1120 Kirby Drive, Duluth, MN 55812"               , "218-726-7305"        ],
]

export var schoolDataTDB = [
    //
    // School name                                      Contact Person          Address                                                                                     Phone number      
    [ "Minnesota State University, Mankato"             , "Paulina Camacho"     , "250 Centennial Student Union, Mankato, MN 56001"                                         , "507-389-1281"        ],
    [ "University of Minnesota - Crookston"             , "Sok Leng Tan"        , "170 Owen Hall, 2900 University Ave., Crookston, MN 56716"                                , "218-281-8442"        ],
    [ "University of Wisconsin - Green Bay"             , ""                    , ""                                                                                        , ""  ],
    [ "Bemidji State University"                        , "Rosy Hjermstad"      , "1500 Birchmont Drive NE #13, Bemidji, MN 56601-2699"                                     , "218-755-4096"        ],
    [ "Northwestern Health Sciences University"         , "Lori Hanegraaf"      , "2501 West 84th Street, Bloomington, MN 55431"                                            , "952-887-1388"        ],
]


export var schoolNameOnlyData = [
    "Select...",
    "Enter My Own Information",
    "Appalachian State University",
    "Lehigh University",
    "University of Maryland, Baltimore County",
    "University of Minnesota - Twin Cities",
    "University of Minnesota - Duluth",
]




