<div class="form-row">

    <div class="col-sm-3 mb-sm-0" [ngClass]="{'mb-2': stateShowLabels, 'mb-4': !stateShowLabels}">
        <label *ngIf="stateShowLabels" for="{{ 'stateEntryDateInput_' + stateVisitId }}">Start Date</label>
        <div class="input-group">
            <input id="{{ 'stateEntryDateInput_' + stateVisitId }}" 
            class="form-control" 
            placeholder="MM/DD/YYYY" 
            name="entry" 
            ngbDatepicker 
            #entryToggle="ngbDatepicker" 
            [(ngModel)]="stateEntryDate" 
            (blur)="stateEntryDateChange()"  
            attr.aria-label="Entry Date {{stateVisitId}}" 
            (dateSelect)="stateEntryDateChange()">

            <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="entryToggle.toggle()">
                    <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="col-sm-3 mb-sm-0" [ngClass]="{'mb-2': stateShowLabels, 'mb-4': !stateShowLabels }">
        <label *ngIf="stateShowLabels" for="{{ 'stateExitDateInput_' + stateVisitId }}">End Date</label>
        <div class="input-group">
            <input id="{{ 'stateExitDateInput_' + stateVisitId }}" 
            class="form-control" 
            placeholder="MM/DD/YYYY" 
            name="exit"
            ngbDatepicker 
            #exitToggle="ngbDatepicker" 
            [(ngModel)]="stateExitDate" 
            (blur)="stateExitDateChange()" 
            attr.aria-label="Exit Date {{stateVisitId}}" 
            (dateSelect)="stateExitDateChange()">

            <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="exitToggle.toggle()">
                    <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="col-sm-2 mb-sm-0" [ngClass]="{'mb-2': stateShowLabels, 'mb-4': !stateShowLabels }">
        <label *ngIf="stateShowLabels" for="{{ 'stateInput_' + stateVisitId }}">OUS/State</label>
        <ng-select id="{{ 'stateInput_' + stateVisitId }}"
            [items]="stateList"
            [(ngModel)]="stateVisit.selectedState"
            (ngModelChange)="stateEntryChange()"
            placeholder="Select state"
            aria-label="State"
            [clearable]="false">
        </ng-select>
    </div>

    <div class="col-sm-2">
        <label *ngIf="stateShowLabels"></label>
        <div class="form-control-static">
            <button class="btn btn-danger float-sm-right" [ngClass]="{ 'mt-sm-2': stateShowLabels }" (click)="removeStateVisit()" id="remove">Remove Visit</button>
        </div>
    </div>

</div>

<div *ngIf="stateVisit.error && stateVisit.error.length > 0" class="row">
    <div *ngFor="let error of stateVisit.error" class="col-12">
        <div class="invalid-feedback d-block">{{error}}</div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <!--<hr>-->
    </div>
</div>

