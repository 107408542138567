import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(public router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-JZNSSS61B5', { 'page_path': event.urlAfterRedirects });
      }      
    })
  }

  
  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    if (String(urlParams.get("loc")) != 'null') {
      sessionStorage.urlRefLoc = String(urlParams.get("loc"))
    }
    else {
      sessionStorage.urlRefLoc = ''
    }
  }

}

//@Component({
//  selector: 'app-root',
//  templateUrl: './app.component.html',
//  styleUrls: ['./app.component.scss']
//})
//export class AppComponent {
//  title = 'NoRTH';
//}