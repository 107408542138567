/**
 * This file contains all the meta data needed for Visas.
 */

 /**
  * All Visas that we currently support
  */
export enum Visa {
    NonExemptVisa = 'Non-exempt visa (B1/B2, TN, H1-b, etc.)',
    F1 = 'F-1 Student',
    J1Student = 'J-1 Student',
    J1Professor = 'J-1 Professor',
    J1ResearchScholar = 'J-1 Research Scholar',
    J1ShortTermScholar = 'J-1 Short-term Scholar',
    J1Teacher = 'J-1 Teacher',
    J1Trainee = 'J-1 Trainee',
    J1Intern = 'J-1 Intern',
    J1Physician = 'J-1 Physician',
    J1Specialist = 'J-1 Specialist',
    J1SummerWorkTravel = 'J-1 Summer Work Travel',
    J1AuPairEduCare = 'J-1 Au Pair and EduCare',
    J1CampCounselor = 'J-1 Camp Counselor',
    J1GovVisitor = 'J-1 Government Visitor',
    J1IntVisitor = 'J-1 International Visitor (Dept. of State use)',
    F2 = 'F-2 Student',
    J2Student = 'J-2 Student',
    J2NonStudent = 'J-2 Non-Student (all other J-2 types)',
    M1 = 'M-1 Student',
    M2 = 'M-2 Student',
    Q1Student = 'Q-1 Student',
    Q1NonStudent = 'Q-1 Non-Student',
    Q2Student = 'Q-2 Student',
    Q2NonStudent = 'Q-2 Non-Student',
    OtherExemptVisa = 'Other Exempt visa (A, G, NATO, etc.)',
    GC = 'Green Card (Permanent Resident)'
}

/**
 * Different Categories of Visas used by the NRA Tool
 */
export enum VisaType {
    Student,
    NonStudent,
    NonExempt,
    Exempt
}

/**
 * Visas that are considered to be Student visas
 */
export const studentVisas: Visa[] = [
    Visa.F1,
    Visa.F2,
    Visa.J1Student,
    Visa.J2Student,
    Visa.M1,
    Visa.M2,
    Visa.Q1Student,
    Visa.Q2Student
];

/**
 * Visas that are considered to be Teacher/Trainee/Student visas
 */
/*
export const teacherTraineeStudentVisas: Visa[] = [
    Visa.F1,
    Visa.F2,
    Visa.J1Student,
    Visa.J2Student,
    Visa.J2NonStudent,
    Visa.M1,
    Visa.M2,
    Visa.Q1Student,
    Visa.Q1NonStudent,
    Visa.Q2Student,
    Visa.Q2NonStudent
];
*/

/**
 * Visas that are considered to be Teacher/Trainee visas
 */
/*
 export const teacherTraineeVisas: Visa[] = [  //SHOULD BE ALL FMJQ BUT CHECK FOR CONNFLICTS
    Visa.J1Professor,
    Visa.J1ResearchScholar,
    Visa.J1ShortTermScholar,
    Visa.J1Teacher,
    Visa.J1Trainee,
    Visa.J1Intern,
    Visa.J1Physician,
    Visa.J1Specialist,
    Visa.J1SummerWorkTravel,
    Visa.J1AuPairEduCare,
    Visa.J1CampCounselor,
    Visa.J1GovVisitor,
    Visa.J1IntVisitor,
    Visa.J2NonStudent,
    Visa.Q1NonStudent,
    Visa.Q2NonStudent
];
*/

// 02/15/2024 - modified this list to be correct F/J/M/Q list
export const teacherTraineeVisas: Visa[] = [
    Visa.F1,
    Visa.F2,
    Visa.J1Student,
    Visa.J1Professor,
    Visa.J1ResearchScholar,
    Visa.J1ShortTermScholar,
    Visa.J1Teacher,
    Visa.J1Trainee,
    Visa.J1Intern,
    Visa.J1Physician,
    Visa.J1Specialist,
    Visa.J1SummerWorkTravel,
    Visa.J1AuPairEduCare,
    Visa.J1CampCounselor,
    Visa.J1GovVisitor,
    Visa.J1IntVisitor,
    Visa.J2Student,
    Visa.J2NonStudent,
    Visa.M1,
    Visa.M2,
    Visa.Q1Student,
    Visa.Q1NonStudent,
    Visa.Q2Student,
    Visa.Q2NonStudent,
]



/**
 * Visas that are F or J visas (for form 8843 check)
 */
 export const ForJVisas: Visa[] = [
    Visa.F1,
    Visa.F2,
    Visa.J1Student,
    Visa.J1Professor,
    Visa.J1ResearchScholar,
    Visa.J1ShortTermScholar,
    Visa.J1Teacher,
    Visa.J1Trainee,
    Visa.J1Intern,
    Visa.J1Physician,
    Visa.J1Specialist,
    Visa.J1SummerWorkTravel,
    Visa.J1AuPairEduCare,
    Visa.J1CampCounselor,
    Visa.J1GovVisitor,
    Visa.J1IntVisitor,
    Visa.J2Student,
    Visa.J2NonStudent,
];