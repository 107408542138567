import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { schoolData, schoolNameOnlyData } from './pg-nr8843Data';
import { J1NonTeacherNonStudent, FJMQStudent } from './pg-nr8843.service';
import { FormBuilder } from '@angular/forms';
import { PDFDocument, StandardFonts, rgb} from 'pdf-lib';

@Component({
  selector: 'app-pg-nr8843',
  templateUrl: './pg-nr8843.component.html',
  styleUrls: ['./pg-nr8843.component.scss']
})
export class PGnr8843Component implements OnInit {

  //Variables
  taxYear: any;
  selectYesNo = ['Select...', 'Yes', 'No'];
  
  //Previous data
  countryOfRes: string = ""

  showIntro: boolean = true
  showNameFormEntry: boolean = false
  showAddressFormEntry: boolean = false
  showVisaChangeLeaveUSFormEntry: boolean = false
  showCitizenFormEntry: boolean = false
  showGCAppFormEntry: boolean = false
  showGCAppExplain: boolean = false
  showSchoolFormEntry: boolean = false
  showContinueToSchoolFormEntry = false
  showCreate8843: boolean = false
  showContinueButton: boolean;
  showAutoFill: boolean = false
  showSelfFill: boolean = false
  showVisaChangeLeaveUS: boolean = false
  showSchoolOther: boolean= false
  showSchoolExisting: boolean = false
  showContactOther: boolean = false
  showContactExisting: boolean = false
  schoolList: string[] = schoolNameOnlyData
  schoolIndex: number = null
  schoolShortName: string = ''
  schoolName: string = ''
  schoolAddress: string = ''
  schoolPhone: string = ''
  schoolContact: string = ''
  schoolNameExisting: string = ''
  schoolAddressExisting: string = ''
  schoolPhoneExisting: string = ''
  schoolContactExisting: string = ''
  schoolNameOther: string = ''
  schoolAddressOther: string = ''
  schoolPhoneOther: string = ''
  schoolContactOther: string = ''
  J1NonTeacherNonStudentList:string[] = J1NonTeacherNonStudent
  FJMQStudentList: string[] = FJMQStudent
  visaJ1Teacher: boolean = false
  visaJ1NonTeacherNonStudent: boolean = false
  visaFJMQStudent: boolean = false
  visaChangeLeaveUS: boolean = false
  visaChangeWithoutLeavingExitInfo: string = ''
  visaChangeWithoutLeavingEntryInfo: string = ''
  visaChangeWithoutLeavingEntryVisa: string = ''
  visaChangeLeaveUSShowContinue = false
  countryCitizen: string = ''
  countryPassport: string = ''
  goToTBPage: boolean = false
  f8843EmptyPDFDoc: any;
  f8843PDFDoc: any;
  newPage: any;
  f8843Entries = {};

  f8843Form = this.formBuilder.group({
    firstName: sessionStorage.f8843_firstName,
    initial: sessionStorage.f8843_initial,
    lastName: sessionStorage.f8843_lastName,
    addressRes: sessionStorage.f8843_addressRes,
    addressUS: sessionStorage.f8843_addressUS,
    visaChangeLeaveUS: sessionStorage.f8843_visaChangeLeaveUS,
    countryCitizen: '', //sessionStorage.f8843_countryCitizen, //'', //sessionStorage.citizenCountry1  + '   ' + sessionStorage.citizenCountry2 + '   ' + sessionStorage.citizenCountry3,
    countryPassport: '', //sessionStorage.f8843_countryPassport, //'', //sessionStorage.citizenCountry1 + '   ' + sessionStorage.citizenCountry2 + '   ' + sessionStorage.citizenCountry3,
    passport: sessionStorage.f8843_passport,
    gcApp: sessionStorage.f8843_gcApp,
    gcAppExplain1: sessionStorage.f8843_gcAppExplain1,
    gcAppExplain2: sessionStorage.f8843_gcAppExplain2,
    schoolDropdownName: sessionStorage.f8843_schoolDropdownName,
    schoolContactExisting: sessionStorage.f8843_schoolContactExisting,
    schoolNameExisting: sessionStorage.f8843_schoolNameExisting,
    schoolAddressExisting: sessionStorage.f8843_schoolAddressExisting,
    schoolPhoneExisting: sessionStorage.f8843_schoolPhoneExisting,
    schoolContactOther: sessionStorage.f8843_schoolContactOther,
    schoolNameOther: sessionStorage.f8843_schoolNameOther,
    schoolAddressOther: sessionStorage.f8843_schoolAddressOther,
    schoolPhoneOther: sessionStorage.f8843_schoolPhoneOther
  })


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.showIntro = true
    this.showAutoFill = false
    this.showSelfFill = false
    this.countryOfRes = sessionStorage.taxResidentCountry
    sessionStorage.f8843_countryCitizen = sessionStorage.citizenCountry1  + '   ' + sessionStorage.citizenCountry2 + '   ' + sessionStorage.citizenCountry3
    sessionStorage.f8843_countryPassport = sessionStorage.citizenCountry1  + '   ' + sessionStorage.citizenCountry2 + '   ' + sessionStorage.citizenCountry3
    if (sessionStorage.visaType == 'J-1 Teacher') {this.visaJ1Teacher = true}
    if (this.J1NonTeacherNonStudentList.includes(sessionStorage.visaType)) {this.visaJ1NonTeacherNonStudent = true}
    if (this.FJMQStudentList.includes(sessionStorage.visaType)) {this.visaFJMQStudent = true}
    if (sessionStorage.visaChangeWithoutLeaving == "Yes") {this.visaChangeLeaveUS = true}
    //if (this.f8843Form.get('firstName').value != '') {}
    this.f8843Form.get('visaChangeLeaveUS').valueChanges.subscribe(x => this.onChangeVisaChangeLeaveUS(x) )
    //this.f8843Form.patchValue({'visaChangeLeaveUS': 'Select...'})
    this.f8843Form.get('gcApp').valueChanges.subscribe(x => this.onChangeGCApp(x) )
    //this.f8843Form.patchValue({'gcApp': 'Select...'})
    this.f8843Form.get('schoolDropdownName').valueChanges.subscribe(x => this.onChangeSchoolDropdownName(x))
    //this.f8843Form.patchValue({'schoolDropdownName': 'Select...'})
    this.countryCitizen = sessionStorage.citizenCountry1  + '   ' + sessionStorage.citizenCountry2 + '   ' + sessionStorage.citizenCountry3
    this.countryPassport = sessionStorage.citizenCountry1 + '   ' + sessionStorage.citizenCountry2 + '   ' + sessionStorage.citizenCountry3
    this.visaChangeWithoutLeavingExitInfo = sessionStorage.visaChangeWithoutLeavingExitInfo
    this.visaChangeWithoutLeavingEntryInfo = sessionStorage.visaChangeWithoutLeavingEntryInfo
    this.visaChangeWithoutLeavingEntryVisa = sessionStorage.visaChangeWithoutLeavingEntryVisa
  }

  onSubmitGoToNameEntry() {
    this.showIntro = false
    this.showAutoFill = true
    this.showNameFormEntry = true
    this.showAddressFormEntry = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }

  onSubmitGoToAddressEntry() {
    sessionStorage.f8843_firstName = this.f8843Form.get('firstName').value
    sessionStorage.f8843_initial = this.f8843Form.get('initial').value
    sessionStorage.f8843_lastName = this.f8843Form.get('lastName').value
    this.showIntro = false
    this.showNameFormEntry = false
    this.showAddressFormEntry = true
    this.showVisaChangeLeaveUSFormEntry = false
    this.visaChangeLeaveUSShowContinue = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }

  onSubmitGoToVisaChangeLeaveUS() {
    sessionStorage.f8843_addressRes = this.f8843Form.get('addressRes').value
    sessionStorage.f8843_addressUS = this.f8843Form.get('addressUS').value
    this.f8843Form.patchValue({'visaChangeLeaveUS': 'Select...'})
      if (this.f8843Form.get('visaChangeLeaveUS').value != "Select...") {
        this.visaChangeLeaveUSShowContinue = true
      }
      else {
        this.visaChangeLeaveUSShowContinue = false
      }
      this.showIntro = false
      this.showNameFormEntry = false
      this.showAddressFormEntry = false
      this.showVisaChangeLeaveUSFormEntry = true
      this.showCitizenFormEntry = false
      this.showGCAppFormEntry = false
      this.showGCAppExplain = false
      this.showContinueToSchoolFormEntry = false
      this.showSchoolFormEntry = false
      this.showCreate8843 = false  
    
/*
    else {
      this.showIntro = true
      this.showNameFormEntry = false
      this.showAddressFormEntry = false
      this.showVisaChangeLeaveUSFormEntry = true
      this.showCitizenFormEntry = false
      this.showGCAppFormEntry = false
      this.showGCAppExplain = false
      this.showContinueToSchoolFormEntry = false
      this.showSchoolFormEntry = false
      this.showCreate8843 = false  
    }
    */
  }


  onChangeVisaChangeLeaveUS(YesNo) {
    if (this.f8843Form.get('visaChangeLeaveUS').value != "Select...") {
      this.visaChangeLeaveUSShowContinue = true
    }
    else {this.visaChangeLeaveUSShowContinue = false}
  }

  onSubmitGoToCitizenEntry() {
    sessionStorage.f8843_addressRes = this.f8843Form.get('addressRes').value
    sessionStorage.f8843_addressUS = this.f8843Form.get('addressUS').value
    sessionStorage.f8843_visaChangeLeaveUS = this.f8843Form.get('visaChangeLeaveUS').value
    this.showIntro = false
    this.showNameFormEntry = false
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = false
    this.visaChangeLeaveUSShowContinue = false
    this.showCitizenFormEntry = true
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }

  onSubmitGoToGCAppEntry() {
    sessionStorage.f8843_countryCitizen = this.f8843Form.get('countryCitizen').value
    sessionStorage.f8843_countryPassport = this.f8843Form.get('countryPassport').value
    sessionStorage.f8843_passport = this.f8843Form.get('passport').value
    this.f8843Form.patchValue({'gcApp': 'Select...'})
    this.showIntro = false
    this.showNameFormEntry = false
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = true
    this.showGCAppExplain = false
    if (this.f8843Form.get('gcApp').value == '') {
      this.showContinueToSchoolFormEntry = false
    }
    else if (this.f8843Form.get('gcApp').value == 'No') {
      this.showGCAppExplain = false
      this.showContinueToSchoolFormEntry = true
    }
    else if (this.f8843Form.get('gcApp').value == 'Yes') {
      this.showGCAppExplain = true
      this.showContinueToSchoolFormEntry = true
    }
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }

  onChangeGCApp(YesNo) {
    this.showIntro = false
    if (this.f8843Form.get('gcApp').value != 'Select...') {
      if (YesNo == "Yes") {
        this.showGCAppExplain = true
        this.showContinueToSchoolFormEntry = true
      }
      else if (YesNo == "No") {
        //this.showGCAppFormEntry = true
        this.showGCAppExplain = false
        this.showContinueToSchoolFormEntry = true
      }
    }
    else {
      //this.showGCAppFormEntry = true
      this.showGCAppExplain = false
      this.showContinueToSchoolFormEntry = false
    }
  }

  onSubmitGoToSchoolEntry() {
    sessionStorage.f8843_countryCitizen = this.f8843Form.get('countryCitizen').value
    sessionStorage.f8843_countryPassport = this.f8843Form.get('countryPassport').value
    sessionStorage.f8843_passport = this.f8843Form.get('passport').value
    sessionStorage.f8843_gcApp = this.f8843Form.get('gcApp').value
    sessionStorage.f8843_gcAppExplain1 = this.f8843Form.get('gcAppExplain1').value
    sessionStorage.f8843_gcAppExplain2 = this.f8843Form.get('gcAppExplain2').value
    this.f8843Form.patchValue({'schoolDropdownName': 'Select...'})
    this.showIntro = false
    this.showNameFormEntry = false
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = true
    this.showSchoolOther = false
    this.showSchoolExisting = false
    this.showCreate8843 = false
    this.onChangeSchoolDropdownName('Select...')
  }

  onChangeSchoolDropdownName(schName) {
    this.schoolShortName = schName
    this.showSchoolFormEntry = true

    if (this.schoolShortName != 'Select...') {

      if (this.schoolShortName == 'Enter My Own Information') {
        this.showSchoolOther = true
        this.showSchoolExisting = false
        this.showContinueToSchoolFormEntry = false
        if (this.visaJ1Teacher == true) {
          this.showContactOther = false
        }
        else {
          this.showContactOther = true
        }
      }

      else {
        this.showSchoolOther = false
        this.showSchoolExisting = true
        this.showContinueToSchoolFormEntry = false
        this.schoolIndex = schoolNameOnlyData.indexOf(this.schoolShortName)

        this.f8843Form.patchValue({'schoolNameExisting': schoolData[this.schoolIndex][0]})
        this.f8843Form.patchValue({'schoolContactExisting': schoolData[this.schoolIndex][1]})
        this.f8843Form.patchValue({'schoolAddressExisting': schoolData[this.schoolIndex][2]})
        this.f8843Form.patchValue({'schoolPhoneExisting': schoolData[this.schoolIndex][3]})

        if (this.visaJ1Teacher == true) {
          this.showContactExisting = false
        }
        else {
          this.showContactExisting = true
        }
      }
    }

    else {
      this.showSchoolExisting = false
      this.showSchoolOther = false
    }

    return
  }

  onSubmitGoToCreateForm8843() {
    sessionStorage.f8843_schoolContactExisting = this.f8843Form.get('schoolContactExisting').value
    sessionStorage.f8843_schoolNameExisting = this.f8843Form.get('schoolNameExisting').value
    sessionStorage.f8843_schoolAddressExisting = this.f8843Form.get('schoolAddressExisting').value
    sessionStorage.f8843_schoolPhoneExisting = this.f8843Form.get('schoolPhoneExisting').value
    sessionStorage.f8843_schoolContactOther = this.f8843Form.get('schoolContactOther').value
    sessionStorage.f8843_schoolNameOther = this.f8843Form.get('schoolNameOther').value
    sessionStorage.f8843_schoolAddressOther = this.f8843Form.get('schoolAddressOther').value
    sessionStorage.f8843_schoolPhoneOther = this.f8843Form.get('schoolPhoneOther').value

    this.showIntro = false
    this.showNameFormEntry = false
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = false
    this.showCreate8843 = true
  }

  async onSubmitGoToSelfFill() {
    this.showSelfFill = true
    this.showAutoFill = false
    sessionStorage.f8843Type = 'blank'

    var currentdate = new Date(); 
    var datetime = "_" + currentdate.getFullYear() + "_"
    + (currentdate.getMonth()+1).toString().padStart(2, "0")  + "_" 
    + currentdate.getDate().toString().padStart(2, "0") + "_"  
    + currentdate.getHours().toString().padStart(2, "0") + "_"  
    + currentdate.getMinutes().toString().padStart(2, "0") + "_" 
    + currentdate.getSeconds().toString().padStart(2, "0");

    this.f8843PDFDoc = await PDFDocument.create();
    await this.addPDFPage('../assets/f8843_2023_2page.pdf')
    await this.addPDFPage('../assets/8843FilingInstructions_2024_02_18.pdf')
    const pdfBytes = await this.f8843PDFDoc.save()
    this.saveByteArray('Form8843_NonFilled' + datetime + '.pdf', pdfBytes);

    // Set up navigation
    sessionStorage.navDir = 'forward';
    sessionStorage.prevPage = 'pg-nr8843'
    this.router.navigate(['../pg-nr8843-complete'], {relativeTo: this.route});
  }

  async onSubmit8843Info(){
    sessionStorage.f8843Type = 'filled'
    this.f8843Entries = {
      firstName: this.f8843Form.get('firstName').value,
      middleInital: this.f8843Form.get('initial').value,
    }
    //Create the PDF Document, then we will create or add pages to it
    this.f8843PDFDoc = await PDFDocument.create();
    var fontChoice = await this.f8843PDFDoc.embedFont(StandardFonts.Courier)
    var bodyFontSize: number = 10;

    await this.addPDFPage('../assets/f8843_2023_2page.pdf')

    const page0 = this.f8843PDFDoc.getPage(0);
    //const size = page0.getSize();
    //width  = 612
    //height = 792
    page0.setLineHeight(12);
    page0.setFontColor(rgb(0, 0, 0))

    ///////////////
    //TOP SECTION//
    //First name & Middle inital
    page0.drawText(this.f8843Form.get('firstName').value + ' ' + this.f8843Form.get('initial').value, {
      x: 37, y: 664, size: bodyFontSize, font: fontChoice, });

    //Last name
    page0.drawText(this.f8843Form.get('lastName').value, {
      x: 250, y: 664, size: bodyFontSize, font: fontChoice, });

    //Address in country of residence
    page0.drawText(this.f8843Form.get('addressRes').value, {
      x: 120, y: 642, size: bodyFontSize, font: fontChoice, });

    //Address in the U.S.
    page0.drawText(this.f8843Form.get('addressUS').value, {
      x: 350, y: 642, size: bodyFontSize, font: fontChoice, });
    
    //////////
    //PART I//
    //1a Visa type and date of last entry to U.S.
    if (this.f8843Form.get('visaChangeLeaveUS').value != "Yes") {
      page0.drawText(sessionStorage.visaType.substring(0,3) + ' ' + sessionStorage.visitEntryLast, {
        x: 420, y: 578, size: bodyFontSize, font: fontChoice, });
      }
    else {
      page0.drawText(sessionStorage.visaChangeWithoutLeaving1a, {
        x: 420, y: 578, size: bodyFontSize, font: fontChoice, });
    }
    
    //1b Nonimmigrant status, (date of change, previous status)
    if (this.f8843Form.get('visaChangeLeaveUS').value != "Yes") {
      page0.drawText(sessionStorage.visaType.substring(0,3), {
        x: 66, y: 555, size: bodyFontSize, font: fontChoice, });
    }
    else {
      page0.drawText(sessionStorage.visaChangeWithoutLeaving1b, {
        x: 66, y: 555, size: bodyFontSize, font: fontChoice, });
    }

    //2 Countries of citizenship
    if (this.f8843Form.get('countryCitizen').value != '') {
      page0.drawText(this.f8843Form.get('countryCitizen').value, {
        x: 340, y: 543, size: bodyFontSize, font: fontChoice, }); 
    }
    else {
      page0.drawText(this.countryCitizen, {
        x: 340, y: 543, size: bodyFontSize, font: fontChoice, }); 
    }

    if (this.f8843Form.get('countryPassport').value != '') {
      page0.drawText(this.f8843Form.get('countryPassport').value, {
        x: 270, y: 531, size: bodyFontSize, font: fontChoice, });
      }
    else {
      page0.drawText(this.countryPassport, {
        x: 270, y: 531, size: bodyFontSize, font: fontChoice, });
    }
      
    //3b Passport number(s)
    page0.drawText(this.f8843Form.get('passport').value, {
      x: 193, y: 519, size: bodyFontSize, font: fontChoice, });

    //4a Day present in U.S. TY
    page0.drawText(sessionStorage.daysInUSTY, {
      x: 98, y: 495, size: bodyFontSize, font: fontChoice, });

    //4a Day present in U.S. TY-1
    page0.drawText(sessionStorage.daysInUSTY1, {
      x: 183, y: 495, size: bodyFontSize, font: fontChoice, });

    //4a Day present in U.S. TY-2
    page0.drawText(sessionStorage.daysInUSTY2, {
      x: 271, y: 495, size: bodyFontSize, font: fontChoice, });

    //4b Days SPT - NEED TO GET DATA
    page0.drawText(sessionStorage.exemptCount, {
      x: 506, y: 483, size: bodyFontSize, font: fontChoice, });

    ///////////
    //PART II//

    //5 J-1 Teacher
    if (this.visaJ1Teacher == true) {
      if (this.schoolShortName == 'Enter My Own Information') {
        page0.drawText(this.f8843Form.get('schoolNameOther').value + '  ' + this.f8843Form.get('schoolPhoneOther').value, {
          x: 66, y: 446, size: bodyFontSize, font: fontChoice, });
        let c: string = this.commaOccurances(this.f8843Form.get('schoolAddressOther').value)
        page0.drawText(c, {
          x: 66, y: 434, size: bodyFontSize, font: fontChoice, });  
      }
      else {
          page0.drawText(this.f8843Form.get('schoolNameExisting').value + '  ' + this.f8843Form.get('schoolPhoneExisting').value, {
          x: 66, y: 446, size: bodyFontSize, font: fontChoice, });
        page0.drawText(this.f8843Form.get('schoolAddressExisting').value.replace('/n', ', '), {
          x: 66, y: 434, size: bodyFontSize, font: fontChoice, }); 
      }
    }

    //6 Other J-1 types
    if (this.visaJ1NonTeacherNonStudent == true) {
      if (this.schoolShortName == 'Enter My Own Information') {
        page0.drawText(this.f8843Form.get('schoolContactOther').value + '  ' + this.f8843Form.get('schoolPhoneOther').value, {
          x: 183, y: 410, size: bodyFontSize, font: fontChoice, });
        let c: string = this.commaOccurances(this.f8843Form.get('schoolAddressOther').value)
        page0.drawText(c, {
          x: 66, y: 398, size: bodyFontSize, font: fontChoice, });
        }
      else {
        page0.drawText(this.f8843Form.get('schoolContactExisting').value + '  ' + this.f8843Form.get('schoolPhoneExisting').value, {
          x: 183, y: 410, size: bodyFontSize, font: fontChoice, });
          page0.drawText(this.f8843Form.get('schoolAddressExisting').value.replace('/n', ', '), {
          x: 66, y: 398, size: bodyFontSize, font: fontChoice, });
      }
    }
    
    //7 J or Q visas in previous years
    //TY-6
    if (this.visaJ1Teacher == true || this.visaJ1NonTeacherNonStudent == true) {
      page0.drawText(sessionStorage.visaAtEndOfYearTY6, {
        x: 355, y: 374, size: bodyFontSize, font: fontChoice, });
      //TY-5
      page0.drawText(sessionStorage.visaAtEndOfYearTY5, {
        x: 450, y: 374, size: bodyFontSize, font: fontChoice, });
      //TY-4
      page0.drawText(sessionStorage.visaAtEndOfYearTY4, {
        x: 98, y: 362, size: bodyFontSize, font: fontChoice, });
      //TY-3
      page0.drawText(sessionStorage.visaAtEndOfYearTY3, {
        x: 183, y: 362, size: bodyFontSize, font: fontChoice, });
      //TY-2
      page0.drawText(sessionStorage.visaAtEndOfYearTY2, {
        x: 271, y: 362, size: bodyFontSize, font: fontChoice, });
      //TY-1
      page0.drawText(sessionStorage.visaAtEndOfYearTY1, {
        x: 356, y: 362, size: bodyFontSize, font: fontChoice, });
    }
    //8 Exempt 2 of 6
    if (this.visaJ1Teacher == true || this.visaJ1NonTeacherNonStudent == true) {
      if (sessionStorage.exempt2of6ForeignIncomeYes == "Yes") {
      page0.drawText('X', {
        x: 512, y: 327, size: bodyFontSize, font: fontChoice, }); }
      else {
        page0.drawText('X', {
          x: 548, y: 327, size: bodyFontSize, font: fontChoice, }); }
    }

    ////////////
    //PART III//
    if (this.visaFJMQStudent == true) {

      //9 FJQM Student
      if (this.schoolShortName == 'Enter My Own Information') {
        page0.drawText(this.f8843Form.get('schoolNameOther').value + '  ' + this.f8843Form.get('schoolPhoneOther').value, {
          x: 66, y: 267, size: bodyFontSize, font: fontChoice, });
        let c: string = this.commaOccurances(this.f8843Form.get('schoolAddressOther').value)
        page0.drawText(c, {
          x: 66, y: 255, size: bodyFontSize, font: fontChoice, });
        }
      else {
        page0.drawText(this.f8843Form.get('schoolNameExisting').value + '  ' + this.f8843Form.get('schoolPhoneExisting').value, {
          x: 66, y: 267, size: bodyFontSize, font: fontChoice, });
        page0.drawText(this.f8843Form.get('schoolAddressExisting').value.replace('/n', ', '), {
          x: 66, y: 255, size: bodyFontSize, font: fontChoice, });
      }
      //10 Other J-1 types
      if (this.schoolShortName == 'Enter My Own Information') {
        page0.drawText(this.f8843Form.get('schoolContactOther').value + '   ' + this.f8843Form.get('schoolPhoneOther').value, {
          x: 133, y: 231, size: bodyFontSize, font: fontChoice, });
        let c: string = this.commaOccurances(this.f8843Form.get('schoolAddressOther').value)
        page0.drawText(c, {
          x: 66, y: 219, size: bodyFontSize, font: fontChoice, });
      }
      else {
        page0.drawText(this.f8843Form.get('schoolContactExisting').value + '   ' + this.f8843Form.get('schoolPhoneExisting').value, {
          x: 133, y: 231, size: bodyFontSize, font: fontChoice, });
        page0.drawText(this.f8843Form.get('schoolAddressExisting').value.replace('/n', ', '), {
          x: 66, y: 219, size: bodyFontSize, font: fontChoice, });
      }

      //11 F/J/M/Q visas in previous years
      //TY-6
      page0.drawText(sessionStorage.visaAtEndOfYearTY6, {
        x: 355, y: 194, size: bodyFontSize, font: fontChoice, });
      //TY-5
      page0.drawText(sessionStorage.visaAtEndOfYearTY5, {
        x: 450, y: 194, size: bodyFontSize, font: fontChoice, });
      //TY-4
      page0.drawText(sessionStorage.visaAtEndOfYearTY4, {
        x: 98, y: 182, size: bodyFontSize, font: fontChoice, });
      //TY-3
      page0.drawText(sessionStorage.visaAtEndOfYearTY3, {
        x: 183, y: 182, size: bodyFontSize, font: fontChoice, });
      //TY-2
      page0.drawText(sessionStorage.visaAtEndOfYearTY2, {
        x: 271, y: 182, size: bodyFontSize, font: fontChoice, });
      //TY-1
      page0.drawText(sessionStorage.visaAtEndOfYearTY1, {
        x: 356, y: 182, size: bodyFontSize, font: fontChoice, });

      //12 Exempt past 5 years
        page0.drawText('X', {
          x: 548, y: 159, size: bodyFontSize, font: fontChoice, });

      //13 Perm. Res. Application
      if (this.f8843Form.get('gcApp').value == "Yes") {
        page0.drawText('X', {
          x: 512, y: 99, size: bodyFontSize, font: fontChoice, }); }
      else {
        page0.drawText('X', {
          x: 548, y: 100, size: bodyFontSize, font: fontChoice, }); }

      //14 Perm Res. Explain
        if (this.f8843Form.get('gcApp').value == "Yes") {
          page0.drawText(this.f8843Form.get('gcAppExplain1').value, {
            x: 260, y: 87, size: bodyFontSize, font: fontChoice, });
          page0.drawText(this.f8843Form.get('gcAppExplain2').value, {
            x: 66, y: 75, size: bodyFontSize, font: fontChoice, }); }
    }


    if (sessionStorage.exempt2of6ForeignIncomeYes == "Yes") {
      await this.addPDFPage('../assets/8843AdditionalExceptionDocumentation_2024_02_18.pdf')
    }

    await this.addPDFPage('../assets/8843FilingInstructions_2024_02_18.pdf')
    
    var currentdate = new Date(); 
    var datetime = "_" + currentdate.getFullYear() + "_"
    + (currentdate.getMonth()+1).toString().padStart(2, "0")  + "_" 
    + currentdate.getDate().toString().padStart(2, "0") + "_"  
    + currentdate.getHours().toString().padStart(2, "0") + "_"  
    + currentdate.getMinutes().toString().padStart(2, "0") + "_" 
    + currentdate.getSeconds().toString().padStart(2, "0");
    const pdfBytes = await this.f8843PDFDoc.save()
    this.saveByteArray('Form8843_PreFilled' + datetime + '.pdf', pdfBytes);

    // Set up navigation
    sessionStorage.navDir = 'forward';
    sessionStorage.prevPage = 'pg-nr8843'
    this.router.navigate(['../pg-nr8843-complete'], {relativeTo: this.route});

  }

  async addPDFPage(filename: string) {
    const f8843filePDFBytes = await fetch(filename).then(res => res.arrayBuffer());
    const f8843Doc = await PDFDocument.load(f8843filePDFBytes);
    const f8843Page = await this.f8843PDFDoc.copyPages(f8843Doc, f8843Doc.getPageIndices());
    f8843Page.forEach((page) => this.f8843PDFDoc.addPage(page));
  }

  commaOccurances(str) {
    let n = str.split("\n").length - 1
    for (let i=0; i <= n; i++) {
      str = str.replace('\n', ', ')
    }
    return str
  }

  saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {type: "application/pdf"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  onSubmitBack() {
    sessionStorage.prevPage = 'pg-nr8843'
    sessionStorage.navDir = 'backward'
    this.router.navigate(['../pg-nr04'], {relativeTo: this.route});
    }

  onSubmitPrevShowIntro(){
    this.showIntro = true
    this.showAutoFill = false
    this.showNameFormEntry = false
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = false
    this.visaChangeLeaveUSShowContinue = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }
    
  onSubmitPrevShowNameFormEntry(){
    this.showIntro = false
    this.showAutoFill = true
    this.showNameFormEntry = true
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = false
    this.visaChangeLeaveUSShowContinue = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }

  onSubmitPrevShowAddressFormEntry(){
    this.showIntro = false
    this.showAutoFill = true
    this.showNameFormEntry = false
    this.showAddressFormEntry = true
    this.showVisaChangeLeaveUSFormEntry = false
    this.visaChangeLeaveUSShowContinue = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }

  onSubmitPrevShowVisaChangeLeaveUSFormEntry() {
    if (this.f8843Form.get('visaChangeLeaveUS').value != "") {
      this.visaChangeLeaveUSShowContinue = true
    }
    else {this.visaChangeLeaveUSShowContinue = false}
    this.showIntro = false
    this.showAutoFill = true
    this.showNameFormEntry = false
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = true
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }


  onSubmitPrevShowCitizenFormEntry(){
    this.showIntro = false
    this.showAutoFill = true
    this.showNameFormEntry = false
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = false
    this.visaChangeLeaveUSShowContinue = false
    this.showCitizenFormEntry = true
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }

  onSubmitPrevShowGCAppFormEntry(){
    this.showIntro = false
    this.showAutoFill = true
    this.showNameFormEntry = false
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = true
    if (this.f8843Form.get('gcApp').value == '') {
      this.showContinueToSchoolFormEntry = false
    }
    else if (this.f8843Form.get('gcApp').value == 'No') {
      this.showGCAppExplain = false
      this.showContinueToSchoolFormEntry = true
    }
    else if (this.f8843Form.get('gcApp').value == 'Yes') {
      this.showGCAppExplain = true
      this.showContinueToSchoolFormEntry = true
    }
    //this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = true
    this.showSchoolFormEntry = false
    this.showCreate8843 = false
  }

  onSubmitPrevShowSchoolFormEntry(){
    this.showIntro = false
    this.showAutoFill = true
    this.showNameFormEntry = false
    this.showAddressFormEntry = false
    this.showVisaChangeLeaveUSFormEntry = false
    this.showCitizenFormEntry = false
    this.showGCAppFormEntry = false
    this.showGCAppExplain = false
    this.showContinueToSchoolFormEntry = false
    this.showSchoolFormEntry = true
    this.showCreate8843 = false
  }

}
