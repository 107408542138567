// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyANKjyc7p7o959OQ5P5_NyFvVpTuxVJCRI",
    authDomain: "north-qa.firebaseapp.com",
    projectId: "north-qa",
    storageBucket: "north-qa.appspot.com",
    messagingSenderId: "539465073789",
    appId: "1:539465073789:web:5b4e2365bfb16469169d14",
    measurementId: "G-6D734VWDGG"
  },
};
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
