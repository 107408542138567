import { Injectable } from '@angular/core';
import { Visit } from './tools/aminra/visit/Visit';
import { StateVisit } from './tools/pg-nr06a/statevisit/statevisit';
//import { Firestore, addDoc, collection, getDocs, query } from 'firebase/firestore/lite';


/*
export const schoolURLDYT = {
  '': 'https://www.nrtaxhelp.org',
  'NoRTH': 'https://www.nrtaxhelp.org/North',
  'NRVTAP': 'https://www.nrtaxhelp.org/NRVTAP',
  'UMD': 'https://www.nrtaxhelp.org/UMD',
  'Lehigh': 'https://www.nrtaxhelp.org/Lehigh',
  'AppState': 'https://www.nrtaxhelp.org/AppState',
  'UMC': 'https://www.nrtaxhelp.org/UMC',
  'Mankato': 'https://www.nrtaxhelp.org/Mankato',
  'HL': 'https://www.nrtaxhelp.org/HL',
  'UND': 'https://www.nrtaxhelp.org/UND',
  'UMBC': 'https://www.nrtaxhelp.org/UMBC',
  'UWGB': 'https://www.nrtaxhelp.org/UWGB',
  'Bemidji': 'https://www.nrtaxhelp.org/Bemidji', 
}

export const schoolList = [
  '',
  'NoRTH',
  'NRVTAP',
  'UMD',
  'Lehigh',
  'AppState',
  'UMC',
  'Mankato',
  'HL',
  'UND',
  'UMBC',
  'UWGB',
  'Bemidji',
]

export const schoolTinyURL = [
['NoRTH', 'https://tools.nrtaxhelp.org/pg-nr00?loc=NoRTH',	'https://tinyurl.nrtaxhelp.org/PGNoRTH'],
['NRVTAP', 'https://tools.nrtaxhelp.org/pg-nr00?loc=NRVTAP',	'https://tinyurl.nrtaxhelp.org/PGNRVTAP'],
['UMD', 'https://tools.nrtaxhelp.org/pg-nr00?loc=UMD',	'https://tinyurl.nrtaxhelp.org/PGUMD'],
['Lehigh', 'https://tools.nrtaxhelp.org/pg-nr00?loc=Lehigh',	'https://tinyurl.nrtaxhelp.org/PGLehigh'],
['AppState', 'https://tools.nrtaxhelp.org/pg-nr00?loc=AppState',	'https://tinyurl.nrtaxhelp.org/PGAppState'],
['UMC', 'https://tools.nrtaxhelp.org/pg-nr00?loc=UMC',	'https://tinyurl.nrtaxhelp.org/PGUMC'],
['Mankato', 'https://tools.nrtaxhelp.org/pg-nr00?loc=Mankato',	'https://tinyurl.nrtaxhelp.org/PGMankato'],
['HL', 'https://tools.nrtaxhelp.org/pg-nr00?loc=HL',	'https://tinyurl.nrtaxhelp.org/PGHL'],
['UND', 'https://tools.nrtaxhelp.org/pg-nr00?loc=UND',	'https://tinyurl.nrtaxhelp.org/PGUND'],
['UMBC', 'https://tools.nrtaxhelp.org/pg-nr00?loc=UMBC',	'https://tinyurl.nrtaxhelp.org/PGUMBC'],
['UWGB', 'https://tools.nrtaxhelp.org/pg-nr00?loc=UWGB',	'https://tinyurl.nrtaxhelp.org/PGUWGB'],
['Bemidji',	'https://tools.nrtaxhelp.org/pg-nr00?loc=Bemidji',	'https://tinyurl.nrtaxhelp.org/PGBemidji']
]

*/

@Injectable({
  providedIn: 'root'
})


export class AppService {

  constructor(
    //public firestore: Firestore
    )
     {}

  private sortedVisits: Visit[] = [];
  private stateVisits: StateVisit[] = [];
  private OUSVisitsTYStart: moment.Moment[] = [];
  private OUSVisitsTYStartShow: moment.Moment[] = [];
  private OUSVisitsTYEnd: moment.Moment[] = [];
  private OUSVisitsTYEndShow: moment.Moment[] = [];
  private totalOUSDays: number = null;
  private stateAddlQ: any[] = []
  private stateIncomes: any[] = [];

  addSortedVisitsInTY(item: Visit) {
    this.sortedVisits.push(item)}
  getSortedVisitsInTY(): Visit[] { 
    return this.sortedVisits}
  clearSortedVisitsInTY() {
    this.sortedVisits = []}

  addOUSVisitsInTaxYearStart(item: moment.Moment[]) {
    this.OUSVisitsTYStart = item}
  getOUSVisitsInTaxYearStart(): moment.Moment[] { 
    return this.OUSVisitsTYStart}

  addOUSVisitsInTaxYearStartShow(item: moment.Moment[]) {
    this.OUSVisitsTYStartShow = item}
  getOUSVisitsInTaxYearStartShow(): moment.Moment[] { 
    return this.OUSVisitsTYStartShow}

  addOUSVisitsInTaxYearEnd(item: moment.Moment[]) {
    this.OUSVisitsTYEnd = item}
  getOUSVisitsInTaxYearEnd(): moment.Moment[] { 
    return this.OUSVisitsTYEnd}

  addOUSVisitsInTaxYearEndShow(item: moment.Moment[]) {
    this.OUSVisitsTYEndShow = item}
  getOUSVisitsInTaxYearEndShow(): moment.Moment[] { 
    return this.OUSVisitsTYEndShow}

  addTotalOUSDays(item: number) {
    this.totalOUSDays = item}
  getTotalOUSDays(): (number) {
    return this.totalOUSDays}

  addStateAddnlQ(item: any) {
    this.stateAddlQ = item}
  getStateAddnlQ(): (any) {
    return this.stateAddlQ}
  clearStateAddlQ() {
    this.stateAddlQ = []}
 
     
  clearOUSVisitsInTY() {
    this.OUSVisitsTYStart = []
    this.OUSVisitsTYStartShow = []
    this.OUSVisitsTYEnd = []
    this.OUSVisitsTYEndShow = []
    this.totalOUSDays = null
  }

  clearStateIncomes() {
    //this.stateIncomes = []
    Object.keys(this.stateIncomes).forEach(key => {
      delete this.stateIncomes[key];
    })
  }
  addStateIncomes(item: string[]) {
  this.stateIncomes = item}
  getStateIncomes(): any[] {
    return this.stateIncomes}
  

  clearStateVisits() {
    this.stateVisits = []
    //Object.keys(this.stateVisits).forEach(key => {
    //  delete this.stateVisits[key];
    //})
  }
  addStateVisits(item: StateVisit) {
    this.stateVisits.push(item)}
  getStateVisits(): StateVisit[] {
    return this.stateVisits}

/*
  async createPGGuidesList(guidePieces: String) {
    const docRef = await addDoc(collection(this.firestore, 'TestGuidePieces'), {
      pieces: guidePieces
    });
    console.log("Document written with ID: ", docRef.id);
  }
*/

}


