//import * as moment from 'moment';

//import { Visa, doctypes, studentVisas, incomecodes, } from './TBData';
import { DocModel } from './DocsModel';

/**
 * This is an object representation of Visit. It encapsulates all information and logic needed by the NRA Tool to
 * compute residency status.
 */
export class Doc {
    private id: number;
    private doctype: string;
    private incomecode: string;
    private incomeval: number;
    private error: string[];

    /**
     * This method is a method used by all Visit objects to compute the number of days between two dates
     * including both the end date and start date.
     * @param firstDate - The date coming first in the calendar
     * @param secondDate - The date coming last in the calendar
     */

    /**
     * Builds a new instance of a Visit.
     * Sets any values passed into the constructor.
     * Sets property defaults.
     * Sets properties that can be inferred by the provided information.
     * @param id - The visits ID
     * @param visa - The Visa held during this visit
     * @param error - Any errors flagged by the UI
     */
    constructor(
        id: number,
        doctype: string,
        incomecode: string,
        incomeval: number,
        error?: string[]
    ) {
        this.id = id;
        this.doctype = doctype;
        this.incomecode = incomecode;
        this.incomeval = incomeval;
        this.error = error;
    }

    getDocModel(): DocModel {
        return {
            id: this.id,
            doctype: this.doctype,
            incomecode: this.incomecode,
            incomeval: this.incomeval,
            error: this.error,
        };
    }

}
