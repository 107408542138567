<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR05.jpg" alt='Progress Bar 7 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - Payments and Refunds</h3>
            <!--<p>Comment</p>-->
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-7 col-lg-7">
            <label>Did you contribute money or goods to any qualified U.S. charitable organization during {{taxYear}}?</label>
        </div>
        <div class="col-sm-2 col-lg-2">
            <ng-select id="charityContribAnswer" [items]="selectYesNo" [(ngModel)]="charityContribAnswer" (ngModelChange)="charityContribChange()" placeholder="Select" [clearable]="false"></ng-select>
        </div> 
    </div>

    <div *ngIf="showSLoan1098EQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Did you make payments on a student loan and receive a form 1098-E to report the amount of interest you paid?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="sLoan1098EAnswer" [items]="selectYesNo" [(ngModel)]="sLoan1098EAnswer" (ngModelChange)="sLoan1098EChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>
    </div>

    <div *ngIf="showFilePrevTaxYearQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Did you file an income tax return for {{taxYear-1}}?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="filePrevTaxYearAnswer" [items]="selectYesNo" [(ngModel)]="filePrevTaxYearAnswer" (ngModelChange)="filePrevTaxYearChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>
    </div>

    <div *ngIf="showStateRefundQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Did you receive a refund from a state income tax return during calendar year {{taxYear}}?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="stateRefundAnswer" [items]="selectYesNo" [(ngModel)]="stateRefundAnswer" (ngModelChange)="stateRefundChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>
    </div>

    <div *ngIf="showPayAddnlStateIncTaxQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Did you pay additional state income tax with your return?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="payAddnlStateIncTaxAnswer" [items]="selectYesNo" [(ngModel)]="payAddnlStateIncTaxAnswer" (ngModelChange)="payAddnlStateIncomeTaxChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>
    </div>

    <div *ngIf="showNextPageContinueButton">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToNextPage()">Continue</button>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a>.</small></p>
        </div>
    </div>


</div>
