<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR06b.jpg" alt='Progress Bar pg-nr06b'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - U.S. States Additional Questions</h3>
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>For each of your OUS and state visits, please answer the questions below.</p>
            <p><b>Note: </b>An abode is a residence in Minnesota suitable for year-round use that is equipped with its own cooking and bathing facilities.</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>0" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[0] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[0] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode0" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN0" (ngModelChange)="OUSMNAbodeChange0()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ0">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome0" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome0" (ngModelChange)="OUSMNAbodeIncomeChange0()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>1" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[1] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[1] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode1" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN1" (ngModelChange)="OUSMNAbodeChange1()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ1">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome1" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome1" (ngModelChange)="OUSMNAbodeIncomeChange1()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>2" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[2] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[2] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode2" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN2" (ngModelChange)="OUSMNAbodeChange2()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ2">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome2" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome2" (ngModelChange)="OUSMNAbodeIncomeChange2()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>3" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[3] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[3] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode3" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN3" (ngModelChange)="OUSMNAbodeChange3()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ3">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome3" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome3" (ngModelChange)="OUSMNAbodeIncomeChange3()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>4" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[4] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[4] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode4" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN4" (ngModelChange)="OUSMNAbodeChange4()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ4">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome4" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome4" (ngModelChange)="OUSMNAbodeIncomeChange4()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>5" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[5] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[5] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode5" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN5" (ngModelChange)="OUSMNAbodeChange5()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ5">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome5" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome5" (ngModelChange)="OUSMNAbodeIncomeChange5()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>6" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[6] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[6] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode6" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN6" (ngModelChange)="OUSMNAbodeChange6()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ6">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome6" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome6" (ngModelChange)="OUSMNAbodeIncomeChange6()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>7" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[7] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[7] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode7" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN7" (ngModelChange)="OUSMNAbodeChange7()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ7">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome7" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome7" (ngModelChange)="OUSMNAbodeIncomeChange7()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>8" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[8] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[8] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode8" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN8" (ngModelChange)="OUSMNAbodeChange8()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ8">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome8" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome8" (ngModelChange)="OUSMNAbodeIncomeChange8()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberOUSVisits>9" class="col-12">
                <h5>{{ OUSVisitsInTaxYearStartShow[9] | date: 'MM/dd/yyyy'}} to {{ OUSVisitsInTaxYearEndShow[9] | date: 'MM/dd/yyyy'}} (OUS):</h5>

                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="OUSMNAbode9" [items]="selectAllPartNone" [(ngModel)]="OUSMNAbodeYN9" (ngModelChange)="OUSMNAbodeChange9()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="OUSShowMNAbodeIncomeQ9">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="OUSMNAbodeIncome9" [items]="selectYesNo" [(ngModel)]="OUSMNAbodeIncome9" (ngModelChange)="OUSMNAbodeIncomeChange9()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>0" class="col-12">
                <h5>{{ stateVisitsInTaxYear[0].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[0].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[0].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[0].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN0" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN0' (ngModelChange)="MNAbodeChange0()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[0].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode0" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN0" (ngModelChange)="notMNAbodeChange0()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ0">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncomeYN0" [items]="selectYesNo" [(ngModel)]="notMNAbodeIncomeYN0" (ngModelChange)="notMNAbodeIncomeChange0()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>1" class="col-12">
                <h5>{{ stateVisitsInTaxYear[1].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[1].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[1].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[1].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN1" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN1' (ngModelChange)="MNAbodeChange1()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[1].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode1" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN1" (ngModelChange)="notMNAbodeChange1()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ1">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncomeYN1" [items]="selectYesNo" [(ngModel)]="notMNAbodeIncomeYN1" (ngModelChange)="notMNAbodeIncomeChange1()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>2" class="col-12">
                <h5>{{ stateVisitsInTaxYear[2].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[2].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[2].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[2].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN2" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN2' (ngModelChange)="MNAbodeChange2()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[2].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode2" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN2" (ngModelChange)="notMNAbodeChange2()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ2">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncomeYN2" [items]="selectYesNo" [(ngModel)]="MNAbodeIncome2" (ngModelChange)="notMNAbodeIncomeChange2()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>3" class="col-12">
                <h5>{{ stateVisitsInTaxYear[3].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[3].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[3].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[3].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN3" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN3' (ngModelChange)="MNAbodeChange3()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[3].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode3" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN3" (ngModelChange)="notMNAbodeChange3()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ3">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncome3" [items]="selectYesNo" [(ngModel)]="MNAbodeIncome3" (ngModelChange)="notMNAbodeIncomeChange3()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>4" class="col-12">
                <h5>{{ stateVisitsInTaxYear[4].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[4].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[4].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[4].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN4" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN4' (ngModelChange)="MNAbodeChange4()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[4].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode4" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN4" (ngModelChange)="notMNAbodeChange4()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ4">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncome4" [items]="selectYesNo" [(ngModel)]="MNAbodeIncome4" (ngModelChange)="notMNAbodeIncomeChange4()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>5" class="col-12">
                <h5>{{ stateVisitsInTaxYear[5].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[5].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[5].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[5].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN5" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN5' (ngModelChange)="MNAbodeChange5()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[5].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode5" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN5" (ngModelChange)="notMNAbodeChange5()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ5">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncome5" [items]="selectYesNo" [(ngModel)]="MNAbodeIncome5" (ngModelChange)="notMNAbodeIncomeChange5()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>6" class="col-12">
                <h5>{{ stateVisitsInTaxYear[6].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[6].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[6].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[6].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN6" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN6' (ngModelChange)="MNAbodeChange6()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[6].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode6" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN6" (ngModelChange)="notMNAbodeChange6()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ6">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncome6" [items]="selectYesNo" [(ngModel)]="MNAbodeIncome6" (ngModelChange)="notMNAbodeIncomeChange6()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    

    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>7" class="col-12">
                <h5>{{ stateVisitsInTaxYear[7].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[7].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[7].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[7].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN7" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN7' (ngModelChange)="MNAbodeChange7()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[7].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode7" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN7" (ngModelChange)="notMNAbodeChange7()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ7">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncome7" [items]="selectYesNo" [(ngModel)]="MNAbodeIncome7" (ngModelChange)="notMNAbodeIncomeChange7()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>8" class="col-12">
                <h5>{{ stateVisitsInTaxYear[8].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[8].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[8].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[8].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN8" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN8' (ngModelChange)="MNAbodeChange8()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[8].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode8" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN8" (ngModelChange)="notMNAbodeChange8()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ8">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncome8" [items]="selectYesNo" [(ngModel)]="MNAbodeIncome8" (ngModelChange)="notMNAbodeIncomeChange8()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-12">
            <div *ngIf="numberStateVisits>9" class="col-12">
                <h5>{{ stateVisitsInTaxYear[9].entry | date: 'MM/dd/yyyy'}} to {{ stateVisitsInTaxYear[9].exit | date: 'MM/dd/yyyy'}} ({{ stateVisitsInTaxYear[9].selectedState }}):</h5>

                <div *ngIf="stateVisitsInTaxYear[9].selectedState == 'Minnesota'" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>Was where you lived an abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="MNAbodeYN9" [items]="selectAllPartNone" [(ngModel)]='MNAbodeYN9' (ngModelChange)="MNAbodeChange9()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="!(stateVisitsInTaxYear[9].selectedState == 'Minnesota')" class="row">
                    <div class="col-sm-7 col-lg-7">
                        <label>While you were outside MN did you still rent, own, occupy, or maintain a MN abode?</label>
                    </div>
                    <div class="col-sm-3 col-lg-3">
                        <ng-select id="notMNAbode9" [items]="selectAllPartNone" [(ngModel)]="notMNAbodeYN9" (ngModelChange)="notMNAbodeChange9()" placeholder="Select" [clearable]="false"></ng-select>
                    </div>
                </div>

                <div *ngIf="showNotMNAbodeIncomeQ9">
                    <div class="row">
                        <div class="col-sm-7 col-lg-7">
                            <label>Did you receive income during this period?</label>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <ng-select id="NotMNAbodeIncome9" [items]="selectYesNo" [(ngModel)]="MNAbodeIncome9" (ngModelChange)="notMNAbodeIncomeChange9()" placeholder="Select" [clearable]="false"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>

<!--
    <div class="row">
        <div class="col-12">
            <p>Total number of days with MN abode: {{ MNAbodeTotalDays }}.</p>
        </div>
    </div>
-->

    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row my-2">
        <div class="col-4">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmit()">Continue</button>
        </div>
    </div>

    <div *ngIf="showUnexpectedErrorMessage" class="col-12 my-2 alert alert-danger">
        <div>
            <p>An unexpected error has occurred while determining your residency status. We apologize for any inconvenience. If you would still like to 
            determine your residency status, please send us screenshots of your input to <b><a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a></b>
            and we will assist in any way we can.</p>
        </div>
    </div>
 

</div>


