import { Component, OnInit } from '@angular/core';
import { PGnr08Service, PGFilenames } from './pg-nr08.service';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PDFDocument, StandardFonts} from 'pdf-lib';
import { StateVisit } from '../pg-nr06a/statevisit/statevisit';

@Component({
  selector: 'app-pg-nr08',
  templateUrl: './pg-nr08.component.html',
  styleUrls: ['./pg-nr08.component.scss']
})

export class PGnr08Component implements OnInit {
  PGPDFDoc: any;
  newPage: any;
  QandASummary: any;
  waitForGuide: boolean = false;
  guideCompleted: boolean = false;
  PGFilenames = PGFilenames;
  taxYear: any;
  activeButton: boolean = true
  pdfNotFoundError: boolean = false
  includedFilenames: string[] = [];
  stateVisitsInTaxYear: StateVisit[] = [];
  OUSVisitsInTaxYearStartShow: moment.Moment[] = [];
  OUSVisitsInTaxYearEndShow: moment.Moment[] = [];
  
  J1NonStudentTypes = ['J-1 Professor', 'J-1 Research Scholar', 'J-1 Short-term Scholar', 'J-1 Teacher', 'J-1 Trainee', 'J-1 Intern', 'J-1 Physician', 'J-1 Specialist', 'J-1 Summer Work Travel', 'J-1 Au Pair and EduCare', 'J-1 Camp Counselor', 'J-1 Government Visitor', 'J-1 International Visitor (Dept. of State use)']
  allFMJQVisaTypes = ['F-1 Student', 'F-2 Student', 'J-1 Student', 
                      'J-1 Professor', 'J-1 Research Scholar', 'J-1 Short-term Scholar', 'J-1 Teacher', 'J-1 Trainee', 'J-1 Intern', 'J-1 Physician', 'J-1 Specialist', 'J-1 Summer Work Travel', 'J-1 Au Pair and EduCare', 'J-1 Camp Counselor', 'J-1 Government Visitor', 'J-1 International Visitor (Dept. of State use)',
                      'J-2 Student', 'J-2 Non-Student', 'M-1 Student', 'M-2 Student', 'Q-1 Student', 'Q-1 Non-Student', 'Q-2 Student', 'Q-2 Non-Student']
  persInfo11VisaTypes = ['F-1 Student', 'F-2 Student', 'J-1 Student', 'J-2 Student', 'M-1 Student', 'M-2 Student', 'Q-1 Student', 'Q-2 Student']
  persInfo12VisaTypes = ['J-1 Professor', 'J-1 Research Scholar', 'J-1 Short-term Scholar', 'J-1 Teacher', 'J-1 Trainee', 'J-1 Intern', 'J-1 Physician', 'J-1 Specialist', 'J-1 Summer Work Travel', 'J-1 Au Pair and EduCare', 'J-1 Camp Counselor', 'J-1 Government Visitor', 'J-1 International Visitor (Dept. of State use)', 'J-2 Non-Student', 'Q-1 Non-Student', 'Q-2 Non-Student']
  wageCountriesList = ['India', 'Luxembourg', 'Netherlands', 'Pakistan', 'Thailand', 'United Kingdom']
  otherState01List = ['Alaska', 'Florida', 'Nevada', 'South Dakota', 'Texas', 'Washington', 'Wyoming']
  otherState03List = ['Minnesota', 'Pennsylvania', 'North Carolina', 'California', 'Maryland', 'Wisconsin', 'Alaska', 'Florida', 'Nevada', 'New Hampshire', 'South Dakota', 'Texas', 'Washington', 'Wyoming', 'Tennessee']
  countriesCAMXINKR = ['Canada', 'Mexico', 'India', 'Korea, South']

  constructor(
    private PGnr08_Service: PGnr08Service,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
  let pdfDocuments = this.PGnr08_Service.pdfDocuments()
  this.QandASummary = pdfDocuments.questionnaireQandA
  this.waitForGuide = false;
  this.guideCompleted = false;
  this.taxYear = sessionStorage.taxYear;
  this.includedFilenames = []
  this.activeButton = true
  this.pdfNotFoundError = false
  }


  async onSubmitCreateGuide() {

    this.waitForGuide = true;
    this.includedFilenames = []
    var i: number
    this.activeButton = false
    
    //Create the PDF Document, then we will create or add pages to it
    this.PGPDFDoc = await PDFDocument.create();
    var timesRomanFont = await this.PGPDFDoc.embedFont(StandardFonts.TimesRoman)
    var timesRomanBoldFont = await this.PGPDFDoc.embedFont(StandardFonts.TimesRomanBold)
    var pageIndices: any;
    var titleFontSize: number = 14
    var bodyFontSize: number = 10;
    var pageWidth: number = 720;
    var pageHeight: number = 540;
    var leftMargin: number = 30;
    var topMargin: number = 40;
    var lineHeight: number = bodyFontSize + 1;
    var textWidth: any;


    try {

      //Add each of the required sections
      if (sessionStorage.nonResidentStatus == 'Nonresident Alien' && sessionStorage.incomeNone != 'Yes') {

        //State Visits Info
        this.stateVisitsInTaxYear = this.appService.getStateVisits()
        this.OUSVisitsInTaxYearStartShow = this.appService.getOUSVisitsInTaxYearStartShow()
        this.OUSVisitsInTaxYearEndShow = this.appService.getOUSVisitsInTaxYearEndShow()        
                  
        // INTRO
        //Intro01 - First general slides
        await this.addPDFPage(this.PGFilenames.Intro01)
        //Intro02 - create acct /log in to TS
        if (sessionStorage.filePrevTaxYear == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Intro02) }
        //Intro03 - creating TS acct
        if (sessionStorage.filePrevTaxYear == 'No' ) {
          await this.addPDFPage(this.PGFilenames.Intro03) }
        //Intro04a - General acct / log in slides to slide before personal information name SSN
        await this.addPDFPage(this.PGFilenames.Intro04a)
        //Intro04b - Slide to use 000, get ITIN, paper filing
        if (sessionStorage.SSNITIN == 'No') {
          await this.addPDFPage(this.PGFilenames.Intro04b) }
        //Intro04c - General Intro Slide
        if (sessionStorage.SSNITIN == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Intro04c) }
        //Intro04d - Remaining general acct / log in slides to filing status nonresident alien
        await this.addPDFPage(this.PGFilenames.Intro04d)
        //Intro05 - slide with married nonresident alien
        if (sessionStorage.married == 'Yes' ) {
          await this.addPDFPage(this.PGFilenames.Intro05) }
        //Intro06 - slide with single nonresident alien
        if (sessionStorage.married == 'No' ) {
          await this.addPDFPage(this.PGFilenames.Intro06) }
        //Intro07 - Slide with possible qualifying widow(er) status, email us
        if (sessionStorage.married == 'No' && this.countriesCAMXINKR.includes(sessionStorage.taxResidentCountry) && sessionStorage.childBornUS == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Intro07) }
        //Intro08 - Slide with saying “no” for now, come back to enter dependents later
        if (this.countriesCAMXINKR.includes(sessionStorage.taxResidentCountry) && (sessionStorage.childBornUS == 'Yes' || sessionStorage.childPresentUS == 'Yes' || sessionStorage.childNotPresent == 'Yes')) {
          await this.addPDFPage(this.PGFilenames.Intro08) }
        //Intro09 - Slide with saying “no” to dependent
        if (!this.countriesCAMXINKR.includes(sessionStorage.taxResidentCountry)
          || (this.countriesCAMXINKR.includes(sessionStorage.taxResidentCountry) 
          && sessionStorage.childBornUS == 'No' 
          && sessionStorage.childPresentUS == 'No' 
          && sessionStorage.childNotPresent == 'No')) {
          await this.addPDFPage(this.PGFilenames.Intro09) }
        //EndIntro01 - IPPIN slide, add forms, log back in (? Do these add value - remove these?)
        await this.addPDFPage(this.PGFilenames.EndIntro01)

        // PERSONAL INFO
        //PersInfo01 - Slides for Schedule OI general info
        await this.addPDFPage(this.PGFilenames.PersInfo01)
        //PersInfo02 - Graham Insert slide with AmINRA results for days of presence in TY, TY-1, TY-2
        const PersInfo02 = this.PGPDFDoc.addPage([pageWidth, pageHeight])
        PersInfo02.drawText('Presence in the U.S.', {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});
        PersInfo02.drawText('Days in the U.S. in ' + String(this.taxYear)   + ': ' + sessionStorage.daysInUSTY,  {x: leftMargin, y: pageHeight - topMargin - 20 - 0*lineHeight, size: bodyFontSize, font: timesRomanFont,});
        PersInfo02.drawText('Days in the U.S. in ' + String(this.taxYear-1) + ': ' + sessionStorage.daysInUSTY1, {x: leftMargin, y: pageHeight - topMargin - 20 - 1*lineHeight, size: bodyFontSize, font: timesRomanFont,});
        PersInfo02.drawText('Days in the U.S. in ' + String(this.taxYear-2) + ': ' + sessionStorage.daysInUSTY2, {x: leftMargin, y: pageHeight - topMargin - 20 - 2*lineHeight, size: bodyFontSize, font: timesRomanFont,});
        this.includedFilenames.push('PersInfo02')
        //PersInfo03 - slide on that part of schedule OI
        if (sessionStorage.filePrevTaxYear == 'Yes') {
          await this.addPDFPage(this.PGFilenames.PersInfo03) }
        //PersInfo04 - slide on that part of schedule OI
        if (sessionStorage.filePrevTaxYear == 'No') {
          await this.addPDFPage(this.PGFilenames.PersInfo04) }
        //PersInfo05 -  Rest of Schedule OI general info slides, current year travel dates slides
        await this.addPDFPage(this.PGFilenames.PersInfo05)
        //PersInfo06 - slides for 8843 part I info up to visa change
        if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.exemptDaysFJMQVisa == 'Yes'                                      ) {
          await this.addPDFPage(this.PGFilenames.PersInfo06) }
        //PersInfo07 - Slide for visa change entry
        if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.exemptDaysFJMQVisa == 'Yes' && sessionStorage.FJMQChange == 'Yes') {
          await this.addPDFPage(this.PGFilenames.PersInfo07) }
        //PersInfo08 - Slide for no visa change
        if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.exemptDaysFJMQVisa == 'Yes' && sessionStorage.FJMQChange == 'No' ) {
          await this.addPDFPage(this.PGFilenames.PersInfo08) }
        //PersInfo09 - “Days in the U.S. in 2022 “ & TY, “Days in the U.S. in 2021 “ & TY-1, “Days in the U.S. in 2020 “ & TY-2, “Days in 2022 you claim you can exclude (substantial presence test) “ & Exemptdays count
        if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.exemptDaysFJMQVisa == 'Yes') {
          const PersInfo09Page = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          PersInfo09Page.drawText('Presence in the U.S.', {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});
          PersInfo09Page.drawText('Days in the U.S. in ' + String(this.taxYear)   + ': ' + sessionStorage.daysInUSTY,                                  {x: leftMargin, y: pageHeight - topMargin - 20 - 0*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo09Page.drawText('Days in the U.S. in ' + String(this.taxYear-1) + ': ' + sessionStorage.daysInUSTY1,                                 {x: leftMargin, y: pageHeight - topMargin - 20 - 1*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo09Page.drawText('Days in the U.S. in ' + String(this.taxYear-2) + ': ' + sessionStorage.daysInUSTY2,                                 {x: leftMargin, y: pageHeight - topMargin - 20 - 2*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo09Page.drawText('Days in ' + this.taxYear + ' you claim you can exclude (substantial presence test): ' + sessionStorage.exemptCount, {x: leftMargin, y: pageHeight - topMargin - 20 - 3*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          this.includedFilenames.push('PersInfo09') }
        //PersInfo10 - Rest of Part I
        if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.exemptDaysFJMQVisa  == 'Yes') {
          await this.addPDFPage(this.PGFilenames.PersInfo10) }
        //PersInfo11 - Slides for Part III
        if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.exemptDaysFJMQSVisa == 'Yes') {
          await this.addPDFPage(this.PGFilenames.PersInfo11) }
        //PersInfo12 - Slides for Part II
        if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.exemptDaysJQNSVisa  == 'Yes') {
          await this.addPDFPage(this.PGFilenames.PersInfo12) }


        // SPOUSE
        //Spouse01 - slides to enter spouse name/info
        if ( (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'Yes' && sessionStorage.spouseFilingType == 'Separate') 
          || (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No'  && sessionStorage.spouseTaxResidency == 'Resident'    && sessionStorage.spouseFilingType == 'Separate' && sessionStorage.spouseSSNITIN == 'Yes') 
          || (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No'  && sessionStorage.spouseTaxResidency == 'Dual Status' && sessionStorage.spouseFilingType == 'Separate' && sessionStorage.spouseSSNITIN == 'Yes') 
          || (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No'  && sessionStorage.spouseTaxResidency == 'Nonresident' && sessionStorage.spouseSSNITIN == 'Yes') ) {
          await this.addPDFPage(this.PGFilenames.Spouse01) }
        //Spouse02 - slides to NOT enter spouse name/info
        if (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No' && sessionStorage.spouseTaxResidency == 'Nonresident' && sessionStorage.spouseSSNITIN == 'No') {
          await this.addPDFPage(this.PGFilenames.Spouse02) }
        //Spouse03 - Slide to say will tell you how to prepare spouse form 8843, included after your return is prepared and filed
        if (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No' && sessionStorage.spouseTaxResidency == 'Nonresident' && sessionStorage.spousePresentFJMQVisa == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Spouse03) }


        // DEPENDENTS
        //Depen01 - Slide that no requirements for this child
        if (this.countriesCAMXINKR.includes(sessionStorage.taxResidentCountry) && sessionStorage.childNotPresent == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Depen01) }
        //Depen02 - Slide to do personalized guide for child
        if (this.countriesCAMXINKR.includes(sessionStorage.taxResidentCountry) && sessionStorage.childPresentUS == 'Yes')  {
          await this.addPDFPage(this.PGFilenames.Depen02) }
        //Depen03 - Slides for claiming a dependent for this child
        if (this.countriesCAMXINKR.includes(sessionStorage.taxResidentCountry) && sessionStorage.childBornUS == 'Yes')     {
          await this.addPDFPage(this.PGFilenames.Depen03) }  
        //Depen04 - slide to say for this child to dependents on tax return, do guide for child to see if they need to file a form 8843
        //if (sessionStorage.children == 'Yes' && sessionStorage.childPresentUS == 'Yes') { await this.addPDFPage(this.PGFilenames.Depen04) }
        //Depen05 - slide to say for this child no claim dependents, no filing obligations for them.
        //if (sessionStorage.children == 'Yes' && sessionStorage.childNotPresentUS == 'Yes') { await this.addPDFPage(this.PGFilenames.Depen05) }
        
        
        // STANDARD DEDUCTION

        //StdDed01 - Slides to choose standard deduction
        if ((sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') && sessionStorage.taxResidentCountry == 'India') {
          await this.addPDFPage(this.PGFilenames.StdDed01) }


        // WAGES
        //Wage01 - Wage starting page
        if (sessionStorage.incomeW2 == 'Yes' || sessionStorage.income1042S19 == 'Yes' || sessionStorage.income1042S20 == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Wage01) }
        //Wage02 - Pages to enter a W-2
        if (sessionStorage.incomeW2 == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Wage02) }
        //Wage03 - Pages to enter a 1042-S for wages 
        if (sessionStorage.income1042S19 == 'Yes' || sessionStorage.income1042S20 == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Wage03) } 
        //Wage01a - Treaty Benefit wage analysis
        if (sessionStorage.TB_Wages == "Yes") {
          const TBWagesPage = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          TBWagesPage.drawText("Treaty Benefits Analysis for Wages", {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});

          TBWagesPage.drawText('Treaty Information', {x: leftMargin, y: pageHeight - topMargin - 30 - 1*lineHeight, size: bodyFontSize + 2, font: timesRomanBoldFont,});
          const TBWagesSec1Text = JSON.parse(sessionStorage.getItem('TB_MatchStatusWagesSec1'))
          TBWagesPage.drawText(TBWagesSec1Text[0], {x: leftMargin,  y: pageHeight - topMargin - 30 - 3*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
          TBWagesPage.drawText(TBWagesSec1Text[1], {x: leftMargin,  y: pageHeight - topMargin - 30 - 4*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
          TBWagesPage.drawText(TBWagesSec1Text[2], {x: leftMargin,  y: pageHeight - topMargin - 30 - 5*lineHeight, size: bodyFontSize,     font: timesRomanFont,});

          TBWagesPage.drawText('My Information',       {x: leftMargin,       y: pageHeight - topMargin - 30 - 8*lineHeight,  size: bodyFontSize + 2, font: timesRomanBoldFont,});
          TBWagesPage.drawText('Income Document Type', {x: leftMargin,       y: pageHeight - topMargin - 30 - 10*lineHeight, size: bodyFontSize,     font: timesRomanBoldFont,});
          TBWagesPage.drawText('Income Amount',        {x: leftMargin + 125, y: pageHeight - topMargin - 30 - 10*lineHeight, size: bodyFontSize,     font: timesRomanBoldFont,});
          const TBWagesSec2Text = JSON.parse(sessionStorage.getItem('TB_MatchStatusWagesSec2'))
          for (i = 0; i < TBWagesSec2Text.length/2; i++) {
            TBWagesPage.drawText(TBWagesSec2Text[2*i],             {x: leftMargin,       y: pageHeight - topMargin - 30 - 10*lineHeight - (i + 1)*lineHeight, size: bodyFontSize, font: timesRomanFont,});
            TBWagesPage.drawText(String(TBWagesSec2Text[2*i + 1]), {x: leftMargin + 125, y: pageHeight - topMargin - 30 - 10*lineHeight - (i + 1)*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          }
          if (sessionStorage.TB_MatchStatusWages == "No") {
            const TBWagesSec3Text = JSON.parse(sessionStorage.getItem('TB_MatchStatusWagesSec3'))
            if (sessionStorage.TB_MatchStatusWagesSec3 != "[]") {
              TBWagesPage.drawText('TaxSlayer Modified Entries',  {x: leftMargin,       y: pageHeight - topMargin - 30 - 12*lineHeight - (i + 1)*lineHeight, size: bodyFontSize + 2, font: timesRomanBoldFont,});
              TBWagesPage.drawText('Where to enter',              {x: leftMargin,       y: pageHeight - topMargin - 30 - 14*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanBoldFont,});
              TBWagesPage.drawText('Amount to enter',             {x: leftMargin + 300, y: pageHeight - topMargin - 30 - 14*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanBoldFont,});
              TBWagesPage.drawText(TBWagesSec3Text[0],           {x: leftMargin,       y: pageHeight - topMargin - 30 - 15*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
              TBWagesPage.drawText(String(TBWagesSec3Text[1]),   {x: leftMargin + 300, y: pageHeight - topMargin - 30 - 15*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
              if (TBWagesSec3Text.length == 4) {
                TBWagesPage.drawText(TBWagesSec3Text[2],         {x: leftMargin,       y: pageHeight - topMargin - 30 - 16*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
                TBWagesPage.drawText(String(TBWagesSec3Text[3]), {x: leftMargin + 300, y: pageHeight - topMargin - 30 - 16*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
              }
            }
          }
          this.includedFilenames.push('Wage01a')
        }

        //Wage05 - Pages to enter schedule OI info, when treaty benefits match
        if (sessionStorage.income1042S20 == 'Yes' && sessionStorage.TB_MatchStatusWages == 'Yes')  {
          await this.addPDFPage(this.PGFilenames.Wage05) }
        //Wage06 - Pages to enter schedule OI info, when treaty benefits match, extra $1 for efile
        if (sessionStorage.income1042S19 == 'Yes' && sessionStorage.TB_MatchStatusWages == 'Yes' && !this.wageCountriesList.includes(sessionStorage.taxResidentCountry)) {
          await this.addPDFPage(this.PGFilenames.Wage06) }
        //Wage07 - Pages to enter schedule OI info, when treaty benefits match, warnings about loss of treaty benefits past 2 years, how to enter without claiming treaty benefits, $1 to efile.
        if (sessionStorage.income1042S19 == 'Yes' && sessionStorage.TB_MatchStatusWages == 'Yes' && this.wageCountriesList.includes(sessionStorage.taxResidentCountry))  {
          await this.addPDFPage(this.PGFilenames.Wage07) }
        //Wage08 - Pages to enter schedule OI info, when treaty benefits do not match
        if ((sessionStorage.income1042S20 == 'Yes' || sessionStorage.incomeW2 == 'Yes')
          && (sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student' || sessionStorage.visaType == 'M-1 Student' || sessionStorage.visaType == 'Q-1 Student')
          && sessionStorage.TB_MatchStatusWages == 'No') {
            await this.addPDFPage(this.PGFilenames.Wage08)
          }
        //Wage09 - Pages to enter schedule OI info, when treaty benefits do not match, extra $1 for efile
        if ((sessionStorage.income1042S19 == 'Yes' || sessionStorage.incomeW2 == 'Yes')
          && (this.J1NonStudentTypes.includes(sessionStorage.visaType) || sessionStorage.visaType == 'Q-1 Non-Student')
          && sessionStorage.TB_MatchStatusWages == 'No'
          && !this.wageCountriesList.includes(sessionStorage.taxResidentCountry)) { 
          await this.addPDFPage(this.PGFilenames.Wage09) }
        //Wage10 - Pages to enter schedule OI info, when treaty benefits match,warnings about loss of treaty benefits past 2 years, how to enter without claiming treaty benefits, $1 to efile.
        if ((sessionStorage.income1042S19 == 'Yes' || sessionStorage.incomeW2 == 'Yes')
          && (this.J1NonStudentTypes.includes(sessionStorage.visaType) || sessionStorage.visaType == 'Q-1 Non-Student')
          && sessionStorage.TB_MatchStatusWages == 'No'
          && this.wageCountriesList.includes(sessionStorage.taxResidentCountry)) {
          await this.addPDFPage(this.PGFilenames.Wage10) }
        
        
        // SHOLARSHIPS & FELLOWSHIPS

        //SchFell001 - Page for csbsju
        if ( sessionStorage.urlRefLoc == 'csbsju') {
          await this.addPDFPage(this.PGFilenames.SchFell001) }        
        //SchFell01 - Scholarship / Fellowship starting page
        if ( sessionStorage.income1042S16 == 'Yes' || sessionStorage.incomeScholarship == 'Yes') {
          await this.addPDFPage(this.PGFilenames.SchFell01) }
        //SchFell01a - Scholarship / Fellowship treaty benefits analysis results
        if (sessionStorage.TB_Scholarship == "Yes") {
          const TBScholarshipPage = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          TBScholarshipPage.drawText("Treaty Benefits Analysis for Scholarships and Fellowships", {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});

          TBScholarshipPage.drawText('Treaty Information',       {x: leftMargin, y: pageHeight - topMargin - 30 - 1*lineHeight, size: bodyFontSize + 2, font: timesRomanBoldFont,});
          const TBScholarshipSec1Text = JSON.parse(sessionStorage.getItem('TB_MatchStatusScholarshipSec1'))
          TBScholarshipPage.drawText(TBScholarshipSec1Text[0], {x: leftMargin, y: pageHeight - topMargin - 30 - 3*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
          TBScholarshipPage.drawText(TBScholarshipSec1Text[1], {x: leftMargin, y: pageHeight - topMargin - 30 - 4*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
          TBScholarshipPage.drawText(TBScholarshipSec1Text[2], {x: leftMargin, y: pageHeight - topMargin - 30 - 5*lineHeight, size: bodyFontSize,     font: timesRomanFont,});

          TBScholarshipPage.drawText('My Information',       {x: leftMargin,       y: pageHeight - topMargin - 30 - 8*lineHeight,  size: bodyFontSize + 2, font: timesRomanBoldFont,});
          TBScholarshipPage.drawText('Income Document Type', {x: leftMargin,       y: pageHeight - topMargin - 30 - 10*lineHeight, size: bodyFontSize,     font: timesRomanBoldFont,});
          TBScholarshipPage.drawText('Income Amount',        {x: leftMargin + 125, y: pageHeight - topMargin - 30 - 10*lineHeight, size: bodyFontSize,     font: timesRomanBoldFont,});
          const TBScholarshipSec2Text = JSON.parse(sessionStorage.getItem('TB_MatchStatusScholarshipSec2'))
          for (i = 0; i < TBScholarshipSec2Text.length/2; i++) {
            TBScholarshipPage.drawText(TBScholarshipSec2Text[2*i],             {x: leftMargin,       y: pageHeight - topMargin - 30 - 10*lineHeight - (i + 1)*lineHeight, size: bodyFontSize, font: timesRomanFont,});
            TBScholarshipPage.drawText(String(TBScholarshipSec2Text[2*i + 1]), {x: leftMargin + 125, y: pageHeight - topMargin - 30 - 10*lineHeight - (i + 1)*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          }
          if (sessionStorage.TB_MatchStatusScholarship == "No") {
            const TBScholarshipSec3Text = JSON.parse(sessionStorage.getItem('TB_MatchStatusScholarshipSec3'))
            if (sessionStorage.TB_MatchStatusScholarshipSec3 != "[]") {
              TBScholarshipPage.drawText('TaxSlayer Modified Entries',        {x: leftMargin,       y: pageHeight - topMargin - 30 - 12*lineHeight - (i + 1)*lineHeight, size: bodyFontSize + 2, font: timesRomanBoldFont,});
              TBScholarshipPage.drawText('Where to enter',                    {x: leftMargin,       y: pageHeight - topMargin - 30 - 14*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanBoldFont,});
              TBScholarshipPage.drawText('Amount to enter',                   {x: leftMargin + 300, y: pageHeight - topMargin - 30 - 14*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanBoldFont,});
              TBScholarshipPage.drawText(TBScholarshipSec3Text[0],            {x: leftMargin,       y: pageHeight - topMargin - 30 - 15*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
              TBScholarshipPage.drawText(String(TBScholarshipSec3Text[1]),    {x: leftMargin + 300, y: pageHeight - topMargin - 30 - 15*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
              if (TBScholarshipSec3Text.length == 4) {
                TBScholarshipPage.drawText(TBScholarshipSec3Text[2],          {x: leftMargin,       y: pageHeight - topMargin - 30 - 16*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
                TBScholarshipPage.drawText(String(TBScholarshipSec3Text[3]),  {x: leftMargin + 300, y: pageHeight - topMargin - 30 - 16*lineHeight - (i + 1)*lineHeight, size: bodyFontSize,     font: timesRomanFont,});
              }
            }
          }
          this.includedFilenames.push('SchFell01a')
        }


        //SchFell02 -   pages to enter a scholarship or fellowship reported on a 1042-S, taxable
        //SchFell03 -   Pages to enter treaty benefit for scholarships, entering 1042-s and schedule OI info
        //SchFell04 -   Pages to enter treaty benefit for scholarships entering 1042-s and schedule OI info, with special limit on treaty benefits
        //SchFell04a -  Pages to enter treaty benefit for scholarships entering 1042-s and schedule OI info, with special limit on treaty benefits
        //SchFell05 -   Pages to enter a scholarship or fellowship not on a 1042-S, taxable
        //SchFell06 -   Pages to enter treaty benefit for scholarships (enter just on schedule OI)
        //SchFell07 -   Pages to enter treaty benefit for scholarships with special limit on treaty benefits - DELETE
        //SchFell07a -  Pages to enter treaty benefit for scholarships with special limit on treaty benefits
        //SchFell08 -   Pages to enter a scholarship or fellowship both on and not on a 1042-S, taxable
        //SchFell09 -   Pages to enter treaty benefit for scholarships, entering 1042-s and combined schedule OI info
        //SchFell10 -   Pages to enter treaty benefit for scholarships, entering 1042-s and combined schedule OI info with special limit on treaty benefits - DELETE
        //SchFell10a -  Pages to enter treaty benefit for scholarships, entering 1042-s and combined schedule OI info with special limit on treaty benefits 
        if ( sessionStorage.income1042S16 == 'Yes' && sessionStorage.incomeScholarship == 'No'   && sessionStorage.TB_Scholarship == 'No'                                                                                                                                    ) {
          await this.addPDFPage(this.PGFilenames.SchFell02) }
        if ( sessionStorage.income1042S16 == 'Yes' && sessionStorage.incomeScholarship == 'No'   && sessionStorage.TB_Scholarship == 'Yes' && sessionStorage.taxResidentCountry != 'Commonwealth of Independent States'                                                      ) {
          await this.addPDFPage(this.PGFilenames.SchFell03) }
        if ( sessionStorage.income1042S16 == 'Yes' && sessionStorage.incomeScholarship == 'No'   && sessionStorage.TB_Scholarship == 'Yes' && sessionStorage.taxResidentCountry == 'Commonwealth of Independent States' && sessionStorage.TB_MatchStatusScholarship == 'Yes' ) {
          await this.addPDFPage(this.PGFilenames.SchFell04) }
        if ( sessionStorage.income1042S16 == 'Yes' && sessionStorage.incomeScholarship == 'No'   && sessionStorage.TB_Scholarship == 'Yes' && sessionStorage.taxResidentCountry == 'Commonwealth of Independent States' && sessionStorage.TB_MatchStatusScholarship == 'No'  ) {
          await this.addPDFPage(this.PGFilenames.SchFell04a) }
        if ( sessionStorage.income1042S16 == 'No'  && sessionStorage.incomeScholarship == 'Yes'  && sessionStorage.TB_Scholarship == 'No'                                                                                                                                    ) {
          await this.addPDFPage(this.PGFilenames.SchFell05) }
        if ( sessionStorage.income1042S16 == 'No'  && sessionStorage.incomeScholarship == 'Yes'  && sessionStorage.TB_Scholarship == 'Yes' && sessionStorage.taxResidentCountry != 'Commonwealth of Independent States'                                                      ) {
          await this.addPDFPage(this.PGFilenames.SchFell06) }
        //if ( sessionStorage.income1042S16 == 'No'  && sessionStorage.incomeScholarship == 'Yes'  && sessionStorage.TB_Scholarship == 'Yes' && sessionStorage.taxResidentCountry == 'Commonwealth of Independent States' && sessionStorage.TB_MatchStatusScholarship == 'Yes' ) { await this.addPDFPage(this.PGFilenames.SchFell07) }
        if ( sessionStorage.income1042S16 == 'No'  && sessionStorage.incomeScholarship == 'Yes'  && sessionStorage.TB_Scholarship == 'Yes' && sessionStorage.taxResidentCountry == 'Commonwealth of Independent States' && sessionStorage.TB_MatchStatusScholarship == 'No'  ) {
          await this.addPDFPage(this.PGFilenames.SchFell07a) }
        if ( sessionStorage.income1042S16 == 'Yes' && sessionStorage.incomeScholarship == 'Yes'  && sessionStorage.TB_Scholarship == 'No'                                                                                                                                    ) {
          await this.addPDFPage(this.PGFilenames.SchFell08) }
        if ( sessionStorage.income1042S16 == 'Yes' && sessionStorage.incomeScholarship == 'Yes'  && sessionStorage.TB_Scholarship == 'Yes' && sessionStorage.taxResidentCountry != 'Commonwealth of Independent States'                                                      ) {
          await this.addPDFPage(this.PGFilenames.SchFell09) }
        //if ( sessionStorage.income1042S16 == 'Yes' && sessionStorage.incomeScholarship == 'Yes'  && sessionStorage.TB_Scholarship == 'Yes' && sessionStorage.taxResidentCountry == 'Commonwealth of Independent States'  && sessionStorage.TB_MatchStatusScholarship == 'Yes') { await this.addPDFPage(this.PGFilenames.SchFell10) }
        if ( sessionStorage.income1042S16 == 'Yes' && sessionStorage.incomeScholarship == 'Yes'  && sessionStorage.TB_Scholarship == 'Yes' && sessionStorage.taxResidentCountry == 'Commonwealth of Independent States'  && sessionStorage.TB_MatchStatusScholarship == 'No' ) {
          await this.addPDFPage(this.PGFilenames.SchFell10a) }


        // STATE REFUND
        //StateRef01 - Pages to confirm standard deduction last year, nothing to include.  Instructions to redo guide if itemized last year
        if (sessionStorage.stateRefund =='Yes' && ((sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') && sessionStorage.taxResidentCountry == 'India'))  {
          await this.addPDFPage(this.PGFilenames.StateRef01) }
        //StateRef02 - State Refund pages
        if (sessionStorage.stateRefund =='Yes' && !((sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') && sessionStorage.taxResidentCountry == 'India'))  {
          await this.addPDFPage(this.PGFilenames.StateRef02) }

        
        // INTEREST
        //Interest01 - Pages on interest income not taxable
        if ((sessionStorage.income1099INT == 'Yes' || sessionStorage.income1042SInterest == 'Yes') && sessionStorage.incomeINTPayor == 'Bank' && sessionStorage.incomeFedTaxWithheld == 'No')  {
          await this.addPDFPage(this.PGFilenames.Interest01) }
        //Interest02 - Pages on interest income not taxable, reporting tax withheld 
        if ((sessionStorage.income1099INT == 'Yes' || sessionStorage.income1042SInterest == 'Yes') && sessionStorage.incomeINTPayor == 'Bank' && sessionStorage.incomeFedTaxWithheld == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Interest02) }
        //Interest03 - Pages to report interest income, any tax withheld 
        if ((sessionStorage.income1099INT == 'Yes' || sessionStorage.income1042SInterest == 'Yes') && sessionStorage.incomeINTPayor == 'IRS or Other')                                         {
          await this.addPDFPage(this.PGFilenames.Interest03) }


        // DIVIDEND
        //Dividend01 - Intro slide for entering dividends                                           
        if ((sessionStorage.income1099DIV == 'Yes' || sessionStorage.income1042SDividends == 'Yes'))  {
          await this.addPDFPage(this.PGFilenames.Dividend01) }
        //Dividend01a - Entering dividends with 0% tax rate
        if (sessionStorage.TB_Dividend == "Yes") {
          var prevParaLines1: number = 0
          var prevParaLines2: number = 0
          const TB_DividendPage = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          TB_DividendPage.drawText("Dividend Rate Gain And Limit", {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});
          
          TB_DividendPage.drawText('Treaty Rate (%)', {x: leftMargin,           y: pageHeight - topMargin - 30 - 1*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,});
          TB_DividendPage.drawText('Treaty Article',  {x: leftMargin + 100,     y: pageHeight - topMargin - 30 - 1*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,});
          const TB_DividendTable = JSON.parse(sessionStorage.getItem('TB_DividendTable'))
          TB_DividendPage.drawText(String(TB_DividendTable[0]), {x: leftMargin, y: pageHeight - topMargin - 30 - 2*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          TB_DividendPage.drawText(TB_DividendTable[1], {x: leftMargin + 100,   y: pageHeight - topMargin - 30 - 2*lineHeight, size: bodyFontSize, font: timesRomanFont,});

          const TB_DividendText = JSON.parse(sessionStorage.getItem('TB_DividendText'))
          for (i = 0; i < TB_DividendText.length; i++) {
            TB_DividendPage.drawText(TB_DividendText[i], {x: leftMargin,        y: pageHeight - topMargin - 30 - 5*lineHeight - i*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          }

          prevParaLines1 = i

          if (sessionStorage.TB_DividendSpecialNotes != '[]') {
            const TB_DividendSpecialNotes = JSON.parse(sessionStorage.getItem('TB_DividendSpecialNotes'))
            for (i = 0; i < TB_DividendSpecialNotes.length; i++) {
              const words = TB_DividendSpecialNotes[i].split(' ');
              let line = '';
              let result = '';
              for (let n = 0; n < words.length; n++) {
                const testLine = line + words[n] + ' ';
                const testWidth = timesRomanFont.widthOfTextAtSize(testLine, bodyFontSize);
                if (testWidth > (pageWidth - 100)) {
                  result += line + '\n';
                  line = words[n] + ' ';
                } else {
                  line = testLine;
                }
              }
              result += line + '\n';

              const lines = result.split('\n')
              for (i = 0; i < lines.length; i++) {
                TB_DividendPage.drawText(lines[i], {x: leftMargin, y: pageHeight - topMargin - 30 - 5*lineHeight - (i + prevParaLines1 + prevParaLines2)*lineHeight, size: bodyFontSize, font: timesRomanFont,});
              }
              prevParaLines2 = i
            }

          }
          this.includedFilenames.push('Dividend01a')
        }

        //Dividend01b - Slides for entering a 1042-S for dividend income.                                          
        if (sessionStorage.income1042SDividends == 'Yes')  {
          await this.addPDFPage(this.PGFilenames.Dividend01b) }
        //Dividend02 - Entering dividends with 10% tax rate                                            
        if ((sessionStorage.income1099DIV == 'Yes' || sessionStorage.income1042SDividends == 'Yes') &&  sessionStorage.TB_IncomeDIVTreatyRate == '10')  {
          await this.addPDFPage(this.PGFilenames.Dividend02) }
        //Dividend03 - Entering dividends with 15% tax rate
        if ((sessionStorage.income1099DIV == 'Yes' || sessionStorage.income1042SDividends == 'Yes') &&  sessionStorage.TB_IncomeDIVTreatyRate == '15')  {
          await this.addPDFPage(this.PGFilenames.Dividend03) }
        //Dividend04 - Entering dividends with 20% tax rate
        if ((sessionStorage.income1099DIV == 'Yes' || sessionStorage.income1042SDividends == 'Yes') &&  sessionStorage.TB_IncomeDIVTreatyRate == '20')  {
          await this.addPDFPage(this.PGFilenames.Dividend04) }
        //Dividend04a - Entering dividends with 25% tax rate
        if ((sessionStorage.income1099DIV == 'Yes' || sessionStorage.income1042SDividends == 'Yes') &&  sessionStorage.TB_IncomeDIVTreatyRate == '25')  {
          await this.addPDFPage(this.PGFilenames.Dividend04a) }
        //Dividend05 - Entering dividends with 30% tax rate
        if ((sessionStorage.income1099DIV == 'Yes' || sessionStorage.income1042SDividends == 'Yes') && (sessionStorage.TB_IncomeDIVTreatyRate == '30' || sessionStorage.TB_Dividend == 'No'))  {
          await this.addPDFPage(this.PGFilenames.Dividend05) }


        // CAPITAL GAINS
        //CapGain01 - Treaty benefits output page
        if (sessionStorage.income1099B == 'Yes' && sessionStorage.TB_IncomeCapGainTreatyRate == '0')  {

          if (sessionStorage.TB_CapGain == 'Yes') {
            var prevParaLines1: number = 0
            var prevParaLines2: number = 0
            const TB_CapGainPage = this.PGPDFDoc.addPage([pageWidth, pageHeight])
            TB_CapGainPage.drawText("Capital Gain Tax Rate and Limit", {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});
            
            TB_CapGainPage.drawText('Treaty Rate (%)', {x: leftMargin,          y: pageHeight - topMargin - 30 - 1*lineHeight,                size: bodyFontSize, font: timesRomanBoldFont,});
            TB_CapGainPage.drawText('Treaty Article',  {x: leftMargin + 100,    y: pageHeight - topMargin - 30 - 1*lineHeight,                size: bodyFontSize, font: timesRomanBoldFont,});
            const TB_CapGainTable = JSON.parse(sessionStorage.getItem('TB_CapGainTable'))
            TB_CapGainPage.drawText(String(TB_CapGainTable[0]), {x: leftMargin, y: pageHeight - topMargin - 30 - 2*lineHeight,                size: bodyFontSize, font: timesRomanFont,});
            TB_CapGainPage.drawText(TB_CapGainTable[1], {x: leftMargin + 100,   y: pageHeight - topMargin - 30 - 2*lineHeight,                size: bodyFontSize, font: timesRomanFont,});

            const TB_CapGainText = JSON.parse(sessionStorage.getItem('TB_CapGainText'))
            for (i = 0; i < TB_CapGainText.length; i++) {
              TB_CapGainPage.drawText(TB_CapGainText[i], {x: leftMargin,        y: pageHeight - topMargin - 30 - 5*lineHeight - i*lineHeight, size: bodyFontSize, font: timesRomanFont,});
            }

            prevParaLines1 = i

            if (sessionStorage.TB_CapGainSpecialNotes != '[]') {
              const TB_CapGainSpecialNotes = JSON.parse(sessionStorage.getItem('TB_CapGainSpecialNotes'))
              for (i = 0; i < TB_CapGainSpecialNotes.length; i++) {
                const words = TB_CapGainSpecialNotes[i].split(' ');
                let line = '';
                let result = '';
                for (let n = 0; n < words.length; n++) {
                  const testLine = line + words[n] + ' ';
                  const testWidth = timesRomanFont.widthOfTextAtSize(testLine, bodyFontSize);
                  if (testWidth > (pageWidth - 100)) {
                    result += line + '\n';
                    line = words[n] + ' ';
                  } else {
                    line = testLine;
                  }
                }

                const lines = result.split('\n')
                for (i = 0; i < lines.length; i++) {
                  TB_CapGainPage.drawText(lines[i], {x: leftMargin, y: pageHeight - topMargin - 30 - 5*lineHeight - (i + prevParaLines1 + prevParaLines2)*lineHeight, size: bodyFontSize, font: timesRomanFont,});
                }
                prevParaLines2 = i
              }

            }
            this.includedFilenames.push('CapGain01')
          }
        }


        //CapGain02 - Pages on capital gains, >25 transactions, Entering dividends with 0% tax rate
        if (sessionStorage.income1099B == 'Yes' && sessionStorage.TB_IncomeCapGainTreatyRate == '0')  {
          await this.addPDFPage(this.PGFilenames.CapGain02) }
        //CapGain03 - Pages on capital gains, >25 transactions, Entering dividends with 30% tax rate
        if (sessionStorage.income1099B == 'Yes' && (sessionStorage.TB_IncomeCapGainTreatyRate == '30' || sessionStorage.TB_CapGain == 'No')) {
          await this.addPDFPage(this.PGFilenames.CapGain03) }


        // IRA
        //IRA401k01 - Slides for entering 1099-R
        if (sessionStorage.income1099R == 'Yes') {
          await this.addPDFPage(this.PGFilenames.IRA401k01) }


        // OTHER INCOME
        //OtherInc01 - Page on contacting us to discuss other income
        if (sessionStorage.incomeOther == 'Yes') {
          await this.addPDFPage(this.PGFilenames.OtherInc01) }


        // OOS INCOME
        //OOSIncome01 - Pages on out of scope income, links to IRS pages to help
        if (sessionStorage.income1099Misc == 'Yes' || sessionStorage.income1099NEC == 'Yes') {
          await this.addPDFPage(this.PGFilenames.OOSIncome01) }


        // ADDITIONAL STATE TAX
        //AddlStTax01 = Pages to confirm standard deduction this year, nothing to include.  Instructions to redo guide if not itemizing this year
        if (sessionStorage.payAddnlStateIncTax == 'Yes'
          && ((sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') && sessionStorage.taxResidentCountry == 'India')) { 
          await this.addPDFPage(this.PGFilenames.AddlStTax01) }
        //AddlStTax02 - This means J-1 Nonstudents, etc. from India, all F/J etc. from other countries - State tax payment pages
        if (sessionStorage.payAddnlStateIncTax == 'Yes' 
          && !((sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') && sessionStorage.taxResidentCountry == 'India')) { 
          await this.addPDFPage(this.PGFilenames.AddlStTax02) }

        
        // CHARITABLE DEDUCTIONS
        //CharDed01 - Pages to confirm standard deduction this year, nothing to include.  Instructions to redo guide if not itemizing this year  
        if (sessionStorage.charityContrib == 'Yes'
          && (sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') 
          && sessionStorage.taxResidentCountry == 'India') { 
          await this.addPDFPage(this.PGFilenames.CharDed01) }
        //CharDed02 - State tax payment pages. This means J-1 Nonstudents, etc. from India, all F/J etc. from other countries
        if (sessionStorage.charityContrib == 'Yes' 
          && !((sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') && sessionStorage.taxResidentCountry == 'India') ) { 
          await this.addPDFPage(this.PGFilenames.CharDed02) }


        // STUDENT LOAN
        //StuLoan01 - Pages for student loan interest Deduction and MN student loan credit
        if (sessionStorage.sLoan1098E == 'Yes' && (sessionStorage.stateLived1 == 'Minnesota' || sessionStorage.stateLived2 == 'Minnesota' || sessionStorage.stateLived3 == 'Minnesota' || sessionStorage.stateLived4 == 'Minnesota' || sessionStorage.stateLived5 == 'Minnesota')) {
          await this.addPDFPage(this.PGFilenames.StuLoan01) }
        //StuLoan02 - Pages for student loan interest Deduction 
        if (sessionStorage.sLoan1098E == 'Yes' && (sessionStorage.stateLived1 != 'Minnesota' && sessionStorage.stateLived2 != 'Minnesota' && sessionStorage.stateLived3 != 'Minnesota' && sessionStorage.stateLived4 != 'Minnesota' && sessionStorage.stateLived5 != 'Minnesota')) {
          await this.addPDFPage(this.PGFilenames.StuLoan02) }


        // STATE
        //State01 - Slide you lived and/or worked in multiple states.  We'll enter each state separately, and then make adjustments.
        if ( sessionStorage.stateLived2  != '' || sessionStorage.stateLived3  != '' || sessionStorage.stateLived4  != '' || sessionStorage.stateLived5  != ''
          || sessionStorage.stateIncome2 != '' || sessionStorage.stateIncome3 != '' || sessionStorage.stateIncome4 != '' || sessionStorage.stateIncome5 != '' ) {
          await this.addPDFPage(this.PGFilenames.State01) }


////////MINNESOTA

        //MN01 - MN Return
//        if (sessionStorage.stateLived1 == 'Minnesota' && sessionStorage.stateIncome1 == 'Yes' && sessionStorage.stateLived2 == '' && sessionStorage.stateLived3 == '' && sessionStorage.stateLived4 == '' && sessionStorage.stateLived5 == '') {
//          await this.addPDFPage(this.PGFilenames.MN01) }

        var livedIncomeMN: boolean = false
        var livedMNNoIncome: boolean = false
        var state1LivedIncomeMN: boolean = false
        var livedIncomeNotMN: boolean = false

        if (( sessionStorage.stateLived1 == 'Minnesota' && sessionStorage.stateIncome1 == 'Yes')
          || (sessionStorage.stateLived2 == 'Minnesota' && sessionStorage.stateIncome2 == 'Yes')
          || (sessionStorage.stateLived3 == 'Minnesota' && sessionStorage.stateIncome3 == 'Yes')
          || (sessionStorage.stateLived4 == 'Minnesota' && sessionStorage.stateIncome4 == 'Yes')
          || (sessionStorage.stateLived5 == 'Minnesota' && sessionStorage.stateIncome5 == 'Yes')) {
          livedIncomeMN = true }

          if (( sessionStorage.stateLived1 == 'Minnesota' && sessionStorage.stateIncome1 == 'No')
          || (sessionStorage.stateLived2 == 'Minnesota' && sessionStorage.stateIncome2 == 'No')
          || (sessionStorage.stateLived3 == 'Minnesota' && sessionStorage.stateIncome3 == 'No')
          || (sessionStorage.stateLived4 == 'Minnesota' && sessionStorage.stateIncome4 == 'No')
          || (sessionStorage.stateLived5 == 'Minnesota' && sessionStorage.stateIncome5 == 'No')) {
          livedMNNoIncome = true }

        if (sessionStorage.stateLived1 == 'Minnesota' && sessionStorage.stateIncome1 == 'Yes') {
          state1LivedIncomeMN = true }
        if ((sessionStorage.stateLived1 != '' && sessionStorage.stateLived1 != 'Minnesota') && sessionStorage.stateIncome1 == 'Yes') {
          livedIncomeNotMN = true }
        if ((sessionStorage.stateLived2 != '' && sessionStorage.stateLived2 != 'Minnesota') && sessionStorage.stateIncome2 == 'Yes') {
          livedIncomeNotMN = true }
        if ((sessionStorage.stateLived3 != '' && sessionStorage.stateLived3 != 'Minnesota') && sessionStorage.stateIncome3 == 'Yes') {
          livedIncomeNotMN = true }
        if ((sessionStorage.stateLived4 != '' && sessionStorage.stateLived4 != 'Minnesota') && sessionStorage.stateIncome4 == 'Yes') {
          livedIncomeNotMN = true }
        if ((sessionStorage.stateLived5 != '' && sessionStorage.stateLived5 != 'Minnesota') && sessionStorage.stateIncome5 == 'Yes') {
          livedIncomeNotMN = true }

        //MN10 - MN intro slide
        if (livedIncomeMN
          || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes"))) {
          await this.addPDFPage(this.PGFilenames.MN10) }

        //MN10a - MN residency summary table (Graham created) - OUS and state visits list, residency result        
        if (livedIncomeMN
          || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes"))) {

          const stateVisistsAnalysisMN = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          stateVisistsAnalysisMN.drawText("MN State Residency Analysis",                                     {x: leftMargin, y: pageHeight - topMargin,                          size: titleFontSize, font: timesRomanBoldFont,});
          
          //States & Incomes
          var lineNo = 3
          stateVisistsAnalysisMN.drawText("States and Income",                                               {x: leftMargin, y: pageHeight - topMargin - 30 - 1*lineHeight,      size: bodyFontSize, font: timesRomanBoldFont,});
          stateVisistsAnalysisMN.drawText(sessionStorage.stateLived1 + ", " + sessionStorage.stateIncome1,   {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++;
          if (sessionStorage.stateLived2 != '') {
            stateVisistsAnalysisMN.drawText(sessionStorage.stateLived2 + ", " + sessionStorage.stateIncome2, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          if (sessionStorage.stateLived3 != '') {
            stateVisistsAnalysisMN.drawText(sessionStorage.stateLived3 + ", " + sessionStorage.stateIncome3, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          if (sessionStorage.stateLived4 != '') {
            stateVisistsAnalysisMN.drawText(sessionStorage.stateLived4 + ", " + sessionStorage.stateIncome4, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          if (sessionStorage.stateLived5 != '') {
            stateVisistsAnalysisMN.drawText(sessionStorage.stateLived5 + ", " + sessionStorage.stateIncome5, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          lineNo = lineNo + 3

          //State Visits      
          stateVisistsAnalysisMN.drawText("Visits",     {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,}); lineNo++; lineNo++
          stateVisistsAnalysisMN.drawText("Entry",      {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          stateVisistsAnalysisMN.drawText("Exit",       {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          stateVisistsAnalysisMN.drawText("Days",       {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          stateVisistsAnalysisMN.drawText("OUS/State",  {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          if (sessionStorage.stateMNAbodeQ == "Yes") {stateVisistsAnalysisMN.drawText("MN Abode",   {x: leftMargin + 230, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})}
          lineNo++

          for (i = 0; i < this.OUSVisitsInTaxYearStartShow.length; i++) {
            stateVisistsAnalysisMN.drawText(this.OUSVisitsInTaxYearStartShow[i].startOf("d").format("MM/DD/YYYY"),                                                       {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMN.drawText(this.OUSVisitsInTaxYearEndShow[i].startOf("d").format("MM/DD/YYYY"),                                                         {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMN.drawText(((this.OUSVisitsInTaxYearEndShow[i].startOf("d").diff(this.OUSVisitsInTaxYearStartShow[i].startOf("d"), "d"))+1).toString(), {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMN.drawText("OUS",                                                                                                                       {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            if (sessionStorage.stateMNAbodeQ == "Yes") {stateVisistsAnalysisMN.drawText(sessionStorage['OUSAbode' + i],                                                  {x: leftMargin + 230, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}) }
            lineNo++
          }

          for (i = 0; i < this.stateVisitsInTaxYear.length; i++) {
            stateVisistsAnalysisMN.drawText(this.stateVisitsInTaxYear[i].entry.format("MM/DD/YYYY").toString(),                                                         {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMN.drawText(this.stateVisitsInTaxYear[i].exit.format("MM/DD/YYYY").toString(),                                                          {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMN.drawText(((this.stateVisitsInTaxYear[i].exit.startOf("d").diff(this.stateVisitsInTaxYear[i].entry.startOf("d"), "d"))+1).toString(), {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMN.drawText(this.stateVisitsInTaxYear[i].selectedState,                                                                                 {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            if (sessionStorage.stateMNAbodeQ == "Yes") {stateVisistsAnalysisMN.drawText(sessionStorage['stateAbode' + i],                                               {x: leftMargin + 230, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})}
            lineNo++
          }

          lineNo = lineNo + 3
          if (sessionStorage.stateResMN == "NR")  {var MNResText = "Nonresident"}
          if (sessionStorage.stateResMN == "PYR") {var MNResText = "Part Year Resident"}
          if (sessionStorage.stateResMN == "FYR") {var MNResText = "Full Year Resident"}
          stateVisistsAnalysisMN.drawText("Based on your entries above, you are a Minnesota " + MNResText + " for tax year " + this.taxYear + ".", {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})

          this.includedFilenames.push('MN State Analysis')

        }

        //MN11 - MN start of resident return
        if (livedIncomeMN && sessionStorage.stateResMN == "FYR") {
          await this.addPDFPage(this.PGFilenames.MN11) }

        //MN12 - MN end of resident return no new address
        if (livedIncomeMN && sessionStorage.stateResMN == "FYR"
          && sessionStorage.certificateRentPaidMN == "No"
          && sessionStorage.payPropTaxMN == 'No'
          && sessionStorage.sLoan1098E == 'No'
          && sessionStorage.onetimePaymentMN == 'No'
          && sessionStorage.newAddressMN == 'No') {
          await this.addPDFPage(this.PGFilenames.MN12) }

        //MN13 - MN resident return adding check for new address
        if (livedIncomeMN && sessionStorage.stateResMN == "FYR"
          && sessionStorage.newAddressMN == 'Yes') {
          await this.addPDFPage(this.PGFilenames.MN13) }

        //MN14 - MN resident return adding MN student loan credit
        if (livedIncomeMN && sessionStorage.stateResMN == "FYR"
          && sessionStorage.sLoan1098E == 'Yes') {
          await this.addPDFPage(this.PGFilenames.MN14) }

        //MN20 - Slides for you don’t need to file a MN return. 
        if (livedMNNoIncome && sessionStorage.stateResMN == "PYR"
        && sessionStorage.certificateRentPaidMN == "No"
        && sessionStorage.payPropTaxMN == 'No' ) {
          await this.addPDFPage(this.PGFilenames.MN20) }

        //MN21 - MN start of PY resident return
        if ((livedIncomeMN && sessionStorage.stateResMN == "PYR")
        || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes"))) {
          await this.addPDFPage(this.PGFilenames.MN21) }

        //MN22 - MN part year return data and new address
        if ((livedIncomeMN && sessionStorage.stateResMN == "PYR" && sessionStorage.newAddressMN == 'Yes')
        || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes") && sessionStorage.newAddressMN == 'Yes')) {
          await this.addPDFPage(this.PGFilenames.MN22) }

        //MN23 - MN part year return data and new address
        if ((livedIncomeMN && sessionStorage.stateResMN == "PYR" && sessionStorage.newAddressMN == 'No')
        || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes") && sessionStorage.newAddressMN == 'No')) {
          await this.addPDFPage(this.PGFilenames.MN23) }

        //MN24 - MN PY resident return adding MN student loan credit
        if (livedIncomeMN && sessionStorage.stateResMN == "PYR"
        && sessionStorage.sLoan1098E == 'Yes') {
          await this.addPDFPage(this.PGFilenames.MN24) }

        //MN31 - MN start of Nonresident return
        if (livedIncomeMN && sessionStorage.stateResMN == "NR") {
          await this.addPDFPage(this.PGFilenames.MN31) }

        //MN32 - MN nonresident return data and new address
        if (livedIncomeMN && sessionStorage.stateResMN == "NR"
          && sessionStorage.newAddressMN == 'Yes') {
          await this.addPDFPage(this.PGFilenames.MN32) }

        //MN33 - MN nonresident return data and no new address
        if (livedIncomeMN && sessionStorage.stateResMN == "NR"
          && sessionStorage.newAddressMN == 'No') {
          await this.addPDFPage(this.PGFilenames.MN33) }

        //MN41 - MN NR/PY schol/fel adjustment entry slides
        if (livedIncomeMN
          && (sessionStorage.stateResMN == "PYR" || sessionStorage.stateResMN == "NR")
          && (sessionStorage.TB_Scholarship == 'Yes' || sessionStorage.income1042S16 == 'Yes')) {
          await this.addPDFPage(this.PGFilenames.MN41) }

        //MN02a - Special scholarship entry slides
        //if (livedIncomeMN && sessionStorage.stateResMN == "PYR"
        // && (sessionStorage.incomeScholarship == 'Yes' || sessionStorage.income1042S16 == 'Yes')) {
        //  await this.addPDFPage(this.PGFilenames.MN02a) }

        //MN42 - MN one time payment slides
        if (livedIncomeMN
          && sessionStorage.onetimePaymentMN == 'Yes') {
          await this.addPDFPage(this.PGFilenames.MN42) }

        //MN44 - MN not eligible for property tax refund slides
        if (livedIncomeMN
          && sessionStorage.stateResMN == "NR"
          && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == 'Yes')) {
          await this.addPDFPage(this.PGFilenames.MN44) }

        //MN45 - MN intro to property tax refund slides
        if ( (livedIncomeMN && (sessionStorage.stateResMN == "PYR" || sessionStorage.stateResMN == "FYR") && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == 'Yes'))
          || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes")) ) {
          await this.addPDFPage(this.PGFilenames.MN45) }

        //MN46 - MN CRP entry slides
        if ( (livedIncomeMN && (sessionStorage.stateResMN == "PYR" || sessionStorage.stateResMN == "FYR") && sessionStorage.certificateRentPaidMN == "Yes")
          || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes")) ) {
          await this.addPDFPage(this.PGFilenames.MN46) }

        //MN47 - MN Statement Prop Tax entry slides
        if ( (livedIncomeMN && (sessionStorage.stateResMN == "PYR" || sessionStorage.stateResMN == "FYR") && sessionStorage.payPropTaxMN == 'Yes')
          || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes")) ) {
          await this.addPDFPage(this.PGFilenames.MN47) }

        //MN48 - MN PY statement slides
        if ( (livedIncomeMN && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == 'Yes'))
          || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes")) ) {
          await this.addPDFPage(this.PGFilenames.MN48) }

        //MN49 - Enter nontaxable income slides
        if ( (livedIncomeMN && (sessionStorage.stateResMN == "PYR" || sessionStorage.stateResMN == "FYR") && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == 'Yes'))
          || (livedMNNoIncome && sessionStorage.stateResMN == "PYR" && (sessionStorage.certificateRentPaidMN == "Yes" || sessionStorage.payPropTaxMN == "Yes")) ) {
          await this.addPDFPage(this.PGFilenames.MN49) }

        //MN50 - Exit MN slides
        if (livedIncomeMN
          && !(sessionStorage.certificateRentPaidMN == "No"
          && sessionStorage.payPropTaxMN == 'No'
          && sessionStorage.sLoan1098E == 'No'
          && sessionStorage.onetimePaymentMN == 'No'
          && sessionStorage.newAddressMN == 'No')) {
          await this.addPDFPage(this.PGFilenames.MN50) }


/*  
        //MN01 - MN Return
        if (sessionStorage.stateLived1 == 'Minnesota' && sessionStorage.stateIncome1 == 'Yes' && sessionStorage.stateLived2 == '' && sessionStorage.stateLived3 == '' && sessionStorage.stateLived4 == '' && sessionStorage.stateLived5 == '') {
          await this.addPDFPage(this.PGFilenames.MN01) }
        //MN02 - Working in MN and another state slides
        if (livedIncomeMN && livedIncomeNotMN) {
          await this.addPDFPage(this.PGFilenames.MN02) }
        //MN02a - Special scholarship entry slides.
        if (livedIncomeMN && livedIncomeNotMN && (sessionStorage.incomeScholarship == 'Yes' || sessionStorage.income1042S16 == 'Yes')) {
            await this.addPDFPage(this.PGFilenames.MN02a) }
        //MN03 - Pages on entering the MN frontline worker payment on federal return, removing it from MN return
        if (sessionStorage.frontlineWorker == 'Yes' && livedIncomeMN) { 
          await this.addPDFPage(this.PGFilenames.MN03) }
        //MN04 - Pages for MN student loan credit
        if (sessionStorage.sLoan1098E == 'Yes' && state1LivedIncomeMN && sessionStorage.stateLived2 == '' && sessionStorage.stateLived3 == '' && sessionStorage.stateLived4 == '' && sessionStorage.stateLived5 == '') {
          await this.addPDFPage(this.PGFilenames.MN04) }
        //MN05 - Pages for MN student loan credit when working in multiple states
        if (sessionStorage.sLoan1098E == 'Yes' && livedIncomeMN && livedIncomeNotMN) {
          await this.addPDFPage(this.PGFilenames.MN05) }
        //MN06 - MN property tax refund instructions for CRP
        if (sessionStorage.certificateRentPaid == 'Yes' && livedIncomeMN) { 
          await this.addPDFPage(this.PGFilenames.MN06) }
        //MN07 - Special adjustment for MN property tax when also doing M1NR
        if (sessionStorage.certificateRentPaid == 'Yes' && livedIncomeMN && livedIncomeNotMN) {
          await this.addPDFPage(this.PGFilenames.MN07) }
        //MN08 - Special note about combining income when multiple people live in property
        if (sessionStorage.payPropTax == 'Yes' && livedIncomeMN) { 
          await this.addPDFPage(this.PGFilenames.MN08) }
*/


////////PENNSYLVANIA
        //PA01 - PA state and local return
        if (( sessionStorage.stateLived1 == 'Pennsylvania' && sessionStorage.stateIncome1 == 'Yes')
          || (sessionStorage.stateLived2 == 'Pennsylvania' && sessionStorage.stateIncome2 == 'Yes')
          || (sessionStorage.stateLived3 == 'Pennsylvania' && sessionStorage.stateIncome3 == 'Yes')
          || (sessionStorage.stateLived4 == 'Pennsylvania' && sessionStorage.stateIncome4 == 'Yes')
          || (sessionStorage.stateLived5 == 'Pennsylvania' && sessionStorage.stateIncome5 == 'Yes')) {
          await this.addPDFPage(this.PGFilenames.PA01) }
        

////////WISCONSIN
        var livedIncomeWI: boolean = false  

        if (( sessionStorage.stateLived1 == 'Wisconsin' && sessionStorage.stateIncome1 == 'Yes') 
          || (sessionStorage.stateLived2 == 'Wisconsin' && sessionStorage.stateIncome2 == 'Yes') 
          || (sessionStorage.stateLived3 == 'Wisconsin' && sessionStorage.stateIncome3 == 'Yes') 
          || (sessionStorage.stateLived4 == 'Wisconsin' && sessionStorage.stateIncome4 == 'Yes') 
          || (sessionStorage.stateLived5 == 'Wisconsin' && sessionStorage.stateIncome5 == 'Yes')) { 
          livedIncomeWI = true }       
          
        //WI01 - WI state return start
        if (livedIncomeWI) {
          await this.addPDFPage(this.PGFilenames.WI01) }

        //WI02 - WI state return undoing standard deduction for WI  
        if (livedIncomeWI
          && ((sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') && sessionStorage.taxResidentCountry == 'India')) { 
          await this.addPDFPage(this.PGFilenames.WI02) }

        //WI03 - WI state return no standard deduction for WI
        if (livedIncomeWI
          && !((sessionStorage.visaType == 'F-1 Student' || sessionStorage.visaType == 'J-1 Student') && sessionStorage.taxResidentCountry == 'India')) { 
          await this.addPDFPage(this.PGFilenames.WI03) }

        //WI04 - WI state return end
        if (livedIncomeWI) {
          await this.addPDFPage(this.PGFilenames.WI04) }
  

////////CALIFORNIA
        var livedIncomeCA: boolean = false
        var livedIncomeNotCA: boolean = false

        if (( sessionStorage.stateLived1 == 'California' && sessionStorage.stateIncome1 == 'Yes')
          || (sessionStorage.stateLived2 == 'California' && sessionStorage.stateIncome2 == 'Yes')
          || (sessionStorage.stateLived3 == 'California' && sessionStorage.stateIncome3 == 'Yes')
          || (sessionStorage.stateLived4 == 'California' && sessionStorage.stateIncome4 == 'Yes')
          || (sessionStorage.stateLived5 == 'California' && sessionStorage.stateIncome5 == 'Yes')) {
          livedIncomeCA = true }

        if ((sessionStorage.stateLived1 != '' && sessionStorage.stateLived1 != 'California') && sessionStorage.stateIncome1 == 'Yes') {
          livedIncomeNotCA = true }
        if ((sessionStorage.stateLived2 != '' && sessionStorage.stateLived2 != 'California') && sessionStorage.stateIncome2 == 'Yes') {
          livedIncomeNotCA = true }
        if ((sessionStorage.stateLived3 != '' && sessionStorage.stateLived3 != 'California') && sessionStorage.stateIncome3 == 'Yes') {
          livedIncomeNotCA = true }
        if ((sessionStorage.stateLived4 != '' && sessionStorage.stateLived4 != 'California') && sessionStorage.stateIncome4 == 'Yes') {
          livedIncomeNotCA = true }
        if ((sessionStorage.stateLived5 != '' && sessionStorage.stateLived5 != 'California') && sessionStorage.stateIncome5 == 'Yes') {
          livedIncomeNotCA = true }

        //CA01 - CA Return
        if (livedIncomeCA) {
          await this.addPDFPage(this.PGFilenames.CA01) }
        //CA02a - Special scholarship entry slides
        if (livedIncomeCA && livedIncomeNotCA && (sessionStorage.incomeScholarship == 'Yes' || sessionStorage.income1042S16 == 'Yes')) { 
          await this.addPDFPage(this.PGFilenames.CA02a) }


////////OTHER STATE
        //OtherState01 - Slide for your state doesn't have a state income tax.
        if ( this.otherState01List.includes(sessionStorage.stateLived1) && sessionStorage.stateIncome1 == 'Yes'
          || this.otherState01List.includes(sessionStorage.stateLived2) && sessionStorage.stateIncome2 == 'Yes'
          || this.otherState01List.includes(sessionStorage.stateLived3) && sessionStorage.stateIncome3 == 'Yes'
          || this.otherState01List.includes(sessionStorage.stateLived4) && sessionStorage.stateIncome4 == 'Yes'
          || this.otherState01List.includes(sessionStorage.stateLived5) && sessionStorage.stateIncome5 == 'Yes') {
          await this.addPDFPage(this.PGFilenames.OtherState01) }

        //OtherState02 - Slide for Tennessee and New Hampshire do not have an income tax on personal income, but they do tax interest and dividends.
        //These are not supported by our tax software.  Please see the relevant dor website for further assistance. 
        if ( (sessionStorage.stateLived1 == 'Tennessee' || sessionStorage.stateLived1 == 'New Hampshire') && sessionStorage.stateIncome1 == 'Yes'
          || (sessionStorage.stateLived2 == 'Tennessee' || sessionStorage.stateLived2 == 'New Hampshire') && sessionStorage.stateIncome2 == 'Yes'
          || (sessionStorage.stateLived3 == 'Tennessee' || sessionStorage.stateLived3 == 'New Hampshire') && sessionStorage.stateIncome3 == 'Yes'
          || (sessionStorage.stateLived4 == 'Tennessee' || sessionStorage.stateLived4 == 'New Hampshire') && sessionStorage.stateIncome4 == 'Yes'
          || (sessionStorage.stateLived5 == 'Tennessee' || sessionStorage.stateLived5 == 'New Hampshire') && sessionStorage.stateIncome5 == 'Yes' ) {
          await this.addPDFPage(this.PGFilenames.OtherState02) }

        //OtherState03 - Single state we can't help, software does, seek the doR for guidance  
        if ( (!this.otherState03List.includes(sessionStorage.stateLived1) && sessionStorage.stateIncome1 == 'Yes')
          || (!this.otherState03List.includes(sessionStorage.stateLived2) && sessionStorage.stateIncome2 == 'Yes')
          || (!this.otherState03List.includes(sessionStorage.stateLived3) && sessionStorage.stateIncome3 == 'Yes')
          || (!this.otherState03List.includes(sessionStorage.stateLived4) && sessionStorage.stateIncome4 == 'Yes')
          || (!this.otherState03List.includes(sessionStorage.stateLived5) && sessionStorage.stateIncome5 == 'Yes')) {
          await this.addPDFPage(this.PGFilenames.OtherState03) }


////////NORTH CAROLINA
        var livedIncomeNC: boolean = false
        var livedIncomeNotNC: boolean = false

        if (( sessionStorage.stateLived1 == 'North Carolina' && sessionStorage.stateIncome1 == 'Yes')
          || (sessionStorage.stateLived2 == 'North Carolina' && sessionStorage.stateIncome2 == 'Yes')
          || (sessionStorage.stateLived3 == 'North Carolina' && sessionStorage.stateIncome3 == 'Yes')
          || (sessionStorage.stateLived4 == 'North Carolina' && sessionStorage.stateIncome4 == 'Yes')
          || (sessionStorage.stateLived5 == 'North Carolina' && sessionStorage.stateIncome5 == 'Yes')) {
          livedIncomeNC = true }

        if ((sessionStorage.stateLived1 != '' && sessionStorage.stateLived1 != 'North Carolina') && sessionStorage.stateIncome1 == 'Yes') {
          livedIncomeNotNC = true }
        if ((sessionStorage.stateLived2 != '' && sessionStorage.stateLived2 != 'North Carolina') && sessionStorage.stateIncome2 == 'Yes') {
          livedIncomeNotNC = true }
        if ((sessionStorage.stateLived3 != '' && sessionStorage.stateLived3 != 'North Carolina') && sessionStorage.stateIncome3 == 'Yes') {
          livedIncomeNotNC = true }
        if ((sessionStorage.stateLived4 != '' && sessionStorage.stateLived4 != 'North Carolina') && sessionStorage.stateIncome4 == 'Yes') {
          livedIncomeNotNC = true }
        if ((sessionStorage.stateLived5 != '' && sessionStorage.stateLived5 != 'North Carolina') && sessionStorage.stateIncome5 == 'Yes') {
          livedIncomeNotNC = true }

/*
        //NC01 - NC Return for only NC state
        if (state1LivedIncomeNC && sessionStorage.stateLived2 == '' && sessionStorage.stateLived3 == '' && sessionStorage.stateLived4 == '' && sessionStorage.stateLived5 == '') {
          await this.addPDFPage(this.PGFilenames.NC01) }
        //NC02 - NC return
        if (livedIncomeNC && (state1LivedIncomeNotNC || state2LivedIncomeNotNC || state3LivedIncomeNotNC || state4LivedIncomeNotNC || state5LivedIncomeNotNC)) {
          await this.addPDFPage(this.PGFilenames.NC02) }
        //NC02a - Special scholarship entry slides
        if (livedIncomeNC
          && (state1LivedIncomeNotNC || state2LivedIncomeNotNC || state3LivedIncomeNotNC || state4LivedIncomeNotNC || state5LivedIncomeNotNC)
          && (sessionStorage.incomeScholarship == 'Yes' || sessionStorage.income1042S16 == 'Yes')) {
          await this.addPDFPage(this.PGFilenames.NC02a) }
*/

        //NC10 - Special scholarship entry slides
        if (livedIncomeNC) {
          await this.addPDFPage(this.PGFilenames.NC10) }
        
        //NC10a - Summary of State responses
        if (livedIncomeNC) {

          const stateVisistsAnalysisNC = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          stateVisistsAnalysisNC.drawText("NC State Residency Analysis",                                     {x: leftMargin, y: pageHeight - topMargin,                          size: titleFontSize, font: timesRomanBoldFont,});
          
          //States & Incomes
          var lineNo = 3
          stateVisistsAnalysisNC.drawText("States and Income",                                               {x: leftMargin, y: pageHeight - topMargin - 30 - 1*lineHeight,      size: bodyFontSize, font: timesRomanBoldFont,});
          stateVisistsAnalysisNC.drawText(sessionStorage.stateLived1 + ", " + sessionStorage.stateIncome1,   {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++;
          if (sessionStorage.stateLived2 != '') {
            stateVisistsAnalysisNC.drawText(sessionStorage.stateLived2 + ", " + sessionStorage.stateIncome2, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          if (sessionStorage.stateLived3 != '') {
            stateVisistsAnalysisNC.drawText(sessionStorage.stateLived3 + ", " + sessionStorage.stateIncome3, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          if (sessionStorage.stateLived4 != '') {
            stateVisistsAnalysisNC.drawText(sessionStorage.stateLived4 + ", " + sessionStorage.stateIncome4, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          if (sessionStorage.stateLived5 != '') {
            stateVisistsAnalysisNC.drawText(sessionStorage.stateLived5 + ", " + sessionStorage.stateIncome5, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          lineNo = lineNo + 3

          //State Visits      
          stateVisistsAnalysisNC.drawText("Visits",     {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,}); lineNo++; lineNo++
          stateVisistsAnalysisNC.drawText("Entry",      {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          stateVisistsAnalysisNC.drawText("Exit",       {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          stateVisistsAnalysisNC.drawText("Days",       {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          stateVisistsAnalysisNC.drawText("OUS/State",  {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          lineNo++

          for (i = 0; i < this.OUSVisitsInTaxYearStartShow.length; i++) {
            stateVisistsAnalysisNC.drawText(this.OUSVisitsInTaxYearStartShow[i].startOf("d").format("MM/DD/YYYY"),                                                       {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisNC.drawText(this.OUSVisitsInTaxYearEndShow[i].startOf("d").format("MM/DD/YYYY"),                                                         {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisNC.drawText(((this.OUSVisitsInTaxYearEndShow[i].startOf("d").diff(this.OUSVisitsInTaxYearStartShow[i].startOf("d"), "d"))+1).toString(), {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisNC.drawText("OUS",                                                                                                                       {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            lineNo++
          }

          for (i = 0; i < this.stateVisitsInTaxYear.length; i++) {
            stateVisistsAnalysisNC.drawText(this.stateVisitsInTaxYear[i].entry.format("MM/DD/YYYY").toString(),                                                         {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisNC.drawText(this.stateVisitsInTaxYear[i].exit.format("MM/DD/YYYY").toString(),                                                          {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisNC.drawText(((this.stateVisitsInTaxYear[i].exit.startOf("d").diff(this.stateVisitsInTaxYear[i].entry.startOf("d"), "d"))+1).toString(), {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisNC.drawText(this.stateVisitsInTaxYear[i].selectedState,                                                                                 {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            lineNo++
          }

          lineNo = lineNo + 3
          if (sessionStorage.stateResNC == "NR")  {var NCResText = "Nonresident"}
          if (sessionStorage.stateResNC == "R") {var NCResText = "Resident"}
          stateVisistsAnalysisNC.drawText("Based on your entries above, you are a North Carolina " + NCResText + " for tax year " + this.taxYear + ".", {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})

          this.includedFilenames.push('NC State Analysis')

        }

        //NC11 - Lived in NC and NC income and was a resident of NC AND also lived and received income from another state
        if (livedIncomeNC && sessionStorage.stateResNC == "R" && livedIncomeNotNC) {
          await this.addPDFPage(this.PGFilenames.NC11) }

        //NC12 - Lived in NC and NC income and was a resident of NC
        if (livedIncomeNC && sessionStorage.stateResNC == "R") {
          await this.addPDFPage(this.PGFilenames.NC12) }

        //NC13 - Lived in NC and NC income and was a resident of NC and did not recieve income from another state
        if (livedIncomeNC && sessionStorage.stateResNC == "R" && !livedIncomeNotNC) {
          await this.addPDFPage(this.PGFilenames.NC13) }

        //NC14 - Lived in NC and NC income and was a resident of NC AND also lived and received income from another state
        if (livedIncomeNC && sessionStorage.stateResNC == "R" && livedIncomeNotNC) {
          await this.addPDFPage(this.PGFilenames.NC14) }

        //NC20 - Lived in NC and NC income and was a NONresident of NC
        if (livedIncomeNC && sessionStorage.stateResNC == "NR") {
          await this.addPDFPage(this.PGFilenames.NC20) }

        //NC02a - Lived in NC and NC income and was a resident of NC AND also lived and received income from another state AND also had a scholarship or fellowship 
        if (livedIncomeNC && livedIncomeNotNC && (sessionStorage.income1042S16 == "Yes" || sessionStorage.incomeScholarship == "Yes")) {
          await this.addPDFPage(this.PGFilenames.NC02a) }

        //NC21 - Lived in NC and NC income and was a NONresident of NC
        if (livedIncomeNC && sessionStorage.stateResNC == "NR") {
          await this.addPDFPage(this.PGFilenames.NC21) }


////////MARYLAND
        var livedIncomeMD: boolean = false
        var livedIncomeNotMD: boolean = false

        if (( sessionStorage.stateLived1 == 'Maryland' && sessionStorage.stateIncome1 == 'Yes')
          || (sessionStorage.stateLived2 == 'Maryland' && sessionStorage.stateIncome2 == 'Yes')
          || (sessionStorage.stateLived3 == 'Maryland' && sessionStorage.stateIncome3 == 'Yes')
          || (sessionStorage.stateLived4 == 'Maryland' && sessionStorage.stateIncome4 == 'Yes')
          || (sessionStorage.stateLived5 == 'Maryland' && sessionStorage.stateIncome5 == 'Yes')) {
          livedIncomeMD = true }

        if ((sessionStorage.stateLived1 != '' && sessionStorage.stateLived1 != 'Maryland') && sessionStorage.stateIncome1 == 'Yes') {
          livedIncomeNotMD = true }
        if ((sessionStorage.stateLived2 != '' && sessionStorage.stateLived2 != 'Maryland') && sessionStorage.stateIncome2 == 'Yes') {
          livedIncomeNotMD = true }
        if ((sessionStorage.stateLived3 != '' && sessionStorage.stateLived3 != 'Maryland') && sessionStorage.stateIncome3 == 'Yes') {
          livedIncomeNotMD = true }
        if ((sessionStorage.stateLived4 != '' && sessionStorage.stateLived4 != 'Maryland') && sessionStorage.stateIncome4 == 'Yes') {
          livedIncomeNotMD = true }
        if ((sessionStorage.stateLived5 != '' && sessionStorage.stateLived5 != 'Maryland') && sessionStorage.stateIncome5 == 'Yes') {
          livedIncomeNotMD = true }


        //MD10 - MD Intro Slide
        if (livedIncomeMD) {
          await this.addPDFPage(this.PGFilenames.MD10) }

        //MD10a - Summary of State responses
        if (livedIncomeMD) {

          const stateVisistsAnalysisMD = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          stateVisistsAnalysisMD.drawText("MD State Residency Analysis",                                     {x: leftMargin, y: pageHeight - topMargin,                          size: titleFontSize, font: timesRomanBoldFont,});
          
          //States & Incomes
          var lineNo = 3
          stateVisistsAnalysisMD.drawText("States and Income",                                               {x: leftMargin, y: pageHeight - topMargin - 30 - 1*lineHeight,      size: bodyFontSize, font: timesRomanBoldFont,});
          stateVisistsAnalysisMD.drawText(sessionStorage.stateLived1 + ", " + sessionStorage.stateIncome1,   {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++;
          if (sessionStorage.stateLived2 != '') {
            stateVisistsAnalysisMD.drawText(sessionStorage.stateLived2 + ", " + sessionStorage.stateIncome2, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          if (sessionStorage.stateLived3 != '') {
            stateVisistsAnalysisMD.drawText(sessionStorage.stateLived3 + ", " + sessionStorage.stateIncome3, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          if (sessionStorage.stateLived4 != '') {
            stateVisistsAnalysisMD.drawText(sessionStorage.stateLived4 + ", " + sessionStorage.stateIncome4, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          if (sessionStorage.stateLived5 != '') {
            stateVisistsAnalysisMD.drawText(sessionStorage.stateLived5 + ", " + sessionStorage.stateIncome5, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++ }
          lineNo = lineNo + 3

          //State Visits      
          stateVisistsAnalysisMD.drawText("Visits",     {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,}); lineNo++; lineNo++
          stateVisistsAnalysisMD.drawText("Entry",      {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          stateVisistsAnalysisMD.drawText("Exit",       {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          stateVisistsAnalysisMD.drawText("Days",       {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          stateVisistsAnalysisMD.drawText("OUS/State",  {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})
          lineNo++

          for (i = 0; i < this.OUSVisitsInTaxYearStartShow.length; i++) {
            stateVisistsAnalysisMD.drawText(this.OUSVisitsInTaxYearStartShow[i].startOf("d").format("MM/DD/YYYY"),                                                       {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(this.OUSVisitsInTaxYearEndShow[i].startOf("d").format("MM/DD/YYYY"),                                                         {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(((this.OUSVisitsInTaxYearEndShow[i].startOf("d").diff(this.OUSVisitsInTaxYearStartShow[i].startOf("d"), "d"))+1).toString(), {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText("OUS",                                                                                                                       {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            lineNo++
          }

          for (i = 0; i < this.stateVisitsInTaxYear.length; i++) {
            stateVisistsAnalysisMD.drawText(this.stateVisitsInTaxYear[i].entry.format("MM/DD/YYYY").toString(),                                                         {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(this.stateVisitsInTaxYear[i].exit.format("MM/DD/YYYY").toString(),                                                          {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(((this.stateVisitsInTaxYear[i].exit.startOf("d").diff(this.stateVisitsInTaxYear[i].entry.startOf("d"), "d"))+1).toString(), {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(this.stateVisitsInTaxYear[i].selectedState,                                                                                 {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            lineNo++
          }

          lineNo = lineNo + 3
          if (sessionStorage.stateResMD == "NR")  {var MDResText = "Nonresident"}
          if (sessionStorage.stateResMD == "R") {var MDResText = "Resident"}
          stateVisistsAnalysisMD.drawText("Based on your entries above, you are a Maryland " + MDResText + " for tax year " + this.taxYear + ".", {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})

          this.includedFilenames.push('MD State Analysis')

        }

        //MD11 - MD do other state first slide
        if (livedIncomeMD && sessionStorage.stateResMD == "R" && livedIncomeNotMD) {
          await this.addPDFPage(this.PGFilenames.MD11) }

        //MD12 - MD resident return slides
        if (livedIncomeMD && sessionStorage.stateResMD == "R") {
          await this.addPDFPage(this.PGFilenames.MD12) }

        //MD13 - MD resident return end slide when not get income from another state
        if (livedIncomeMD && sessionStorage.stateResMD == "R" && !livedIncomeNotMD) {
          await this.addPDFPage(this.PGFilenames.MD13) }

        //MD14 - MD other state tax credit slides, resident return end slides
        if (livedIncomeMD && sessionStorage.stateResMD == "R" && livedIncomeNotMD) {
          await this.addPDFPage(this.PGFilenames.MD14) }

        //MD20 - MD Nonresident return slides 
        if (livedIncomeMD && sessionStorage.stateResMD == "NR") {
          await this.addPDFPage(this.PGFilenames.MD20) }

        //MD21 - MD Nonresident return slides 
        if (livedIncomeMD && sessionStorage.stateResMD == "NR") {
          await this.addPDFPage(this.PGFilenames.MD21) }

        //MD02a - Special scholarship entry slides
        if (livedIncomeMD && (sessionStorage.income1042S16 == "Yes" || sessionStorage.incomeScholarship == "Yes")) {
          await this.addPDFPage(this.PGFilenames.MD02a) }

        //MD22 - MD Nonresident end return slides 
        if (livedIncomeMD && sessionStorage.stateResMD == "NR") {
          await this.addPDFPage(this.PGFilenames.MD21) }










/*
var livedIncomeMD: boolean = false
        if (( sessionStorage.stateLived1 == 'Maryland' && sessionStorage.stateIncome1 == 'Yes') 
          || (sessionStorage.stateLived2 == 'Maryland' && sessionStorage.stateIncome2 == 'Yes') 
          || (sessionStorage.stateLived3 == 'Maryland' && sessionStorage.stateIncome3 == 'Yes') 
          || (sessionStorage.stateLived4 == 'Maryland' && sessionStorage.stateIncome4 == 'Yes') 
          || (sessionStorage.stateLived5 == 'Maryland' && sessionStorage.stateIncome5 == 'Yes')) {
          livedIncomeMD = true
          }

        //MD01a - Summary of State responses
        if (livedIncomeMD == true) {

          const stateVisistsAnalysisMD = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          stateVisistsAnalysisMD.drawText("MD State Residency Analysis",                                     {x: leftMargin, y: pageHeight - topMargin,                          size: titleFontSize, font: timesRomanBoldFont,});
          
          //States & Incomes
          var lineNo = 3
          stateVisistsAnalysisMD.drawText("States and Income",                                               {x: leftMargin, y: pageHeight - topMargin - 30 - 1*lineHeight,      size: bodyFontSize, font: timesRomanBoldFont,});
          stateVisistsAnalysisMD.drawText(sessionStorage.stateLived1 + ", " + sessionStorage.stateIncome1,   {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}); lineNo++;
          if (sessionStorage.stateLived2 != '') {
            stateVisistsAnalysisMD.drawText(sessionStorage.stateLived2 + ", " + sessionStorage.stateIncome2, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}) }; lineNo++
          if (sessionStorage.stateLived3 != '') {
            stateVisistsAnalysisMD.drawText(sessionStorage.stateLived3 + ", " + sessionStorage.stateIncome3, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}) }; lineNo++
          if (sessionStorage.stateLived4 != '') {
            stateVisistsAnalysisMD.drawText(sessionStorage.stateLived4 + ", " + sessionStorage.stateIncome4, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}) }; lineNo++
          if (sessionStorage.stateLived5 != '') {
            stateVisistsAnalysisMD.drawText(sessionStorage.stateLived5 + ", " + sessionStorage.stateIncome5, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,}) }; lineNo++
          lineNo++

          //State Visits
          this.stateVisitsInTaxYear = this.appService.getStateVisits()
          this.OUSVisitsInTaxYearStartShow = this.appService.getOUSVisitsInTaxYearStartShow()
          this.OUSVisitsInTaxYearEndShow = this.appService.getOUSVisitsInTaxYearEndShow()        
          
          stateVisistsAnalysisMD.drawText("Visits",     {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,}); lineNo++; lineNo++
          stateVisistsAnalysisMD.drawText("Entry",      {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
          stateVisistsAnalysisMD.drawText("Exit",       {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
          stateVisistsAnalysisMD.drawText("OUS/State",  {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
          lineNo++

          for (i = 0; i < this.OUSVisitsInTaxYearStartShow.length; i++) {
            stateVisistsAnalysisMD.drawText(this.OUSVisitsInTaxYearStartShow[i].startOf("d").format("MM/DD/YYYY"),                                                       {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(this.OUSVisitsInTaxYearEndShow[i].startOf("d").format("MM/DD/YYYY"),                                                         {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(((this.OUSVisitsInTaxYearEndShow[i].startOf("d").diff(this.OUSVisitsInTaxYearStartShow[i].startOf("d"), "d"))+1).toString(), {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText("OUS",                                                                                                                       {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            lineNo++
          } 

          for (i = 0; i < this.stateVisitsInTaxYear.length; i++) {
            stateVisistsAnalysisMD.drawText(this.stateVisitsInTaxYear[i].entry.format("MM/DD/YYYY").toString(),                                                         {x: leftMargin,       y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(this.stateVisitsInTaxYear[i].exit.format("MM/DD/YYYY").toString(),                                                          {x: leftMargin + 60,  y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(((this.stateVisitsInTaxYear[i].exit.startOf("d").diff(this.stateVisitsInTaxYear[i].entry.startOf("d"), "d"))+1).toString(), {x: leftMargin + 120, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            stateVisistsAnalysisMD.drawText(this.stateVisitsInTaxYear[i].selectedState,                                                                                 {x: leftMargin + 160, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanFont,})
            lineNo++
          }

          lineNo = lineNo + 2
          if (sessionStorage.stateResMD == "R")  {var MDResText = "Resident"}
          if (sessionStorage.stateResMD == "NR") {var MDResText = "Nonresident"}
          stateVisistsAnalysisMD.drawText("Based on your entries above, you are a Maryland " + MDResText, {x: leftMargin, y: pageHeight - topMargin - 30 - lineNo*lineHeight, size: bodyFontSize, font: timesRomanBoldFont,})

          this.includedFilenames.push('MD State Analysis')

        }


        //MD01 - MD Return
        if (livedIncomeMD) {
          await this.addPDFPage(this.PGFilenames.MD01) }

        //MD02a - MD with scholarship
        if (livedIncomeMD && (sessionStorage.incomeScholarship == 'Yes' || sessionStorage.income1042S16 == 'Yes')) {
          await this.addPDFPage(this.PGFilenames.MD02a) }
*/


        // FILING
        //Filing01 - Pages for Fed and state efile
        if (sessionStorage.SSNITIN == 'Yes' && sessionStorage.married == 'No') {
          await this.addPDFPage(this.PGFilenames.Filing01) } 
        //Filing02 - Pages for Fed efile and state paper file
        if (sessionStorage.SSNITIN == 'Yes' && sessionStorage.married == 'Yes') {
          await this.addPDFPage(this.PGFilenames.Filing02) }

      }


      //Add each of the required sections
      if (sessionStorage.nonResidentStatus == 'Nonresident Alien' && sessionStorage.incomeNone == 'Yes') {

        //8843 only slides for those with no SSN/ITIN, manually filling out the form
        if (sessionStorage.SSNITIN == 'No') {

          //PersInfo09 - “Days in the U.S. in 2022 “ & TY, “Days in the U.S. in 2021 “ & TY-1, “Days in the U.S. in 2020 “ & TY-2, “Days in 2022 you claim you can exclude (substantial presence test) “ & Exemptdays count
          const PersInfo09 = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          PersInfo09.drawText('Presence in the U.S.',                                                              {x: leftMargin, y: pageHeight - topMargin,                     size: titleFontSize, font: timesRomanBoldFont,});
          PersInfo09.drawText('Days in the U.S. in ' + String(this.taxYear)   + ': ' + sessionStorage.daysInUSTY,  {x: leftMargin, y: pageHeight - topMargin - 20 - 0*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo09.drawText('Days in the U.S. in ' + String(this.taxYear-1) + ': ' + sessionStorage.daysInUSTY1, {x: leftMargin, y: pageHeight - topMargin - 20 - 1*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo09.drawText('Days in the U.S. in ' + String(this.taxYear-2) + ': ' + sessionStorage.daysInUSTY2, {x: leftMargin, y: pageHeight - topMargin - 20 - 2*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo09.drawText('Days exempt in '      + String(this.taxYear)   + ': ' + sessionStorage.exemptCount, {x: leftMargin, y: pageHeight - topMargin - 20 - 3*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          this.includedFilenames.push('PersInfo09')

          //Only884301 - Include form 8843 pages
          await this.addPDFPage(this.PGFilenames.Only884301)

        }


        //EFILE OF 8843 ONLY
        if (sessionStorage.SSNITIN == 'Yes') {

          // INTRO

          //Intro01 - First general slides
          await this.addPDFPage(this.PGFilenames.Intro01)
          //Intro02 - create acct /log in to TS
          if (sessionStorage.filePrevTaxYear == 'Yes') { 
            await this.addPDFPage(this.PGFilenames.Intro02) }
          //Intro03 - creating TS acct
          if (sessionStorage.filePrevTaxYear == 'No' ) { 
            await this.addPDFPage(this.PGFilenames.Intro03) }
          //Intro04a - General acct / log in slides to slide before personal information name SSN
          await this.addPDFPage(this.PGFilenames.Intro04a)
          //Intro04c - General Intro Slide
          if (sessionStorage.SSNITIN == 'Yes') { 
            await this.addPDFPage(this.PGFilenames.Intro04c) }
          //Intro04d - Remaining general acct / log in slides to filing status nonresident alien
          await this.addPDFPage(this.PGFilenames.Intro04d)
          //Intro05 - slide with married nonresident alien
          if (sessionStorage.married == 'Yes' ) { 
            await this.addPDFPage(this.PGFilenames.Intro05) }
          //Intro06 - slide with single nonresident alien
          if (sessionStorage.married == 'No' )  { 
            await this.addPDFPage(this.PGFilenames.Intro06) }
          //Intro07 - Slide with possible qualifying widow(er) status, email us
          if (sessionStorage.married == 'Yes' && this.countriesCAMXINKR.includes(sessionStorage.taxResidentCountry) && sessionStorage.childBornUS == 'Yes') { await this.addPDFPage(this.PGFilenames.Intro07) }


          // PERSONAL INFO

          //PersInfo01 - Slides for Schedule OI general info
          await this.addPDFPage(this.PGFilenames.PersInfo01)
          
          //PersInfo02 - 
          //Graham Insert slide with AmINRA results for days of presence in TY, TY-1, TY-2
          //“Days in the U.S. in 2022 “ & TY
          //“Days in the U.S. in 2021 “ & TY-1
          //“Days in the U.S. in 2020 “ & TY-2
          const PersInfo02 = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          PersInfo02.drawText('Presence in the U.S.',                                                              {x: leftMargin, y: pageHeight - topMargin,                     size: titleFontSize, font: timesRomanBoldFont,});
          PersInfo02.drawText('Days in the U.S. in ' + String(this.taxYear)   + ': ' + sessionStorage.daysInUSTY,  {x: leftMargin, y: pageHeight - topMargin - 20 - 0*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo02.drawText('Days in the U.S. in ' + String(this.taxYear-1) + ': ' + sessionStorage.daysInUSTY1, {x: leftMargin, y: pageHeight - topMargin - 20 - 1*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo02.drawText('Days in the U.S. in ' + String(this.taxYear-2) + ': ' + sessionStorage.daysInUSTY2, {x: leftMargin, y: pageHeight - topMargin - 20 - 2*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          this.includedFilenames.push('PersInfo02')

          //PersInfo03 - slide on that part of schedule OI
          if (sessionStorage.filePrevTaxYear == 'Yes') { 
            await this.addPDFPage(this.PGFilenames.PersInfo03) }
          //PersInfo04 - slide on that part of schedule OI
          if (sessionStorage.filePrevTaxYear == 'No' ) { 
            await this.addPDFPage(this.PGFilenames.PersInfo04) }
          
          //PersInfo05 - Rest of Schedule OI general info slides, current year travel dates slides
          await this.addPDFPage(this.PGFilenames.PersInfo05)
          //PersInfo06 - slides for 8843 part I info up to visa change
          if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.FJMQVisaType == 'Yes'                                      ) { 
            await this.addPDFPage(this.PGFilenames.PersInfo06) }
          //PersInfo07 - Slide for visa change entry
          if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.FJMQVisaType == 'Yes' && sessionStorage.FJMQChange == 'Yes') { 
            await this.addPDFPage(this.PGFilenames.PersInfo07) }
          //PersInfo08 - Slide for no visa change
          if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.FJMQVisaType == 'Yes' && sessionStorage.FJMQChange == 'No' ) { 
            await this.addPDFPage(this.PGFilenames.PersInfo08) }
          
          //PersInfo09
          //Graham Insert slide with AmINRA results for days of presence in TY, TY-1, TY-2
          //“Days in the U.S. in 2022 “ & TY
          //“Days in the U.S. in 2021 “ & TY-1
          //“Days in the U.S. in 2020 “ & TY-2
          //“Days in 2022 you claim you can exclude (substantial presence test) “ & Exemptdays count
          const PersInfo09 = this.PGPDFDoc.addPage([pageWidth, pageHeight])
          PersInfo09.drawText('Presence in the U.S.',                                                                                                                  {x: leftMargin, y: pageHeight - topMargin,                     size: titleFontSize, font: timesRomanBoldFont,});
          PersInfo09.drawText('Days in the U.S. in ' + String(this.taxYear)   + ': ' + sessionStorage.daysInUSTY,                                                      {x: leftMargin, y: pageHeight - topMargin - 20 - 0*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo09.drawText('Days in the U.S. in ' + String(this.taxYear-1) + ': ' + sessionStorage.daysInUSTY1,                                                     {x: leftMargin, y: pageHeight - topMargin - 20 - 1*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo09.drawText('Days in the U.S. in ' + String(this.taxYear-2) + ': ' + sessionStorage.daysInUSTY2,                                                     {x: leftMargin, y: pageHeight - topMargin - 20 - 2*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          PersInfo09.drawText('Days in '             + String(this.taxYear) + ' you claim you can exclude (substantial presence test): ' + sessionStorage.exemptCount, {x: leftMargin, y: pageHeight - topMargin - 20 - 3*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          this.includedFilenames.push('PersInfo09')

          //PersInfo10 - Rest of Part I
          if (Number(sessionStorage.exemptCount) > 0 && sessionStorage.FJMQVisaType == 'Yes') { 
            await this.addPDFPage(this.PGFilenames.PersInfo10) }
          //PersInfo11 - Slides for Part II
          if (Number(sessionStorage.exemptCount) > 0 && this.persInfo11VisaTypes.includes(sessionStorage.visaType)) { 
            await this.addPDFPage(this.PGFilenames.PersInfo11) }
          //PersInfo12 - Slides for Part III
          if (Number(sessionStorage.exemptCount) > 0 && this.persInfo12VisaTypes.includes(sessionStorage.visaType)) { 
            await this.addPDFPage(this.PGFilenames.PersInfo12) }


          // SPOUSE

          //Spouse01 - slides to enter spouse name/info
          if ( (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'Yes' && sessionStorage.spouseFilingType   == 'Separate') 
            || (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No'  && sessionStorage.spouseTaxResidency == 'Resident'    && sessionStorage.spouseFilingType == 'Separate' && sessionStorage.spouseSSNITIN == 'Yes') 
            || (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No'  && sessionStorage.spouseTaxResidency == 'Dual Status' && sessionStorage.spouseFilingType == 'Separate' && sessionStorage.spouseSSNITIN == 'Yes') 
            || (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No'  && sessionStorage.spouseTaxResidency == 'Nonresident' && sessionStorage.spouseSSNITIN == 'Yes') 
            ) {
              await this.addPDFPage(this.PGFilenames.Spouse01)
            }
          //Spouse02 - slides to NOT enter spouse name/info
          if (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No' && sessionStorage.spouseTaxResidency == 'Nonresident' && sessionStorage.spouseSSNITIN == 'No'  ) {
            await this.addPDFPage(this.PGFilenames.Spouse02)
          }
          //Spouse03 - Slide that repeats what we said on guide – spouse likely needs to file form 8843, have spouse create their own personalized guide to confirm / help with that.
          if (sessionStorage.married == 'Yes' && sessionStorage.spouseCitizen == 'No' && sessionStorage.spouseTaxResidency == 'Nonresident' && sessionStorage.spousePresentFJMQVisa == 'Yes') {
            await this.addPDFPage(this.PGFilenames.Spouse03)
          }

          //Only884302 - Slides for entering $1 to efile
          await this.addPDFPage(this.PGFilenames.Only884302)
  
        }

      }

      //Responses
      //If it reaches the bottom of the first page, start a new page, up to 3 pages
      var endOfPageQ: number;
      endOfPageQ = 0
      const responsesPage = this.PGPDFDoc.addPage([pageWidth, pageHeight]);
      responsesPage.drawText("Appendix A - Summary of Questionnaire Responses", {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});
      for (i = 0; i < this.QandASummary.length; i++) {
        if ((pageHeight - topMargin - 20 - i*lineHeight) < 40) {
          endOfPageQ = i
          break
        }
        else {
          responsesPage.drawText(this.QandASummary[i], {x: leftMargin, y: pageHeight - topMargin - 30 - i*lineHeight, size: bodyFontSize, font: timesRomanFont,});
        }
      }

      if (endOfPageQ == i) {
        const responsesPage2 = this.PGPDFDoc.addPage([pageWidth, pageHeight]);
        responsesPage2.drawText("Appendix A - Summary of Questionnaire Responses (Cont.)", {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});
        var j = 0
        for (i = endOfPageQ; i < this.QandASummary.length; i++) {
          if ((pageHeight - topMargin - 20 - j*lineHeight) < 40) {
            endOfPageQ = i
            break
          } 
          responsesPage2.drawText(this.QandASummary[i], {x: leftMargin, y: pageHeight - topMargin - 30 - j*lineHeight, size: bodyFontSize, font: timesRomanFont,});
          j += 1
        }
      }
        
      //Files used to create guide
      var endOfPageFiles: number
      const filenamesPage = this.PGPDFDoc.addPage([pageWidth, pageHeight]);
      filenamesPage.drawText("Appendix B - Sections Included In This Guide", {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});
      for (i = 0; i < this.includedFilenames.length; i++) {
        if ((pageHeight - topMargin - 20 - i*(lineHeight )) < 40) {
          endOfPageFiles = i
          break
        }
        else {
          filenamesPage.drawText(this.includedFilenames[i], {x: leftMargin, y: pageHeight - topMargin - 30 - i*lineHeight, size: bodyFontSize, font: timesRomanFont,});
        }
      }

      
      if (endOfPageFiles == i) {
        j = 0
        const filenamesPage2 = this.PGPDFDoc.addPage([pageWidth, pageHeight]);
        filenamesPage2.drawText("Appendix B - Sections Included In This Guide (Cont.)", {x: leftMargin, y: pageHeight - topMargin, size: titleFontSize, font: timesRomanBoldFont,});
        for (i = endOfPageFiles; i < this.includedFilenames.length; i++) {
            if ((pageHeight - topMargin - 20 - j*lineHeight) < 40) {
              endOfPageFiles = i
              break
            } 
          filenamesPage2.drawText(this.includedFilenames[i], {x: leftMargin, y: pageHeight - topMargin - 30 - j*lineHeight, size: bodyFontSize, font: timesRomanFont,});
            j += 1
        }
      }

      //Add page footer info
      
      //Printed Date/Time
      
      let currentDate = new Date(); 
      
      const dateTimeText = "Generated on " 
      + currentDate.getFullYear() + "/"
      + (currentDate.getMonth()+1).toString().padStart(2, "0")  + "/" 
      + currentDate.getDate().toString().padStart(2, "0") + ", "  
      + currentDate.getHours().toString().padStart(2, "0") + ":"  
      + currentDate.getMinutes().toString().padStart(2, "0")
      
      pageIndices = this.PGPDFDoc.getPageIndices();
      const pageCount = await this.PGPDFDoc.getPageCount();
      for (const pageIndex of pageIndices) {
        const page = this.PGPDFDoc.getPage(pageIndex);
        var { width, height } = page.getSize();
        
        //Date & Time
        page.drawText(dateTimeText, {x: 15, y: 5, font: timesRomanFont, size: 9});
        
        //Page Numbers
        textWidth = timesRomanFont.widthOfTextAtSize(`${pageIndex + 1}` + ' of ' + pageCount, 10);
        page.drawText(`${pageIndex + 1}` + ' of ' + pageCount, {x: width/2 - textWidth/2, y: 5, font: timesRomanFont, size: 9});

        //Copyright
        let currentYear = currentDate.getFullYear();
        textWidth = timesRomanFont.widthOfTextAtSize('© 2024: The NonResident Tax Help Group', 10);
        page.drawText('© ' + currentYear + ': The NonResident Tax Help Group', {x: width - textWidth - 15, y: 5, font: timesRomanFont, size: 9});
      }

      //Set document properties
      this.PGPDFDoc.setTitle('Personalized Guide')
      this.PGPDFDoc.setAuthor('NoRTH')

      //Save Personalized Guide full PDF file
      var datetime = currentDate.getFullYear() + "_"
        + (currentDate.getMonth()+1).toString().padStart(2, "0")  + "_" 
        + currentDate.getDate().toString().padStart(2, "0") + " "  
        + currentDate.getHours().toString().padStart(2, "0") + "_"  
        + currentDate.getMinutes().toString().padStart(2, "0") + "_" 
        + currentDate.getSeconds().toString().padStart(2, "0");
      
    }

    catch (error) {
      console.log("error")
      this.pdfNotFoundError = true
    }
    finally {
    }    
    
    
    if (!this.pdfNotFoundError) {
      const pdfBytes = await this.PGPDFDoc.save()
      this.saveByteArray('Personal Guide ' + datetime + '.pdf', pdfBytes);
      this.waitForGuide = false;
      this.guideCompleted = true;
      this.activeButton = true

      // Set up navigation
      sessionStorage.navDir = 'forward';
      sessionStorage.prevPage = 'pg-nr08'
      this.router.navigate(['../pg-nr08-complete'], {relativeTo: this.route});

      //analytics.logEvent("Download Personal Guide", parameters: [
      //  "name": name as NSObject,
      //  "full_text": text as NSObject
      //])
    }
    else {
    }

  }




  async addPDFPage(filename: string) {
    const guideSectionPDFBytes = await fetch('../assets/' + filename).then(res => res.arrayBuffer());
    const guideSectionDoc = await PDFDocument.load(guideSectionPDFBytes);
    const guideSectionPage = await this.PGPDFDoc.copyPages(guideSectionDoc, guideSectionDoc.getPageIndices());
    this.includedFilenames.push(filename)
    guideSectionPage.forEach((page) => this.PGPDFDoc.addPage(page));
  }

  saveByteArray(reportName, byte) {
    var blob = new Blob([byte], {type: "application/pdf"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };
 
  onSubmitInactiveButton() {
  }

  onSubmitBack() {
    //var prevPageNow = sessionStorage.prevPage
    sessionStorage.navDir = 'backward'
    sessionStorage.prevPage = 'pg-nr08'

    if (sessionStorage.stateVisitEntries == 'No' && sessionStorage.stateAddlQ == 'No' && sessionStorage.MNIncome != "Yes") {
      this.router.navigate(['../pg-nr06'], {relativeTo: this.route});}
    if (sessionStorage.stateVisitEntries == 'Yes' && sessionStorage.stateAddlQ == 'No' && sessionStorage.MNIncome == "Yes") {
      this.router.navigate(['../pg-nr06b'], {relativeTo: this.route});}
      if (sessionStorage.stateVisitEntries == 'Yes' && sessionStorage.stateAddlQ == 'No' && sessionStorage.MNIncome != "Yes") {
        this.router.navigate(['../pg-nr06a'], {relativeTo: this.route});}  
    if (sessionStorage.stateAddlQ == 'Yes') {
      this.router.navigate(['../pg-nr07'], {relativeTo: this.route});}
    
    //this.router.navigate(['../' + sessionStorage.backpgnr08], {relativeTo: this.route});
  }

  goToNRSite() {
    sessionStorage.navDir = 'forward'
    sessionStorage.prevPage = 'pg-nr08'
    this.router.navigate(['https://www.nrtaxhelp.org/']);
  }
}

