<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR02.jpg" alt='Progress Bar 3 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!--<hr>-->
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - Spouse</h3>
            <!--<p>U.S. income tax law uses marital status as a part of your tax return forms and calculations.</p>-->
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-7 col-lg-7">
            <label for="married">Were you married on 12/31/{{taxYear}}?</label>
            <div class="col-sm-1 col-lg-1"></div>
            <div class="col-sm-12 col-lg-12">
                <p><small>Note:  Married is married!  It doesn't matter who your spouse is or where they are, if you are legally married you are married for tax purposes.</small></p>
            </div>
        </div>
        <div class="col-sm-2 col-lg-2">
            <ng-select id="married" [items]="selectYesNo" [(ngModel)]="marriedAnswer" (ngModelChange)="marriedChange()" placeholder="Select" [clearable]="false"></ng-select>
        </div> 
    </div>
     

    <div *ngIf="showSpouseCitizenQ">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Is your spouse a U.S. citizen, national, or lawful permanent resident?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="spouseCitizen" [items]="selectYesNo" [(ngModel)]="spouseCitizenAnswer" (ngModelChange)="spouseCitizenChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div>
        </div>
    </div>

    <div *ngIf="spouseCitizen">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>Married nonresident aliens must generally file separately - they cannot file a joint return.</p>
                <p>However, a nonresident alien who is married at the end of the tax year to a U.S. citizen or resident alien may elect (choose) 
                    to file a joint return with their spouse. If they do this, they will be treated as U.S. tax residents for the full year.  
                    The advantage of this is that the tax rates are slightly lower, and they are eligible for some additional credits and deductions.  
                    The disadvantage of this is that U.S. tax residents pay U.S. tax on their worldwide income.</p>
            </div>
        </div>
        
        <div class="container">
            <form [formGroup]="formFilingTypeSpouseCitizen" (ngSubmit)="submitFormFilingSpouseCitizen()">
                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <div class="form-group">
                            <label>How do you want to file?</label>
                            <div>
                                <input id="separate" type="radio" value="separate" name="filingTypeSpouseCitizen" formControlName="filingTypeSpouseCitizen" (change)="changeFilingTypeSpouseCitizen($event)">
                                <label for="separate">&nbsp;Separately</label>
                            </div>
                            <div>
                                <input id="joint" type="radio" value="joint" name="filingTypeSpouseCitizen" formControlName="filingTypeSpouseCitizen" (change)="changeFilingTypeSpouseCitizen($event)">
                                <label for="joint">&nbsp;Jointly with spouse</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>


        <div *ngIf="showfilingJointSpouseCitizenText">
            <div class="row">               
                <div class="col-12">
                    <p>The election to choose to file a joint return is out of scope for IRS VITA tax clinics.  While you can use our software to prepare your joint return
                        we cannot help you prepare the election statement, and you will need to file your return on paper. If you want to do this, IRS Publication 519 has
                        instructions about the statement you need to include with your return to make this choice. A knowledgeable tax professional could compare your 
                        options and make a recommendation on your optimal filing status, and may have software that would enable you to make the election and file 
                        electronically, though they would likely charge a fee for their services.</p>
                    </div>
            </div>    
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-custom mb-sm-0 mb-2" onclick="window.location.href='https://www.nrvtap.com/secure/prepreturnr';">Go to resident site</button>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="showSpouseTaxResQ || spouseCitizen==false">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">               
            <div class="col-sm-7 col-lg-7">
                <label>What is your spouse's federal tax residency?</label>
                <div class="col-sm-1 col-lg-1">
                </div>
                <div class="col-sm-12 col-lg-12">  
                    <p><small>If you are not sure, use the "Am I a Nonresident Alien" tool linked directly below 
                        to determine their tax residency (tool will open in a new tab).</small></p>
                </div>                            
                <div class="col-12">
                    <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToaminra()">Am I a NRA Tool</button>
                </div>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="spouseTaxResidency" [items]="selectTaxResidencyStatus" [(ngModel)]="spouseTaxResidency" (ngModelChange)="spouseTaxResidencyChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div>
        </div>
    </div>

    <div *ngIf="spouseTaxResidency=='Resident'">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>Married nonresident aliens must generally file separately - they cannot file a joint return.</p>
                <p>However, a nonresident alien who is married at the end of the tax year to a U.S. citizen or resident alien may elect (choose) 
                    to file a joint return with their spouse. If they do this, they will be treated as U.S. tax residents for the full year.  
                    The advantage of this is that the tax rates are slightly lower, and they are eligible for some additional credits and deductions.  
                    The disadvantage of this is that U.S. tax residents pay U.S. tax on their worldwide income.</p>
            </div>
        </div>
        
        <div class="container">
            <form [formGroup]="formFilingTypeSpouseTaxResidencyResident" (ngSubmit)="submitFormFilingSpouseTaxResidencyResident()">
                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <div class="form-group">
                            <label for="filingTypeSpouseTaxResidencyResident">How do you want to file?</label>
                            <div>
                                <input id="separate" type="radio" value="separate" name="filingTypeSpouseTaxResidencyResident" formControlName="filingTypeSpouseTaxResidencyResident" (change)="changeFilingTypeSpouseTaxResidencyResident($event)">
                                <label for="separate">&nbsp;Separately</label>
                            </div>
                            <div>
                                <input id="joint" type="radio" value="joint" name="filingTypeSpouseTaxResidencyResident" formControlName="filingTypeSpouseTaxResidencyResident" (change)="changeFilingTypeSpouseTaxResidencyResident($event)">
                                <label for="joint">&nbsp;Jointly with spouse</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="spouseTaxResidency=='Dual Status'">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>Married nonresident aliens must generally file separately - they cannot file a joint return.</p>
                <p>However, a nonresident alien who is married at the end of the tax year to an individual who is also U.S. citizen or resident alien at the end 
                    of the year may elect (choose) to file a joint return with their spouse. If they do this, they will be treated as U.S. tax residents for the 
                    full year.  The advantage of this is that the tax rates are slightly lower, and they are eligible for some additional credits and deductions.  
                    The disadvantage of this is that U.S. tax residents pay U.S. tax on their worldwide income.</p>
            </div>
        </div>
        
        <div class="container">
            <form [formGroup]="formFilingTypeSpouseTaxResidencyDS" (ngSubmit)="submitFormFilingSpouseTaxResidencyDS()">
                <div class="row">
                    <div class="col-sm-7 col-lg-7">
                        <div class="form-group">
                            <label for="filingTypeSpouseTaxResidencyDS">How do you want to file?</label>
                            <div>
                                <input id="separate" type="radio" value="separate" name="filingTypeSpouseTaxResidencyDS" formControlName="filingTypeSpouseTaxResidencyDS" (change)="changeFilingTypeSpouseTaxResidencyDS($event)">
                                <label for="separate">&nbsp;Separately</label>
                            </div>
                            <div>
                                <input id="joint" type="radio" value="joint" name="filingTypeSpouseTaxResidencyDS" formControlName="filingTypeSpouseTaxResidencyDS" (change)="changeFilingTypeSpouseTaxResidencyDS($event)">
                                <label for="joint">&nbsp;Jointly with spouse</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>


    <div *ngIf="showOutOfScope">
        <div class="row">               
            <div class="col-12">
                <p>The election to choose to file a joint return is out of scope for IRS VITA tax clinics.  While you can use our software to prepare your joint return
                    we cannot help you prepare the election statement, and you will need to file your return on paper. If you want to do this, IRS Publication 519 has
                    instructions about the statement you need to include with your return to make this choice. A knowledgeable tax professional could compare your 
                    options and make a recommendation on your optimal filing status, and may have software that would enable you to make the election and file 
                    electronically, though they would likely charge a fee for their services.</p>
            </div>
        </div>    
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" onclick="window.location.href='https://www.nrvtap.com/secure/prepreturnr';">Go to resident site</button>
            </div>
        </div>
    </div>

    <div *ngIf="spouseTaxResidency=='Nonresident'">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <p>Was your spouse present in the U.S. at any time during {{taxYear}} on a F/J/M/Q visa?</p>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="spousePresentFJMQVisa" [items]="selectYesNo" [(ngModel)]="spousePresentFJMQVisaAnswer" (ngModelChange)="spousePresentFJMQVisaChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>
        
        <div *ngIf="spousePresentFJMQVisaAnswer=='Yes'">
            <div class="col-sm-7 col-lg-7">
                <div class="col-sm-1 col-lg-1"></div>
                <div class="col-sm-12 col-lg-12">  
                    <p><small>Have your spouse create their own Personalized Guide to help them prepare their tax documents.</small></p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showSpouseSSNITINQ">
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Does your spouse have a Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN)?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="spouseSSNITIN" [items]="selectYesNo" [(ngModel)]="spouseSSNITINAnswer" (ngModelChange)="spouseSSNITINChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div>
        </div>
    </div>

            
    <div *ngIf="showResidentGuideContinueButton">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" onclick="window.location.href='https://www.nrvtap.com/secure/prepreturnr';">Go to resident site</button>
            </div>
        </div>
    </div>

    <div *ngIf="showNextPageContinueButton">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToNextPage()">Continue</button>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a>.</small></p>
        </div>
    </div>


</div>

