<div class="container page-with-footer">
    <div class="row">
        <div class="col-12">
            <h2>Tax Tools</h2>
            <p>These free tools can help you in your tax filing process.</p>
            <hr class="custom-line-silver">
        </div>
    </div>
    <div class="row">
        <div *ngFor="let tool of tools" class="col-sm-6 col-md-4 col-12 mb-2">
            <div class="card card-custom p-4 clickable" (click)="openTool(tool)">
                <div class="card-title">
                    <h4>{{ tool.title }}</h4>
                    <hr>
                </div>
                <div class="card-body p-0">
                    {{ tool.description }}
                </div>
            </div>
        </div>
    </div>
    <!-- <router-outlet></router-outlet> -->
</div>


