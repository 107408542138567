<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR06a.jpg" alt='Progress Bar pg-nr06a'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - U.S. States Visits</h3>
            <hr class="custom-line-silver">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h5>Enter Your Visits to Any U.S. States</h5>
            <p>{{ oneStateText }}</p>
        </div>
    </div>

    <div *ngFor="let OUSVisits of OUSVisitsInTaxYearStartShow; index as ii" class="col-12 mb-2 mt-1">
        <div class="form-row">
            <div class="col-sm-3 mb-sm-0">
                <label *ngIf="ii===0">Start Date</label>
                <div class="input-group" disabled>
                    <input id="1" 
                    class="form-control" 
                    placeholder="{{OUSVisitsInTaxYearStartShow[ii] | date: 'MM/dd/yyyy'}}" 
                    name="e" 
                    ngbDatepicker 
                    #entryToggle="ngbDatepicker" 
                    (dateSelect)="s">
        
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="entryToggle.toggle()" disabled>
                            <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 mb-sm-0">
                <label *ngIf="ii===0">End Date</label>
                <div class="input-group" disabled>
                    <input id="1" 
                    class="form-control" 
                    placeholder="{{OUSVisitsInTaxYearEndShow[ii] | date: 'MM/dd/yyyy'}}" 
                    name="e" 
                    ngbDatepicker 
                    #entryToggle="ngbDatepicker" 
                    (dateSelect)="s">
        
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="entryToggle.toggle()" disabled>
                            <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-sm-2 mb-sm-0">
                <label *ngIf="ii===0">State</label>
                <ng-select id="a"
                    placeholder="OUS"
                    aria-label="State"
                    [clearable]="false"
                    disabled>
                </ng-select>
            </div>
<!--
            <div class="col-sm-2">
                <p>&nbsp;</p>
            </div>
        -->
        </div>
    </div>







    <div *ngIf="oneState">
        <div *ngFor="let oneStateVisits of sortedVisitsInTaxYear; index as iii" class="col-12 mb-2 mt-1">
            <div class="form-row">
                <div class="col-sm-3 mb-sm-0">
                    <!--<label *ngIf="ii===0">Start Date</label>-->
                    <div class="input-group" disabled>
                        <input id="1" 
                        class="form-control" 
                        placeholder="{{sortedVisitsInTaxYear[iii].entry | date: 'MM/dd/yyyy'}}" 
                        name="e" 
                        ngbDatepicker 
                        #entryToggle="ngbDatepicker" 
                        (dateSelect)="s">
            
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="entryToggle.toggle()" disabled>
                                <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 mb-sm-0">
                    <!--<label *ngIf="ii===0">End Date</label>-->
                    <div class="input-group" disabled>
                        <input id="1" 
                        class="form-control" 
                        placeholder="{{sortedVisitsInTaxYear[iii].exit | date: 'MM/dd/yyyy'}}" 
                        name="e" 
                        ngbDatepicker 
                        #entryToggle="ngbDatepicker" 
                        (dateSelect)="s">
            
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="entryToggle.toggle()" disabled>
                                <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-sm-2 mb-sm-0">
                    <label *ngIf="ii===0">State</label>
                    <ng-select id="a"
                        placeholder={{oneStateName}}
                        aria-label="State"
                        [clearable]="false"
                        disabled>
                    </ng-select>
                </div>
                <!--
                <div class="col-sm-2">
                    <p>&nbsp;</p>
                </div>
                -->
            </div>
        </div>


    </div>
    


    <div *ngIf="!oneState" class="row">
        <div class="col-12">
        <div *ngFor="let stateVisit of stateVisits; index as i; trackBy:trackIndex" class="col-12 mb-2 mt-1">
            <app-statevisit [stateVisitId]="stateVisit.id" (stateVisitRemoved)="onStateVisitRemoved($event, i)" (stateVisitChanged)="onStateVisitChanged($event, i)"></app-statevisit>
        </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div *ngIf="!oneState" class="col-12">
                <a class="no-href clickable" (click)="addNewStateVisit()">Add another state visit</a>
            </div>
        </div>
    </div>




    <div *ngIf="!oneState" class="col-10">
        <button class="btn btn-danger float-right" (click)="clearStateVisits()">Clear Visits</button>
    </div>

    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="col-12">
                <p>Total days accounted for in {{ taxYear }} (including OUS): <b>{{ runningTotalTYDays }}</b> of {{ maxDaysInTY }}.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div *ngIf="showNewStateVisitHelpText" class="col-12">
            <div class="invalid-feedback d-block">You have incomplete visits! Finish adding information before adding another or submitting.</div>
        </div>
        <div *ngIf="stateVisitsHaveErrors" class="col-12">
            <div class="invalid-feedback d-block">Some of your visits have errors. Please review and fix them.</div>
        </div>
        <div *ngIf="showOverlapError" class="col-12">
            <div class="invalid-feedback d-block">You have entered overlapping visits. Please review and fix your entries.</div>
        </div>
        <div *ngIf="showYearError" class="col-12">
            <div class="invalid-feedback d-block">You have entered a date not in the tax year ({{taxYear}}). Please review and fix your entries.</div>
        </div>
        <div *ngIf="statesIncomesVisitsMismatch" class="col-12">
            <div class="invalid-feedback d-block">The states you have entered above for visits do not match the states that you entered on the previous page for incomes in states {{ stateIncomeStateNames }}. Please review and fix your entries.</div>
        </div>
    </div>

    <div class="row my-2">
        <div *ngIf="showContinueButton || oneState" class="col-4">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmit()" [disabled]="stateVisitsHaveErrors || showOverlapError">Continue</button>
        </div>

    </div>

    <div *ngIf="showUnexpectedErrorMessage" class="col-12 my-2 alert alert-danger">
        <div>
            <p>An unexpected error has occurred while determining your residency status. We apologize for any inconvenience. If you would still like to 
            determine your residency status, please send us screenshots of your input to <b><a href="mailto:taxhelp@nrtaxhelp.org">taxhelp@nrtaxhelp.org</a></b>
            and we will assist in any way we can.</p>
        </div>
    </div>
 

</div>


